
/*
 *  Service Location Interface
 */

import { OverrideDetail } from "src/app/common/Models/common.models";

export interface SystemConfiguration {
    id: number;
    switchId: number;
    switch: string;
    switchType: string;
    value: string | boolean;
    isActive: boolean;
    moduleId: number;
    updated?: boolean;
}

export interface SettingsGrid<T> {
    dataheader: GridHeader[];
    datacolumn: any;
}

export interface ServiceLocationGrid {
    dataheader: GridHeader[];
    datacolumn: ServiceLocations[];
}

export interface ServiceLocations {
    id?: number;
    code: string;
    description: string;
    comments: string;
    listOrder: number;
    maxGuests: number;
}

export interface LocationDetail {
    location: ServiceLocations;
    serviceLocations: SpaServiceLinkLocation[];
}

export interface ServiceEquipments {
    id?: number;
    code: string;
    description: string;
    quantity: number;
}

export interface MedicalConditions {
    id?: number;
    code: string;
    description: string;
    warning: string;
    privateCondition: boolean;
}
export interface MedicalConditionsGrid {
    dataheader: GridHeader[];
    datacolumn: MedicalConditions[];
}

export interface ServiceEquipmentGrid {
    dataheader: GridHeader[];
    datacolumn: ServiceEquipments[];
}

export interface GridHeader {
    title: string;
    jsonkey: string;
}

export interface ServiceGroupGrid {
    dataheader: GridHeader[];
    datacolumn: ServiceGroup[];
}

export interface ServiceGroup {
    id?: number,
    code: string;
    description: string;
    listOrder: number;
}

export interface ClassGroup {
    id?: number,
    code: string;
    description: string;
    listOrder: number;
}

export interface GuestType {
    id?: number,
    code: string;
    description: string;
    listOrder: number;
}

export interface Relationship {
    id?: number,
    code: string;
    name: string;
    listOrder: number;
    isActive: boolean;
    propertyId?: number;
}

export interface ServiceAddOns {
    id?: number;
    addOnName: string;
    price: number;
    retailCategoryId: number;
    commissionable: boolean;
    retailItemId: number;
    isAvailableOnWeb: boolean;
}

export interface SpaServices {
    id: number,
    code: string;
    description: string;
    serviceGroupDescription?: string;
}

export interface AllPriceType {
    id: number;
    code: string;
    description: string;
}

export interface SpaServiceLocation {
    id: number;
    code: string;
    name: string;
    description: string;
    listOrder: number;
    selected?: boolean;
}

export interface ServicePriceTypeConfig {
    belongto: number;
    NumofPriceTypesAdded: any[];
    selectedItems: any[];
}

export interface ServiceEquipmentTypeConfig {
    belongto: number;
    NumofEquipTypesAdded: any[];
    selectedItems: any[];
}


export interface ServiceYield {
    yieldDate: any;
    serviceId: number;
    yield1BookingPercent: any;
    isYield1FlatAmount: boolean;
    yield1Value: any;
    yield2BookingPercent: any;
    isYield2FlatAmount: boolean;
    yield2Value: any;
    yield3BookingPercent: any;
    isYield3FlatAmount: boolean;
    yield3Value: any;
    available: number;
    sold: number;
    remaining: number;
    isClose: boolean;
}

export interface SpaService {
    id: number;
    serviceDetail: SpaServiceDetail;
    serviceAddOns: SpaServiceAddOn[];
    serviceLocations: SpaServiceLinkLocation[];
    serviceEquipments: SpaServiceEquipment[];
    serviceTherapists: SpaServiceTherapist[];
    serviceMedicalConditions: SpaServiceMedicalCondition[];
    servicePriceTypes: SpaServicePriceType[];
    serviceSeasonalDates: SpaServiceSesonalDate[];
    serviceAvailableDays: SpaServiceAvailableDays;
}

export interface SpaServiceDetail {
    id: number;
    code: string;
    description: string;
    serviceGroupId: string;
    effectiveFromDate: string;
    effectiveToDate: string;
    price: string;
    time: string;
    breakDownTime: string;
    listOrder: number;
    minimumAge: string;
    minimumGuest: string;
    maximumGuest: string;
    minimumStaff: string;
    maximumStaff: string;
    isAutoGratuity: string;
    isAutoServiceCharge: string;
    isCommissionable: string;
    priceType: string;
    comments: string;
    policy: string;
    cancellationPolicy: string;
    requireStaffAtCheckin: string;
    isOffset: string;
    isAvailableOnWeb: string;
    propertyId: string;
    outletId: string;
    retailItemId: number;
    colorCode: string;
    eFormDatas: SpaServiceEFormsData[];
}

export interface SpaServiceLinkLocation {
    id: number;
    serviceId: number;
    locationId: number;
}

export interface SpaServiceAddOn {
    id: number;
    serviceId: number;
    addOnId: number;
}

export interface SpaServiceEquipment {
    id: number;
    serviceId: number;
    equipmentId: number;
    quantity: number;
}

export interface SpaServiceTherapist {
    id: number;
    serviceId: number;
    therapistId: number;
    seniorityLevel: string;
    maximumNumberOfServices: number
}

export interface SpaServiceMedicalCondition {
    id: number;
    serviceId: number;
    medicalConditionId: number;
}

export interface SpaServicePriceType {
    id: number;
    serviceId: number;
    priceTypeId: number;
    price: number;
}

export interface SpaServiceSesonalDate {
    id: number;
    serviceId: number;
    fromDate: Date;
    toDate: Date;
    priceMon: any;
    priceTue: any;
    priceWed: any;
    priceThu: any;
    priceFri: any;
    priceSat: any;
    priceSun: any;
    seasonalDaysOverrideDetail: OverrideDetail[]
}

export interface SpaServiceEFormsData {
    eFormId: string;
    eFormName: string;
}

export interface SpaServiceAvailableDays {
    id: number;
    serviceId: number;
    typeName: string;
    isAvailableOnSunday: boolean;
    isAvailableOnMonday: boolean;
    isAvailableOnTuesday: boolean;
    isAvailableOnWednesday: boolean;
    isAvailableOnThursday: boolean;
    isAvailableOnFriday: boolean;
    isAvailableOnSaturday: boolean;
}

//Therapist Models
export interface Therapist {
    code: string;
    fname: string;
    lname: string;
    gender: string;
    doj: string;
    // level:
}


export interface AvailableDays {
    IsAvailableOnSunday: boolean
    IsAvailableOnMonday: boolean
    IsAvailableOnTuesday: boolean
    IsAvailableOnWednesday: boolean
    IsAvailableOnThursday: boolean
    IsAvailableOnFriday: boolean
    IsAvailableOnSaturday: boolean
}


export interface ThearapistDetails {
    Code: string;
    FirstName: string;
    LastName: string;
    Gender: string;
    DateOfHire: string;
    SeniorityLevel: string;
    AlsoKnownAs: string;
    HourlyRate: number;
    AllowGratuity: boolean;
    AllowServiceCharge: boolean;
    AllowCommission: boolean;
    listOrder: number;
    isActive: boolean;
    id?: any;
    commissionClassId?: number;
    displayOnAppointmentGrid: boolean;
}

export interface TherapistDetails {
    code: string;
    firstName: string;
    lastName: string;
    gender: string;
    dateOfHire: string;
    seniorityLevel: string;
    alsoKnownAs: string;
    hourlyRate: number;
    allowGratuity: boolean;
    allowServiceCharge: boolean;
    allowCommission: boolean;
    listOrder: number;
    id: number;
    isActive: boolean;

}

export interface ServiceDetails {
    code: string;
    description: string;
    serviceGroupId: number;
    IsActive: boolean;
}

export interface TherapistCertification {
    id?: number;
    serviceId: number;
    therapistId: number;
    seniorityLevel: number;
    maximumNumberOfServices: number;
}

export interface TherapistAddOn {
    id?: number;
    therapistId: number;
    addOnId: number;
}

export interface ComboOptions {
    Type: string;
    Description: string;
    Id: number;
}

export interface TherapistComboOptions {
    Type: string;
    Description: string;
    Id: number;
    Active: boolean;
}

export interface TherapistContact {
    Id?: number;
    TherapistId: number;
    Type: string;
    ContactLocationId: any;
    ContactDetail: string;
    Extension?: string;
}

export interface TherapistCommission {
    id?: number;
    therapistId: number;
    itemId: number;
    categoryId: number;
    itemDescription: string;
    locationDescription: string;
    classId: number;
    clerkId: number;
    flatAmount: any;
    percentageAmount: any;
    threshold: any;
    afterDiscount: boolean;
    onTotalRevenue: boolean;
    onItemOnly: boolean;
}



export interface PatchFormat {
    op: string;
    path: string;
    value: any;
}

export interface TherapistCertificationPatch {
    Id?: number;
    ServiceId: number;
    TherapistId: number;
    SeniorityLevel: number;
    MaximumNumberOfServices: number;
}
export interface TherapistSchedule {
    id?: number;
    name?: string,
    therapistId: number;
    scheduleDate: string;
    isOff: boolean;
    availableTime: any[];
    breakTime?: any[];
    comments: any;
}

export interface TherapistScheduleEditModel {
    therapistId: number;
    scheduleDate: string;
    isOff: boolean;
    oldTime: any;
    scheduleId: any;
    availableTime: any[];
    comments: any;
}


export interface CommissionDetails {

    id?: number;
    itemId: number;
    categoryId: number;
    itemDescription: string;
    locationDescription: string;
    classId: number;
    flatAmount: any;
    percentage: any;
    threshold: any;
    isAfterDiscount: boolean;
    isTotalRevenue: boolean;
    isItemOnly: boolean;
    productId: number;
    outletId: number;
}


export interface CommissionDetailsUI {
    id?: number;
    itemId: number;
    categoryId: number;
    itemDescription: string;
    locationDescription: string;
    classId: number;
    flatAmount: any;
    percentage: any;
    threshold: any;
    isAfterDiscount: boolean;
    isTotalRevenue: boolean;
    isItemOnly: boolean;
    commissionon: string;
    outletId: number;
}


export interface ScheduleData {
    id: number;
    day: string;
    startTime: string;
    endTime: string;
    onCall: boolean;
    Off: boolean;
    paid: boolean;
    unPaid: boolean;
    shortDay?: string;
    locationId?: number;
    breakTypeId?: number,
    breakTime?: string;
    disabled: boolean;
    comments: string;
}

export interface TherapistData {
    code: string;
    firstName: string;
    lastName: string;
    gender: string;
    dateOfHire: string;
    seniorityLevel: string;
    alsoKnownAs: string;
    allowGratuity: boolean;
    allowServiceCharge: boolean;
    allowCommission: boolean;
    isActive: boolean;
    listOrder: number;
    id: number;
}

export interface TherapistGridData {
    code: string;
    name: string;
    dateOfHire: string;
    listOrder: number;
    id: number;
    isActive: boolean;
}


export interface TherapistName {
    therapistname: string;
    onlineStatus: boolean;
}

export interface PackageDetail {
    id: number;
    code: string;
    description: string;
    startDate: string;
    endDate: string;
    packagePrice: number;
    maxDays: any;
    maxPeople: any;
    isSelectCompAtBooking: boolean;
    numberOfItems: number;
    isAutomaticRecap: boolean;
    isBookLunch: boolean;
    isActive: boolean;
    packagePolicy: string;
    cancellationPolicy: string;
    isDefaultCancelPolicy: boolean;
    autopick: any;
    emailCommunicationType: any;
    retailItemId: number;
    isAvailableOnWeb: boolean;
    numberOfRetailItems?: number;
    isTopCompAtBooking?: boolean;
}
export interface PackageYieldGroupDetails {
    packageId: number;
    yieldStartDate: string;
    yieldEndDate: string;
    available: number;
    packageClass: number;
    packageWeeklyPrices: PackageWeeklyPrices[];
}

export interface PackageWeeklyPrices {
    serviceId: number;
    retailItemId: number;
    weeklyPrice: string;
    packageYieldGroupDetailsId: number;
    componentIndex: number;
    isServiceComponent: boolean;
}

export interface PackageComponent {
    packageId: any;
    serviceId: number;
    retailItemId: any;
    quantity: number
    price: any;
    description: string;
    id: number;
    percentage: any;
    incrementDecrement: any;
    allowPriceChange: boolean;
    basePrice: any;
    isFixedComponent: boolean;
    serviceCharge?: any;
    gratuity?: any;
}

export interface PackageAvailableDays {
    packageId: number;
    typeName: string;
    isAvailableOnSunday: boolean;
    isAvailableOnMonday: boolean;
    isAvailableOnTuesday: boolean;
    isAvailableOnWednesday: boolean;
    isAvailableOnThursday: boolean;
    isAvailableOnFriday: boolean;
    isAvailableOnSaturday: boolean;
}

export interface PackageModel {
    id: number;
    vode: string;
    description: string;
    packagePrice: number;
    packagePolicy: string;
}

export interface RetailItemUIMapper {
    id: number;
    itemType: number;
    code: string;
    description: string;
    price: number;
    quantity: number;
    isActive: boolean;
    serviceId: number;
}
export interface ServiceUIMapper {
    serviceId: number;
}

export interface PackageYield {
    id: number;
    code: string;
    description: string;
    available: number;
    sold: number;
    remaining: number;
    packageClass: number;
    yieldDate: string;
    IsAvailableReadOnly?: boolean;
}

export interface PackageInfo {
    packageDetail: PackageDetail;
    packageComponents: PackageComponent[];
    availableDays: PackageAvailableDays;
    packageYields: PackageYield[];
    packageYieldGroupDetails: PackageYieldGroupDetails[];
    id: number;
}

export interface passwordMgmt {
    id: number;
    ruleName: string;
    status: boolean;
}

export interface LocationMaintenance {
    id: number;
    locationCode: string;
    locationDescription: string;
    beginsAt: string;
    endsAt: string;
    comments: string;
}

export interface PackageAppointmentDate {
    startDate: any;
    endDate: any;
}


export interface EquipmentMaintenance {
    id: number;
    equipmentCode: string;
    equipmentDescription: string;
    beginsAt: string;
    endsAt: string;
    comments: string;
}


export interface CustomField {
    Id: number
    columnName: string
    FieldName: string
    moveRecordFromClient: boolean
    RequiredOnAppointment: boolean
    DisplayOn: number
    customFieldValues: CustomFieldValue[]
}

export interface CustomFieldValue {
    id: number;
    CustomFieldId: number;
    Code: string;
    description: string;
    IsUtilized: boolean;
}



export interface BreakType {
    Code: string;
    Description: string;
    Color: string;
    IsUtilized: boolean;
}

export interface PriceType {
    Code: string;
    Description: string;
}

export interface PackageClass {
    Code: string;
    ClassName: string;
}


export interface CancelReason {
    ReasonCode: string;
    IsActive: boolean;
}

export interface TherapistUnavailability {
    id: number;
    therapistCode: string;
    therapistName: string;
    startDate: Date;
    endDate: Date;
    unavailabilityReason: string;
    services: any[];
}

export interface RetailOutlets {
    id: number;
    outletName: string;
    terminalId: string;
    active: CustomFieldValue[];
    outletNumber: number;
    CustomFieldValues: CustomFieldValue[]

}

export interface LinkCode {
    id: number;
    code: string;
    description: string;
    color: string;
    listOrder: number;
    status: any;
    startDate: any;
    endDate: any;
}

export interface SystemConfiguration {
    id: number;
    switchId: number;
    switch: string;
    switchType: string;
    value: string | boolean;
    isActive: boolean;
    moduleId: number;
}

export interface RoleConfiguration {
    headerData: HeaderData;
    count: number;
    viewNotAllowedCount: number;
}

export interface HeaderData {
    titleData: string;
    titledesc: string;
    details: Detail[];
}

export interface Detail {
    id: number;
    description: string;
    localeId: number;
    count: number;
    userClaims: UserClaim[];
}

export interface UserClaim {
    userClaimId: number;
    userRoleId: number;
    breakPointNumber: number;
    description: string;
    allow: boolean;
    view: boolean;
    viewOnlyAllowed: boolean;
}


export interface SystemConfiguration {
    id: number;
    switchId: number;
    switch: string;
    switchType: string;
    value: string | boolean;
    isActive: boolean;
    moduleId: number;
}


export interface SetupMenu {
    id: any;
    viewValue: string;
    callDesc: string;
    IsAuthorized: boolean;
    breakPointNumber?: number;
}

export interface PackageYielding {
    packageId: number;
    yieldDate: string;
    available: string;
    sold: number;
    remaining: string;
    packageClass: number;
}

export interface CascadeDropDownConfig {
    label: string,
    placeholder: string,
    dropdownError?: string,
    inputRequired: boolean,
    extendedInput: boolean,
    displayProperty: string,
    inputError?: string,
    inputDefaultValue?: string | number,
    addedItems: CascadeDropDownOptions[]
}

export interface CascadeDropDownOptions {
    options: any[],
    selectedValue: any,
    value: string | number
}

export interface CascadeDropDownInput {
    inputType: string;
    inputError?: string,
    inputDefaultValue?: string | number,
    inputPlaceholder: string,
}

export interface GatewayConfiguration {
    id?: number,
    payAgentURL: string,
    payGatewayID: string,
    isInterfaceActive: boolean
    isPartialPayAllowed: boolean
    isAVSActive: boolean,
    gatewayValues: GatewayValues[]
}

export interface GatewayValues {
    configurationId: number,
    configKey: string,
    configValue: string
}

export interface PropertySetting {
    id: number;
    propertyId: number;
    productId: number;
    propertyDate: string;
    productVersion: string;
    userProductVersion: string;
    activateRetailInterface: boolean;
    vatEnabled: boolean;
}

export interface PaymentConfiguration {
    propertyId: number;
    subPropertyId: number;
    outletId: number;
    configKey: string;
    configValue: string;
    isActive: boolean;
}

export interface UserSubPropertyAccess {
    userSubPropertyAccessID: number;
    userPropertyAccessID: number;
    userID: number;
    hasAccess: boolean;
    subPropertyID: number;
}

export interface PropertyConfiguration<T> {
    id: number;
    propertyId: number;
    productId: number;
    configurationName: string;
    configValue: T;
    defaultValue: T;
    lastModifiedBy: string;
    lastModifiedDate: Date | string;
}

export interface ItemSearchRequest {
    itemType: number;
    searchKey: string;
    requestId: string;
}

export interface ItemSearchBaseResponse {
    id: number;
    externalPOSId: number;
    itemNumber: string;
    itemDescription: string;
}

export interface ItemSearchResponse {
    requestId: string;
    retailItems: ItemSearchBaseResponse[];
}

export interface StaffScheduleRequest {
    therapistId: any;
    noOfWeeks: number;
    noOfInstance: number;
    startDate: any;
    isIncludeBreak: boolean;
    isOverwriteExisting: boolean;
    comments: any;
}
export enum IncDec {
    notSelected = 0,
    increment = 1,
    decrement = 2
}
