import { Component, OnInit, ViewEncapsulation, Input, SimpleChange, OnDestroy } from '@angular/core';
import { RetailTransactions, Host, ButtonType, RetailBreakPoint, Product, ReceiptType, ButtonOptions, RetailFunctionalities, OpenTransactionAction, ClientType, CustomFeeSourceType } from '../../../shared/globalsContant';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonVariablesService, TransactionStatus } from '../../../shared/service/common-variables.service';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { BaseResponse, OutletSubProperty, PayeeInfo, PaymentMethods, GolfPoints, Transaction, TransactionListData } from '../../../shared/business/shared.modals';
import { RedirectToModules, RetailUtilities } from '../../../shared/utilities/retail-utilities';
import { RetailLocalization } from '../../../common/localization/retail-localization';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { VoidReasonComponent } from '../void-reason/void-reason.component';
import { AlertMessagePopupComponent } from '../../../shared/alert-message-popup/alert-message-popup.component';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { RetailPropertyInformation } from '../../../common/services/retail-property-information.service';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { RetailTransactionService } from './retail.transactions.service';
import { SubPropertyModel, RetailItemDetail, RetailMenu, PrintType, MultiPackDetails, ClientMultipack } from '../../../../retail/retail.modals';
import { DisplayOption } from '../../retail-table/retail-table.component';
import { RetailSetupService } from '../../../../retail/retail-setup/retail-setup.service';
import { ShopBussinessService } from '../../shop-business.service';
import { ReceiptBusinessService } from '../../receipt/business/receipt-business.service';
import { dateRangeUIModel, ReportAPIOptions,LockerAssignmentDetails } from '../../shop.modals';
import { takeUntil } from 'rxjs/operators';
import { RetailService } from '../../../retail.service';
import { RetailSharedVariableService } from '../../../shared/retail.shared.variable.service';
import { RetailEventType, RetailEventParameters, VoidEventModel, DayPassEventModel } from '../../../shared/events/event.model';
import { retailPublisher } from '../../../shared/events/pubsub/retail.publishers';
import { RetailDataAwaiters } from '../../../shared/events/awaiters/retail.data.awaiters';
import { RetailValidationService } from '../../../shared/retail.validation.service';
import { DialogOverviewExampleDialog } from 'src/app/retail/shared/dialog-popup/dialogPopup-componenet';
import { MemberBusinessService } from 'src/app/retail/shared/business/Member-business.service';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { GiftCardBusiness } from 'src/app/retail/sytem-config/gift-cards/gift-cards.business';
import { ReprintAdvancedSearch } from '../../retail-table/reprint-advanced-search/reprint-advanced-search.component';
import { CaddyShackBusinessService } from '../../caddyshack/caddyshack-business.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { SettleRefundTransactionBusiness } from 'src/app/retail/shared/business/Settle-Refund-Transaction-business.service';
import { CMSBusinessService } from 'src/app/retail/shared/business/CMS-business.service';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { PropertySettingDataService } from 'src/app/retail/sytem-config/property-setting.data.service';
import { RetailRoutes } from 'src/app/retail/retail-route';
import { SaleChitBusinessService } from '../../salechit/salechit-business.service';
import { PaymentHistoryDetails, MemberPaymentHistory} from 'src/app/retail/shared/service/payment/payment-business.model';
import { Localization } from 'src/app/common/localization/localization';
import { Client } from '@microsoft/microsoft-graph-client';
import { ClientInfo, RetailItemType } from 'src/app/retail/shared/shared.modal'
import { FastReportBusinessService } from 'src/app/retail/retail-reports/fast-report/fastreport.business.service';
import { cloneDeep } from 'lodash';
import { PlayerCategory} from 'src/app/common/enums/shared-enums';
import { CancellationNoShowItemType, RWT_TransactionTypes,SpaPackageItemType } from 'src/app/retail/payment/PaymentFlowHelpers';
import { UI } from "src/app/common/data-magine/data-magine-config/data-magine-config.model";
import { DatamagineConfigBusiness } from "src/app/common/data-magine/data-magine-config/data-magine-config.business";
import { CommonDataAwaiters } from 'src/app/common/shared/events/awaiters/common.data.awaiters';
import * as GlobalConst from 'src/app/retail/shared/globalsContant';
import { TransactionService } from 'src/app/retail/shared/service/transaction-service/transaction.dataservice';
//import { PrintInformationType } from 'src/app/common/Models/printer-default-configuration.model';
@Component({
  selector: 'app-retail-transactions',
  templateUrl: './retail-transactions.component.html',
  styleUrls: ['./retail-transactions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailTransactionsComponent implements OnInit, OnDestroy {
  tableData: any;
  Categories: { "id": number; "name": string; "title": string; "filters": ({ id: string | number; name: string; "isAll": boolean; } | { id: string | number; name: string; isAll?: undefined; })[]; "filtered": any[]; }[];
  tableoptions: any;
  tableoptions_New: any;
  displayOptions: DisplayOption = {
    options: [],
    selectedValue: 0,
    showOutlet: false,
    showOutletInGrid: true,
    reprintDateFilterValues: { startDate: this.PropertyInfo.CurrentDate, endDate: this.PropertyInfo.CurrentDate }
  };

  @Input() LoadTransaction: boolean = true;

  transactions: any[] = [];
  templateName: string = 'VR';
  disbleSyncUpBtn: boolean;
  isOpenTrasactionList: boolean = true;
  captions: any;
  syncupText: string;
  transactionData: any[] = [];
  dataArrayCopy: any;
  service: any[];
  userConfigcaptions: any;
  voidReason: string;
  ArrayList: any = [];
  serviceId: number;
  LastSelectedId: number;
  FormGrp: UntypedFormGroup;
  selectedTicketNumber: string = '';
  selectedTransactionId: number;
  selectedTransactionClientName: string = '';
  selectedClientId: number;
  $destroyed: ReplaySubject<any> = new ReplaySubject(1);
  selectedOutlet: number[] = [];
  outlets: OutletSubProperty[] = [];
  selectedOutletId: number = 0;
  public filterDateDefault: Date = this.PropertyInfo.CurrentDate;
  selectedElem: any;
  callDesc: TransactionStatus = TransactionStatus.OPEN;
  ListId: RetailTransactions;
  transactionsToSyncPerCall = 20;
  memberpin: string = "";
  receiptComment: string = "";
  userList: any[] = [];
  PayeeSearchTypes = { member: 1, guest: 2 };
  isAdvancedSearchLoading = false;
  functionalities: { [key: string]: boolean } = {};
  defaultOutlet: number;
  selectedMemberCardNumber: string = '';
  shopScreenItemCount: number = 0;
  closeTransactions: number[] = [];
  transactionDate: Date;
  outletsBySubProperty: any;
  quickUserId: string = '';
  isMultipleLinkedItem: boolean = false;
  advSearchParams: ReprintAdvancedSearch;
  floatLabelNever: string;
  loaderCaptions: any;
  sigCapProduts: number[] = [Product.SPA, Product.GOLF, Product.RETAIL];
  dmConfig: UI.DmConfig;
  voidAPIRoute:string;
  isGiftReceiptEnabled: boolean = false;
  memberConfiguration: any;
  get RefundTicketFilterFlow() {
    return (this._shopservice.retailTransaction.dropdownState == RetailTransactions.returnwithticket && this._shopservice.isFromRefund && this._shopservice.RefundTicketTransactionIDs.length > 0);
  }
  constructor(public router: Router, public _shopservice: CommonVariablesService, private http: HttpServiceCall, private utils: RetailUtilities, public dialog: MatDialog,
    public localization: RetailLocalization, private breakPoint: BreakPointAccess, private Form: UntypedFormBuilder, public PropertyInfo: RetailPropertyInformation,
    public retailTransactionService: RetailTransactionService, private shopBusinessService: ShopBussinessService,
    public retailSetupService: RetailSetupService,
    private receiptService: ReceiptBusinessService,
    public _ams: RetailService,
    private _retailService: RetailSharedVariableService,
    private _memberService: MemberBusinessService,
    private retailValidationService: RetailValidationService,
    private retailFeatureFlagInfo: RetailFeatureFlagInformationService,
    private _giftCardBussinessService: GiftCardBusiness,
    private route: ActivatedRoute,
    private _caddyshackService: CaddyShackBusinessService,
    private _fastReportBusinessService: FastReportBusinessService,
    private commonUtils: QuickLoginUtilities,
    private _settleRefundTransBusiness: SettleRefundTransactionBusiness,
    private _cmsBusiness: CMSBusinessService,
    private _retailFunctionalityBusiness: RetailFunctionalityBusiness,
    private propertySettingDataService: PropertySettingDataService,
    private _saleChitBusiness: SaleChitBusinessService,
    private localize: Localization,
    private dataMagineConfigBusiness: DatamagineConfigBusiness,
    private transactionService: TransactionService
  ) {
    this.floatLabelNever = this.localization.setFloatLabelNever;
    this.transactionDate = this.PropertyInfo.CurrentDate;
    this._shopservice.GoToRetailTransaction = false;
    this.FormGrp = this.Form.group({
      searchtext: '',
    });
    this.transactionDate = this.PropertyInfo.CurrentDate;
    this.captions = this.localization.captions.shop;
    this.loaderCaptions = this.localization.captions;
    this.syncupText = this.captions.SyncUpOpenTransactions;
    this.userConfigcaptions = this.localization.captions.userConfig;
    if (Number(this.utils.GetPropertyInfo('ProductId')) != Product.PMS) {
      this.InitializeModifyPostedCommission();
    }
    this.defaultOutlet = this.PropertyInfo.GetDefaultOutlet();
  }

  IsViewOnly: boolean = false;

  async ngOnInit() {
    this.route.data.pipe(takeUntil(this.$destroyed)).subscribe((data) => {
      this.serviceId = data.id;
      this._shopservice.retailTransaction.dropdownState = data.id;
      //RWT is not applicable incase of IG Flow, Redirecting to OPEN Transaction
      if (this.PropertyInfo.UseRetailInterface && data.id == RetailTransactions.returnwithticket && !this.RefundTicketFilterFlow) {
        this.utils.RedirectTo(RedirectToModules.RetailTransactions);
        return;
      }
      this.LastSelectedId = data.id;
      this.MapRetailFunctionality();
      this.voidAPIRoute = this.retailFeatureFlagInfo.SkipPMAgent ? RetailRoutes.VoidTransactionV2 : "VoidTransaction";
      if (this.serviceId === RetailTransactions.returnwithticket || this.serviceId === RetailTransactions.reprintticket) {
        this.propertySettingDataService.GetShopItemListCountConfigurationSetting().then(x => {
          const config: any = x;
          if (config) {
            this.shopScreenItemCount = Number(config.value) || 10;
          }
        });
      }
    });
    this.service = [
      { id: RetailTransactions.opentransactions, viewValue: this.captions.OpenTransactions, callDesc: TransactionStatus.OPEN, IsAuthorized: true, breakPointNumber: RetailBreakPoint.ReOpenTransaction },
      { id: RetailTransactions.correctvoid, viewValue: this.captions.Correct_Void, callDesc: TransactionStatus.CLOSED, IsAuthorized: true },
      { id: RetailTransactions.returnwithticket, viewValue: this.captions.ReturnwithTicket, callDesc: TransactionStatus.CLOSED, IsAuthorized: true },
      { id: RetailTransactions.returnwithoutticket, viewValue: this.captions.ReturnwithoutTicket, callDesc: TransactionStatus.CLOSED, IsAuthorized: true },
      { id: RetailTransactions.modifypostedcommission, viewValue: this.captions.ModifyPostedCommissions, callDesc: TransactionStatus.CLOSED, IsAuthorized: true, breakPointNumber: RetailBreakPoint.ModifyPostedCommission },
      { id: RetailTransactions.reprintticket, viewValue: this.captions.reprintticket, callDesc: TransactionStatus.CLOSED, IsAuthorized: true, breakPointNumber: RetailBreakPoint.ReprintTicket }
    ];
    // Commented to avoid duplicate
    await this.loadInitialData();
    await this.onChange(this.serviceId, this.service.filter(x => x.id === this.serviceId));
    this._ams.loaderEnable.pipe(takeUntil(this.$destroyed)).subscribe(loader => {
      const loadingContainer = document.getElementById('cover-spin-retail');
      const loadingContainerMessage = document.getElementById('default-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';
          loadingContainerMessage.innerText = loader;
        }
        else {
          loadingContainer.style.display = 'none';
          loadingContainerMessage.innerText = '';
        }
      }
    });

    this.service = this.retailSetupService.MapIsAuthorizedRetailMenu(this.service);
    this.serviceId = this._shopservice.retailTransaction.dropdownState; // Default DropDown
    this.LastSelectedId = this.serviceId;
    if (this._shopservice.reOpenTransaction && this.retailValidationService.transactionLockId > 0) { // remove the lock if user comes back from shop screen during reopen transaction
      this.retailValidationService.RemoveTransactionLock(this.retailValidationService.transactionLockId);
    }
    if (!this._shopservice.isFromDayEnd) {
      this._shopservice.reOpenTransaction = this._shopservice.isReopenViewOnly = false;
    }
    // await this.loadOutlets();
    await this.receiptService.SetPropertyReceiptConfiguration();
		this.isGiftReceiptEnabled =this.receiptService.PropertyConfigurationModel?.configValue?.printGiftReceipt ?? false;
    this.receiptService.SetReceiptConfiguration(this.selectedOutletId);
    this._retailService.SelectedOutletId = this.selectedOutletId;
    this.memberConfiguration = JSON.parse(sessionStorage.getItem('memberConfiguration'));
  }

  MapRetailFunctionality() {
    this._retailFunctionalityBusiness.getRetailFunctionality().then(res => {
      this.functionalities = res;
      switch (this.serviceId) {
        case RetailTransactions.opentransactions:
          this.displayOptions.showOutlet = res[RetailFunctionalities.ShowOpenRetailTransactionSelectOutletField] || false;
          this.displayOptions.showOutletInGrid = res[RetailFunctionalities.ShowOutletFieldInOpenTransactionGrid] || false;
          break;
        case RetailTransactions.correctvoid:
          this.displayOptions.showOutlet = res[RetailFunctionalities.ShowCorrectOrVoidRetailTransactionSelectOutletField] || false;
          this.displayOptions.showOutletInGrid = res[RetailFunctionalities.ShowOutletFieldInCorrectVoidTransactionGrid] || false;
          break;
        case RetailTransactions.returnwithticket:
          this.displayOptions.showOutlet = res[RetailFunctionalities.ShowReturnWithTicketRetailTransactionSelectOutletField] || false;
          this.displayOptions.showOutletInGrid = res[RetailFunctionalities.ShowOutletFieldInReturnWithTicketTransactionGrid] || false;
          break;
        case RetailTransactions.returnwithoutticket:
          this.displayOptions.showOutletInGrid = res[RetailFunctionalities.ShowOutletFieldInReturnWithOutTicketTransactionGrid] || false;
          break;
        case RetailTransactions.reprintticket:
          this.displayOptions.showOutlet = res[RetailFunctionalities.ShowReprintRetailTransactionSelectOutletField] || false;
          break;
        default:
          this.displayOptions.showOutlet = false;
          break;
      }
      if (!this.displayOptions.showOutlet && this.defaultOutlet === 0 && !this.displayOptions.showOutletInGrid) {
        this._retailFunctionalityBusiness.getDefaultOutlet().then(x => {
          this.defaultOutlet = x;
          if (this.defaultOutlet > 0) {
            this.PropertyInfo.SetDefaultOutlet(this.defaultOutlet);
          }
        });
      }
    });
  }

  authorizeLandingScreen(menu: RetailMenu): void {
    // first screen alone should be blank if not authorized.
    if (!menu.IsAuthorized) {
      this.breakPoint.showBreakPointPopup(this.localization.captions.breakpoint[menu.breakPointNumber]);
    }
  }

  reprintDateRangeChange(event: dateRangeUIModel) {
    if (event.controlchanged === 'transactionDate') {
      this.transactionDate = event.value;
      return;
    }
    this.displayOptions.reprintDateFilterValues.startDate = event.dateRange.startDate;
    this.displayOptions.reprintDateFilterValues.endDate = event.dateRange.endDate;
  }

  public async getTransactionDetail(transactionId: number): Promise<Transaction> {
    let response: BaseResponse<Transaction> = await this.http.CallApiAsync<Transaction>({
      callDesc: "GetTransactionInfoById",
      host: Host.retailPOS,
      method: HttpMethod.Get,
      uriParams: { transactionId: transactionId }
    });
    return response.result;
  }

  OutletChangedEvt(e) {
    this.selectedOutletId = e.value;
    this._retailService.SelectedOutletId = e.value;
    let extraParams: OnchangeParams = { resetRePrintDate: false };
    this.onchangeLoad(this.callDesc, this.ListId, extraParams);
    this.receiptService.SetReceiptConfiguration(this.selectedOutletId);
  }

  async loadInitialData() {
    await this.loadOutlets();
    if (this._shopservice.retailTransaction.fromModifyCommission) {
      this.serviceId = this._shopservice.retailTransaction.dropdownState;
      this.getClosedTransactionbyDate(this.localization.ConvertDateToISODateTime(this.PropertyInfo.CurrentDate), RetailTransactions.modifypostedcommission);
      this.setOutletFilterData()
      this.isOpenTrasactionList = false;
      this._shopservice.retailTransaction.fromModifyCommission = false;
    }
    else if (!this.PropertyInfo.UseRetailInterface && this._shopservice.returnTicket.fromReturnTicket) {
      this.serviceId = this._shopservice.returnTicket.dropdownState;
      this.setOutletFilterData()
      this.isOpenTrasactionList = false;
      this._shopservice.returnTicket.fromReturnTicket = false;
    }
    else if (this.serviceId === RetailTransactions.opentransactions) {
      this._shopservice.retailTransaction.dropdownState = RetailTransactions.opentransactions;
      // if (this.serviceId === RetailTransactions.opentransactions && !this.breakPoint.CheckForAccess([RetailBreakPoint.ReOpenTransaction])) {
      //   return;
      // }
      this.IsViewOnly = this.breakPoint.IsViewOnly(this.service[0].breakPointNumber);
      // this.getAllTransactions(TransactionStatus.OPEN, this.selectedOutletId, this._shopservice.retailTransaction.dropdownState ? this._shopservice.retailTransaction.dropdownState : RetailTransactions.opentransactions, this.localization.ConvertDateToISODateTime(this.PropertyInfo.CurrentDate));
    }
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
    this._retailService.GoToRetailTransaction = false;
  }
  accessCheckAndAlert(menu: RetailMenu) {
    if (!menu.IsAuthorized) {
      this.breakPoint.showBreakPointPopup(this.localization.captions.breakpoint[menu.breakPointNumber]);
    }
  }
  getAllTransactions(status: TransactionStatus, outletId: number, retailTransactions: RetailTransactions, transactionDate?: string) {
    this.http.CallApiWithCallback<any>({
      host: Host.retailPOS,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetAllTransactions",
      uriParams: { outletId: outletId, status: status, transactionDate: transactionDate ? transactionDate : "null" },
      method: HttpMethod.Get,
      showError: true,
      extraParams: [status, retailTransactions]
    });
  }

  getClosedTransactionbyDate(transactionDate: string, retailTransactions: RetailTransactions) {
    this.http.CallApiWithCallback<any>({
      host: Host.retailPOS,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetClosedTransactionByDate",
      uriParams: { closedDate: transactionDate },
      method: HttpMethod.Get,
      showError: true,
      extraParams: [status, retailTransactions]
    });
  }

  validateGiftReceiptReprint(transactionId:number) {
    this.http.CallApiWithCallback<any>({
      host: Host.retailPOS,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: RetailRoutes.ValidateGiftReceiptReprint,
      uriParams: { transactionId: transactionId,},
      method: HttpMethod.Get,
      showError: false,
      extraParams: []
    });
  }

  isOutletDropDownRequired(serviceId: number): boolean {
    return (serviceId === RetailTransactions.opentransactions ||
      serviceId === RetailTransactions.reprintticket ||
      serviceId === RetailTransactions.returnwithticket ||
      serviceId === RetailTransactions.correctvoid);
  }
  async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): Promise<void> {
    switch (callDesc) {
      case "GetTransactionByRange":
      case "GetAllTransactions":
      case "GetClosedTransactionByDate":
      case "GetTransactionsForDay":
      case 'GetTransactions':
        this.quickUserId = sessionStorage.getItem('quickIdUser');
        this.ArrayList = [{}];
        this.ArrayList[0].dataheader = this.getDataHeader();
        if (!this.displayOptions.showOutletInGrid) {
          this.ArrayList[0].dataheader = this.ArrayList[0].dataheader.filter(x => x.title !== this.captions.Outlet);
        }
        let GuestIds, clerkInfo;
        let payeeInfo: PayeeInfo[] = [];
        if (callDesc == "GetAllTransactions" && this.serviceId === RetailTransactions.opentransactions && this.PropertyInfo.UseRetailInterface) {
          const tempResult: any = result.result;
          const mappedRes: any = this.IGOpenTransactionUIListModelMapper(tempResult);
          result.result = mappedRes;
        }
        if (this.RefundTicketFilterFlow && callDesc == "GetTransactions") {
          const tempResult: any = result.result;
          const mappedRes: any = this.TransactionAggregateToTransactionUIListModelMapper(tempResult);
          result.result = mappedRes;
        }
        if (result.result && this.serviceId !== RetailTransactions.modifypostedcommission) {
          if (callDesc === 'GetAllTransactions' && this.serviceId === RetailTransactions.opentransactions) {
            this.closeTransactions = await this.getClosedTransactions();
            (<any>result.result).forEach(tran => {
              tran['enableCloseTransaction'] = this.closeTransactions && this.closeTransactions.some(x => x === tran.id);
            });
          }
          GuestIds = (<any>result.result).map(x => x.guestId);
          if (GuestIds && GuestIds.length > 0) {
            GuestIds = GuestIds.filter((v, i, a) => a.indexOf(v) === i);
            GuestIds = GuestIds.filter(g => g > 0);
            payeeInfo = await RetailDataAwaiters.getPayeeDetails(GuestIds);
          }
          //clerkInfo = await this.getClerkInfo();
          let clerkIds : Number[] = (<any> result?.result)?.map(x => x.clerkId);
          let userIds : Number[] = clerkIds.concat((<any> result?.result)?.filter(x => x.voidedByClerkId != 0)?.map(x => x.voidedByClerkId));
          
          if(userIds.length > 0) {
          clerkInfo = await this.getUsersByIds(userIds);
          }  
        }

        this.ArrayList[0].datacolumn = this.FormGridData(result.result ? <any>result.result : [], payeeInfo, clerkInfo);
        this.dataArrayCopy = _.clone(this.ArrayList[0].datacolumn);
        let sortByDefault: string = "ticketNumber";
        if (this.serviceId == RetailTransactions.modifypostedcommission) {
          this.ArrayList[0].datacolumn = this.DataFilterBasedOnOutlet(this.dataArrayCopy);
        }
        this.populateTableInfo(false, false, this.serviceId, "", sortByDefault, "", true, this.displayOptions.showOutlet);
        if (extraParams && extraParams['action'] === 'Void') {
          this._ams.loaderEnable.next("");
          const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
            width: '305px',
            height: '300px',
            hasBackdrop: true,
            panelClass: 'small-popup',
            data: { headername: this.userConfigcaptions.wellDone, headerIcon: 'icon-success-icon', headerMessage: this.captions.SuccessfullyVoided, buttonName: this.userConfigcaptions.okay, type: 'message' },
            disableClose: true
          });
          let playerIds: number[] = [];
          playerIds.push(this._shopservice.selectedPayeeId);
          RetailDataAwaiters.PrintAgreement(Number(this.selectedTransactionId), playerIds);
          let transactionData = await this.getTransactionDetail(Number(this.selectedTransactionId));
          if (this.functionalities[RetailFunctionalities.PrintCaddyShackReceipt]) {
            this._caddyshackService.GenerateCaddyShack(this.selectedTicketNumber, this.selectedOutletId, ReceiptType.void, Number(this.selectedTransactionId), this.voidReason, null, transactionData ? transactionData.transactionData.caddyShackComment : null);
          }
          var availableRounds = 0;
          var IsMemberTransaction = false;
          if (extraParams["eventInfo"] && extraParams['eventInfo'] != undefined) {
            if (extraParams["eventInfo"].memberId || extraParams["eventInfo"].memberCardNumber) {
              let memInfo = await RetailDataAwaiters.getMemberInfo(extraParams["eventInfo"]?.memberId != "0" ? extraParams["eventInfo"].memberId : extraParams["eventInfo"]?.memberCardNumber, extraParams["eventInfo"].transactionDateTime)
              if (memInfo && memInfo.golfPoints) {
                const uniqueBucketCode: GolfPoints[] = [];
                memInfo.golfPoints.forEach((value, key) => {
                  if (uniqueBucketCode.find(x => x.bucketCode == value.bucketCode) == undefined) {
                    uniqueBucketCode.push(value);
                    availableRounds += value.eligibleRounds;
                  }
                });
                IsMemberTransaction = true;
              }
            }
          }
          this.receiptService.GenerateReceipt(this.selectedTicketNumber, this.selectedOutletId, ReceiptType.void,
            Number(this.selectedTransactionId), this.selectedMemberCardNumber, this.selectedTransactionClientName, this.voidReason, undefined,
            undefined, undefined, undefined, undefined, IsMemberTransaction, availableRounds, undefined, undefined, this.quickUserId);
        }
        break;

      case "GetTransactionDetails": let response = <any>result.result;
        if (this._shopservice.correctTransaction) {
          this._ams.loaderEnable.next("");
        }
        this._shopservice.isReturnWithoutTicket = response?.some((x) => x.isReturn);
        await this.loadTransactionDetails(response, extraParams[0]);
        break;

      case "VoidTransaction":
      case RetailRoutes.VoidTransactionV2:
        if (result && result.result) {
          let sourceType : Set<CustomFeeSourceType> = new Set<CustomFeeSourceType>();
          if(extraParams['transactionDetails']){
            sourceType = new Set(extraParams['transactionDetails'].filter(d => d.sourceType).map(data=>data.sourceType));
          } 
          const eventData: RetailEventParameters<VoidEventModel> =
            {
              data: {
                transactionId: extraParams['transactionId'],
                isDeposit: this._shopservice.HasDepoistItem(extraParams['transactionDetails']),
                ticketNumber: this.selectedTicketNumber,
                sourceType: sourceType.size > 0 ? sourceType?.values()?.next()?.value : null
              },
              eventType: RetailEventType.Void
            }
            retailPublisher.publishEvent(eventData);
            if (
              extraParams &&
              extraParams["action"] === "Void" &&
               extraParams["transactionDetails"].some(
                (t) => GlobalConst.CustomFeeSourceType.Package == t.sourceType
              )
            ) {
              var dayPassId = extraParams["transactionDetails"].find(
                (x) => x.sourceType == GlobalConst.CustomFeeSourceType.Package
              )?.sourceTypeId;
              if (dayPassId > 0) {
                RetailDataAwaiters.UpdateIsActiveForDayPass(dayPassId);
              }
            }
          if (extraParams && extraParams['action'] === 'Correct') {
            this._shopservice.correctTransaction = true;
            this._retailService.SelectedOutletId = this._shopservice.SelectedOutletId = this.selectedOutletId;
            this._shopservice.voidedTransactionId = extraParams['transactionId'];
            this._shopservice.voidedTicketNumber = this.selectedTicketNumber;
            this._shopservice.isDepositTransactionCorrection = this._shopservice.HasDepoistItem(extraParams['transactionDetails']);
            this.getTransactionDetails(extraParams['transactionId'], 'Correct', this._shopservice.ProductId);
            let isHotelGuest = (extraParams['eventInfo']?.playerCategoryId == PlayerCategory.HotelReservation);
            let isHotelSpaGuest=(extraParams['eventInfo']?.guesttype==ClientType.HotelReservation);            
            if (this.sigCapProduts.includes(this._shopservice.ProductId)) {
              this.dmConfig = await this.dataMagineConfigBusiness.GetDMConfig();
          }

          if(this.dmConfig && this.dmConfig.postCheckReceiptToDM){
            await this.receiptService.PostReceiptToDM(this.selectedTicketNumber, this.selectedOutletId, ReceiptType.void, Number(this.selectedTransactionId),this.selectedTransactionClientName,this.voidReason
            ,availableRounds,isHotelGuest,isHotelSpaGuest);
          }
            
          } else {
            await this.handleAfterVoid(extraParams);
            this.onAdvancedSearch(this.advSearchParams);
            this._shopservice.destroy();
          }
          this._shopservice.GetWebProxyHealthCheckStatusForPMSPostings();
        } else {
          this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(10714));
          this._shopservice.destroy();
        }
        break;

      case RetailRoutes.GetTransactionEmails:
        this._retailService.manualEmailId = (<any>result.result).map(x => x.emailId);
        this._retailService.orgEmailId = cloneDeep(this._retailService.manualEmailId);
        break;
      case RetailRoutes.ValidateGiftReceiptReprint:
        this.receiptService.GenerateReceipt(this.selectedTicketNumber, this.selectedOutletId , ReceiptType.reprint,
          Number(this.selectedTransactionId), undefined, this.selectedTransactionClientName, null, false, false,
          0, null, false, IsMemberTransaction, availableRounds, this.receiptComment, undefined, undefined, undefined, undefined, false,
          undefined, undefined, false, undefined, undefined, undefined, true);
    }
  }

  async handleAfterVoid(extraParams) {
    this._ams.loaderEnable.next("");
    this.utils.ToggleLoader(false);
    const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
      width: '305px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.userConfigcaptions.wellDone,
        headerIcon: 'icon-success-icon', headerMessage: this.captions.SuccessfullyVoided,
        buttonName: this.userConfigcaptions.okay, type: 'message'
      },
      disableClose: true
    });
    let playerIds: number[] = [];
    playerIds.push(this._shopservice.selectedPayeeId);
    RetailDataAwaiters.PrintAgreement(Number(this.selectedTransactionId), playerIds);
    let transactionData = await this.getTransactionDetail(Number(this.selectedTransactionId));
    if (this.functionalities[RetailFunctionalities.PrintCaddyShackReceipt]) {
      this._caddyshackService.GenerateCaddyShack(this.selectedTicketNumber, this.selectedOutletId,
        ReceiptType.void, Number(this.selectedTransactionId), this.voidReason, null, transactionData ?
        transactionData.transactionData.caddyShackComment : null);
    }
    var availableRounds = 0;
    var IsMemberTransaction = false;
    let isHotelGuest = (extraParams['eventInfo']?.playerCategoryId == PlayerCategory.HotelReservation);
    let isHotelSpaGuest=(extraParams['eventInfo']?.guesttype==ClientType.HotelReservation);
    const memberPayment: MemberPaymentHistory[] = [];
    if (extraParams["eventInfo"] && extraParams['eventInfo'] != undefined) {
      if ((extraParams["eventInfo"].memberId && extraParams["eventInfo"].memberId != "0") || (extraParams["eventInfo"].memberCardNumber != null && extraParams["eventInfo"].memberCardNumber != "0")) {
        var paymentRecords: any = await this._memberService.getACESPaymentRecords(extraParams["eventInfo"].id);
        let paymentReferenceIds = paymentRecords.map(x => x.PaymentReferenceId);
        let acesPaymentRecords: any[] = await this._memberService.GetACESPaymentRecordsByPaymentTransactionId(paymentReferenceIds);
        for (let x of acesPaymentRecords) {
          let memInfo = await RetailDataAwaiters.getMemberInfo(x.memberId == "0" ? x.memberCardNumber : x.memberId , extraParams["eventInfo"].transactionDateTime)
          if (memInfo && memInfo.golfPoints) {
            const uniqueBucketCode: GolfPoints[] = [];
            memInfo.golfPoints.forEach((value, key) => {
              if (uniqueBucketCode.find(x => x.bucketCode == value.bucketCode) == undefined) {
                uniqueBucketCode.push(value);
                availableRounds += value.eligibleRounds;
              }
            });
          }
          memberPayment.push({
            memberName: memInfo ? memInfo.firstName + " " + memInfo.lastName : "",
            golfPoints: availableRounds.toString(),
            arAccountNumber: x.arAccountNumber,
            memberCardNumber: x.memberId == "0" ? x.memberCardNumber : x.memberId,
            paymentReferenceId: x.paymentTransactionId
          });
          availableRounds = 0;
        }
        IsMemberTransaction = true
      }
    }
    this.receiptService.GenerateReceipt(this.selectedTicketNumber, this.selectedOutletId, ReceiptType.void,
      Number(this.selectedTransactionId), this.selectedMemberCardNumber, this.selectedTransactionClientName, this.voidReason, undefined,
      undefined, undefined, undefined, undefined, IsMemberTransaction, availableRounds, undefined, undefined, this.quickUserId, undefined, undefined,this.shopBusinessService.requireReceipts, memberPayment, undefined, false, undefined, isHotelGuest,isHotelSpaGuest);
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]) {
    switch (callDesc) {
      case 'GetAllTransactions':
      case "GetTransactionDetails": break;
      case "VoidTransaction":
      case RetailRoutes.VoidTransactionV2:
      case RetailRoutes.ValidateGiftReceiptReprint:
        if (callDesc == "VoidTransaction" || callDesc == RetailRoutes.VoidTransactionV2) {
          if (error?.errorCode == -5 || error?.errorCode == -6 || error?.errorCode == -7 || error?.errorCode == -8) {
            this.utils.ShowErrorMessage(this.localization.captions.common.Error, error?.errorDescription);
          }
          else if (error?.errorCode == -9) {
            const errorMessage = `${error.errorDescription}\n${this.captions.PartialVoidFailure}`
            this.utils.ShowErrorMessage(this.localization.captions.common.Error, errorMessage);
          }
          else {
            const errorMsg = error?.errorDescription ? error.errorDescription : this.localization.getUnexpectedErrorMessage();
            this.utils.ShowErrorMessage(this.localization.captions.common.Error, errorMsg);
          }
        }
        if(callDesc == RetailRoutes.ValidateGiftReceiptReprint){
          const errMsg = this.localization.getError(error.errorCode)
          this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
        }
        this._ams.loaderEnable.next("");
        this._shopservice.destroy();
        break;
    }
  }
  loadOutlets = async () => {
    this.outletsBySubProperty = await this.retailTransactionService.getUserOutletSubProperty();
    const outletSubPropertyResult: any = await this.outletsBySubProperty.toPromise();
    if (outletSubPropertyResult && outletSubPropertyResult.length > 1) {
      this.outlets = outletSubPropertyResult[1].result.filter(o => o.isActive);
    } else {
      this.outlets = [];
    }
    if (this._shopservice.SelectedOutletId > 0) {
      this.selectedOutletId = this._shopservice.SelectedOutletId;
    } else if (this.outlets && this.outlets.length > 0) {
      this.selectedOutletId = !this.displayOptions.showOutlet && this.defaultOutlet > 0 ? this.defaultOutlet : this.outlets[0].subPropertyID;
    }
  }

  async getRetailItemDetails(id: Number[]): Promise<RetailItemDetail[]> {
    let result = await this.http.CallApiAsync({
      callDesc: "GetRetailItemDetails",
      method: HttpMethod.Put,
      host: Host.retailManagement,
      body: id
    });
    if (result && result.result) { return <RetailItemDetail[]>result.result; }
  }

  async getClosedTransactions(): Promise<number[]> {
    const result = await this.http.CallApiAsync({
      callDesc: 'GetSettlementHistory',
      method: HttpMethod.Get,
      host: Host.retailPOS,
      uriParams: {
        transactionDate: this.localization.ConvertDateToISODateTime(this.PropertyInfo.CurrentDate),
        status: TransactionStatus.OPEN, outletId: this.selectedOutletId
      }
    });
    return result && result.result ? result.result as number[] : [];
  }

  async getClerkInfo(): Promise<any[]> {
    let result = await this.http.CallApiAsync({
      callDesc: "GetAllUsers",
      method: HttpMethod.Get,
      host: Host.authentication,
      uriParams: { tenantId: Number(this.utils.GetPropertyInfo('TenantId')) }
    });
    let response: any = result && result.result ? result.result : [];
    this.userList = response;
    return response;
  }

  async getUsersByIds(Ids: Number[]) {
    let response: BaseResponse<Transaction> = await this.http.CallApiAsync<Transaction>({
      callDesc: "GetAllUsersbyUserIds",
      host: Host.authentication,
      method: HttpMethod.Get,
      uriParams: { tenantId: this.utils.GetPropertyInfo('TenantId') },
      withQueryString: "ids",
      queryString: { key: "ids", value: Ids }
    });

    if (!response.successStatus) {
      let errMsg = this.localization.getError(response.errorCode);
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
    }
    return response.result;
  }

  FormGridData(transData: any, payeeInfo: PayeeInfo[], clerkInfo: any[], members: any[] = [], voidedByClerks: any[] = [], isCardNumberSearch: boolean = false, searchParam: string = '') {
    let result = [];
    transData = transData ? transData : [];
    if (this.RefundTicketFilterFlow) {
      transData = transData.filter(x => this._shopservice.RefundTicketTransactionIDs.some(y => y == x.id));
      this.displayOptions.restrictDataFilterChange = true;
    }
    for (let i = 0; i < transData.length; i++) {
      const element = transData[i];
      element.ticketNumber = Number(element.ticketNumber) ? Number(element.ticketNumber) : "";
      element.transactionDateTime = _.cloneDeep(element.transactionDate);
      element.transactionDate = this.serviceId == RetailTransactions.reprintticket ? this.localization.LocalizeShortDate(element.transactionDate) + ' ' + this.localization.LocalizeTime(element.transactionDate, true) : this.localization.LocalizeTime(element.transactionDate, true);
      element.arpost = false;
      let payee: PayeeInfo;
      if (payeeInfo && payeeInfo.length > 0) {
        payee = payeeInfo.find(x => x.id == transData[i].guestId);
        element.clientName = payee && transData[i].guestId > 0 ? payee.name : '';
        element.playerCategoryId = payee && transData[i].guestId > 0 ? payee.playerCategoryId : '';
        element.guesttype=payee && transData[i].guestId > 0 ?payee.guesttype:'';
        if ((element.memberId && Number(element.memberId) > 0) || (element.memberCardNumber != null && element.memberCardNumber != "0")) {
          element.memberName = element.clientName;
          element.clientName = '';
        }
      }
      
      if (members && members.length > 0) {
        const member = members.find(
          (x) =>
            Number(x.guestProfileId) === Number(transData[i].memberId) ||
            x.guestProfileId === transData[i].memberCardNumber
        );
        element.clientName = member ? '' : element.clientName;
        element.memberName = member ? member.name : element.memberName;
      }

      if (clerkInfo && clerkInfo.length > 0) {
        let clerk = clerkInfo.filter(x => x.userId == transData[i].clerkId);
        element.clerkId = (clerk && clerk.length > 0) ? clerk[0].userName : ""
        if (voidedByClerks == null || voidedByClerks?.length == 0) {
          if (this.serviceId === RetailTransactions.reprintticket ||
            this.serviceId === RetailTransactions.returnwithticket ||
            this.serviceId === RetailTransactions.correctvoid) {
            let clerk = clerkInfo.filter(x => x.userId == transData[i].voidedByClerkId);
            element.voidedByClerkId = (clerk && clerk.length > 0) ? clerk[0].userName : ""
          }
        }
      }
      if (voidedByClerks && voidedByClerks.length > 0) {
        if (this.serviceId === RetailTransactions.reprintticket ||
          this.serviceId === RetailTransactions.returnwithticket ||
          this.serviceId === RetailTransactions.correctvoid) {
          let clerk = voidedByClerks.filter(x => x.userId == transData[i].voidedByClerkId);
          element.voidedByClerkId = (clerk && clerk.length > 0) ? clerk[0].userName : ""
        }
      }
      if (isCardNumberSearch) {
        this.sortCardNumbersBySearch(element, searchParam);
      }
      result.push(element);
    }
    return result;
  }

  async onChange(id, data) {
    this.tableoptions_New = undefined;
    this.tableoptions = undefined;
    let selectedElem: any;
    if (id) {
      this.ListId = id;
      selectedElem = data;
      this.callDesc = data && data[0] && data[0].callDesc;
      let extraParams: OnchangeParams = { resetRePrintDate: true }
      this.onchangeLoad(this.callDesc, this.ListId, extraParams);
      let _breakPointNumber: number = this.service.find(s => s.id == this.serviceId).breakPointNumber;
      if (_breakPointNumber) {
        this.IsViewOnly = this.retailSetupService.retailSetupBreakPoints.find(bp => bp.breakPointNumber === _breakPointNumber) ? this.retailSetupService.retailSetupBreakPoints.find(bp => bp.breakPointNumber === _breakPointNumber).view : false;
      }
    }
    this._shopservice.retailTransaction.dropdownState = this.LastSelectedId;
    this.ListId = this.LastSelectedId;
    if (this.ListId == 7) {
      this._shopservice.retailTransaction.dropdownState = 3;
      this.isOpenTrasactionList = true;
      this.serviceId = RetailTransactions.opentransactions;
    }
    if (this.ListId == 8) {
      this._shopservice.modifyCommission.dropdownState = 8;
      this.isOpenTrasactionList = false;
      this.serviceId = RetailTransactions.modifypostedcommission;
    }
    if (this.serviceId != RetailTransactions.opentransactions) {
      this._shopservice.syncOpenTrasactionList = false;
    }
  }
  async onchangeLoad(callDesc, ListId, extraParams?: OnchangeParams) {
    switch (this.serviceId) {
      case RetailTransactions.opentransactions:
        this.isOpenTrasactionList = callDesc == TransactionStatus.OPEN
        this.getAllTransactions(callDesc, this.selectedOutletId, ListId, this.localization.ConvertDateToISODateTime(this.PropertyInfo.CurrentDate));
        break;

      case RetailTransactions.correctvoid:
        this.isOpenTrasactionList = callDesc == TransactionStatus.OPEN
        await this.formTableData([], [], [], [], []);
        break;

      case RetailTransactions.returnwithticket:
        if (!this.ValidateAccess(this.captions.Return)) {
          this.serviceId = this.LastSelectedId;
          return;
        }
        this.isOpenTrasactionList = false;
        if (this.RefundTicketFilterFlow) {
          this.InvokeServiceCall("GetTransactions", HttpMethod.Put, null, this._shopservice.RefundTicketTransactionIDs);
        } else {
          await this.formTableData([], [], [], [], []);
        }
        break;

      case RetailTransactions.returnwithoutticket:
        if (!this.ValidateAccess(this.captions.ReturnwithoutTicket)) {
          this.serviceId = this.LastSelectedId;
          return;
        }
        this._shopservice.retailTransaction.tabState = 0;
        this._shopservice.selectedProducts = [];
        this._shopservice.ticketDiscount = null;
        this._shopservice.isReturnItem = true;
        this._shopservice.isReturnWithoutTicket = true;
        this._shopservice.selectedPayeeId = 0;
        this.router.navigate(['/shop/viewshop/retailitems']);
        break;

      case RetailTransactions.modifypostedcommission:
        if (!this.ValidateAccess(this.captions.ModifyPostedCommissions)) {
          this.serviceId = this.LastSelectedId;
          return;
        }
        let transactionDate: string = this.localization.ConvertDateToISODateTime(this.PropertyInfo.CurrentDate)
        this.getClosedTransactionbyDate(transactionDate, ListId);
        this.setOutletFilterData();
        break;
      case RetailTransactions.reprintticket:
        if (extraParams && extraParams.resetRePrintDate) {
          this.displayOptions.reprintDateFilterValues.startDate = this.PropertyInfo.CurrentDate;
          this.displayOptions.reprintDateFilterValues.endDate = this.PropertyInfo.CurrentDate;
        }
        this.isOpenTrasactionList = callDesc === TransactionStatus.OPEN;
        await this.formTableData([], [], [], [], []);
        break;
    }
    this.LastSelectedId = this.serviceId;
  }
  private getTransactionDetailsByDateRange(startDate: Date, endDate: Date, outletId: number): void {
    let toAPI = this.localization.ConvertDateToISODateTime;
    let params = {
      status: TransactionStatus.CLOSED, outletId: outletId,
      startDate: toAPI(startDate), endDate: toAPI(endDate)
    };
    this.InvokeServiceCall('GetTransactionByRange', HttpMethod.Get, params);
  }


  populateTableInfo(draggable: boolean, blnPagination: boolean, selectedSettingID: number, checkType: string, sortedByDefault: string, lastModifiedData: any, isInitial?: boolean, showOutlet?: boolean): void {
    this.tableoptions = [{
      TableHdrData: this.ArrayList[0].dataheader,
      TablebodyData: this.ArrayList[0].datacolumn,
      ServiceId: this.serviceId,
      pagination: blnPagination,
      sortable: true,
      CustomColumn: true,
      TableSearchText: "",
      PlaceHoldertext: this.serviceId === RetailTransactions.opentransactions
        ? this.captions.searchTextOpenTransaction : this.captions.searchTextRetailTransaction,
      EnableActions: false,
      TableDraggable: draggable,
      SelectedSettingId: selectedSettingID,
      Sortable: sortedByDefault,
      SelectRows: false,
      NewData: lastModifiedData ? lastModifiedData.NewData : {},
      isInitial: isInitial,
      Searchable: true,
      EditMoreOption: true,
      disableDelete: false,
      IsViewOnly: this.IsViewOnly
    }];

    this.displayOptions.options = (this.outlets) ? this.outlets.map(o => ({ id: o.subPropertyID, value: o.subPropertyName })) : [];
    this.displayOptions.selectedValue = this.selectedOutletId;
    this.displayOptions.showOutlet = showOutlet;

    let header = [{ "title": this.captions.TicketNumber, "jsonkey": "ticketNumber", "type": "clickable", "alignType": "right", "searchable": true },
    { "title": this.captions.Time, "jsonkey": "transactionDate", "alignType": "left", "searchable": false, "sortcolumn": "transactionDateTime", "sortcolumndatatype": "Date" },
    { "title": this.captions.Outlet, "jsonkey": "outletName", "alignType": "left", "searchable": false },
    { "title": `${this.captions.Amount} (${this.localization.currencySymbol})`, "jsonkey": "totalAmount", "alignType": "right", "datatype": "money", "sortcolumndatatype": "number", "searchable": false }];
    if (Number(this.utils.GetPropertyInfo('ProductId')) == Product.PMS) {
      header.splice(2, 1);
    }
    this.tableoptions_New = [{
      TableHdrData: header,
      TablebodyData: this.ArrayList[0].datacolumn,
      ServiceId: this.serviceId,
      pagination: blnPagination,
      sortable: true,
      CustomColumn: true,
      TableSearchText: this.FormGrp.value.searchtext,
      PlaceHoldertext: this.captions.searchTextRetailTransactionTab1,
      EnableActions: false,
      TableDraggable: draggable,
      InactiveTherapists: checkType == 'T' ? true : false,
      InactiveService: checkType == 'S' ? true : false,
      SelectedSettingId: selectedSettingID,
      Sortable: sortedByDefault,
      SelectRows: false,
      NewData: lastModifiedData ? lastModifiedData.NewData : {},
      isInitial: isInitial,
      Searchable: false,
      EditMoreOption: false,
      disableDelete: false
    }]
  }

  getEmailByTransactionId(transactionId:number)
  {
    let params = {
      transactionId : transactionId
    };
    this.InvokeServiceCall(RetailRoutes.GetTransactionEmails,HttpMethod.Get,params);
  }

  async EditRecords(event) {
    let action: string = event[3];
    let voidtransactionpayments: any[] = [];
    const memberPayment: MemberPaymentHistory[] = [];
    if (!this.ValidateAccess(action)) {
      return;
    }

    if (action == this.captions.Reopen || action == this.captions.Settle) {
      let isSettleReOpenViewOnly: boolean = this.breakPoint.IsViewOnly(RetailBreakPoint.ReOpenTransaction);
      if (isSettleReOpenViewOnly) {
        this.breakPoint.showBreakPointPopup(this.localization.captions.breakpoint[RetailBreakPoint.ReOpenTransaction]);
        return;
      }
    }

    const hasreturnItems = event[0].transactionDetails.some(t => t.isReturn);
    const isReturnTicket = hasreturnItems || RWT_TransactionTypes.includes(event[0].retailTransactionType);
    const transactionType = event[0].retailTransactionType == RWT_TransactionTypes[0] ? this.localization.captions.shop.ReturnwithoutTicket
      : this.localization.captions.shop.ReturnwithTicket;
    if (isReturnTicket && action === this.captions.Void) {
      this.utils.ShowErrorMessage(
        this.localization.captions.common.Error
        , this.localization.replacePlaceholders(this.localization.captions.common.VoidNotAllowedForReturn, ["transactionType"], [transactionType])
        , ButtonType.Ok
      );
      return;
    }

    if (isReturnTicket && action === this.captions.Correct) {
      this.utils.ShowErrorMessage(
        this.localization.captions.common.Error
        , this.localization.replacePlaceholders(this.localization.captions.common.CorrectNotAllowedForReturn, ["transactionType"], [transactionType])
        , ButtonType.Ok);
      return;
    }

    //CancellationNoShowFeeValidation
    const hasCancellationOrNoShowItem = event[0].transactionDetails.some(t => CancellationNoShowItemType.includes(t.itemType));
    if (hasCancellationOrNoShowItem && (action === this.captions.Void || action === this.captions.Correct || action === this.captions.Return)) {
      const itemType = event[0].transactionDetails.some(t => t.itemType == RetailItemType.CancellationFee) ? this.localization.captions.retailsetup.cancellationFee
        : this.localization.captions.retailsetup.noShowFee;
      this.utils.ShowErrorMessage(
        this.localization.captions.common.Error
        , this.localization.replacePlaceholders(this.localization.captions.shop.CancellationNoShowFeeCorrectVoidNotAllowed, ["transactionType", "itemType"], [action, itemType])
        , ButtonType.Ok
      );
      return;
    }
     //SpaPackageValidationForCorrect
     const hasPackageItem = event[0].transactionDetails.some(
       (t) => GlobalConst.CustomFeeSourceType.Package == t.sourceType
     );
     if (hasPackageItem && action === this.captions.Correct) {
       const daypassId = event[0]?.transactionDetails?.find(
         (x) => x.sourceType == GlobalConst.CustomFeeSourceType.Package
       )?.sourceTypeId;
       if (daypassId > 0) {
         const itemType = this.localization.captions.retailsetup.spaPackage;
         this.utils.ShowErrorMessage(
           this.localization.captions.common.Error,
           this.localization.replacePlaceholders(
             this.localization.captions.shop.SpaPackageCorrectNotAllowed,
             ["transactionType", "itemType"],
             [action, itemType]
           ),
           ButtonType.Ok
         );
         return;
       }
     }

     //SpaPackageValidationForVoidAllowsOnlyScheduleChange
     if (
       hasPackageItem &&
       (action === this.captions.Void || action === this.captions.Return)
     ) {
       const daypassId = event[0]?.transactionDetails?.find(
         (x) => x.sourceType == GlobalConst.CustomFeeSourceType.Package
       )?.sourceTypeId;
       if (daypassId > 0) {
         const itemType = this.localization.captions.retailsetup.spaPackage;
         var appointmentStatus =
           await RetailDataAwaiters.CheckAppointmentStatusByDayPassId(
             daypassId
           );
         if (appointmentStatus) {
           this.utils.ShowErrorMessage(
             this.localization.captions.common.Error,
             this.localization.replacePlaceholders(
               this.localization.captions.shop.SpaPackageVoidNotAllowed,
               ["transactionType", "itemType"],
               [action, itemType]
             ),
             ButtonType.Ok
           );
           return;
         }
       }
     }

    //Validation to restrict Deposit refunds which are already credited as part of Cancellation / NoShow / Streamline deposit refund flows
    const hasDepositItem = event[0].transactionDetails.some(t => t.itemType == RetailItemType.Deposit);
    if (hasDepositItem && (action === this.captions.Void || action === this.captions.Correct || action === this.captions.Return)) {
      this.utils.ToggleLoader(true);
      const dependentTransactionExists = await this.http.CallApiAsync<boolean>({
        callDesc: RetailRoutes.ValidateDependentTransactionStatus,
        host: Host.retailPOS,
        method: HttpMethod.Get,
        uriParams: { transactionId: event[0].id }
      });
      this.utils.ToggleLoader(false);
      if (dependentTransactionExists?.result) {
        this.utils.ShowErrorMessage(
          this.localization.captions.common.Error
          , this.localization.replacePlaceholders(this.localization.captions.shop.DepositRefundNotAllowedMsg, ["transactionType"], [action])
          , ButtonType.Ok
        );
        return;
      }
    }

    //MultiPack redemption Returns / Correct / Void Restriction
    if ((action === this.captions.Void || action === this.captions.Correct || action === this.captions.Return)
      && event[0].transactionDetails.some(x => (x.clientMultiPackRedeemId > 0 || x.itemDescription.includes(this.localization.captions.shop.RedeemingMultipack)))) {
      this.utils.ToggleLoader(true);
      const clientMultipack = await this.http.CallApiAsync<ClientMultipack[]>({
        callDesc: RetailRoutes.ClientMultiPacksByTransactionIds,
        host: Host.retailPOS,
        method: HttpMethod.Put,
        body: [event[0].id]
      });
      console.log(clientMultipack);
      this.utils.ToggleLoader(false);
      if (clientMultipack?.result && clientMultipack.result.length == 0) {
        this.utils.ShowErrorMessage(
          this.localization.captions.common.Error
          , this.localization.replacePlaceholders(this.localization.captions.shop.MultiPackRedemptionCorrectVoidReturnNotAllowed, ["transactionType"], [action])
          , ButtonType.Ok
        );
        return;
      }
    }

    const extraParams: any = { action: "", transactionDetails: event[0].transactionDetails, eventInfo: event[0] };
    this.selectedTicketNumber = event[0].ticketNumber;
    this.selectedTransactionId = event[0].id;
    const tempClientFalse = (event[0].memberId > 0 || event[0].memberCardNumber != null)  && event[0].memberName ? event[0].memberName : event[0].clientName;
    this.selectedTransactionClientName = event[0].clientId > 0 ? event[0].clientName : tempClientFalse;
    this.selectedClientId = event[0].clientId > 0 ? event[0].clientId : null;
    this.selectedMemberCardNumber = event[0].memberId ? (event[0].memberId != "0" ? event[0].memberId : event[0].memberCardNumber) : '';
    this._shopservice.retailTransaction.dropdownState = this.serviceId;
    this.receiptComment = event[0].receiptComment;
    this._shopservice.selectedTransaction = event[0];

    this._shopservice.selectedPayeeId = event[0].guestId;
    this._shopservice.transactionId = event[0].id ? event[0].id : 0;
    this._shopservice.ticketNumber = event[0].ticketNumber ? event[0].ticketNumber : "";
    this._shopservice.memberCardNumber = event[0].memberId ? (event[0].memberId != "0" ? event[0].memberId : event[0].memberCardNumber) : "0";
    this._shopservice.sourceCorpId = event[0].sourceCorpId ? event[0].sourceCorpId : 0;
    this._shopservice.ReceiptComment = event[0].receiptComment ? event[0].receiptComment : "";
    if (String(action) == this.captions.Correct || String(action) == this.captions.Void) {
      const response = await this._cmsBusiness.GetTransactionPaymentWithAdditionalDetails(event[0].id);
      voidtransactionpayments = response?.result;
    }
    switch (action.toString()) {
      case this.captions.CancelTransaction:
        let isReturn = event[0].transactionLinkId > 0
        let settlementHistory;
        let isRefundPaymentInitiated = false;
        this.ResetAttachedGuestInfo();
        if (isReturn) {
          settlementHistory = await this._settleRefundTransBusiness.getSettlementHistory(event[0]);
          isRefundPaymentInitiated = settlementHistory.some(x => !x.isReversed);
        }
        if (isRefundPaymentInitiated || !isReturn) {
          if (this.retailValidationService.CheckIfLinkedTransactionExists(event[0], OpenTransactionAction.Cancel)) { return; }
          var paymentHistoryDetails: PaymentHistoryDetails = await this.shopBusinessService.GetPaymentHistoryDetails(event[0].id);
          if ((paymentHistoryDetails && (paymentHistoryDetails?.paymentHistory?.length > 0 || paymentHistoryDetails?.isHavingPaymentHistory))) {
            const confirmationMsgForCancel = this.localization.replacePlaceholders(
              this.captions.CancelNotAllowed,
              ['TicketNumber'],
              [this.selectedTicketNumber]
            );
            this.utils.ShowErrorMessage(this.localization.captions.common.Error, confirmationMsgForCancel, ButtonType.Ok);
            this._shopservice.destroy();
            return;
          }
        }
        if (await this.retailValidationService.IsTransactionLocked(event[0].id)) {
          this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.captions.TransactionLock, ButtonType.Ok);
          break;
        }

        const confirmationMsg = this.localization.replacePlaceholders(
          this.captions.CancelOpenTransaction,
          ['TicketNumber'],
          [this.selectedTicketNumber]
        );
        this.utils.ShowErrorMessage(this.localization.captions.common.Warning, confirmationMsg, ButtonType.YesNo,
          this.CancelTransaction.bind(this), extraParams);
        break;

      case this.captions.Settle:
        this._shopservice.reOpenTransaction = false;
        this._shopservice.settleOpenTransaction = true;
        this._shopservice.isFromBuy = false;
        this.getEmailByTransactionId(event[0].id);
        if (this.retailValidationService.CheckIfLinkedTransactionExists(event[0], OpenTransactionAction.Settle)) {
          if (!this.PropertyInfo.UseRetailInterface) {
            await this._settleRefundTransBusiness.FormReturnedRetainedExchangedItems(event[0]);
          }
          return;
        }
        if (! await this.retailValidationService.ValidateSettleReopenAction(event[0].id, action, this.TransactionLockCallback.bind(this))) {
          return;
        }
        this.retailValidationService.LockTransaction(event[0].id);
        this.getTransactionDetails(event[0].id, action, this._shopservice.ProductId);
        break;

      case this.captions.Reopen:
        this.getEmailByTransactionId(event[0].id);
        if (this.retailValidationService.CheckIfLinkedTransactionExists(event[0], OpenTransactionAction.Reopen)) { return; }
        var paymentHistoryDetails: PaymentHistoryDetails = await this.shopBusinessService.GetPaymentHistoryDetails(event[0].id);
        if ((paymentHistoryDetails && (paymentHistoryDetails.paymentHistory.length > 0 || paymentHistoryDetails.isHavingPaymentHistory))) {
          const confirmationMsgForReopen = this.localization.replacePlaceholders(
            this.captions.ReOpenNotAllowed,
            ['TicketNumber'],
            [this.selectedTicketNumber]
          );
          this.utils.ShowErrorMessage(this.localization.captions.common.Error, confirmationMsgForReopen, ButtonType.Ok);
          this._shopservice.destroy();
          return;
        }
        this._shopservice.settleOpenTransaction = false;
        this._shopservice.reOpenTransaction = true;
        this._shopservice.isReopenViewOnly = this.breakPoint.IsViewOnly(RetailBreakPoint.ReOpenTransaction);

        if (! await this.retailValidationService.ValidateSettleReopenAction(event[0].id, action, this.TransactionLockCallback.bind(this))) {
          this._shopservice.selectedPayeeId = 0;
          return;
        }
        this.retailValidationService.LockTransaction(event[0].id);
        this.getTransactionDetails(event[0].id, action, this._shopservice.ProductId);
        break;

      case this.captions.Correct:
        if (!(await this.CheckIfVoidAllowed())) {
          this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.getError(500106));
          break;
        }
        if (await this.checkIfBEODeposit()) {
          this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.captions.CorrectTicketWarning, ButtonType.YesNo, this.PopUpCallBack.bind(this, extraParams));
          break;
        }
        if (this._shopservice.ProductId == Product.PMS && this._shopservice.memberCardNumber && this._shopservice.memberCardNumber != "0") {
          this.utils.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.captions.common.InfoProcess, ButtonType.Ok)
          this._shopservice.destroy();
          break;
        }

        if (voidtransactionpayments && voidtransactionpayments.length > 0) {
          if ((voidtransactionpayments.some(t => Number(t.transactionPayment?.paymentMethod) == PaymentMethods.CompRedemption) && !this.retailFeatureFlagInfo.AllowCompRedemptionVoid)
            || (voidtransactionpayments.some(t => Number(t.transactionPayment?.paymentMethod) == PaymentMethods.OfferRedemption && !this.retailFeatureFlagInfo.ValidateOfferRedemptionVoid(t.additionalInformation?.VendorType)))) {
            this.utils.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.captions.shop.CMS.CorrectMessage, ButtonType.Ok)
            this._shopservice.destroy();
            break;
          }
        }

        if (this._shopservice.ProductId == Product.GOLF && !(await this.VerifyRainCheckIssued(event))) {
          break;
        }
        if (!(await this.ValidateVoidCorrectAction(event[0]))) {
          this._shopservice.destroy();
          break;
        }
        extraParams['action'] = 'Correct';
        this._shopservice.reOpenTransaction = true;
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.captions.CorrectTicketWarning, ButtonType.YesNo, this.PopUpCallBack.bind(this, extraParams));
        break;

      case this.captions.Return:
        if (this._shopservice.ProductId == Product.GOLF) {
          let returnonlyoriginalconfig = await this.shopBusinessService.getReturnOriginalPaymentConfigurationSetting();
          if (returnonlyoriginalconfig && returnonlyoriginalconfig.value === 'true') {
            let transactionpaymentDetails = await this.shopBusinessService.GetPaymentTransaction(event[0].id);
            if (transactionpaymentDetails && transactionpaymentDetails.some(t => Number(t.paymentMethod) == PaymentMethods.RainCheck && t.paymentStatus == 1 && !t.isRefunded)) {
              this.utils.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.captions.common.RefundTeeTimeWithRainCheckRedeemed, ButtonType.Ok)
              this._shopservice.destroy();
              break;
            }
          }
        }
        if (this._shopservice.ProductId == Product.GOLF && !(await this.VerifyRainCheckIssued(event))) {
          break;
        }
        if (await this.checkIfBEODeposit()) {
          this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.getError(500111));
          break;
        }
        this._shopservice.sectionName = "RTID";//Retail transaction Item Detail
        this._shopservice.settleOpenTransaction = false;
        this._shopservice.reOpenTransaction = false;
        this._shopservice.selectedRetailItem = event[0];
        this._shopservice.isReturnWithoutTicket = false;
        this._retailService.SelectedOutletId = this._shopservice.SelectedOutletId = (this.selectedOutletId || event[0].outletId);
        this._shopservice.selectedTransactionRetailProducts = [];
        let isTransactionLocked = await this.retailValidationService.IsTransactionLocked(event[0].id);

        if (isTransactionLocked) {
          this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.captions.TransactionLockWarning + " - " + this.retailValidationService.lockedUserName, ButtonType.Ok, this.TransactionLockCallback.bind(this), [event[0].id, action]);
          return;
        }
        if (!this.retailValidationService.isLockedCurrentUser) {
          this.retailValidationService.LockTransaction(event[0].id);
        }
        this.getTransactionDetails(event[0].id, action, this._shopservice.ProductId);
        break;

      case this.captions.Void:
        if (!(await this.CheckIfVoidAllowed())) {
          this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.getError(500105));
          break;
        }
        if (await this.checkIfBEODeposit()) {
          this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.getError(500305));
          break;
        }

        this.ResetAttachedGuestInfo();
        if (voidtransactionpayments && voidtransactionpayments.length > 0) {
          if ((voidtransactionpayments.some(t => Number(t.transactionPayment.paymentMethod) == PaymentMethods.CompRedemption) && !this.retailFeatureFlagInfo.AllowCompRedemptionVoid)
            || (voidtransactionpayments.some(t => Number(t.transactionPayment.paymentMethod) == PaymentMethods.OfferRedemption && !this.retailFeatureFlagInfo.ValidateOfferRedemptionVoid(t.additionalInformation.VendorType)))) {
            this.utils.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.captions.shop.CMS.VoidMessage, ButtonType.Ok)
            this._shopservice.destroy();
            break;
          }
        }
        if (this._shopservice.ProductId == Product.GOLF && !(await this.VerifyRainCheckIssued(event))) {
          break;
        }
        if (!(await this.ValidateVoidCorrectAction(event[0]))) {
          this._shopservice.destroy();
          break;
        }
        extraParams['action'] = 'Void';
        this.voidOrCorrectWithQuickLogin(extraParams);
        break;
      case RetailTransactions.reprintticket.toString():
      case RetailTransactions.PrintGiftReceipt.toString():
        this.ResetAttachedGuestInfo();
        let playerIds: number[] = [];
        playerIds.push(event[0]?.guestId);
        this.reprintCaddyShack();
        RetailDataAwaiters.PrintAgreement(event[0].id, playerIds);
        let isHotelGuest = (event[0]?.playerCategoryId == PlayerCategory.HotelReservation);
        let isHotelSpaGuest =(event[0]?.guesttype == ClientType.HotelReservation);
        let printGiftReceipt = action.toString() === RetailTransactions.PrintGiftReceipt.toString() ? true : false;
        //Feature 57221: Cart, Shoe, Club Agreement ,Retail sale Chit , Retail sale chit Not Implemented 
        //Uncomment to Print Sale Chit
        //this.printRetailSalechit(event[0],true);
        var availableRounds = 0;
        var IsMemberTransaction = false;
        
        if ((event[0].memberId && event[0].memberId != "0") || (event[0].memberCardNumber != null && event[0].memberCardNumber != "0")) {
          if (this._memberService.IsShowCurrentRoundsOnReprint) {
            var paymentRecords: any = await this._memberService.getACESPaymentRecords(event[0].id);
            let paymentReferenceIds = paymentRecords.map(x => x.PaymentReferenceId);
            let acesPaymentRecords: any[] = await this._memberService.GetACESPaymentRecordsByPaymentTransactionId(paymentReferenceIds);
            for (let x of acesPaymentRecords) {
              let memInfo = await RetailDataAwaiters.getMemberInfo(x.memberId == "0" ? x.memberCardNumber : x.memberId, event[0].transactionDateTime)
              if (memInfo && memInfo.golfPoints) {
                const uniqueBucketCode: GolfPoints[] = [];
                memInfo.golfPoints.forEach((value, key) => {
                  if (uniqueBucketCode.find(x => x.bucketCode == value.bucketCode) == undefined) {
                    uniqueBucketCode.push(value);
                    availableRounds += value.eligibleRounds;
                  }
                });
              }
              memberPayment.push({
                memberName: memInfo ? memInfo.firstName + " " + memInfo.lastName : "",
                golfPoints: availableRounds.toString(),
                arAccountNumber: x.arAccountNumber,
                memberCardNumber: x.memberId == "0" ? x.memberCardNumber : x.memberId,
                paymentReferenceId: x.paymentTransactionId
              });
              availableRounds = 0;
            }
            IsMemberTransaction = true
          }
          else {
            var paymentRecords: any = await this._memberService.getACESPaymentRecords(event[0].id);
            let paymentReferenceIds = paymentRecords.map(x => x.PaymentReferenceId);
            let acesPaymentRecords: any[] = await this._memberService.GetACESPaymentRecordsByPaymentTransactionId(paymentReferenceIds);

            acesPaymentRecords.forEach(x => {
              memberPayment.push({
                memberName: x.memberName == null || x.memberName == "" ? this.captions.NotAvailable : x.memberName,
                golfPoints: x.availableRounds == null || x.availableRounds == 0 ? this.captions.NotAvailable : x.availableRounds.toString(),
                arAccountNumber: x.arAccountNumber,
                memberCardNumber: x.memberId == "0" ? x.memberCardNumber : x.memberId,
                paymentReferenceId: x.paymentTransactionId
              })
            });
            IsMemberTransaction = true;
          }
        }

        if (printGiftReceipt) {
          await this.validateGiftReceiptReprint(Number(this.selectedTransactionId));
        }
        else {
          this.receiptService.GenerateReceipt(this.selectedTicketNumber, this.selectedOutletId || event[0].outletId, ReceiptType.reprint,
            Number(this.selectedTransactionId), event[0].memberId == "0" ? event[0].memberCardNumber : event[0].memberId, this.selectedTransactionClientName, null, false, false,
            0, null, false, IsMemberTransaction, availableRounds, this.receiptComment, undefined, undefined, undefined, undefined, false,
            memberPayment, undefined, false, undefined, isHotelGuest, isHotelSpaGuest, printGiftReceipt);
        }

        
        break;
      case this.captions.notify:
        this.ResetAttachedGuestInfo();
        RetailDataAwaiters.OpenManualNotifyPopup(this.selectedTransactionId, this._shopservice.selectedPayeeId);
        break;
      case this.captions.Close:
        this.shopBusinessService.CheckIfcloseTransactionllowed(this.selectedTransactionId, this.selectedTicketNumber)
          .then(async x => {
            this.ResetAttachedGuestInfo();
            await this.CommentsPopup()
          }).catch()
        break;
      case this.captions.emailreceipt:
        
        let clientinfo: ClientInfo = {
          transactionId : event[0].id,
          reportQuery: null,
          playerId: [],
          appointmentId:[]
        };
        if (event[0].memberId && event[0].memberId != "0" || (event[0].memberCardNumber != null && event[0].memberCardNumber != "0")) {
          if (this._memberService.IsShowCurrentRoundsOnReprint) {
            var paymentRecords: any = await this._memberService.getACESPaymentRecords(event[0].id);
            let paymentReferenceIds = paymentRecords.map(x => x.PaymentReferenceId);
            let acesPaymentRecords: any[] = await this._memberService.GetACESPaymentRecordsByPaymentTransactionId(paymentReferenceIds);
            for (let x of acesPaymentRecords) {
              let memInfo = await RetailDataAwaiters.getMemberInfo(x.memberId == "0" ? x.memberCardNumber : x.memberId, event[0].transactionDateTime)
              if (memInfo && memInfo.golfPoints) {
                const uniqueBucketCode: GolfPoints[] = [];
                memInfo.golfPoints.forEach((value, key) => {
                  if (uniqueBucketCode.find(x => x.bucketCode == value.bucketCode) == undefined) {
                    uniqueBucketCode.push(value);
                    availableRounds += value.eligibleRounds;
                  }
                });
              }
              memberPayment.push({
                memberName: memInfo ? memInfo.firstName + " " + memInfo.lastName : "",
                golfPoints: availableRounds.toString(),
                arAccountNumber: x.arAccountNumber,
                memberCardNumber: x.memberId == "0" ? x.memberCardNumber : x.memberId,
                paymentReferenceId: x.paymentTransactionId
              });
              availableRounds = 0;
            }
            IsMemberTransaction = true
          }
          else {
            var paymentRecords: any = await this._memberService.getACESPaymentRecords(event[0].id);
            let paymentReferenceIds = paymentRecords.map(x => x.PaymentReferenceId);
            let acesPaymentRecords: any[] = await this._memberService.GetACESPaymentRecordsByPaymentTransactionId(paymentReferenceIds);

            acesPaymentRecords.forEach(x => {
              memberPayment.push({
                memberName: x.memberName == null || x.memberName == "" ? this.captions.NotAvailable : x.memberName,
                golfPoints: x.availableRounds == null || x.availableRounds == 0 ? this.captions.NotAvailable : x.availableRounds.toString(),
                arAccountNumber: x.arAccountNumber,
                memberCardNumber: x.memberId == "0" ? x.memberCardNumber : x.memberId,
                paymentReferenceId: x.paymentTransactionId
              })
            });
            IsMemberTransaction = true;
          }
        }
        var clerkid = event[0].clerkId;
        const clerkInfo = await this.getClerkInfo();
        const currentUser = Number(this.localization.GetsessionStorageValue('_userInfo', 'userId'))
        const printedBy = clerkInfo.find(x=>x.userId==currentUser).userName;
        
        let isHotelReservation = (event[0]?.playerCategoryId == PlayerCategory.HotelReservation);
        let isHotelSpaReservation=(event[0]?.guesttype==ClientType.HotelReservation);

        const options: ReportAPIOptions =await this.receiptService.formReportAPIOptions(this.selectedTicketNumber, this.selectedOutletId || event[0].outletId, ReceiptType.reprint, Number(event[0].id), this.selectedTransactionClientName, null,
          false, false, 0, false, IsMemberTransaction, availableRounds, this.receiptComment, clerkid,
          false, this._shopservice.memberCardNumber.toString(), printedBy, '', false, '', memberPayment, isHotelReservation,isHotelSpaReservation);

        clientinfo.reportQuery = this._fastReportBusinessService.createAPIOptions(options);
        this.ResetAttachedGuestInfo();
        RetailDataAwaiters.SendNotification(clientinfo, event[4]?event[4]:[],false);
        break;
    }
  }
  printRetailSalechit(event, isRePrint) {
    let retailItems = event.transactionDetails;
    this._saleChitBusiness.getRetailSaleChit(event.id, retailItems.map(r => r.itemId), isRePrint, PrintType.Html);
  }
  async reprintCaddyShack() {
    let allProducts = [];
    allProducts = await RetailDataAwaiters.PrintCaddyShack(this.selectedTransactionId);
    if (allProducts.some(r => r.isPrintToCaddyShack)) {
      let transactionData = await this.getTransactionDetail(Number(this.selectedTransactionId));
      this._caddyshackService.GenerateCaddyShack(this.selectedTicketNumber, this.selectedOutletId, ReceiptType.void, Number(this.selectedTransactionId), this.voidReason, null, transactionData ? transactionData.transactionData.caddyShackComment : null);
    }
  }
  async CommentsPopup() {
    const dialogRef = this.dialog.open(VoidReasonComponent, {
      height: 'auto',
      width: '40%',
      data: { headername: this.localization.captions.shop.ReasonForClose, closebool: true },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
    dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(resultData => {
      if (resultData.action.toLowerCase() === 'ok') {
        this.shopBusinessService.closeTransaction(resultData.reason, this.selectedTransactionId, this.selectedTicketNumber).then(
          x => this.onChange(this.serviceId, this.service.filter(x => x.id === this.serviceId))
        )
      }
    });
  }

  async CheckIfcloseTransactionllowed() {
    const response = await this.http.CallApiAsync({
      callDesc: 'ValidateCloseTransaction',
      method: HttpMethod.Put,
      host: Host.retailPOS,
      uriParams: { transactionId: this.selectedTransactionId },
      showError: true
    }).catch(err => {
      this.CloseTransactionErrorHandler(err);
      throw err;
    });
    return response;
  }

  CloseTransactionErrorHandler(err) {
    if (this.http.ApiCallErrorHandler(err)) {
      let _errCode: number = (err.error != null && err.error.errorCode && !err.error.successStatus) ? err.error.errorCode : -2;
      let erroMsg = "";
      if (_errCode == -1) {
        const confirmationMsgForReopen = this.localization.replacePlaceholders(
          this.captions.UnableToCloseTransaction,
          ['TicketNumber'],
          [this.selectedTicketNumber]
        );
        erroMsg = confirmationMsgForReopen;
        this.http.ShowError(erroMsg, this.localization.captions.common.Error);
      }
      else {
        erroMsg = this.localization.getError(_errCode);
        const confirmationMsgForClose = this.localization.replacePlaceholders(
          erroMsg,
          ['TicketNumber'],
          [this.selectedTicketNumber]
        );
        this.http.ShowError(confirmationMsgForClose, this.localization.captions.common.Error);
      }
    }
    console.log(err);
  }

  async closeTransaction(comments: string) {
    const result = await this.http.CallApiAsync({
      callDesc: 'TryCloseTransaction',
      method: HttpMethod.Put,
      host: Host.retailPOS,
      body: comments,
      uriParams: { transactionId: this.selectedTransactionId },
      showError: true
    }).catch(err => {
      this.CloseTransactionErrorHandler(err);
      throw err;
    });
    await this.onChange(this.serviceId, this.service.filter(x => x.id === this.serviceId));
  }


  async CheckIfVoidAllowed(): Promise<boolean> {
    let result: boolean = true;
    result = await this.CheckVoidForMultiPack(this.selectedTransactionId);
    return result;
  }

  async checkIfBEODeposit(): Promise<boolean> {
    if (this._shopservice.ProductId == Product.SNC) {
      let result: boolean = true;
      result = await this.CheckIfDepositBEO(this.selectedTransactionId);
      return result;
    }
    else {
      return false;
    }

  }



  ResetAttachedGuestInfo() {
    this._shopservice.selectedPayeeId = 0;
    this._shopservice.memberCardNumber = "0";
    this._shopservice.sourceCorpId = 0;
    this._ams.labelRecords = [];
    this._ams.clientsearchArray = [];
  }

  async VerifyRainCheckIssued(event) {
    const transactionDetails = event[0].transactionDetails.map(x => x.id) || [];
    const transactionData = await RetailDataAwaiters.GetPlayerNamesByTransactionId(event[0].id);
    const currentTransPlayer = transactionDetails && transactionDetails.length ?
      transactionData.playerTransactionDetails.filter(x => transactionDetails.includes(x.transactionDetailId)) :
      transactionData.playerTransactionDetails;
    const rainCheckDetail = transactionData.rainCheckDetail;
    const playerIdList: Array<number> = Array.from(new Set([...currentTransPlayer.map(x => x.playerId)]));
    if (rainCheckDetail && rainCheckDetail.length && playerIdList.some(x => rainCheckDetail.findIndex(y => y.playerId === x) !== -1)) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Error,
        this.localization.captions.common.RefundTeeTimeWithRainCheckIssue, ButtonType.Ok)
      return false;
    }
    return true;
  }


  async TransactionLockCallback(result: string, extraparams) {
    if (result.toLowerCase() == ButtonOptions.Yes.toLowerCase()) {
      this.retailValidationService.LockTransaction(extraparams[0], true);
      this.getTransactionDetails(extraparams[0], extraparams[1], this._shopservice.ProductId);
    }
    else {
      this._shopservice.reOpenTransaction = false;
      this._shopservice.settleOpenTransaction = false;
      this._shopservice.selectedPayeeId = 0;
    }
  }

  async CancelTransaction(result, extraParams) {
    if (result.toLowerCase() == ButtonOptions.Yes.toLowerCase()) {
      this._ams.loaderEnable.next(this.captions.RefundInProgress);
      var lstTransId: number[] = [];
      lstTransId.push(this._shopservice.transactionId);
      var lstTransaction = await this.retailTransactionService.GetTransactionsByIds(lstTransId)
      var lstTransactionDetailId: number[] = [];
      if (lstTransaction && lstTransaction?.length > 0)
        lstTransaction.forEach(x => {
          let data = x.transactionDetails.map(y => y.id)
          if (data && data?.length > 0)
            lstTransactionDetailId.push.apply(lstTransactionDetailId, data)
        })
      let response: any = await this.retailValidationService.CancelTransaction(this._shopservice.transactionId);
      this._ams.loaderEnable.next("");
      if (response) {
        if (response.successStatus) {
          this.getAllTransactions(TransactionStatus.OPEN, this.selectedOutletId, RetailTransactions.opentransactions, this.localization.ConvertDateToISODateTime(this.PropertyInfo.CurrentDate));
          const BeforePublishlogType = "CancelTransactionEventEmitBeforePublishFromRetailTransactions";
          const AfterPublishlogType = "CancelTransactionEventEmitAfterPublishFromRetailTransactions";
          let eventData: RetailEventParameters<VoidEventModel> =
          {
            data: {
              transactionId: this._shopservice.transactionId,
              isDeposit: this._shopservice.HasDepoistItem(extraParams['transactionDetails']),
              lstTransactionDetailId: lstTransactionDetailId
            },
            eventType: RetailEventType.Cancel
          }
          this._ams.CreateTransLog(this._shopservice.transactionId, BeforePublishlogType, { newValue: eventData })
          retailPublisher.publishEvent(eventData);
          this._ams.CreateTransLog(this._shopservice.transactionId, AfterPublishlogType, { newValue: eventData })
          this._shopservice.GetWebProxyHealthCheckStatusForPMSPostings();
        }
        else {
          this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.getError(response.result));
        }
      }
    }
  }


  async ValidateVoidCorrectAction(transactionDetail): Promise<boolean> {
    let isValid = true;
    if (transactionDetail.isCorrected) {
      const correctedTicketNo = await this.GetCorrectedTicketNumber(transactionDetail.id);
      if (correctedTicketNo > 0) {
        this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.replacePlaceholders(this.localization.getError(10716), ['transactionId'], [correctedTicketNo]));
        isValid = false;
      }
    }
    else if (transactionDetail.isVoided) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.getError(10715));
      isValid = false;
    }
    else if (await this.retailValidationService.IsTransactionLocked(transactionDetail.id)) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.captions.TransactionLock, ButtonType.Ok);
      isValid = false;
    }
    else if (!await this._giftCardBussinessService.CanUnloadGiftCard(this.selectedTransactionId, this.selectedTicketNumber)) {
      isValid = false;
    }

    //Block If Any Giftcard issuances done during the RWT operations
    if (transactionDetail.transactionLinkId > 0) {
      if (await this.retailValidationService.CheckIfAnyGiftcardIssuedForRefund(this.selectedTransactionId)) {
        isValid = false
      }
    }

    return isValid;
  }

  async GetCorrectedTicketNumber(transactionId: number): Promise<number> {
    const transactions = await this.GetLinkedTransactions(transactionId);
    if (transactions && transactions.length > 0) {
      return transactions[0].ticketNumber;
    }
    return 0;
  }

  async GetLinkedTransactions(id: number): Promise<any> {
    const result = await this.http.CallApiAsync({
      callDesc: 'GetLinkedTransactions',
      method: HttpMethod.Get,
      host: Host.retailPOS,
      uriParams: { transactionId: id, outletId: this.selectedOutletId }
    });
    if (result && result.result) { return <any[]>result.result; }
  }

  async CheckVoidForMultiPack(transactionid: number): Promise<boolean> {
    const result = await this.http.CallApiAsync({
      callDesc: 'CheckVoidForMultipack',
      method: HttpMethod.Get,
      host: Host.retailPOS,
      uriParams: { transactionId: transactionid }
    });
    if (result && result.result) { return <boolean>result.result; }
  }
  async CheckIfDepositBEO(transactionid: number): Promise<boolean> {
    const result = await this.http.CallApiAsync({
      callDesc: 'GetBookingDepositByTransactionId',
      method: HttpMethod.Get,
      host: Host.SnCService,
      uriParams: { transactionId: transactionid }
    });
    if (result && result.result) { return <boolean>result.result; }
  }

  ValidateAccess(action): boolean {
    let hasAccess: boolean = true;
    let breakpointNumber: number[] = [];
    switch (action) {
      case this.captions.Reopen:
      case this.captions.Settle:
        breakpointNumber.push(RetailBreakPoint.ReOpenTransaction);
        break;
      case this.captions.Void:
        breakpointNumber.push(RetailBreakPoint.VoidTransaction);
        break;
      case this.captions.Correct:
        breakpointNumber.push(RetailBreakPoint.CorrectTransaction);
        break;
      case this.captions.Return:
        breakpointNumber.push(RetailBreakPoint.ReturnWithTicket);
        break;
      case this.captions.ReturnwithoutTicket:
        breakpointNumber.push(RetailBreakPoint.ReturnWithoutTicket);
        break;
      case this.captions.ModifyPostedCommissions:
        breakpointNumber.push(RetailBreakPoint.ModifyPostedCommission);
        break;
    }
    if (breakpointNumber.length > 0) {
      hasAccess = this.breakPoint.CheckForAccess(breakpointNumber);
    }
    return hasAccess;
  }

  voidOrCorrectWithQuickLogin(extraParams) {
    let quickIdConfig = this.PropertyInfo.getQuickIdConfig;
    if (quickIdConfig && quickIdConfig.QUICKID_RETAILTRANSACTIONS) {
      const quickLoginDialogRef = this.commonUtils.QuickLogin();
      quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
        // update retail communication component for quickjwt
        if (quickLoginDialogResult.isLoggedIn) {
          this.utils.ShowErrorMessage(this.localization.captions.common.Warning, this.captions.VoidTicketWarning, ButtonType.YesNo, this.PopUpCallBack.bind(this, extraParams));
        }
      });
    } else {
      this.utils.ShowErrorMessage(this.localization.captions.common.Warning, this.captions.VoidTicketWarning, ButtonType.YesNo, this.PopUpCallBack.bind(this, extraParams));
    }
  }

  PopUpCallBack(extraPrams, result) {
    const action = extraPrams["action"];
    let headerTitle: string;
    if (action === 'Void' || action === 'Correct') {
      headerTitle = action === 'Correct' ? this.localization.captions.shop.ReasonForCorrection : this.localization.captions.shop.ReasonForVoid
      if (result.toLowerCase() == 'yes') {
        //  Open popup for void reson
        let dialogRef = this.dialog.open(VoidReasonComponent, {
          height: 'auto',
          width: '40%',
          data: { headername: headerTitle, closebool: true },
          panelClass: 'small-popup',
          disableClose: true,
          hasBackdrop: true
        });
        dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(resultData => {
          if (resultData.action.toLowerCase() == 'ok') {
            this.utils.ToggleLoaderWithMessage(true, this.captions.lbl_processing);
            if (this._shopservice.giftCardListToUnload && this._shopservice.giftCardListToUnload.length > 0) {
              this._giftCardBussinessService.PerformGiftCardUnLoad();              
              this._shopservice.giftCardListToUnload = [];
            }
            
            this.voidReason = resultData.reason;
            let body = {
              transactionId: this.selectedTransactionId,
              ticketNumber: this.selectedTicketNumber,
              reason: this.voidReason,
              OutletId: this.selectedOutletId,
              productId: this._shopservice.ProductId,
              isCorrected: action === 'Correct' ? true : false,
              MachineNameId: this._shopservice.ProductId == Product.PMS ? 0 : this.localization.GetMachineId(),
            }
            extraPrams.transactionId = this.selectedTransactionId;
            if (extraPrams && extraPrams.IsMember) {
              this.openmembershippopup(body, extraPrams, this.ACESRefund.bind(this))
            }
            else {
              this._ams.loaderEnable.next(this.captions.RefundInProgress);
              this.LockAndTriggerVoid(body, extraPrams);
            }
          }
          else {
            this._shopservice.giftCardListToUnload = [];
            this._shopservice.destroy();
          }
        });
      }
      else {
        this._shopservice.giftCardListToUnload = [];
        this._shopservice.destroy();
      }
    }
  }

  async LockAndTriggerVoid(body, extraPrams) {
    let isTranlocked = await this.retailValidationService.IsTransactionLocked(this.selectedTransactionId);
    if (isTranlocked) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.captions.TransactionLockWarning + " - " + this.retailValidationService.lockedUserName, ButtonType.Ok, this.TransactionLockCallback.bind(this), [this.selectedTransactionId, this.captions.Void]);
      return;
    }
    if (!this.retailValidationService.isLockedCurrentUser) {
      this.retailValidationService.LockTransaction(this.selectedTransactionId);
    }
    try {
      let transactionDetailIdsForRental = extraPrams.transactionDetails.filter(i => i.itemType == RetailItemType.PMSAddOnsRentalItem).map(i => i.id);
      if (transactionDetailIdsForRental.length > 0) {
        let rentalItemPurchaseByTransactionDetailIds = await this.getRentalItemPurchaseIds(transactionDetailIdsForRental);
        this._shopservice.rentalItemPurchaseDetails = rentalItemPurchaseByTransactionDetailIds;
      }
      let transactionDetailIdsForLocker = extraPrams.transactionDetails.filter(i => i.itemType == RetailItemType.Lockers).map(i => i.transactionId);
      if (transactionDetailIdsForLocker.length > 0) {
        let lockerDetailsByTransactionDetailId: LockerAssignmentDetails[] = await this.getLockerAssignmentDetailIdByTransactionId(body.transactionId);
        this._shopservice.lockerAssignmentDetail = lockerDetailsByTransactionDetailId;
      }
    } catch (err) {
      console.error('error when Assigning the rental and locker assignment for shop service');
    }
    this.InvokeServiceCall(this.voidAPIRoute, HttpMethod.Put, '', body, extraPrams, false);
  }
  async getRentalItemPurchaseIds(TransactionDetailIds: number[]) {
    let rentalItemPurchaseResult = await this.http.CallApiAsync<any[]>({
      host: GlobalConst.Host.retailPOS,
      callDesc: RetailRoutes.GetTransactionDetailIds,
      method: HttpMethod.Put,
      body: TransactionDetailIds
    });
    return rentalItemPurchaseResult.result;
  }
  async getLockerAssignmentDetailIdByTransactionId(TransactionId: number): Promise<any> {
    let lockerAssignmentDetailsResult = await this.http.CallApiAsync({
      host: GlobalConst.Host.retailPOS,
      callDesc: RetailRoutes.GetLockerAssignmentList,
      method: HttpMethod.Get,
      uriParams: { transactionid: TransactionId },
      showError: true
    });
    return lockerAssignmentDetailsResult.result;
  }

  async ACESRefund(pin: string, body: any, extraPrams: any) {

    let Response: any = await RetailDataAwaiters.ACESRefund(this.selectedTransactionId.toString(), pin);
    if (Response != null && Response.SuccessCode == 1) {
      this._ams.loaderEnable.next(this.captions.RefundInProgress);
      this.InvokeServiceCall(this.voidAPIRoute, HttpMethod.Put, '', body, extraPrams);
    }
    else {
      this._shopservice.destroy();
    }
  }


  getTransactionDetails(transactionId, action: string, productId: number) {
    this.http.CallApiWithCallback<boolean>({
      host: Host.retailPOS,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetTransactionDetails",
      method: HttpMethod.Get,
      uriParams: { transactionId: transactionId, productId: productId },
      showError: true,
      extraParams: [action]
    });
  }

  async loadTransactionDetails(result, action: string) {

    this._shopservice.MultiPackProducts = [];
    this._shopservice.MultipackproductIds = [];
    this._shopservice.ClientMultiPacks = await RetailDataAwaiters.GetClientMultiPack(result[0].transactionId);
    this._shopservice.selectedProducts = [];
    this._shopservice.TaxValue = 0;
    this._retailService.SelectedOutletId = this._shopservice.SelectedOutletId = this.selectedOutletId;
    await this._shopservice.LoadSelectedProducts(result, [], action);

    if(action === this.captions.Settle ||  action === this.captions.Reopen ){
     let locker = this._shopservice.selectedProducts.find(x => x.retailItemType === RetailItemType.Lockers);
     if(locker){
      let lockerAssignment = await this.GetLockerAssignmentDetailByTransactionId(result[0].transactionId);
      if(lockerAssignment){
        lockerAssignment.isActive = true;
        this._retailService.lockerAssignmentDetails = lockerAssignment
      }
     }
    }

    if (this._shopservice.ClientMultiPacks && this._shopservice.ClientMultiPacks.length > 0) {
      await this.BulidMultiPackExChangeItem();
    }
    if (this._shopservice.settleOpenTransaction) {
      if (this.PropertyInfo.UseRetailInterface && this.ArrayList[0].datacolumn && result && result.length > 0) {
        let transaction = this.ArrayList[0].datacolumn.find((x) => x.id == result[0].transactionId);
        if (transaction) {
          this._shopservice.TaxValue = transaction.totalTax;
        }
      }
      this.router.navigate(['/shop/viewshop/order']);
    }
    else if (this._shopservice.reOpenTransaction || this._shopservice.correctTransaction) {
      this._shopservice.retailTransaction.tabState = 0;
      this.router.navigate(['/shop/viewshop']);
    }
    else {
      this.router.navigate(['/shop/viewshop/shopItemDetails']);
    }
  }
  async BulidMultiPackExChangeItem() {
    this._shopservice.MultiPackProducts = [];
    this._shopservice.selectedMultiPackExchangedProducts = [];
    let products = _.cloneDeep(this._shopservice.selectedProducts);
    let multipackDetails: MultiPackDetails[] = [];
    this._shopservice.MultipackproductIds = [...new Set(this._shopservice.ClientMultiPacks.map(x => x.transactionDetailId))];
    if (this.functionalities[RetailFunctionalities.ShowMultipleLinkedRetailItemForMultipack]) {
      if (this._shopservice.MultipackproductIds.length > 0) {
        multipackDetails = await this.transactionService.GetMultiPackDetailsByTransactionDetailIds(this._shopservice.MultipackproductIds);
      }
      this._shopservice.MultipackproductIds.forEach(x => {
        let multipacks = this._shopservice.ClientMultiPacks.filter(y => y.transactionDetailId === x);
        let filteredProducts = products.find(y => y.id === x);
        let multipackDetail = multipackDetails.find(y => y.multiPackTransactionDetailId === x);
        let multiSalePrice: number = 0;
        if (multipacks && filteredProducts && multipacks.length > 0) {
          let soldQuantity = multipacks[0].quanitity - multipacks[0].remaining;
          if (multipackDetail && multipackDetail.multiPackPerSalePrice) {
            multiSalePrice = multipackDetail.multiPackPerSalePrice;
          } else {
            multiSalePrice = multipacks.reduce((sum, pack) => sum + pack.salePrice, 0);
          }
          filteredProducts.ProductPrice = multiSalePrice * soldQuantity;
          filteredProducts.Noofitems = 1;
          filteredProducts.netUnitPrice = multiSalePrice * soldQuantity;
          filteredProducts.totalAmount = filteredProducts.ProductPrice;
          if (filteredProducts.Gratuity && filteredProducts.Gratuity.length > 0) {

            filteredProducts.Gratuity.forEach(x => {
              x.Amount = filteredProducts.ProductPrice * x.Percentage / 100;
              x.gratuity = filteredProducts.ProductPrice * x.Percentage / 100;
              filteredProducts.totalAmount += x.gratuity;
            });
          }
          if (filteredProducts.ServiceCharge && filteredProducts.ServiceCharge.length > 0) {
            filteredProducts.ServiceCharge.forEach(x => {
              x.Amount = filteredProducts.ProductPrice * x.Percentage / 100;
              x.ServiceCharge = filteredProducts.ProductPrice * x.Percentage / 100;
              filteredProducts.totalAmount += x.ServiceCharge;
            });
          }
          this._shopservice.MultiPackProducts.push(filteredProducts);
        }
      });
    }
    else {
      const  multipackDetails = await this.transactionService.GetMultiPackDetailsByTransactionDetailIds(this._shopservice.MultipackproductIds);
      this._shopservice.ClientMultiPacks.forEach(x => {
        if (x.quanitity != x.remaining && !x.isUnlimited) {
          let multipackDetail = multipackDetails.find(y => y.multiPackTransactionDetailId === x.transactionDetailId);
          let multiSalePrice: number = 0;
          const availableQty = (x.quanitity - x.remaining);
          let filteredProducts = products.find(y => y.id == x.transactionDetailId);
          if (multipackDetail && multipackDetail.multiPackPerSalePrice) {
            multiSalePrice = multipackDetail.multiPackPerSalePrice;
          } else {
            multiSalePrice = x.salePrice;
          }
          filteredProducts.ProductPrice = multiSalePrice * availableQty;
          filteredProducts.Noofitems = 1;
          filteredProducts.netUnitPrice = multiSalePrice * availableQty;
          filteredProducts.totalAmount = filteredProducts.ProductPrice;
          if (filteredProducts.Gratuity && filteredProducts.Gratuity.length > 0) {

            filteredProducts.Gratuity.forEach(x => {
              x.Amount = filteredProducts.ProductPrice * x.Percentage / 100;
              x.gratuity = filteredProducts.ProductPrice * x.Percentage / 100;
              filteredProducts.totalAmount += x.gratuity;
            });
          }
          if (filteredProducts.ServiceCharge && filteredProducts.ServiceCharge.length > 0) {
            filteredProducts.ServiceCharge.forEach(x => {
              x.Amount = filteredProducts.ProductPrice * x.Percentage / 100;
              x.ServiceCharge = filteredProducts.ProductPrice * x.Percentage / 100;
              filteredProducts.totalAmount += x.ServiceCharge;
            });
          }
          this._shopservice.MultiPackProducts.push(filteredProducts);
        }
      });

    }



  }

  getDataHeader(isCardNumberSearch: boolean = false) {
    let header = [{ "title": this.captions.TicketNumber, "jsonkey": "ticketNumber", "type": "clickable", "alignType": "right", "searchable": true },
    { "title": this.captions.lbl_transactionType, "jsonkey": "retailTransactionType", "alignType": "left", "searchable": true },
    { "title": this.serviceId == RetailTransactions.reprintticket ? this.captions.Date : this.captions.Time, "jsonkey": "transactionDate", "alignType": "left", "searchable": false, "sortcolumn": "transactionDateTime", "sortcolumndatatype": "Date" },
    { "title": this.captions.SoldBy, "jsonkey": "clerkId", "alignType": "left", "searchable": true },
    { "title": this.captions.Outlet, "jsonkey": "outletName", "alignType": "left", "searchable": false },
    { "title": `${this.captions.Amount} (${this.localization.currencySymbol})`, "jsonkey": "totalAmount", "alignType": "right", "datatype": "money", "sortcolumndatatype": "number", "searchable": true },
    { "title": "", "jsonkey": "", "alignType": "left" },
    { "title": this.captions.ClientName, "jsonkey": "clientName", "alignType": "left", "searchable": true },
    { "title": this.captions.MemberName, "jsonkey": "memberName", "alignType": "left", "searchable": true }];
    if (isCardNumberSearch) {
      header.push({ "title": this.captions.CardNumber, "jsonkey": "cardNumber", "alignType": "left", "searchable": true });
    }
    if (Number(this.utils.GetPropertyInfo('ProductId')) == Product.PMS) {
      header.splice(4, 1);
    }
    if (this.serviceId === RetailTransactions.reprintticket ||
      this.serviceId === RetailTransactions.returnwithticket ||
      this.serviceId === RetailTransactions.correctvoid) {
      header.push({ "title": this.captions.CorrectedOrVoidedByID, "jsonkey": "voidedByClerkId", "alignType": "left", "searchable": true });
    }
    if (this.serviceId === RetailTransactions.opentransactions && !this.PropertyInfo.UseRetailInterface) {
      header.push({ 'title': `${this.captions.lbl_payment_received} (${this.localization.currencySymbol})`, "jsonkey": "paymentReceivedAmount", "alignType": "right", "datatype": "money", "sortcolumndatatype": "number", "searchable": true });
    }
    if (Number(this.utils.GetPropertyInfo('ProductId')) === Product.SNC) {
      header.unshift({ "title": this.captions.BeoNumber, "jsonkey": "transactionAdditionalReference", "alignType": "right", "searchable": true });
    }
    return header;
  }

  async CloseOpenTransaction() {
    this.disbleSyncUpBtn = true;
    if (this.ArrayList.length == 0 || !this.ArrayList[0].datacolumn || this.ArrayList[0].datacolumn.length == 0) {
      return;
    }
    let transactionId: number[] = this.ArrayList[0].datacolumn.map(r => r.id);
    await this.ProcessSyncUp(transactionId);
    this.disbleSyncUpBtn = false;
    this.getAllTransactions(TransactionStatus.OPEN, this.selectedOutletId, RetailTransactions.opentransactions, this.localization.ConvertDateToISODateTime(this.PropertyInfo.CurrentDate));
    let eventData: RetailEventParameters<any> = {
      data: "",
      eventType: RetailEventType.SyncUpTransaction
    };
    retailPublisher.publishEvent(eventData);
  }

  async ProcessSyncUp(transactionId: number[]): Promise<boolean> {
    var tranId = transactionId.splice(0, this.transactionsToSyncPerCall);
    let isSuccess = false;
    if (tranId.length > 0) {
      let uriParams = { productId: this._shopservice.ProductId, outletId: this.selectedOutletId }
      let apiResponse: BaseResponse<boolean> = await this.retailSetupService.InvokeServiceCallAsync("SyncUpTransactions", Host.retailPOS, HttpMethod.Put, tranId, uriParams);
      if (apiResponse && apiResponse.successStatus) {
        isSuccess = apiResponse.result;
      }
      else {
        return isSuccess;
      }
    }
    if (isSuccess && transactionId.length > 0) {
      this.ProcessSyncUp(transactionId);
    }
    else {
      return isSuccess;
    }
  }

  InvokeServiceCall(route: string, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any, showError: boolean = true) {
    this.http.CallApiWithCallback<any>({
      host: Host.retailPOS,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: showError,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }

  gotoRecords(event) {

    this.selectedTicketNumber = event.ticketNumber;
    this.selectedTransactionId = event.id;
    this._shopservice.retailTransaction.dropdownState = this.serviceId;

    this._shopservice.selectedPayeeId = event.guestId;
    this._shopservice.transactionId = event.id ? event.id : 0;
    this._shopservice.ticketNumber = event.ticketNumber ? event.ticketNumber : "";

    this._shopservice.reOpenTransaction = false;
    this._shopservice.settleOpenTransaction = false;

    this.router.navigate(['/shop/viewshop/ticketDetails/' + this.selectedTicketNumber]);
  }

  onChangesearchtext() {
    this.populateTableInfo(false, false, this.serviceId, "", "ServiceCode", "", true);
  }

  filterChange(values) {
    let outlet = values[0];
    this.selectedOutlet = outlet.filtered ? outlet.filtered : [];
    this.ArrayList[0].datacolumn = this.DataFilterBasedOnOutlet(this.dataArrayCopy);
    this.populateTableInfo(false, false, this.serviceId, "", "ticketNumber", "", true);
  }

  dateChange(values: Date): void {
    let transactionDate: string = this.localization.ConvertDateToISODateTime(values);
    this.getClosedTransactionbyDate(transactionDate, RetailTransactions.modifypostedcommission);
  }

  InitializeModifyPostedCommission(): void {
    this.Categories = [
      {
        "id": 1,
        "name": "outlet",
        "title": this.captions.Outlet,
        "filters": [],
        "filtered": []
      }
    ];
  }

  filterReset($event) {
    this.selectedOutlet = [];
    this.filterDateDefault = this.PropertyInfo.CurrentDate;
    this.dateChange(this.filterDateDefault);
  }

  DataFilterBasedOnOutlet(transactionData: any[]): any[] {
    transactionData = transactionData ? transactionData : [];
    this.retailTransactionService.ClosedTransactions = transactionData;
    return transactionData.filter(x => {
      return this.SelectedOutletFilter(x);
    });
  }

  SelectedOutletFilter(x): boolean {
    return !this.selectedOutlet || this.selectedOutlet.length == 0 || (this.selectedOutlet && this.selectedOutlet.length > 0 && this.selectedOutlet.includes(x.outletId));
  }
  setOutletFilterData() {
    // TBD - get user outlet
    // var SubPropAccPromise = this.retailTransactionService.getUserOutletSubProperty();
    let SubPropAccPromise = this.outletsBySubProperty;

    SubPropAccPromise.subscribe(results => {
      let outlets: SubPropertyModel[] = results[0].result || [];
      let outletAccess: OutletSubProperty[] = results[1].result || [];
      let list: number[] = outletAccess.filter(x => x.isActive).map(x => x.subPropertyID);
      outlets = outlets.filter(x => list.includes(x.subPropertyID));

      if (outlets.length > 0) {
        this.Categories[0].filters = [{
          id: 0,
          name: this.captions.AllOutlets,
          isAll: true
        }];
        this.Categories[0].filters = this.Categories[0].filters.concat(outlets.map(x => {
          return {
            id: x.subPropertyID,
            name: x.subPropertyName,
            isAll: false
          };
        }));
      }
      this.Categories[0].filtered = [];
      this.selectedOutlet = this.Categories[0].filtered;
    });

  }

  openmembershippopup(body?: any, params?: any[] | any, callback?: (result: string, body: any, params: any[] | any) => Promise<void>) {
    let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      height: 'auto',
      width: '40%',
      data: { headername: this.localization.captions.shop.RedeemRoundsHeader, closebool: true, Type: "RedeemRounds", buttonType: ButtonType.OkCancel },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
    let subscription = dialogRef.afterClosed().subscribe(res => {
      if (res.res == 'ok' && res.labelPosition) {
        this.memberpin = res.labelPosition;
        if (callback) {
          callback(res.labelPosition.toString(), body, params);
        }
      }
      else
        this.memberpin = "";
    });
  }


  //#region Reprint ticket - Advanced Search

  onAdvancedSearchClose() {
    // reload on advanced search close
    this.onchangeLoad(this.callDesc, this.ListId);
  }

  async onAdvancedSearch(params: ReprintAdvancedSearch) {
    this.isAdvancedSearchLoading = true;
    this.advSearchParams = params;
    try {
      const userInfo =  this.getClerkInfo();
      const memberSearch = this.getMemberSearch(params);
      const guestSearch = this.getClientSearch(params);
      const nameSearchTask = [userInfo, memberSearch, guestSearch];
      const nameSearchResult = await Promise.all(nameSearchTask);
      const clerkSearch = await this.getClerkSearch(params);
      const voidedByClerkSearch = this.getVoidedByClerkSearch(params);
      const searchParams = this.getAdvancedSearchParams(params);
      const isClerkSearched = (params.clerkId || '').trim() !== '';
      let members = [], guests = [];
      // To stop searching transaction if client/member/clerk not found
      let canSearchTransaction = true;
      // Clerk
      if (nameSearchResult[0] != null && isClerkSearched) {
        const userId = clerkSearch[0]?.userId;
        searchParams.clerkIdList = userId ? [userId] : [];
        canSearchTransaction = canSearchTransaction && searchParams.clerkIdList.length > 0;
      }
      // member
      if (nameSearchResult[1] != null) {
        members = nameSearchResult[1][1];
        // guestProfileId - playerLinkId
        if(!this.memberConfiguration?.isExternalMembershipEnabled){
          searchParams.memberIdList = members.map(x => x.guestProfileId).filter(x => x > 0);
          canSearchTransaction = canSearchTransaction && searchParams?.memberIdList.length > 0
        }else{
          searchParams.memberCardNumberList = members.map(x => x.guestProfileId).filter(x => x.trim().length > 0);
          canSearchTransaction = canSearchTransaction && searchParams?.memberCardNumberList.length > 0
        }
      }
      // guest
      if (nameSearchResult[2] != null) {
        guests = nameSearchResult[2][1];
        searchParams.guestIdList = guests.map(x => x.id).filter(x => x > 0);
        canSearchTransaction = canSearchTransaction && searchParams.guestIdList.length > 0;
      }
      // Voided By Clerk
      if (nameSearchResult[3] != null && params.voidedByclerkId) {
        searchParams.voidedByClerkIdList = nameSearchResult[3].map(x => x.userId).filter(x => x > 0);
        canSearchTransaction = canSearchTransaction && searchParams.voidedByClerkIdList.length > 0;
      }

      const transactionSearch = canSearchTransaction ? await this.searchTransaction(searchParams) : [];
      const result = {
        transaction: transactionSearch,
        clerks: nameSearchResult[0],
        members: members,
        guests: guests,
        voidedByClerks: nameSearchResult[3]
      };
      this.filterTransaction(result, params);
    } catch {
      this.isAdvancedSearchLoading = false;
    }
  }

  async filterTransaction(results, params: ReprintAdvancedSearch) {

    const isGuestSearched = (params.clientName || '').trim() !== '';
    const isMemberSearched = (params.memberName || '').trim() !== '';
    const isClerkSearched = (params.clerkId || '').trim() !== '';
    const isVoidedByClerkSearched = (params.voidedByclerkId || '').trim() !== '';

    let transactions = results.transaction.result || [];
    const clerks = results.clerks || [];
    const members = results.members || [];
    const guests = results.guests || [];
    const voidedByClerks = results.voidedByClerks || [];

    // filter transaction
    transactions = isClerkSearched ? this.filterTransactionByClerk(clerks, transactions) : transactions;
    transactions = isMemberSearched ? this.filterTransactionByMember(members, transactions) : transactions;
    transactions = isGuestSearched ? this.filterTransactionByGuest(guests, transactions) : transactions;
    transactions = isVoidedByClerkSearched ? this.filterTransactionByVoidedClerk(voidedByClerks, transactions) : transactions;
    let isCardNumberSearch = params.cardNumber != '' || params.cardType != '';
    await this.formTableData(transactions, clerks, guests, members, voidedByClerks, isCardNumberSearch, params.cardNumber);
  }

  sortCardNumbersBySearch(transaction, searchValue) {
    if (transaction.cardNumber.length > 1) {
      let index = transaction.cardNumber.findIndex((a) => {
        return a.includes(searchValue);
      });
      if (index != 0) {
        const value = transaction.cardNumber[index];
        transaction.cardNumber.splice(index, 1);
        transaction.cardNumber.unshift(value);
      }
    }
  }


  async formTableData(transactions: any[], clerks: any[], clients: PayeeInfo[], members: PayeeInfo[], voidedByClerks: any[], isCardNumberSearch: boolean = false, searchParam: string = '') {
    this.ArrayList = [{}];
    this.ArrayList[0].dataheader = this.getDataHeader(isCardNumberSearch);
    if (!this.displayOptions.showOutletInGrid) {
      this.ArrayList[0].dataheader = this.ArrayList[0].dataheader.filter(x => x.title !== this.captions.Outlet);
    }
    if (clients.length === 0) {
      const GuestIds = transactions.map(x => x.guestId).filter((v, i, a) => a.indexOf(v) === i && v > 0);
      clients = GuestIds.length > 0 ? await RetailDataAwaiters.getPayeeDetails(GuestIds) : [];
      this.receiptService.clientDetails = clients;
    }

    if (members.length === 0) {
      const memberIds = transactions.map(x => x.memberId).filter((v, i, a) => a.indexOf(v) === i && v > 0);
    }

    this.isAdvancedSearchLoading = false;
    this.ArrayList[0].datacolumn = this.FormGridData(transactions, clients, clerks, members, voidedByClerks, isCardNumberSearch, searchParam);
    this.dataArrayCopy = _.clone(this.ArrayList[0].datacolumn);
    this.populateTableInfo(false, false, this.serviceId, '', 'ticketNumber', '', true, this.displayOptions.showOutlet);
  }

  async getClientSearch(params: ReprintAdvancedSearch) {
    try {
      const uuid = `${Date.now()}${this.utils.getRandomDecimal() * 10000}`;
      const clientName = (params.clientName || '').trim();
      return !clientName ? null : await RetailDataAwaiters.searchTransactionGuest(clientName, this.PayeeSearchTypes.guest, uuid);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async getMemberSearch(params: ReprintAdvancedSearch) {
    try {
      const memberName = (params.memberName || '').trim();
      return !memberName ? null : await this._memberService.searchGuest(memberName, this.PayeeSearchTypes.member, true);
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async getClerkSearch(params: ReprintAdvancedSearch) {

    let clerkInfo = this.userList && this.userList.length > 0 ? [...this.userList]
      : await this.getClerkInfo();

    if (params.clerkId && params.clerkId.trim() !== '') {
      clerkInfo = clerkInfo.filter(x => {
        const userName = (x.userName || '').toLowerCase();
        const clerkId = (params.clerkId || '').toLowerCase();
        return userName.includes(clerkId);
      });
    }

    return clerkInfo;
  }

  async getVoidedByClerkSearch(params: ReprintAdvancedSearch) {

    let clerkInfo = this.userList && this.userList.length > 0 ? [...this.userList]
      : await this.getClerkInfo();

    if (params.voidedByclerkId && params.voidedByclerkId.trim() !== '') {
      clerkInfo = clerkInfo.filter(x => {
        const userName = (x.userName || '').toLowerCase();
        const clerkId = (params.voidedByclerkId || '').toLowerCase();
        return userName.includes(clerkId);
      });
    }

    return clerkInfo;
  }

  async searchTransaction(params) {
    try {
      return await this.http.CallApiAsync({
        host: Host.retailPOS,
        callDesc: 'SearchTransaction',
        body: params,
        method: HttpMethod.Put,
        showError: true
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async GetLockerAssignmentDetailByTransactionId(id: number): Promise<LockerAssignmentDetails> {
    try {
      let response = await this.http.CallApiAsync({
        host: Host.retailPOS,
        callDesc: RetailRoutes.GetLockerAssignmentDetailByTransactionId,
        method: HttpMethod.Get,
        uriParams: { transactionid: id },
        showError: true
      });
      return <LockerAssignmentDetails>response.result;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  getAdvancedSearchParams(params: ReprintAdvancedSearch): any {
    const toAPI = this.localization.ConvertDateToISODateTime;
    return {
      status: this.serviceId === RetailTransactions.opentransactions ? TransactionStatus.OPEN : TransactionStatus.CLOSED,
      outletId: this.selectedOutletId,
      ticketNumber: params.ticketNumber,
      amount: params.amount,
      itemId: params.itemId,
      fromDate: this.serviceId === RetailTransactions.returnwithticket ? toAPI(this.transactionDate)
        : toAPI(this.displayOptions.reprintDateFilterValues.startDate),
      toDate: this.serviceId === RetailTransactions.returnwithticket ? toAPI(this.transactionDate)
        : toAPI(this.displayOptions.reprintDateFilterValues.endDate),
      pageLength: this.shopScreenItemCount,
      retailtransactiontype: params.transactiontype,
      cardNumber: params.cardNumber,
      cardType: params.cardType,
      transactionAdditionalReference: params.transactionAdditionalReference? params.transactionAdditionalReference:''
    }
  }

  filterTransactionByGuest(clients: PayeeInfo[], transactions: any[]) {
    const guestList = clients.map(x => x.id);
    transactions = transactions.filter(x => {
      return guestList.includes(x.guestId);
    });
    return transactions;
  }

  filterTransactionByMember(clients: PayeeInfo[], transactions: any[]) {
    if(!this.memberConfiguration?.isExternalMembershipEnabled){
      const memberList = clients.map(x => Number(x.guestProfileId));
      transactions = transactions.filter(x => {
        return memberList.includes(Number(x.memberId));
      });
    }else{
      const memberList = clients.map(x => String(x.guestProfileId));
      transactions = transactions.filter(x => {
        return memberList.includes(x.memberCardNumber);
      });
    }
    return transactions;
  } 

  filterTransactionByClerk(clerks: any[], transactions: any[]) {
    const clerkList = clerks.map(x => x.userId);
    transactions = transactions.filter(x => {
      return clerkList.includes(x.clerkId);
    });
    return transactions;
  }

  filterTransactionByVoidedClerk(clerks: any[], transactions: any[]) {
    const clerkList = clerks.map(x => x.userId);
    transactions = transactions.filter(x => {
      return clerkList.includes(x.voidedByClerkId);
    });
    return transactions;
  }

  TransactionAggregateToTransactionUIListModelMapper(aggregateTransList: Transaction[]): TransactionListData[] {
    let transactionUIList: TransactionListData[] = [];
    if (aggregateTransList && aggregateTransList.length > 0) {
      aggregateTransList.forEach(tran => {
        const transctionData = tran.transactionData;
        const transUIObj: TransactionListData = {
          outletName: this.outlets.find(o => o.subPropertyID == this.selectedOutletId)?.subPropertyName,
          terminalId: transctionData.terminalId,
          transactionDetails: tran.transactionDetails,
          id: tran.id,
          transactionType: transctionData.transactionType,
          ticketNumber: this.PropertyInfo.UseRetailInterface ? transctionData.ticketNumber : String(transctionData.retailTicketNumber),
          transactionDate: transctionData.transactionDate,
          clerkId: transctionData.clerkId,
          totalPrice: transctionData.totalPrice,
          totalTax: transctionData.totalTax,
          totalAmount: transctionData.totalAmount,
          guestId: transctionData.guestId,
          memberId: transctionData.memberId,
          memberCardNumber: transctionData.memberCardNumber,
          comment: transctionData.comment,
          stayId: transctionData.stayId,
          isVoided: transctionData.isVoided,
          isCorrected: transctionData.isCorrected,
          outletId: transctionData.outletId,
          gratuity: transctionData.gratuity,
          serviceCharge: transctionData.serviceCharge,
          discount: transctionData.discount,
          transactionLinkId: transctionData.transactionLinkId,
          voidReason: transctionData.voidReason,
          productId: transctionData.productId,
          receiptComment: transctionData.receiptComment,
          retailTransactionType: transctionData.transactionType,
          propertyId: transctionData.propertyId,
          subPropertyId: transctionData.subPropertyId
        }
        transactionUIList.push(transUIObj);
      });
    }
    return transactionUIList;
  }

  IGOpenTransactionUIListModelMapper(IGOpenTransList: any[]): any[] {
    IGOpenTransList.forEach(tran => {
      if (tran.totalTax != 0) {
        tran.totalAmount = Number((tran.totalAmount - tran.totalTax).customToFixed());
        tran.totalTax = 0;
      }
    });
    return IGOpenTransList;
  }

}

export interface OnchangeParams {
  resetRePrintDate: boolean;
}
