import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash'; // STORAGE THE BACK ARRAY
import { ReplaySubject, Subscription } from 'rxjs';
import { RetailLocalization } from '../../../common/localization/retail-localization';
import { RetailPropertyInformation } from '../../../common/services/retail-property-information.service';
import * as GlobalConst from '../../../shared/globalsContant';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { ApplyDiscountInput, CommonVariablesService, ReturnWithTicketType, SelectedProducts, ServiceChargeGratuity, Ticket, ServiceCharge as Svcg, Gratuity as Grat, LineItem, CustomFeeConfig, TransactionCustomFee, Gratuity } from '../../../shared/service/common-variables.service';
import { HttpMethod } from '../../../shared/service/http-call.service';
import { RetailUtilities } from '../../../shared/utilities/retail-utilities';
import { ApplyDiscountService } from '../../apply-discount/appply-discount.service';
import { ShopBussinessService } from '../../shop-business.service';
import { RetailService } from '../../../retail.service';
import { takeUntil } from 'rxjs/operators';
import { RetailDataAwaiters } from '../../../shared/events/awaiters/retail.data.awaiters';
import { ShopDialogPopUp } from '../../shop-dialog-popup/shop-dialog-popup.component';
import { ApplyDiscount, ApplyTipAsGratuityData, DiscountTypeDetails, ItemDiscount, RetailShopItem, ReturnItemTotal } from '../../shop.modals';
import { RetailTransactionService } from '../../view-categories/retail-transactions/retail.transactions.service';
import { GiftCardBusiness } from 'src/app/retail/sytem-config/gift-cards/gift-cards.business';
import { GiftCardTransactionItem } from 'src/app/retail/shared/service/payment/payment-model';
import { RetailItemType, SystemConfiguration } from 'src/app/retail/retail.modals';
import { AlertAction, AlertType, TransactionDetailAggregate } from 'src/app/retail/shared/shared.modal';
import { ButtonType } from 'src/app/common/shared/shared/globalsContant';
import { Router } from '@angular/router';
import { ApplyDiscountComponent } from '../../apply-discount/apply-discount.component';
import { TransactionEngineBusiness } from 'src/app/retail/retail-transaction-engine/transaction-engine-business';
import { PaymentMethods,PayeeInfo } from 'src/app/retail/shared/business/shared.modals';
import {PlayerCategory} from 'src/app/common/enums/shared-enums';
import * as myGlobals from '../../../shared/globalsContant';
import { EditCustomFeeComponent } from '../../edit-custom-fee/edit-custom-fee.component';
import { AddCustomFeePopupComponent } from '../../add-custom-fee-popup/add-custom-fee-popup.component';
import { DiscountLevel, EngineDiscountModel } from 'src/app/retail/retail-transaction-engine/transaction-engine-model';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { PaymentHistory } from 'src/app/retail/shared/service/payment/payment-business.model';
@Component({
  selector: 'app-shop-service-items',
  templateUrl: './shop-service-items.component.html',
  styleUrls: ['./shop-service-items.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShopServiceItemsComponent implements OnInit, AfterViewInit, OnDestroy {

  summaryCollection: any[];
  @Input() frompage;
  @ViewChild("discountPopover") discountPopover: any;
  @ViewChild("applyAllPopover") applyAllPopover: any;
  applyDiscountInput: ApplyDiscountInput;
  SSItotal: any = 0.00;
  SSITax: any = 0.00;
  SSISubtotal: any = 0.00;

  disabledGratuity = false;
  disableServiceCharge = false;
  isDataWithBeo:boolean=false;
  total: any = 0.00;
  tax: any = 0.00;
  taxOnTax = 0.00;
  gratuityTax: any = 0.00;
  serviceChargetax: any = 0.00;
  vat: number = 0.00;
  subTotal: any = 0.00;
  totalDiscount: any = 0.00;
  ticketDiscount: any = 0.00;
  totalGratuityOfReturnWithTicketItems: any = 0.00;
  totalServiceChargeOfReturnWithTicketItems: any = 0.00;

  selectedReturnItems: any = [];
  selectedGratuityReturnItems: any = [];
  selectedServiceChargeReturnItems: any = [];

  RS_selectedExchangeItems: any = [];
  RS_selectedReturnItems: any = [];
  RS_selectedRtainedItems: any = [];

  Itemtotal: ReturnItemTotal = {
    retainedItemSubTotal: 0.00,
    retainedItemTax: 0.00,
    retainedItemGratuityTax: 0.00,
    retainedItemServiceChargeTax:0.00,
    retainedItemVat: 0.00,
    retainedItemDiscount: 0.00,
    retainedItemTicketDiscount:0.00,
    retainedItemGratuity:0.00,
    retainedItemServiceCharge:0.00,
    returnedItemGratuity:0.00,
    returnedItemServiceCharge:0.00,
    returnedItemSubtotal: 0.00,
    returnedItemTax: 0.00,
    returnedItemGratuityTax: 0.00,
    returnedItemServiceChargeTax: 0.00,
    returnedItemVat:0.00,
    returnedItemDiscount: 0.00,
    returnedItemWeightedDiscount: 0.00,
    exchangedItemSubtotal: 0.00,
    exchangedItemTax: 0.00,
    exchangedItemGratuityTax: 0.00,
    exchangedItemServiceChargeTax: 0.00,
    exchangedItemDiscount: 0.00,
    exchangedItemGratuity:0.00,
    exchangedItemServiceCharge:0.00,
    returnedItemTaxOnTax: 0.00,
    retainedItemTaxOnTax: 0.00,
    exchangedItemTaxOnTax: 0.00
  };

  retainedTicketDiscount: EngineDiscountModel;

  GridItems: any = [];
  captions: any;
  tempItemDetails: any;
  exchangedGridData: any[] = [];
  subscription: Subscription;
  xPos: number;
  isRight: boolean;
  openedIdx: number = -1;
  isApplyDiscountFromOrderSummaryGrid: boolean = false;
  isOpened: boolean = false;
  percentage = [
    { id: 1, name: 'Staff', percentage: '30%' }
  ]
  exempttax: any;
  returnTaxExempt: any;
  ShopCaptions: any = this.localization.captions.shop;
  currentSelectedItemForDiscount: any;
  applyAllDiscountInput: ApplyDiscountInput = {
    CategoryId: -1,
    SelectedDiscount: null,
    showItemDetails: false,
    isMultipleItems: true
  };
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  isVATEnabled: boolean;
  SelectedRetailItemDetails: any[] = [];
  playerDetails: PayeeInfo[] = [];
  customFeeItemDetails: any[] = [];
  get EnableApplyDiscount() {
    return (!this._retailModuleService.paymentProcessing && this.RS_selectedExchangeItems.length > 0 && this.RS_selectedReturnItems.length == 0 && this.RS_selectedRtainedItems.length == 0)
      && !this.RS_selectedExchangeItems.some(x => x.isGiftCardItem) && this.RS_selectedExchangeItems.every(x => !this._shopservice.nonApplicableItemTypeForDiscount.includes( x.retailItemType ));
  }

  public get TotalVatAmount()
  {
    if(!this.PropertyInfo.UseRetailInterface){
      return this._shopservice && this._shopservice?.Ticket && this._shopservice?.Ticket?.lineItems && this._shopservice?.Ticket?.lineItems?.some(s=> s.vat) ?  this._shopservice.Ticket.lineItems.reduce((sum, current) => sum + current.vat, 0) : 0;
    }
    else{
      return 0;
    }
  }

  public get ExchangedSectionTotal() {
    return this.isVATEnabled ? this.localization.localizeCurrency((this.Itemtotal.exchangedItemSubtotal - this.Itemtotal.exchangedItemDiscount).toString(), false)
      : this.localization.localizeCurrency((this.Itemtotal.exchangedItemSubtotal - this.Itemtotal.exchangedItemDiscount
        + (this.exempttax.value ? 0 : this.Itemtotal.exchangedItemTax + this.Itemtotal.exchangedItemTaxOnTax + this.Itemtotal.exchangedItemServiceChargeTax
          + this.Itemtotal.exchangedItemGratuityTax)).toString(), false)
  }

  public get RetainedSectionTotal(){
    return this.isVATEnabled ? this.localization.localizeCurrency((this.Itemtotal.retainedItemSubTotal - this.Itemtotal.retainedItemDiscount - this.Itemtotal.retainedItemTicketDiscount).toString(),
    false): this.localization.localizeCurrency((this.Itemtotal.retainedItemSubTotal + this.Itemtotal.retainedItemTax  + this.Itemtotal.retainedItemTaxOnTax
    + this.Itemtotal.retainedItemServiceChargeTax + this.Itemtotal.retainedItemGratuityTax - this.Itemtotal.retainedItemDiscount - this.Itemtotal.retainedItemTicketDiscount).toString(),false)
  }

  public get ReturnedSectionTotal() {
    return this.isVATEnabled ? this.localization.localizeCurrency((this.Itemtotal.returnedItemSubtotal - this.Itemtotal.returnedItemDiscount - this.Itemtotal.returnedItemWeightedDiscount).toString(), false)
      : this.localization.localizeCurrency((this.Itemtotal.returnedItemSubtotal + (this.returnTaxExempt.value ? 0
        : this.Itemtotal.returnedItemTax + this.Itemtotal.returnedItemTaxOnTax + this.Itemtotal.returnedItemServiceChargeTax + this.Itemtotal.returnedItemGratuityTax)
        - this.Itemtotal.returnedItemDiscount - this.Itemtotal.returnedItemWeightedDiscount).toString(), false)
  }
  
  @Input("surcharge") surcharge = 0;

  constructor(public localization: RetailLocalization,
    public _shopservice: CommonVariablesService,
    public dialog: MatDialog, private utils: RetailUtilities,
    private transactionService: RetailTransactionService,
    public PropertyInfo: RetailPropertyInformation,
    private BPoint: BreakPointAccess,
    private applyDiscountService: ApplyDiscountService,
    public _sbs: ShopBussinessService,
    public _retailModuleService: RetailService,
    private giftcardBusiness: GiftCardBusiness,
    private router: Router,
    private transactionEngineBusiness: TransactionEngineBusiness,
    private _featureFlagInfo: RetailFeatureFlagInformationService) {
    this.exempttax = new UntypedFormControl(false);
    this.returnTaxExempt = new UntypedFormControl(false);
    this._shopservice.exemptTaxForExchange = this.exempttax.value;
    this.isVATEnabled = PropertyInfo.IsVATEnabled;
  }

  async ngOnInit() {
    this._retailModuleService.loaderEnable.pipe(takeUntil(this.$destroyed)).subscribe(loader => {
      const loadingContainer = document.getElementById('custom-cover-spin');
      const loadingContainerMessage = document.getElementById('custom-cover-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';
          loadingContainerMessage.innerText = loader;
        }
        else {
          loadingContainer.style.display = 'none';
          loadingContainerMessage.innerText = '';
        }
      }
    });
    this.captions = this.localization.captions.shop;
    this._shopservice.ReturnMethod = 1;
    this._shopservice.DDselection = this.localization.captions.shop.ReturnWithoutExchange;
    this._shopservice.getCustomFee();
    if(this._shopservice.returnWithticketItems && this._shopservice.returnWithticketItems.length > 0)
    {
      this._shopservice.returnWithticketItems.forEach(itms => 
        {
          let totalServiceCharge = 0;
          if(itms.ServiceCharge && itms.ServiceCharge.length > 0)
          {
            itms.ServiceCharge.forEach( sc => {
              totalServiceCharge += (sc.Amount + sc.customAmount);
            })
          }  
          let totalGratuity = 0;
          if(itms.Gratuity && itms.Gratuity.length > 0)
          {
            itms.Gratuity.forEach( g => {
              totalGratuity += (g.Amount + g.customAmount);
            });
          }  
          this.totalGratuityOfReturnWithTicketItems += totalGratuity;
          this.totalServiceChargeOfReturnWithTicketItems += totalServiceCharge;

          itms.OriginalLineNumber = itms.LineNumber;
        }); 
    }
    this._shopservice.selectedTransactionRetailProducts = (this._shopservice.selectedTransactionRetailProducts.length > 0) ? this._shopservice.selectedTransactionRetailProducts : this._shopservice.selectedProducts;
    if (this._shopservice.selectedTransactionRetailProducts.length > 0) {
      this._shopservice.selectedTransactionRetailProducts.forEach(itms => {
        itms.OriginalLineNumber = itms.LineNumber;
      });
      this._shopservice.selectedTransactionRetailProducts = this._shopservice.selectedTransactionRetailProducts.filter(r => !r.isReturn);
    }

    if (!this.giftcardBusiness.IsGiftCardConfigLoaded) {
      await this.giftcardBusiness.GetGiftCardConfig();
    }

    // Giftcards cannot be returned, so hiding it in the return items list
    if (this.giftcardBusiness.UseGiftcardInterface && this._shopservice.retainedGiftCards.length == 0) {
      this._shopservice.retainedGiftCards = [];
      const giftcardItems: GiftCardTransactionItem[] = await this.giftcardBusiness.GetGiftcardItems(this._shopservice.transactionId);
      if (giftcardItems && giftcardItems.length > 0) {
        this._shopservice.retainedGiftCards = this._shopservice.returnWithticketItems.filter(r => giftcardItems.some(x => x.transactionDetailId == r.id));
        this._shopservice.returnWithticketItems = this._shopservice.selectedTransactionRetailProducts = this._shopservice.selectedProducts = this._shopservice.selectedProducts.filter(r => !giftcardItems.some(x => x.transactionDetailId == r.id));
      }
    }

    if (this._shopservice.isFromRefund && !this._shopservice.canSelectProducts && this._shopservice.selectedProducts && this._shopservice.selectedExchangeRetailProducts.length == 0) {
      if (this._shopservice.retainedGiftCards && this._shopservice.retainedGiftCards.length > 0) {
        this._shopservice.selectedProducts = this._shopservice.selectedProducts.filter(x => !this._shopservice.retainedGiftCards.some(y => y.id == x.id));
      }
      if (this._shopservice.sectionName == "RTID") {
        this._shopservice.selectedProducts.forEach(x => {
          if (x.Gratuity && x.Gratuity.length) x.isGratuityReturn = true;
          if (x.ServiceCharge && x.ServiceCharge.length) x.isServiceChargeReturn = true;
        });
      }
      this.selectedReturnItems = this._shopservice.selectedProducts;
      const refundlabel = this._shopservice.ReturnMethod == 1 ? this.captions.ReturnWithoutExchange : this.captions.ReturnWithExchange;
      await this.onDDschange(this._shopservice.ReturnMethod, refundlabel, '');
    }

    if (this._shopservice.ProductId == GlobalConst.Product.GOLF) {
      await this.GetPlayerDetails(this._shopservice.transactionId);
      if (this._shopservice.returnWithticketItems.some(x => x.rainCheckIssued)) {
        if (!this._shopservice.selectedRetainedRetailProducts.some(x => x.rainCheckIssued)) {
          this._shopservice.selectedRetainedRetailProducts = this._shopservice.returnWithticketItems.filter(x => x.rainCheckIssued);
        }
        this._shopservice.selectedReturnedRetailProducts = this._shopservice.returnWithticketItems.filter(x => !x.rainCheckIssued);
      }
    }

    if (this._shopservice.ProductId === GlobalConst.Product.SPA) {
      await this.GetAppointmentClientDetails(this._shopservice.transactionId);
    }
    if (this._shopservice.selectedclient && this._shopservice.selectedclient.guestProfileId != undefined &&
      (this._shopservice.selectedclient.playerCategoryId === PlayerCategory.Member || this._shopservice.selectedclient.guesttype == myGlobals.ClientType.Member)) {
      this._shopservice.memberCardNumber = this._shopservice.selectedclient.guestProfileId;
      await this._shopservice.GetRetailItemDetails(this._shopservice.selectedExchangeRetailProducts);

      let miscSettings = await this._shopservice.storeMiscSetting;
      const memberConfig = this.PropertyInfo.getMemberConfig;
      const applyMemberDiscountAutomatically = memberConfig?.applyMemberDiscountAutomatically?.toLowerCase() === 'true';
      const overrideMemberDiscount = memberConfig?.overrideWithMemberDiscounts?.toLowerCase() === 'true';
      const activeDiscountReasons = applyMemberDiscountAutomatically ? await this.applyDiscountService.getActiveDiscountReasons() : null;
      const activeDiscountTypes = applyMemberDiscountAutomatically ? await this.applyDiscountService.getActiveDiscountTypes() : null;
      const memberInfo =  this._shopservice.selectedMemberInfo;
      let nonEligibleForDiscount = [];

      this._shopservice.selectedExchangeRetailProducts.forEach(product => {
        let currentItem = this._shopservice.SelectedRetailItemDetails.find((x) => x.id == product.ItemId);
        const tempProductPrice = currentItem.retailItemDetail.isOpenItem ? product.ProductPrice :
          currentItem.retailItemDetail.memberPrice;
        product.ProductPrice = currentItem.retailItemDetail.isScaledItem ?
          this.localization.currencyToSQLFormat(String(product.scaledUnits)) * currentItem.retailItemDetail.memberPrice : tempProductPrice;

        //Applying discount based on the discount type in Engage						
        if (applyMemberDiscountAutomatically && !product.isAutoMemDiscApplied && (overrideMemberDiscount || product.Discount == 0)) {
          let nonEligible = this._shopservice.ApplyMemberDiscountAutomatically(product, miscSettings, activeDiscountReasons, this.applyDiscountService.AllCategoryDiscountList, activeDiscountTypes, memberInfo?.discountType);
          if (nonEligible)
            nonEligibleForDiscount.push(nonEligible);
        }
        product.Discount = product.DiscountPercentage > 0 ? product.ProductPrice * product.DiscountPercentage * 0.01 : product.Discount;
      });
      if (nonEligibleForDiscount.length > 0) {
        this.utils.ShowErrorMessage(
          this.localization.captions.common.Error,
          this.localization.replacePlaceholders(
            this.ShopCaptions.DiscountNotEligibleForSomeItem,
            ['ItemCount'],
            [nonEligibleForDiscount.length]
          )
        );
      }
    }
    this.FormExchangedGridData();

    this.GridItems = this._shopservice;

    this.SSITax = this._shopservice.selectedRetailItem.totalTax;

    await this.GetTaxForExchangedItems(ShopSummaryExchangedItemAction.AddTax);
    if (!this.PropertyInfo.UseRetailInterface) {
      this._shopservice.checkForAccess();
    }
    else {
      await this.CreateTicketForNonEmbedded();
    }
    this.CreateTicketForRetainItems();    
    this.SelectedRetailItemDetails = await this._shopservice.GetRetailItemDetails(this._shopservice.selectedExchangeRetailProducts);
    this.setCustomFeeItemDetails();

    if(this._sbs.scheduledTeeTimeIds && this._sbs.scheduledTeeTimeIds.length && this._sbs.isMultipleMemberTransaction) {
      this.ListChecked({ checked : true}, this.GridItems.selectedTransactionRetailProducts, 'arr_SI',0, 0);
    }
    if(this.GridItems.selectedTransactionRetailProducts && this.GridItems.selectedTransactionRetailProducts.some(r => r.ItemType && r.ItemType == RetailItemType.RevenueItem))
      {
        this.ListChecked({ checked : true}, this.GridItems.selectedTransactionRetailProducts, 'arr_SI',0, 0); 
      }
    this._sbs.isRefreshShopServiceItems.subscribe(x => {
      if (x) {
        this.CalculateSectionTotal();
      }
    });

    this._shopservice.AutoApplyTipAsGratuity.subscribe(async (data: ApplyTipAsGratuityData) => {
      if (data) {
        await this.ApplyTipAsGratuityForExchangeItem(data);
      }
    });

    this._shopservice.RemoveTipAsGratuity.subscribe(async (data: PaymentHistory[]) => {
      if (data) {
        await this.RemoveTipFromReversedPayment(data);
      }
    });
  }

  recheckCustomFeeList(removedItems) {
    this.GridItems.selectedExchangeRetailProducts.filter(x => x.appliedCustomFee && x.appliedCustomFee.length > 0).forEach(element => {
      element.appliedCustomFee.forEach(x => {
        if (removedItems.some(t => t.customFee && t.sourceTypeId == element.sourceTypeId && t.customFee.customFeeId == x)) {
          element.appliedCustomFee = element.appliedCustomFee.filter(e => e != x);
        }
      })
      element.charges[5].isactive = element.appliedCustomFee.length < this._shopservice.currentCustomFee.length
    });
  }

  async CreateTicketForNonEmbedded() {
    // Check should be created to calculate servicecharge/gratuity from net price based on config for IG
    if (this.PropertyInfo.UseRetailInterface && this._shopservice.selectedExchangeRetailProducts
      && this._shopservice.selectedExchangeRetailProducts.length > 0) {
      if (await this._shopservice.IsServiceChargeOnNet() || await this._shopservice.IsGratuityOnNet()) {
        this._shopservice.OriginalTicket = this._shopservice.Ticket = await this.transactionService.CreateTicket
          (this._shopservice.selectedExchangeRetailProducts, this._shopservice.settleOpenTransaction);
          this.CalculateSectionTotal();
      }
    }
  }
  ngAfterViewInit() {
    this.updateQuantityValue();
  }

  async setCustomFeeItemDetails() {
		if (this._shopservice.currentCustomFee && this._shopservice.currentCustomFee.length > 0) {
			console.log(this._shopservice.currentCustomFee.map(x => x.linkedItemId));
			this.customFeeItemDetails = (await this._shopservice.getRetailItemsDetailedInfoByIds(this._shopservice.currentCustomFee.map(x => x.linkedItemId)));
		}
	}


  ngOnDestroy() {
    if (this.$destroyed) {
      this.$destroyed.next(true);
      this.$destroyed.complete();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.returnedValuesforGratuityAndServiceCharge = [];
  }

  MapLineNumberForEmbeddedItems() {
		this._shopservice.selectedExchangeRetailProducts.filter(f => !f.isGroupingKey || f.isPackagedItem || f.PackageItemId > 0).forEach((itm, index) => {
			itm.LineNumber = index + 1;
		});
	}

  async GetTaxForExchangedItems(action: ShopSummaryExchangedItemAction) {
    if (this.PropertyInfo.UseRetailInterface || this._shopservice.selectedExchangeRetailProducts.length === 0) {
      return;
    }
    if (action == ShopSummaryExchangedItemAction.AddTax) {
      this._retailModuleService.loaderEnable.next(this.ShopCaptions.AddTax);
    }
    else if (action == ShopSummaryExchangedItemAction.RemoveItems) {
      this._retailModuleService.loaderEnable.next(this.ShopCaptions.RemoveItem);
    }
    this.MapLineNumberForEmbeddedItems();
    await this.InvokeCreateTicketForExchangedItems(this._shopservice.settleOpenTransaction || this._shopservice.settleRWTTransaction);
  }

  FormExchangedGridData() {
    let _thisref = this;
    if (this._shopservice.selectedExchangeRetailProducts.length == 0) {
      return;
    }
    this._shopservice.selectedExchangeRetailProducts.forEach(elem => {
      elem.charges = [];
      if (!elem.customFee) {
        elem.charges = [
          {
            "id": 1, "name": elem.Commission && elem.Commission.length > 0 ? _thisref.localization.captions.shop.EditCommissionGratuity : _thisref.localization.captions.shop.AddCommissionGratuity,
            "alias": "commission", "isactive": true, isApplied: elem.Commission && elem.Commission.length > 0 ? true : false, "commissionable": elem.isCommissionable
          },
          { "id": 2, "name": _thisref.localization.captions.shop.RemoveGratuity, "alias": "gratuity", "isactive": (elem.Gratuity && elem.Gratuity.length > 0 && elem.Gratuity.find(r => r.gratuity > 0)) ? true : false, isApplied: (elem.Gratuity && elem.Gratuity.length > 0 && elem.Gratuity.find(r => r.gratuity > 0)) ? true : false },
          { "id": 3, "name": _thisref.localization.captions.shop.RemoveCommission, "alias": "commission", "isactive": elem.Commission && elem.Commission.length > 0 ? true : false, isApplied: elem.Commission && elem.Commission.length > 0 ? true : false },
          { "id": 4, "name": _thisref.localization.captions.shop.RemoveServicecharge, "alias": "servicecharge", "isactive": (elem.ServiceCharge && elem.ServiceCharge.length > 0 && elem.ServiceCharge.find(r => r.ServiceCharge > 0)) ? true : false, isApplied: (elem.ServiceCharge && elem.ServiceCharge.length > 0 && elem.ServiceCharge.find(r => r.ServiceCharge > 0)) ? true : false },
          { "id": 5, "name": _thisref.localization.captions.shop.RemoveDiscount, "alias": "percent", "isactive": elem.Discount > 0, isApplied: elem.Discount > 0 },
        ]
      }
      if (_thisref._shopservice.isCustomFeeEnabled && !elem.customFee && elem.ItemType != RetailItemType.CustomFee && _thisref._shopservice.currentCustomFee && _thisref._shopservice.currentCustomFee.length > 0) {
        elem.charges.push({
          "id": 6,
          "name": _thisref.localization.captions.shop.addCustomFee,
          "isactive": !elem.MultiPack && ((elem.appliedCustomFee != null) ? elem.appliedCustomFee.length < this._shopservice.currentCustomFee.length : true),
          isApplied: false
        })
      }
      else if (elem.ItemType == RetailItemType.CustomFee && elem.customFee) {
        elem.charges.push({
          "id": 7,
          "name": _thisref.localization.captions.shop.editCustomFee,
          "isactive": elem.retailItemType == RetailItemType.CustomFee,
          isApplied: false
        })
      }
    });
  }

  async onDDschange(index, data, frompage) {   //frompage =='RetailSummary'
    this._shopservice.ReturnMethod = index;
    this._shopservice.selectedMultiPackExchangedProducts = [];
    let fullRedeemCount = 0;
    if (this._shopservice.MultiPackProducts.length > 0) {
      if (frompage == 'RetailSummary') {
        this._shopservice.MultiPackProducts.forEach(y => {
          const selectedmultipackitem = this.RS_selectedRtainedItems.find(x => x.id == y.id);
          if (selectedmultipackitem) {
            if (selectedmultipackitem.ProductPrice * selectedmultipackitem.Noofitems == y.ProductPrice * y.Noofitems) {
              fullRedeemCount = fullRedeemCount + 1;
            }
            this._shopservice.selectedMultiPackExchangedProducts.push(y);
          }
        })
      }
      else {
        this._shopservice.MultiPackProducts.forEach(y => {
          const selectedmultipackitem = this.selectedReturnItems.find(x => x.id == y.id);
          if (selectedmultipackitem) {
            if (selectedmultipackitem.ProductPrice * selectedmultipackitem.Noofitems == y.ProductPrice * y.Noofitems) {
              fullRedeemCount = fullRedeemCount + 1;
            }
            this._shopservice.selectedMultiPackExchangedProducts.push(y);
          }
        })
      }
    }
    this._shopservice.DDselection = data;
    if (this._shopservice.selectedMultiPackExchangedProducts.length > 0) {
      let multipackRedeemAmount = 0;
      this._shopservice.selectedMultiPackExchangedProducts.forEach(x => {
        multipackRedeemAmount += x.totalAmount
      });
      if (fullRedeemCount > 0) {
        const errorMsg = this.localization.replacePlaceholders(this.captions.MultiPackReturnConfirmationFullRedeem,
          ['Amount'], [this.localization.localizeCurrency(multipackRedeemAmount)]);
        this.utils.showAlert(errorMsg, AlertType.Info, ButtonType.OkCancel, async (res) => {
          if (res == AlertAction.CONTINUE) {
            this._shopservice.selectedMultiPackExchangedProducts = [];
            this.ReturnTypeSelection(index, data, frompage);
          }
        });
      }
      else {
        const errorMsg = this.localization.replacePlaceholders(this.captions.MultiPackReturnConfirmation,
          ['Amount'], [this.localization.localizeCurrency(multipackRedeemAmount)]);
        this.utils.showAlert(errorMsg, AlertType.Info, ButtonType.YesNo, async (res) => {
          if (res == AlertAction.NO) {
            this._shopservice.selectedMultiPackExchangedProducts = []
          }
          this.ReturnTypeSelection(index, data, frompage);
        });
      }
    }
    else {
      this.ReturnTypeSelection(index, data, frompage);
    }
    this._sbs.returnItemsListModified.emit(true);
  }

  async ReturnTypeSelection(index, data, frompage) {
    if (index == 1) {  // Item Without Exchange
      this._shopservice.returnExchangeType = "item_WOE" // without Exchange
      this._shopservice.selectedExchangeRetailProducts = [];
      if (frompage == 'RetailSummary') {
        this.GetReturnedAndReatinedProductsFromSummary(this.RS_selectedRtainedItems);
        this.RS_selectedRtainedItems = [];
        console.log("end of DDSCHANGE from order summary", this._shopservice.selectedRetainedRetailProducts, this._shopservice.selectedReturnedRetailProducts, this._shopservice.selectedExchangeRetailProducts);
      } else {
        this._shopservice.selectedRetainedRetailProducts = [];
        this._shopservice.selectedReturnedRetailProducts = [];
        await this.GetReturnedAndReatinedProducts(this.selectedReturnItems);
        console.log("end of DDSCHANGE to order summ", this._shopservice.selectedRetainedRetailProducts, this._shopservice.selectedReturnedRetailProducts, this._shopservice.selectedExchangeRetailProducts);
      }
      this._shopservice.sectionName = 'RIS'; // Retail Item Summary
    }
    if (index == 2) { // Item With Exchange
      this._shopservice.returnExchangeType = "item_WE" // with Exchange
      if (frompage == 'RetailSummary') {
        this.GetReturnedAndReatinedProductsFromSummary(this.RS_selectedRtainedItems);
        this._shopservice.selectedProducts = this._shopservice.selectedExchangeRetailProducts; // exisitng exchaged items should be added in shop cart by default
      } else {
        this._shopservice.selectedRetainedRetailProducts = [];
        this._shopservice.selectedReturnedRetailProducts = [];
        await this.GetReturnedAndReatinedProducts(this.selectedReturnItems);
        this._shopservice.selectedProducts = [];
      }
      this._shopservice.retailTransaction.tabState = 0;
      this._shopservice.isfromReturnPage = true;
      this._shopservice.sectionName = "RI"; // Retail Item
    }
  }

  MapRetainedServiceChargeGratuityOfSelectedProduct(item: SelectedProducts, originalQuantity, retainedItemQunatity): ServiceChargeGratuity[] {
    let svcg: ServiceChargeGratuity[] = [];
    if (item.Gratuity && item.Gratuity.length > 0) {
      item.Gratuity.forEach(g => {
        let grat: ServiceChargeGratuity = {
          Id: 0,
          TransactionDetailId: 0,
          GratuityPercent: g.Percentage,
          Gratuity: g.PercOrAmount == 1 ? g.gratuity : Number(((g.gratuity / originalQuantity) * retainedItemQunatity)),
          TotalGratuity: g.PercOrAmount == 1 ? g.gratuity : Number(((g.gratuity / originalQuantity) * retainedItemQunatity)) + g.customPercOrAmount == 1 ? g.additionalGratuity : Number(((g.additionalGratuity / originalQuantity) * retainedItemQunatity)),
          Date: this.utils.convertDateFormat(this.PropertyInfo.CurrentDate),
          ItemId: item.ItemId,
          StaffType: g.StaffType,
          TherapistId: g.TherapistId,
          ServiceCharge: 0,
          ServiceChargePercent: 0,
          TotalServiceCharge: 0,
          additionalGratuity: g.customPercOrAmount == 1 ? g.additionalGratuity : Number(((g.additionalGratuity / originalQuantity) * retainedItemQunatity)),
          additionalGratuityPercent: g.customPercentage,
          additionalServiceCharge: 0,
          additionalServiceChargePercent: 0
        }

        svcg.push(grat);
      });
    }
    if (item.ServiceCharge && item.ServiceCharge.length > 0) {
      item.ServiceCharge.forEach(s => {
        let svc: ServiceChargeGratuity = {
          Id: 0,
          TransactionDetailId: 0,
          GratuityPercent: 0,
          Gratuity: 0,
          TotalGratuity: 0,
          Date: this.utils.convertDateFormat(this.PropertyInfo.CurrentDate),
          ItemId: item.ItemId,
          StaffType: s.StaffType,
          TherapistId: s.TherapistId,
          ServiceCharge: s.PercOrAmount == 1 ? s.ServiceCharge : Number(((s.ServiceCharge / originalQuantity) * retainedItemQunatity)),
          ServiceChargePercent: s.Percentage,
          TotalServiceCharge: s.PercOrAmount == 1 ? s.ServiceCharge : Number(((s.ServiceCharge / originalQuantity) * retainedItemQunatity)),
          additionalServiceCharge: s.customPercOrAmount == 1 ? s.additionalServiceCharge : Number(((s.additionalServiceCharge / originalQuantity) * retainedItemQunatity)),
          additionalServiceChargePercent: s.customPercentage,
          additionalGratuity: 0, 
          additionalGratuityPercent: 0
        }

        svcg.push(svc);
      });
    }
    return svcg;
  }

  // Will be used only from middle page - Return without exchange
  async GetReturnedAndReatinedProducts(returnItem) {

    let modifiedReturnItems: any = [];
    modifiedReturnItems = this.frameReturnItems(returnItem);
    if (returnItem.length > 0) {
      const shopItems: RetailShopItem[] = [];
      const retainedProducts: SelectedProducts[] = [];
      const returnedProducts: SelectedProducts[] = [];
      let index = 0;
      let lineNumber = 1;

      for (let i = 0; i < this._shopservice.returnWithticketItems.length; i++) {
        const e = _.cloneDeep(this._shopservice.returnWithticketItems[i]);
        const originalQuantity = Number(e.Noofitems);
        const originalDiscount = e.Discount != 0 ? e.Discount : 0;
        const originalWeightedDiscount = e.weightedDiscount != 0 ? e.weightedDiscount : 0;
        const originalTax = e.Tax != 0 ? e.Tax : 0;
        const originalVat = e.Vat !=0 ? e.Vat : 0;
        const originalItem = modifiedReturnItems.filter(r => {
          return r.ItemId == e.ItemId && r.LineNumber == e.LineNumber
        });
        if (originalItem.length > 0) {
          originalItem[0].Discount = originalDiscount;
          originalItem[0].weightedDiscount = originalWeightedDiscount;
        }
        if (originalItem.length == 0) {
          this._shopservice.returnWithticketItems[i].returnType = ReturnWithTicketType.Default;
          e.returnType = ReturnWithTicketType.Default;
        }
        // part of returm
        if (originalItem && originalItem.length) {
          const returnQuantity = Number(originalItem[0].Noofitems);

          if (originalQuantity > returnQuantity) {
            const retainedItemQunatity = originalQuantity - returnQuantity;
            let isPercent = originalItem[0].DiscountPercentage > 0;
            let retainedDiscount = this.utils.MidPointRoundOffTwo(Number(((originalDiscount / originalQuantity) * retainedItemQunatity)));
            let retainedWeightedDiscount = this.utils.MidPointRoundOffTwo(Number(((originalWeightedDiscount / originalQuantity) * retainedItemQunatity)));
            if (isPercent) {
              retainedDiscount = originalItem[0].DiscountPercentage;
            }
            e.Noofitems = retainedItemQunatity;
            e.Discount = retainedDiscount;
            e.weightedDiscount = retainedWeightedDiscount;
            if (!originalItem[0].returnType || (originalItem[0].returnType == ReturnWithTicketType.Default)) {

              const discount: ApplyDiscount = {
                index: index,
                isPercentage: isPercent,
                value: retainedDiscount
              };

              // new ticket has been created to avoid 0.01 round off in manual tax split-up
              // ex : original items tax - 2.21 for qty of 2 and return 1 qty
              let shopItem = this.getRetailShopItemModel(originalItem[0].ItemId, originalItem[0].ExternalPOSItemId, originalItem[0].ProductPrice,
                (originalQuantity - returnQuantity), discount, lineNumber, originalItem.allowEarn, this.MapRetainedServiceChargeGratuityOfSelectedProduct(originalItem[0], originalQuantity, retainedItemQunatity));
              shopItem.DiscountTypeId = e.DiscountTypeId;
              shopItem.weightedDiscount = e.weightedDiscount;
              shopItems.push(shopItem);
              returnedProducts.push(originalItem[0]);
              e.returnType = ReturnWithTicketType.Default;
              retainedProducts.push(e);
              index++;
              lineNumber++;
              continue;
            }

            else {
              // in case of partial quantity, for item to be retained when both service charge and gratuity alone are returned
              const discount: ApplyDiscount = {
                index: index,
                isPercentage: isPercent,
                value: retainedDiscount
              };
              let shopItem =this.getRetailShopItemModel(originalItem[0].ItemId, originalItem[0].ExternalPOSItemId, originalItem[0].ProductPrice,
                (originalQuantity - returnQuantity), discount, lineNumber, originalItem.allowEarn, this.MapRetainedServiceChargeGratuityOfSelectedProduct(originalItem[0], originalQuantity, retainedItemQunatity));
                shopItem.DiscountTypeId = e.DiscountTypeId;
                shopItem.weightedDiscount = retainedWeightedDiscount;
              shopItems.push(shopItem);
              e.returnType = ReturnWithTicketType.Default;
              e.isPartialReturn = true;
              retainedProducts.push(e);
              let dummyItemForServiceChargeAndGratuity = _.cloneDeep(e);
              dummyItemForServiceChargeAndGratuity.returnType = ReturnWithTicketType.DUMMYRETURNFORSERVICECHARGEANDGRATUITY;
              returnedProducts.push(dummyItemForServiceChargeAndGratuity);
              index++;
              lineNumber++;
            }

          }
          let retainedQuantity = originalQuantity - returnQuantity;
          if (originalQuantity == 1) {
            retainedQuantity = 1;
          }
          originalItem.forEach(element => {
            if (element.returnType <= ReturnWithTicketType.RETAINITEMONLY && element.returnType >= ReturnWithTicketType.RETAINITEMANDSERVICECHARGE) {
              let isPercent = element.DiscountPercentage > 0;
              let disc = element.Discount;
              if (originalQuantity > returnQuantity) {
                const retainedDiscount = this.utils.MidPointRoundOffTwo(Number(((originalDiscount / originalQuantity) * retainedQuantity)));
                const retainedWeightedDiscount = this.utils.MidPointRoundOffTwo(Number(((originalWeightedDiscount / originalQuantity) * retainedQuantity)));
                element.Discount = element.Discount - retainedDiscount;
                element.weightedDiscount = element.weightedDiscount - retainedWeightedDiscount;
                disc = element.Discount;
                if (isPercent) {
                  disc = element.DiscountPercentage
                }
                  element.Tax = Number(((originalTax / originalQuantity) * retainedQuantity).customToFixed());
                if(this.isVATEnabled)
                {
                    element.Vat = Number(((originalVat / originalQuantity) * retainedQuantity).customToFixed());
                }
                  const retainQtyPrice = Number((element.ProductPrice * retainedQuantity).customToFixed());
                element.SalesPrice = retainQtyPrice;
                element.isPartialReturn = true;
              }
              const discount: ApplyDiscount = {
                index: index,
                isPercentage: isPercent,
                value: disc
              };
              let shopItem =this.getRetailShopItemModel(element.ItemId, element.ExternalPOSItemId, element.ProductPrice,
                retainedQuantity, discount, lineNumber, element.allowEarn, this.MapRetainedServiceChargeGratuityOfSelectedProduct(originalItem[0], originalQuantity, retainedQuantity), element.returnType);
                shopItem.DiscountTypeId = element.DiscountTypeId;
                shopItem.weightedDiscount = element.weightedDiscount;
              shopItems.push(shopItem);
              element.isPartialReturn = true;
              retainedProducts.push(element);
              let dummyItemForServiceChargeAndGratuity = _.cloneDeep(e);
              dummyItemForServiceChargeAndGratuity.returnType = ReturnWithTicketType.DUMMYRETURNFORSERVICECHARGEANDGRATUITY;
              returnedProducts.push(dummyItemForServiceChargeAndGratuity);
              index++;
              lineNumber++;
              // this._shopservice.selectedRetainedRetailProducts.push(element);
            }
            else {
              if (this._shopservice.selectedMultiPackExchangedProducts.length > 0) {
                let multipack = this._shopservice.selectedMultiPackExchangedProducts.find(x => x.id == element.Id);
                if(multipack){
                  const price = Number((element.SalesPrice - multipack.totalAmount).customToFixed()) ;
                  element.ProductPrice = price;
                  element.SalesPrice =  price;
                  element.netPrice = price;
                  element.netUnitPrice = price;
                  element.costPrice = price;
                  element.netUnitPrice = price;
                }
              }
              this._shopservice.selectedReturnedRetailProducts.push(element);
            }
          });
        }
        else { // Not part of return
          this._shopservice.selectedRetainedRetailProducts.push(e);
        }
      }
      if (shopItems.length > 0) {
        const result = this.PropertyInfo.UseRetailInterface ? await this.CreateNewTicketAndDiscard(shopItems)
          : await this.CreateNewTicketForEmbedded(shopItems);
        if (result && result.lineItems && result.lineItems.length > 0) {
          let itemIndex = 0;
          result.lineItems.forEach(x => {
            // setting tax&discount for retained quantity
            retainedProducts[itemIndex].Discount = x.discount;
            retainedProducts[itemIndex].isDiscountModifiedByCreateTicket = true;
            retainedProducts[itemIndex].Tax = x.tax;
            if(this.isVATEnabled)
            {
              retainedProducts[itemIndex].Vat = x.vat;
            }
            retainedProducts[itemIndex].SalesPrice = x.amount;
            let ServciceChargeList: Svcg[] = [];
            if (x.serviceCharges && x.serviceCharges.length > 0) {
              retainedProducts[itemIndex].isServiceChargeModifiedByCreateTicket = true;
              x.serviceCharges.forEach(s => {
                let svc: Svcg = {
                  ServiceCharge: s.serviceChargeValue,
                  StaffType: s.staffType,
                  Id: s.id,
                  TherapistId: s.therapistId,
                  TransactionDetailId: s.transactionDetailId,
                  PercOrAmount: s.isPercentage ? 1 : 2,
                  Percentage: s.isPercentage ? s.value : 0,
                  PercentageId: 0,
                  Amount: s.serviceChargeValue,
                  customAmount: s.additionalServiceChargeValue,
                  additionalServiceCharge: s.additionalServiceChargeValue,
                  customPercentage: s.additionalServiceChargeIsPercentage ? s.additionalValue : 0,
                  customPercOrAmount: s.additionalServiceChargeIsPercentage ? 1 : 2
                }
                ServciceChargeList.push(svc);
              })
            }
            retainedProducts[itemIndex].ServiceCharge = ServciceChargeList;
            let GratutityList: Grat[] = [];
            if (x.gratuities && x.gratuities.length > 0) {
              retainedProducts[itemIndex].isGratuityModifiedByCreateTicket = true;
              x.gratuities.forEach(s => {
                let grat: Grat = {
                  gratuity: s.gratuityValue,
                  StaffType: s.staffType,
                  Id: s.id,
                  TherapistId: s.therapistId,
                  TransactionDetailId: s.transactionDetailId,
                  PercOrAmount: s.isPercentage ? 1 : 2,
                  Percentage: s.isPercentage ? s.value : 0,
                  PercentageId: 0,
                  Amount: s.gratuityValue,
                  customAmount: s.additionalGratuityValue,
                  customPercentage: s.additionalGratuityIsPercentage ? s.additionalValue : 0,
                  customPercOrAmount: s.additionalGratuityIsPercentage ? 1 : 2,
                  additionalGratuity: s.additionalGratuityValue
                }
                GratutityList.push(grat);
              })
            }
            retainedProducts[itemIndex].Gratuity = GratutityList;
            this._shopservice.selectedRetainedRetailProducts.push(retainedProducts[itemIndex]);
            // setting tax&discount for returned quantity
            if (returnedProducts[itemIndex]) {
              if (returnedProducts[itemIndex].returnType != ReturnWithTicketType.DUMMYRETURNFORSERVICECHARGEANDGRATUITY) {
                if (returnedProducts[itemIndex].Discount > 0) {
                  returnedProducts[itemIndex].Discount = Number((Math.abs(returnedProducts[itemIndex].Discount - x.discount)).customToFixed());
                  returnedProducts[itemIndex].isDiscountModifiedByCreateTicket = true;
                }
                  returnedProducts[itemIndex].Tax = Number((Math.abs(returnedProducts[itemIndex].Tax - x.tax)).customToFixed());
                if(this.isVATEnabled)
                {
                    returnedProducts[itemIndex].Vat = Number((Math.abs(returnedProducts[itemIndex].Vat - x.vat)).customToFixed());
                }
                returnedProducts[itemIndex].SalesPrice = x.amount;
                returnedProducts[itemIndex]?.Gratuity.forEach(gt => {
                  returnedProducts[itemIndex].isGratuityModifiedByCreateTicket = true;
                  let retainedGratuity = x.gratuities.find(rg => rg.therapistId == gt.TherapistId);
                  if (retainedGratuity) {
                    gt.Amount = Number((Math.abs(gt.Amount - retainedGratuity.gratuityValue)).customToFixed());
                    gt.gratuity = Number((Math.abs(gt.gratuity - retainedGratuity.gratuityValue)).customToFixed());
                    if (gt?.GratuityTax != 0) {
                      let gratuityTax = retainedGratuity.taxDetails != null && retainedGratuity.taxDetails.length > 0 ?
                        retainedGratuity.taxDetails.reduce((prevTaxValue, currentTaxValue) => prevTaxValue + currentTaxValue.value, 0) : 0;
                      gt.GratuityTax = gt.GratuityTax - gratuityTax;
                    }
                  }
                });
                returnedProducts[itemIndex]?.ServiceCharge.forEach(sc => {
                  returnedProducts[itemIndex].isServiceChargeModifiedByCreateTicket = true;
                  let retainedServiceCharge = x.serviceCharges.find(rs => rs.therapistId == sc.TherapistId);
                  if (retainedServiceCharge) {
                    sc.Amount = Number((Math.abs(sc.Amount - retainedServiceCharge.serviceChargeValue)).customToFixed());
                    sc.ServiceCharge = Number((Math.abs(sc.ServiceCharge - retainedServiceCharge.serviceChargeValue)).customToFixed());
                    if (sc?.ServiceChargeTax != 0) {
                      let serviceChargeTax = retainedServiceCharge.taxDetails != null && retainedServiceCharge.taxDetails.length > 0 ?
                        retainedServiceCharge.taxDetails.reduce((prevTaxValue, currentTaxValue) => prevTaxValue + currentTaxValue.value, 0) : 0;
                      sc.ServiceChargeTax = sc.ServiceChargeTax - serviceChargeTax;
                    }
                  }
                });
                this._shopservice.selectedReturnedRetailProducts.push(returnedProducts[itemIndex]);
              }

            }
            itemIndex++;
          }
          );
        }
      }
    }
    this.CalculateTotal();
    this._sbs.returnItemsListModified.emit(true);
  }
  buildServiceChargeTotalPrice(item: SelectedProducts) {
    let price = 0;
    item.ServiceCharge.forEach(x => {
      price += x.Amount;
    })
    return price;
  }

  buildGratutityTotalPrice(item: SelectedProducts) {
    let price = 0;
    item.Gratuity.forEach(x => {
      price += x.Amount;
    })
    return price;
  }

  buildServiceChargeandGratuitAmt(originalItem, amount, type, partialQty) {
    if(! originalItem) return;
    const originalQuantity: number = Number(originalItem.Noofitems);
    if (type == "GRATUITY") {
      let gratuity = 0;
      const perUnitGratuity = amount / originalQuantity;
      gratuity = Number((perUnitGratuity * partialQty).customToFixed());
      return gratuity;
    }
    if (type == "SERVICECHARGE") {

      let servCharge = 0;
      const perUnitServCharge = amount / originalQuantity;
      servCharge = Number((perUnitServCharge * partialQty).customToFixed());
      return servCharge;
    }
  }

  CalculateServiceChargeTaxForPartialQty(item:SelectedProducts, originalQty:number, retainQty:number)
  {
    item.ServiceCharge.forEach(s => 
      {
        if(s.ServiceChargeTax != null && s.ServiceChargeTax != undefined)
        {
          s.ServiceChargeTax = this.utils.RoundOff((s.ServiceChargeTax /originalQty ) * retainQty);
        }               
      });
  }
  CalculateGratuityTaxForPartialQty(item:SelectedProducts, originalQty:number, retainQty:number)
  {
    item.Gratuity.forEach(s => 
      {
        if(s.GratuityTax != null || s.GratuityTax !=undefined)
        {
          s.GratuityTax =this.utils.RoundOff((s.GratuityTax /originalQty ) * retainQty);
        }               
      });
  }


  frameReturnItems(returnItems: Array<any>): any {
    const partialItemReturn: Array<SelectedProducts> = this.GridItems.selectedTransactionRetailProducts?.filter(x => x?.isServiceChargeReturn || x?.isGratuityReturn);
    if (partialItemReturn && partialItemReturn.length) {
      partialItemReturn.forEach(x => {
        const originalItem = this._shopservice.returnWithticketItems.filter(r => r.id == x.id && r.OriginalLineNumber == x.OriginalLineNumber)[0];
        const returnItem = returnItems?.find(y => y.LineNumber == x.LineNumber);
        if (!returnItem) {
          const item: SelectedProducts = _.cloneDeep(x);
          if (x?.isServiceChargeReturn && !x?.isGratuityReturn) {
            if (x.isPartialReturn) {
              x.ServiceCharge.forEach(serv => {
                const price = this.buildServiceChargeandGratuitAmt(originalItem, serv.ServiceCharge, "SERVICECHARGE", x.Noofitems);
                serv.ServiceCharge = serv.Amount = price;
              })
              this.CalculateServiceChargeTaxForPartialQty(item,originalItem.Noofitems,x.Noofitems);
            }
            x.returnType = ReturnWithTicketType.RETURNSERVICECHARGEONLY;
            if (x.Gratuity.length > 0) {
              item.returnType = ReturnWithTicketType.RETAINITEMANDGRATUITY;
            }
            else {
              item.returnType = ReturnWithTicketType.RETAINITEMONLY;
            }
            returnItems.push(item);
          }
          else if (x?.isGratuityReturn && !x?.isServiceChargeReturn) {
            if (x.isPartialReturn) {
              x.Gratuity.forEach(grat => {
                const price = this.buildServiceChargeandGratuitAmt(originalItem, grat.gratuity, "GRATUITY", x.Noofitems);
                grat.gratuity = grat.Amount = price;
              })
              this.CalculateGratuityTaxForPartialQty(item,originalItem.Noofitems,x.Noofitems);
            }
            x.returnType = ReturnWithTicketType.RETURNGRATUITYONLY;
            if (x.ServiceCharge.length > 0) {
              item.returnType = ReturnWithTicketType.RETAINITEMANDSERVICECHARGE;
            }
            else {
              item.returnType = ReturnWithTicketType.RETAINITEMONLY;
            }
            returnItems.push(item);
          }
          else if (x?.isServiceChargeReturn && x?.isGratuityReturn) {
            const item1: SelectedProducts = _.cloneDeep(x);
            if (x.Gratuity.length > 0) {
              item.returnType = ReturnWithTicketType.RETURNGRATUITYONLY;
              if (x.isPartialReturn) {
                item.Gratuity.forEach(grat => {
                  const price = this.buildServiceChargeandGratuitAmt(originalItem, grat.gratuity, "GRATUITY", item.Noofitems);
                  grat.gratuity = grat.Amount = price;
                })
                this.CalculateGratuityTaxForPartialQty(item,originalItem.Noofitems,x.Noofitems);
              }
              returnItems.push(item);
            }
            if (x.ServiceCharge.length > 0) {

              if (x.isPartialReturn) {
                item1.ServiceCharge.forEach(serv => {
                  const price = this.buildServiceChargeandGratuitAmt(originalItem, serv.ServiceCharge, "SERVICECHARGE", item1.Noofitems);
                  serv.ServiceCharge = serv.Amount = price;
                })
                this.CalculateServiceChargeTaxForPartialQty(item1,originalItem.Noofitems,x.Noofitems);
              }
              item1.returnType = ReturnWithTicketType.RETURNSERVICECHARGEONLY;
              item1.isServiceChargeReturn = item1.isGratuityReturn = false;
              returnItems.push(item1);
            }
            x.returnType = ReturnWithTicketType.RETAINITEMONLY;
          }
          item.isServiceChargeReturn = item.isGratuityReturn = x.isServiceChargeReturn = x.isGratuityReturn = false;
          returnItems.push(_.cloneDeep(x))
        }
        else {
          returnItem.returnType = ReturnWithTicketType.Default;
          returnItem.isServiceChargeReturn = returnItem.isGratuityReturn = false;
        }
      });
    }
    return returnItems;
  }

  Display(item: SelectedProducts, type: string) {
    if (type == "ITEMNAME") {
      let name;
      if (item.returnType == ReturnWithTicketType.RETURNSERVICECHARGEONLY) {
        name = this.ShopCaptions.ServiceCharge;
        return name;
      }
      else if (item.returnType == ReturnWithTicketType.RETURNGRATUITYONLY) {
        name = this.ShopCaptions.Gratuity;
        return name;
      }
      else {
        return item.ProductName;
      }
    }
    if (type == "ITEMPRICE") {     
      if (item.returnType == ReturnWithTicketType.RETURNSERVICECHARGEONLY) {             
        return  this.FormatPrice(0);
      }
      else if (item.returnType == ReturnWithTicketType.RETURNGRATUITYONLY) {
        return  this.FormatPrice(0);
      }
      else {
        return item.ProductPrice.toString();
      }
    }

    if (type == "QTY") {

      return item.Noofitems;
    }
  }

  getGratuity(item) {
    const grat = this._shopservice.Ticket && this._shopservice.Ticket.lineItems.some(l => l.index == item.LineNumber) ?
      this._shopservice.Ticket.lineItems.find(l => l.index == item.LineNumber)?.gratuity : 0;
    return this.FormatPrice(grat);
  }

  getServiceCharge(item) {
    const serviceCharge = this._shopservice.Ticket && this._shopservice.Ticket.lineItems.some(l => l.index == item.LineNumber) ?
      this._shopservice.Ticket.lineItems.find(l => l.index == item.LineNumber)?.serviceCharge : 0;
    return this.FormatPrice(serviceCharge);
  }

  MapModifiedValuestoOriginalItem(item: SelectedProducts, originalItem: SelectedProducts) {
    const originalClone = _.cloneDeep(originalItem);
    item.Discount = originalClone?.Discount;
    item.ServiceCharge = originalClone?.ServiceCharge;
    item.Gratuity = originalClone?.Gratuity;
  }

  ShowUnitGratuity(item: SelectedProducts, isfromRetain: boolean = false): string {

    let Unitgratuity: number = 0;
    if (item.returnType == ReturnWithTicketType.RETAINITEMONLY || item.returnType == ReturnWithTicketType.RETURNSERVICECHARGEONLY || item.returnType == ReturnWithTicketType.RETAINITEMANDSERVICECHARGE) {
      Unitgratuity = 0;
    }
    else {
      const originalItem = this._shopservice.returnWithticketItems.filter(r => r.id == item.id && r.OriginalLineNumber == item.OriginalLineNumber)[0];
      if (!originalItem) return;
      const originalQuantity: number = Number(originalItem.Noofitems);
      const newQuantity = Number(item.Noofitems);
      if (!isfromRetain && originalQuantity != newQuantity) {
        Unitgratuity = this.CalculateGrauityforPartialQty(item, originalItem, originalQuantity, newQuantity);
      }
      else if (isfromRetain) {
        const originalItem = this._shopservice.returnWithticketItems.filter(r => r.id == item.id && r.OriginalLineNumber == item.OriginalLineNumber)[0];
        const originalQuantity: number = Number(originalItem.Noofitems);
        const newQuantity = Number(item.Noofitems);

        if (originalQuantity != newQuantity) {
          if (item.isGratuityModifiedByCreateTicket) {
            Unitgratuity = this.CalculateGrauityforPartialQty(item, item, originalQuantity, newQuantity);
          }
          else {
            Unitgratuity = this.CalculateGrauityforPartialQty(item, originalItem, originalQuantity, newQuantity);
          }
        }
        else if (item?.returnType == ReturnWithTicketType.Default || item?.returnType == ReturnWithTicketType.RETAINITEMANDGRATUITY) {
          Unitgratuity = item && item.Gratuity && item.Gratuity.length > 0 ? item.Gratuity.map(x => x.gratuity + x.additionalGratuity ?? 0).reduce((a, b) => a + b, 0) : 0;
        }
      }
      else {
        Unitgratuity = item && item.Gratuity && item.Gratuity.length > 0 ? item.Gratuity.map(x => x.gratuity + x.additionalGratuity?? 0).reduce((a, b) => a + b, 0) : 0;
      }
    }

    return this.FormatPrice(Unitgratuity);

  }

  ShowUnitServiceCharge(item, isfromRetain: boolean = false): string {
    let UnitServiceCharge: number = 0;
    if (item.returnType == ReturnWithTicketType.RETAINITEMONLY || item.returnType == ReturnWithTicketType.RETURNGRATUITYONLY || item.returnType == ReturnWithTicketType.RETAINITEMANDGRATUITY) {
      UnitServiceCharge = 0;
    }
    else {
      const originalItem = this._shopservice.returnWithticketItems.filter(r => r.id == item.id && r.OriginalLineNumber == item.OriginalLineNumber)[0];
      if (!originalItem) return;
      const originalQuantity: number = Number(originalItem.Noofitems);
      const newQuantity = Number(item.Noofitems);
      if (!isfromRetain && originalQuantity != newQuantity) {

        UnitServiceCharge = this.CalculateServiceChargeforPartialQty(item, originalItem, originalQuantity, newQuantity);
      }
      else if (isfromRetain) {
        const originalItem = this._shopservice.returnWithticketItems.filter(r => r.id == item.id && r.OriginalLineNumber == item.OriginalLineNumber)[0];
        if (!originalItem) return;
        const originalQuantity: number = Number(originalItem.Noofitems);
        const newQuantity = Number(item.Noofitems);

        if (originalQuantity != newQuantity) {
          if (item.isServiceChargeFromCreateTicket) {
            UnitServiceCharge = this.CalculateServiceChargeforPartialQty(item, item, originalQuantity, newQuantity);
          }
          else {
            UnitServiceCharge = this.CalculateServiceChargeforPartialQty(item, originalItem, originalQuantity, newQuantity);
          }
        }
        else if (item?.returnType == ReturnWithTicketType.Default || item?.returnType == ReturnWithTicketType.RETAINITEMANDSERVICECHARGE) {
          UnitServiceCharge = item && item.ServiceCharge && item.ServiceCharge.length > 0 ? item.ServiceCharge.map(x => x.ServiceCharge + x.additionalServiceCharge ?? 0).reduce((a, b) => a + b, 0) : 0;
        }
      }
      else {
        UnitServiceCharge = item && item.ServiceCharge && item.ServiceCharge.length > 0 ? item.ServiceCharge.map(x => x.ServiceCharge + x.additionalServiceCharge ?? 0).reduce((a, b) => a + b, 0) : 0;
      }
    }

    return this.FormatPrice(UnitServiceCharge ?? 0);
  }

  FormatPrice(price: number) {
    if (price < 0) {
      return `(${(price * -1).customToFixed()})`;
    } else {
      return `${(price).customToFixed()}`;
    }
  }



  UpdateItemPrice(item: SelectedProducts, originalQuantity: number, returnQuantity: number) {
    if (item && originalQuantity > returnQuantity) {
      item.SalesPrice = Number(((item.SalesPrice / originalQuantity) * returnQuantity).customToFixed());
      item.Discount = this.utils.MidPointRoundOffTwo(Number(((item.Discount / originalQuantity) * returnQuantity)));
      item.Tax = item.Tax ? Number(((item.Tax / originalQuantity) * returnQuantity).customToFixed()) : 0;
    }
  }
  getRetailShopItemModel(itemId: number, externalPOSId: number, price: number, qunatity: number,
    discount: ApplyDiscount, lineNumber: number = 0, allowEarn: boolean = false, serviceChargeGratuity: ServiceChargeGratuity[] = [], returnType:ReturnWithTicketType = ReturnWithTicketType.Default) {
    return {
      ItemId: itemId,
      ItemType: 0,
      ItemDescription: '',
      ExternalPOSItemId: externalPOSId,
      QuantitySold: qunatity,
      UnitPrice: price,
      Discount: 0,
      DiscountTypeId: 0,
      DiscountPercentage: 0,
      StaffTransactionDetail: [],
      LineNumber: 0,
      Tax: 0,
      BaseTax: 0,
      LinkedTax: 0,
      TotalAmount: qunatity * price,
      OutletId: this._shopservice.SelectedOutletId,
      IsGroupingKey: false,
      IsPackagedItem: false,
      PackageItemId: 0,
      IsMultiPackRedeem: false,
      IsModificationRestricted: false,
      ClientMultiPackId: 0,
      PackageGroupId: 0,
      IsOpenPricedItem: false,
      id: 0,
      IsTaxExempt: false,
      discountModel: discount,
      costPrice: price,
      marginPercentage: price,
      allowEarn: allowEarn,
      discountComments: '',
      discountReason: 0,
      returnType: returnType,
      ServiceChargeGratuity: serviceChargeGratuity,
      weightedDiscount: 0
    };
  }


  async CreateNewTicketAndDiscard(lineItem: RetailShopItem[]): Promise<Ticket> {
    const ticket = await this.transactionService.CreateTicketForItem(lineItem, this._shopservice.SelectedTerminalId);
    if (this.PropertyInfo.UseRetailInterface && ticket && ticket.checkData) {
      this.transactionService.DiscardCheck(ticket.checkData);
    }
    return ticket;
  }

  async CreateNewTicketForEmbedded(lineItem: RetailShopItem[]): Promise<Ticket> {
    const ticket = await this.transactionEngineBusiness.CreateTicketForItem(this._shopservice.SelectedOutletId, lineItem);
    this.CalculateSectionTotal();
    return ticket;
  }
  
  frameReturnItemsFromRetainItems(returnItem: SelectedProducts[]) {
    const retainItems = this._shopservice.selectedRetainedRetailProducts.filter(x =>
      (!(returnItem || []).some(y => y.ItemId == x.ItemId && y.OriginalLineNumber == x.OriginalLineNumber) && (x?.isServiceChargeReturn || x?.isGratuityReturn)) || (x.returnType == ReturnWithTicketType.RETAINITEMONLY))
    if (retainItems && retainItems.length) {
      retainItems.forEach(x => {
        const originalItem = this._shopservice.returnWithticketItems.find(y => y.id == x.id);
        if (x?.isServiceChargeReturn) {
          const checkExistsInReturned = this._shopservice.selectedReturnedRetailProducts.findIndex(y => y.id == x.id && y.returnType == ReturnWithTicketType.RETURNSERVICECHARGEONLY);
          if (checkExistsInReturned != -1) {
            const originalClone = _.cloneDeep(originalItem);
            originalClone.returnType = ReturnWithTicketType.RETURNSERVICECHARGEONLY;
            originalClone.isServiceChargeReturn = false;
            this._shopservice.selectedReturnedRetailProducts[checkExistsInReturned] = originalClone;
          }
          else {
            const cloneItem = _.cloneDeep(x);
            cloneItem.returnType = ReturnWithTicketType.RETURNSERVICECHARGEONLY;
            cloneItem.isServiceChargeReturn = false;
            let originalServiceChargesToBeModified = _.cloneDeep(originalItem.ServiceCharge);
            let serviceChargeTax = 0;
            originalServiceChargesToBeModified.forEach(y => {
              y.ServiceCharge = y.Amount = this.buildServiceChargeandGratuitAmt(originalItem, y.ServiceCharge, "SERVICECHARGE", x.Noofitems);             
            })
            cloneItem.ServiceCharge = originalServiceChargesToBeModified;
            this.CalculateServiceChargeTaxForPartialQty(cloneItem,originalItem.Noofitems,x.Noofitems);           
            this._shopservice.selectedReturnedRetailProducts.push(cloneItem);
          }
        }
        if (x?.isGratuityReturn) {
          const checkExistsInReturned = this._shopservice.selectedReturnedRetailProducts.findIndex(y => y.id == x.id && y.returnType == ReturnWithTicketType.RETURNGRATUITYONLY);
          if (checkExistsInReturned != -1) {
            const originalClone = _.cloneDeep(originalItem);
            originalClone.returnType = ReturnWithTicketType.RETURNGRATUITYONLY;
            originalClone.isGratuityReturn = false;
            this._shopservice.selectedReturnedRetailProducts[checkExistsInReturned] = originalClone;
          }
          else {
            const cloneItem = _.cloneDeep(x);
            cloneItem.returnType = ReturnWithTicketType.RETURNGRATUITYONLY;
            cloneItem.isGratuityReturn = false;
            let originalGratuityToBeModified = _.cloneDeep(originalItem.Gratuity);
            originalGratuityToBeModified.forEach(y => {
              y.gratuity = y.Amount = this.buildServiceChargeandGratuitAmt(originalItem, y.gratuity, "GRATUITY", x.Noofitems);
            })
            cloneItem.Gratuity = originalGratuityToBeModified;
            this.CalculateGratuityTaxForPartialQty(cloneItem,originalItem.Noofitems,x.Noofitems);           
            this._shopservice.selectedReturnedRetailProducts.push(cloneItem);
          }
        }
        this.ModifyRetainedProducts(x);

      })
    }
  }

  ModifyRetainedProducts(i) {

    if (i.returnType == ReturnWithTicketType.Default) {
      if (i.isGratuityReturn && i.isServiceChargeReturn) {
        i.returnType = ReturnWithTicketType.RETAINITEMONLY;
      }
      else if (i.isGratuityReturn) {
        if (i.ServiceCharge.length) {
          i.returnType = ReturnWithTicketType.RETAINITEMANDSERVICECHARGE;
        }
        else {
          i.returnType = ReturnWithTicketType.RETAINITEMONLY;
        }

      }
      else if (i.isServiceChargeReturn) {
        if (i.Gratuity.length) {
          i.returnType = ReturnWithTicketType.RETAINITEMANDGRATUITY;
        }
        else {
          i.returnType = ReturnWithTicketType.RETAINITEMONLY;
        }
      }

    }
    else if (i.returnType == ReturnWithTicketType.RETAINITEMANDSERVICECHARGE) {
      if (i.isServiceChargeReturn) {
        i.returnType = ReturnWithTicketType.RETAINITEMONLY;
      }
    }
    else if (i.returnType == ReturnWithTicketType.RETAINITEMANDGRATUITY) {
      if (i.isGratuityReturn) {
        i.returnType = ReturnWithTicketType.RETAINITEMONLY;
      }
    }
    i.isServiceChargeReturn = i.isGratuityReturn = false;

    const checkMatch = this._shopservice.selectedRetainedRetailProducts.filter(z => z.id == i.id && z.returnType == i.returnType);
    if (checkMatch.length > 1) {
      let count = 0, qty = 0;
      this._shopservice.selectedRetainedRetailProducts = _.remove(this._shopservice.selectedRetainedRetailProducts, val => {
        if (val.id == i.id) {
          if (count == 0) {
            qty = val.Noofitems;
            count++;
            return false;
          }
          else {
            qty += Number(val.Noofitems);
          }
        }
        return true;
      });
      const mergedItem = this._shopservice.selectedRetainedRetailProducts.find(x => x.id == i.id);
      mergedItem.Noofitems = qty;
      mergedItem.isPartialReturn = false;
    }

  }

  async CreateTicketForRetainItems()
  {
    if(this._shopservice.selectedRetainedRetailProducts && this._shopservice.selectedRetainedRetailProducts.length > 0)
    {
      let modifiedRetainedItems = _.cloneDeep(this._shopservice.selectedRetainedRetailProducts);
      modifiedRetainedItems.forEach(ritm => {
        if (ritm.returnType) {
          switch (ritm.returnType) {
            case ReturnWithTicketType.RETAINITEMANDGRATUITY:
              ritm.ServiceCharge = [];
              break;
            case ReturnWithTicketType.RETAINITEMANDSERVICECHARGE:
              ritm.Gratuity = [];
              break;
            case ReturnWithTicketType.RETAINITEMONLY:
              ritm.ServiceCharge = [];
              ritm.Gratuity = [];
              break;
            default:
              break;
          }
        }
      });
      if(this._shopservice.transactionTicketDiscount?.length > 0) {
        const ticketDiscount = this._shopservice.transactionTicketDiscount[0];
        const isTicketDiscApplicableForRetainedItems = this._shopservice.selectedRetainedRetailProducts.some(x => x.weightedDiscount != 0);
        if(isTicketDiscApplicableForRetainedItems) {
          if(ticketDiscount.discountAmount > 0) {
            ticketDiscount.discountAmount = _.sumBy(this._shopservice.selectedRetainedRetailProducts , x => {return x.weightedDiscount } );
          }
          const discValue = ticketDiscount.discountPercentage > 0 ? ticketDiscount.discountPercentage : ticketDiscount.discountAmount;
          this.retainedTicketDiscount = {
            value : discValue,
            isPercentage: ticketDiscount.discountPercentage > 0,
            discountAmount : discValue,
            discountComments : ticketDiscount.discountComments,
            discountTypeId : ticketDiscount.discountTypeId,
            discountReason : ticketDiscount.discountReasonId,
            discountLevel: DiscountLevel.Ticket
          }
        }
      }
      console.log(this.retainedTicketDiscount)
      this._shopservice.retainedTicket = await this.transactionEngineBusiness.CreateTicket(
        this._shopservice.SelectedOutletId, modifiedRetainedItems, false, this.retainedTicketDiscount );
    }   
    this.CalculateSectionTotal();
  }

  // Will be used only from summary - Return without exchange
  GetReturnedAndReatinedProductsFromSummary(returnItem) {

    this.frameReturnItemsFromRetainItems(returnItem);
    this.CreateTicketForRetainItems();
    if (returnItem.length > 0) {
      for (let i = 0; i < returnItem.length; i++) {
        const item = _.cloneDeep(returnItem[i]);
        const removedFromRetained = this._shopservice.selectedRetainedRetailProducts.filter(r => {
          return r.ItemId == item.ItemId && r.LineNumber == item.LineNumber
            && r.Noofitems == item.Noofitems && r.returnType == item.returnType; // this condition for partial DD change
        });
        const returnQuantity = Number(item.Noofitems);
        let ExistingReturnItem;

        if (!item.isPartialReturn) {
          if (item.returnType > ReturnWithTicketType.Default) {
            ExistingReturnItem = this._shopservice.selectedReturnedRetailProducts.filter(r => {
              return r.ItemId == item.ItemId && r.LineNumber == item.LineNumber && !r.isPartialReturn
            });
          }
          else {
            ExistingReturnItem = this._shopservice.selectedReturnedRetailProducts.filter(r => {
              return r.ItemId == item.ItemId && r.LineNumber == item.LineNumber
            });
          }

        }
        else {
          ExistingReturnItem = this._shopservice.selectedReturnedRetailProducts.filter(r => {
            return r.ItemId == item.ItemId && r.LineNumber == item.LineNumber && r.isPartialReturn
          });
        }
        const originalItem = this._shopservice.returnWithticketItems.filter(r => r.id == ExistingReturnItem[0]?.id && r.OriginalLineNumber == ExistingReturnItem[0]?.OriginalLineNumber)[0];
        
        if (ExistingReturnItem[0]?.returnType > 0 && (item.returnType != ReturnWithTicketType.Default) && item.returnType != undefined) {
          if (ExistingReturnItem.length > 1) {

            var returnSCAndGItemIdx = this._shopservice.selectedReturnedRetailProducts.indexOf(ExistingReturnItem[1]);
            if (returnSCAndGItemIdx != -1) {
              this._shopservice.selectedReturnedRetailProducts.splice(returnSCAndGItemIdx, 1);
            }
            ExistingReturnItem[0].isPartialReturn = false;
            ExistingReturnItem[0].returnType = ReturnWithTicketType.RETURNSERVICECHARGEANDGRATUITY;

          }
          if ([ReturnWithTicketType.RETURNGRATUITYONLY, ReturnWithTicketType.RETURNSERVICECHARGEONLY, ReturnWithTicketType.RETURNSERVICECHARGEANDGRATUITY].includes(ExistingReturnItem[0].returnType)) {

            const retainQuantity = originalItem.Noofitems - returnItem[i].Noofitems;
            if (retainQuantity != 0) {                       
                ExistingReturnItem[0].Noofitems = retainQuantity;
                ExistingReturnItem[0].Discount = removedFromRetained[0] !=null ? removedFromRetained[0].Discount : this.utils.RoundOff(Number(((ExistingReturnItem[0].Discount / originalItem.Noofitems) * retainQuantity)));
                ExistingReturnItem[0].Tax = removedFromRetained[0] !=null ?  removedFromRetained[0].Tax : this.utils.RoundOff(Number(((ExistingReturnItem[0].Tax / originalItem.Noofitems) * retainQuantity)));
                ExistingReturnItem[0].Vat = removedFromRetained[0] !=null ?  removedFromRetained[0].Vat : this.utils.RoundOff(Number(((ExistingReturnItem[0]?.Vat / originalItem.Noofitems) * retainQuantity)));
                ExistingReturnItem[0].SalesPrice = originalItem.ProductPrice * retainQuantity;                                   
            }
            else {
              ExistingReturnItem[0].Discount = originalItem.Discount;
              ExistingReturnItem[0].Tax = originalItem.Tax;
              ExistingReturnItem[0].Vat = this.isVATEnabled ?originalItem.Vat : 0;
              ExistingReturnItem[0].SalesPrice = originalItem.SalesPrice;              
            }         
            ExistingReturnItem[0].Gratuity = originalItem.Gratuity;
            ExistingReturnItem[0].ServiceCharge = originalItem.ServiceCharge;                     
            ExistingReturnItem[0].returnType = ReturnWithTicketType.Default;
            ExistingReturnItem[0].isServiceChargeReturn = ExistingReturnItem[0].isGratuityReturn = false;
            ExistingReturnItem[0].isPartialReturn = false;
          }
        }
        else if (ExistingReturnItem && ExistingReturnItem.length > 0 && ExistingReturnItem[0].returnType == item.returnType) { // Update exisitng return object quantity when same item exists
          ExistingReturnItem[0].Noofitems = returnQuantity + Number(ExistingReturnItem[0].Noofitems);
          ExistingReturnItem[0].Discount = originalItem.Discount;
          ExistingReturnItem[0].Tax = originalItem.Tax;
          ExistingReturnItem[0].Vat = this.isVATEnabled ?originalItem.Vat : 0;
          ExistingReturnItem[0].SalesPrice = originalItem.SalesPrice;
          ExistingReturnItem[0].isPartialReturn = false;
        }
        else { // add into retuned object
          item.isPartialReturn = false;
          this._shopservice.selectedReturnedRetailProducts.push(item);
        }

        // Remove from retained object     
        const index = this._shopservice.selectedRetainedRetailProducts.indexOf(removedFromRetained[0]);
        if (index > -1) {
          this._shopservice.selectedRetainedRetailProducts.splice(index, 1);
        }

      }
    }
    this._shopservice.selectedReturnedRetailProducts.forEach(element => {
      element.isServiceChargeReturn = element.isGratuityReturn = false;
    });
    this.CalculateTotal();
  }



  ListChecked(e, data, arrname, type, i) {

    let collectionName;
    if (arrname == 'arr_SI') {
      collectionName = this.selectedReturnItems;
    }
    else if (arrname == 'arr_SER') {
      collectionName = this.RS_selectedExchangeItems;
    }
    else if (arrname == 'arr_SReR') {
      collectionName = this.RS_selectedReturnItems;
    }
    else if (arrname == 'arr_SRtR') {
      collectionName = this.RS_selectedRtainedItems;
    }        
    let associatedCustomFee;
    if(data.length!= this.GridItems.selectedTransactionRetailProducts.length)
    associatedCustomFee = this.GridItems.selectedTransactionRetailProducts.filter(x=>x.customFee && data.appliedCustomFee?.some(t=>t == x.customFee.customFeeId) && x.linkedItemLineNumber == data.LineNumber)

    if (data.length > 0) {
      //Reset the collection to avoid duplicate items. Adding any one item from the list and select all option will cause duplicate items. #Bug 100639
      collectionName = [];
      if (e.checked) {
        if (arrname == 'arr_SI') {
          this.disabledGratuity = this.disableServiceCharge = true;
        }
        data.forEach(element => {
          if (element.ServiceCharge && element.ServiceCharge.length > 0) {
            element.isServiceChargeReturn = true;
          }
          if (element.Gratuity && element.Gratuity.length > 0) {
            element.isGratuityReturn = true;
          }
          if(element.ItemType!=2)
          {
            collectionName.push(element);
          }
        });
      }
      else {
        collectionName = [];
        this.disabledGratuity = this.disableServiceCharge = false;
        data.forEach(element => {
          if (element.ServiceCharge && element.ServiceCharge.length > 0) {
            element.isServiceChargeReturn = false;
          }
          if (element.Gratuity && element.Gratuity.length > 0) {
            element.isGratuityReturn = false;
          }
        });
      }
    }
    else {
      if (e.checked) {
        if (this.RS_selectedReturnItems && (type == ReturnWithTicketType.RETURNGRATUITYONLY || type == ReturnWithTicketType.RETURNSERVICECHARGEONLY)) {
          collectionName.push(data);
        }
        else {
          let CheckDataExist;
          CheckDataExist = collectionName.filter(x => x.LineNumber == data.LineNumber);
          if (CheckDataExist.length == 0) {
            if (data.ServiceCharge && data.ServiceCharge.length > 0) {
              data.isServiceChargeReturn = true;
            }
            if (data.Gratuity && data.Gratuity.length > 0) {
              data.isGratuityReturn = true;
            }

            if (arrname == 'arr_SI') {
              this.disabledGratuity = this.disableServiceCharge = true;
            }
            if (arrname == 'arr_SRtR' && data) {
              if (data.isPartialReturn && (data.returnType == ReturnWithTicketType.RETAINITEMANDSERVICECHARGE || data.returnType == ReturnWithTicketType.RETAINITEMANDGRATUITY || data.returnType == ReturnWithTicketType.RETAINITEMONLY)) {
                data.isItemChecked = true;
                if (data.returnType == ReturnWithTicketType.RETAINITEMONLY) {
                  data.isServiceChargeReturn = data.isGratuityReturn = false;
                }
              }
            }

            collectionName.push(data);
          }
        }
        associatedCustomFee?.forEach(element => {
          collectionName.push(element)
        });
      }
      else {
        collectionName = collectionName.filter(x => x.LineNumber != data.LineNumber);
        this.disabledGratuity = this.disableServiceCharge = false;
        
        collectionName = collectionName.filter(x => !associatedCustomFee?.some(t => t.LineNumber == x.LineNumber))
        if (arrname == 'arr_SRtR' && data) {
          if (data.isPartialReturn && (data.returnType == ReturnWithTicketType.RETAINITEMANDSERVICECHARGE || data.returnType == ReturnWithTicketType.RETAINITEMANDGRATUITY || data.returnType == ReturnWithTicketType.RETAINITEMONLY)) {
            data.isItemChecked = false;
            if (data.returnType == ReturnWithTicketType.RETAINITEMONLY) {
              data.isServiceChargeReturn = data.isGratuityReturn = false;
            }
          }
        }
      }
    }
    if (arrname == 'arr_SI') {
      this.selectedReturnItems = collectionName;
    }
    if (arrname == 'arr_SER') {
      this.RS_selectedExchangeItems = collectionName;
    }
    if (arrname == 'arr_SReR') {
      this.RS_selectedReturnItems = collectionName;
    }
    if (arrname == 'arr_SRtR') {
      this.RS_selectedRtainedItems = collectionName;
    }
    if (this._shopservice.ProductId === GlobalConst.Product.SNC) {
      if (this._shopservice.returnWithticketItems && this._shopservice.returnWithticketItems.length > 0) {
        this.isDataWithBeo = data && data.some(r => r.ItemType && r.ItemType == RetailItemType.RevenueItem);
        if (this.isDataWithBeo && arrname == 'arr_SI') {
          this.RS_selectedReturnItems = collectionName;
        }
      }
    }
    const selectedDiscount: ItemDiscount = {
      itemId: data.ItemId,
      discountId: data.DiscountTypeId,
      discountPercentage: data.DiscountPercentage,
      discountAmount: data.DiscountPercentage > 0 ? 0 : data.Discount,
      discountComment: data.dicountComment,
      discountReason: data.discountReason,
      isPercentage: data.DiscountPercentage > 0,
      isCostDiscount: data.isCostDiscount,
      isCostPlusDiscount: data.isCostPlusDiscount
    };

    this.applyDiscountInput = {
      index: i,
      CategoryId: data.category,
      SelectedDiscount: selectedDiscount,
      showItemDetails: false,
      isMultipleItems: false
    };
    this.isApplyDiscountFromOrderSummaryGrid = true;
  }

  updateQuantityValue() {
    this.tax = 0.00;
    this.taxOnTax = 0.00;
    this.vat = 0.00;
    this.gratuityTax = this.serviceChargetax=0;
    this.subTotal = 0.00;
    this.totalDiscount = 0.00;
    this.totalGratuityOfReturnWithTicketItems = 0.00;
    this.totalServiceChargeOfReturnWithTicketItems = 0.00;
    this.ticketDiscount  = this._shopservice.transactionTicketDiscount?.length > 0 ? this._shopservice.transactionTicketDiscount[0].discount : 0;

    this._shopservice.selectedTransactionRetailProducts.forEach(r => {
      this.subTotal += this.CalculateItemSubTotal(r);
      this.tax += this.CalculateTax(r, true, false);
      this.taxOnTax += this.CalculateTaxOnTax(r);
      this.vat += this.CalculateVat(r);
      this.gratuityTax += this.CalculateGratuityTax(r);
      this.serviceChargetax += this.CalculateServiceChargeTax(r);
     
        const originalItem = this._shopservice.returnWithticketItems.filter(o => {
          return o.id == r.id && o.OriginalLineNumber ==r.OriginalLineNumber;
        })[0];       
        const originalQuantity: number = Number(originalItem.Noofitems);
        const newQuantity = Number(r.Noofitems);     
     if(newQuantity != originalQuantity)
     {    
        this.totalDiscount = this.CalculateDiscountForPartialQty(r,originalItem,originalQuantity,newQuantity);
        let returnItemsGratuity =  0;
        let returnItemsServiceCharge = 0;
        returnItemsGratuity = this.CalculateGrauityforPartialQty(r, originalItem,  originalQuantity,newQuantity);
        returnItemsServiceCharge = this.CalculateServiceChargeforPartialQty(r, originalItem, originalQuantity,newQuantity);
        this.totalGratuityOfReturnWithTicketItems +=  returnItemsGratuity;
        this.totalServiceChargeOfReturnWithTicketItems += returnItemsServiceCharge;          
      }
      else
      {
        this.totalDiscount +=  r.Discount;
        this.totalGratuityOfReturnWithTicketItems +=  r && r.Gratuity && r.Gratuity.length > 0 ? r.Gratuity.map(x => x.gratuity + x.additionalGratuity ??0).reduce((a, b) => a + b, 0) : 0;
        this.totalServiceChargeOfReturnWithTicketItems += r && r.ServiceCharge && r.ServiceCharge.length > 0 ? r.ServiceCharge.map(x => x.ServiceCharge + x.additionalServiceCharge).reduce((a, b) => a + b, 0) : 0;
      }     
    });
    this.total = this.isVATEnabled ? this.subTotal + this.tax + this.taxOnTax - this.totalDiscount - this.ticketDiscount 
                :  this.subTotal + this.tax + this.taxOnTax + this.gratuityTax + this.serviceChargetax - this.totalDiscount - this.ticketDiscount;    
  }

  RemoveFromReturnedList() {
    if (this.RS_selectedReturnItems.length > 0) {
      this.RemoveFromReturn();
      if (this._shopservice.MultiPackProducts.length > 0) {
        this._shopservice.selectedMultiPackExchangedProducts = [];
        this._shopservice.MultiPackProducts.forEach(y => {
          const selectedmultipackitem = this._shopservice.selectedReturnedRetailProducts.find(x => x.id == y.id);
          if (selectedmultipackitem) {
            this._shopservice.selectedMultiPackExchangedProducts.push(y);
          }
        });
      }
    }
    this._shopservice.selectedExchangeRetailProducts = _.difference(this._shopservice.selectedExchangeRetailProducts, this.RS_selectedExchangeItems);
    if (this.RS_selectedExchangeItems.some(x => x.appliedCustomFee.length > 0 && x.sourceType && x.sourceTypeId > 0)) {
      this.RS_selectedExchangeItems.filter(x => x.appliedCustomFee.length > 0 && x.sourceType && x.sourceTypeId > 0).forEach(element => {
        let customfeeitems = this._shopservice.selectedExchangeRetailProducts.filter(x => x.sourceType == element.sourceType && x.sourceTypeId == element.sourceTypeId)
        this._shopservice.selectedExchangeRetailProducts = _.difference(this._shopservice.selectedExchangeRetailProducts, customfeeitems);
      });
    }

    if (this._shopservice.selectedReturnedRetailProducts.length == 0) {
      this._shopservice.selectedExchangeRetailProducts = [];
    }

    this.CalculateTotal();
    if (this.RS_selectedExchangeItems.length > 0) {
      if (this.PropertyInfo.UseRetailInterface && this._shopservice.Ticket) {
        this.transactionService.DiscardCheck(this._shopservice.Ticket.checkData);
      }
      this.recheckCustomFeeList(this.RS_selectedExchangeItems);
      this.GetTaxForExchangedItems(ShopSummaryExchangedItemAction.RemoveItems);
      this.CheckAllPackagedItemRemoved();
      this.CreateTicketForNonEmbedded();
    }
    this.RS_selectedExchangeItems = [];
    this.RS_selectedReturnItems = [];
    this._sbs.returnItemsListModified.emit(true);
  }

  // Will be used when remove items from Return section
  RemoveFromReturn() {
    this._shopservice.selectedReturnedRetailProducts = _.difference(this._shopservice.selectedReturnedRetailProducts, this.RS_selectedReturnItems);
    for (let i = 0; i < this.RS_selectedReturnItems.length; i++) {
      const item: SelectedProducts = _.cloneDeep(this.RS_selectedReturnItems[i]);
      if (item.returnType == 0 || item.returnType == undefined) {
        const returnQuantity = Number(item.Noofitems);
        const isRetainedSameItem = this._shopservice.selectedRetainedRetailProducts.filter(r => {
          return r.id == item.id && !r.returnType  // Return Type will be greater than 0 for return SC & G flows
        });
        if (isRetainedSameItem && isRetainedSameItem.length > 0) { // update quantity alone if same item exists in reatined
          const retainedQuantity = Number(isRetainedSameItem[0].Noofitems);
          isRetainedSameItem[0].Noofitems = retainedQuantity + returnQuantity;
        }
        else { // move returned item to retained list.
          if (item.ServiceCharge.length > 0 || item.Gratuity.length > 0) {
            if ((item.isServiceChargeReturn && !item.isGratuityReturn && item.Gratuity.length) || (!item.isServiceChargeReturn && !item.isGratuityReturn) && (item.Gratuity.length)) {
              const gratuityItem: SelectedProducts = _.cloneDeep(item);
              gratuityItem.returnType = ReturnWithTicketType.RETURNGRATUITYONLY;
              this._shopservice.selectedReturnedRetailProducts.splice(gratuityItem.LineNumber - 1, 0, gratuityItem);
              item.returnType = ReturnWithTicketType.RETAINITEMANDSERVICECHARGE;
            }
            if ((!item.isServiceChargeReturn && item.isGratuityReturn && item.ServiceCharge.length) || (!item.isServiceChargeReturn && !item.isGratuityReturn) && (item.ServiceCharge.length)) {
              const serviceChargeItem: SelectedProducts = _.cloneDeep(item);
              serviceChargeItem.returnType = ReturnWithTicketType.RETURNSERVICECHARGEONLY;
              this._shopservice.selectedReturnedRetailProducts.splice(serviceChargeItem.LineNumber - 1, 0, serviceChargeItem);
              item.returnType = ReturnWithTicketType.RETAINITEMANDGRATUITY;
            }
            if (item.isServiceChargeReturn && item.isGratuityReturn) {
              item.returnType = ReturnWithTicketType.Default;
            }
            if (!item.isServiceChargeReturn && !item.isGratuityReturn) {
              item.returnType = ReturnWithTicketType.RETAINITEMONLY;
            }
            item.isServiceChargeReturn = false;
            item.isGratuityReturn = false;
          }
          this._shopservice.selectedRetainedRetailProducts.splice(item.LineNumber - 1, 0, item);
        }
      }
      else {
        let isRetainedSameItem: SelectedProducts[] = this._shopservice.selectedRetainedRetailProducts.filter(r => {
          return r.id == item.id && r.Noofitems == item.Noofitems
        });
        if (isRetainedSameItem && isRetainedSameItem.length) {
          if (item.returnType == ReturnWithTicketType.RETURNSERVICECHARGEONLY)  //RETURNSERVICECHARGEONLY
          {
            if (isRetainedSameItem[0].returnType == ReturnWithTicketType.Default || isRetainedSameItem[0].returnType == ReturnWithTicketType.RETAINITEMONLY) {
              if (isRetainedSameItem[0].Gratuity.length) {
                isRetainedSameItem[0].returnType = ReturnWithTicketType.RETAINITEMANDSERVICECHARGE;
                isRetainedSameItem[0].isServiceChargeReturn = false;
              }
              else {
                isRetainedSameItem[0].returnType = ReturnWithTicketType.Default;
              }
            }
            if (isRetainedSameItem[0].returnType == ReturnWithTicketType.RETAINITEMANDGRATUITY) {
              isRetainedSameItem[0].returnType = ReturnWithTicketType.Default;
              isRetainedSameItem[0].isGratuityReturn = false;
              isRetainedSameItem[0].isServiceChargeReturn = false;
              if (item.isPartialReturn) {
                const originalItem = this._shopservice.returnWithticketItems.find(org => org.id == isRetainedSameItem[0].id);
                originalItem.returnType = ReturnWithTicketType.Default;
                _.remove(this._shopservice.selectedRetainedRetailProducts, retain => retain.id == item.id);
                this._shopservice.selectedRetainedRetailProducts.splice(originalItem.LineNumber - 1, 0, originalItem);
              }
            }
          }
          else if (item.returnType == ReturnWithTicketType.RETURNGRATUITYONLY)  //RETURNGRATUITYONLY
          {
            if (isRetainedSameItem[0].returnType == ReturnWithTicketType.Default || isRetainedSameItem[0].returnType == ReturnWithTicketType.RETAINITEMONLY) {

              if (isRetainedSameItem[0].ServiceCharge.length) {
                isRetainedSameItem[0].returnType = ReturnWithTicketType.RETAINITEMANDGRATUITY;
                isRetainedSameItem[0].isGratuityReturn = false;
              }
              else {
                isRetainedSameItem[0].returnType = ReturnWithTicketType.Default;
              }

            }
            if (isRetainedSameItem[0].returnType == ReturnWithTicketType.RETAINITEMANDSERVICECHARGE) {
              isRetainedSameItem[0].returnType = ReturnWithTicketType.Default;
              isRetainedSameItem[0].isGratuityReturn = false;
              isRetainedSameItem[0].isServiceChargeReturn = false;
              if (item.isPartialReturn) {
                const originalItem = this._shopservice.returnWithticketItems.find(org => org.id == isRetainedSameItem[0].id);
                originalItem.returnType = ReturnWithTicketType.Default;
                _.remove(this._shopservice.selectedRetainedRetailProducts, retain => retain.id == item.id);
                this._shopservice.selectedRetainedRetailProducts.splice(originalItem.LineNumber - 1, 0, originalItem);
              }
            }
          }
        }
        isRetainedSameItem = this._shopservice.selectedRetainedRetailProducts.filter(r => {
          return r.id == item.id && !r.returnType
        });
        if (isRetainedSameItem && isRetainedSameItem.length > 1) {
          const originalItem = this._shopservice.returnWithticketItems.find(org => org.id == isRetainedSameItem[0].id);
          originalItem.returnType = ReturnWithTicketType.Default;
          _.remove(this._shopservice.selectedRetainedRetailProducts, retain => retain.id == item.id);
          this._shopservice.selectedRetainedRetailProducts.splice(originalItem.LineNumber - 1, 0, originalItem);
        }
      }
    }
    console.log("end of remove", this._shopservice.selectedRetainedRetailProducts, this._shopservice.selectedReturnedRetailProducts, this._shopservice.selectedExchangeRetailProducts);
  }

  ShowExchagedItemsFinalVATAmountTotal(): string
  {
      return  this.FormatPrice(this.TotalVatAmount)  ;
  }
  ShowReturnedItemsFinalVATAmountTotal(items:SelectedProducts[]): string
  {      
      return  this.FormatPrice(this.Itemtotal.returnedItemVat);
  }
  ShowRetainedItemsFinalVATAmountTotal(items:SelectedProducts[]): string
  {     
      return  this.FormatPrice(_.cloneDeep( this.Itemtotal.retainedItemVat));
  }

  CalculateTotal() {
    const customToFixed = (val: number) => { return Number(val.customToFixed()); }
    this._shopservice.returnItemTotal = this._shopservice.retainedItemTotal = this._shopservice.exchangedItemTotal = 0;
    this.CalculateSectionTotal();
    this._shopservice.returnItemTotal = this.isVATEnabled ? customToFixed(this.Itemtotal.returnedItemSubtotal - this.Itemtotal.returnedItemDiscount - this.Itemtotal.returnedItemWeightedDiscount) :
      customToFixed(this.Itemtotal.returnedItemSubtotal + (this.returnTaxExempt.value ? 0 : this.Itemtotal.returnedItemTax + this.Itemtotal.returnedItemTaxOnTax +
        this.Itemtotal.returnedItemServiceChargeTax + this.Itemtotal.returnedItemGratuityTax) - this.Itemtotal.returnedItemDiscount - this.Itemtotal.returnedItemWeightedDiscount);
    
    this._shopservice.returnedItemTax = (this.returnTaxExempt.value ? 0 : this.Itemtotal.returnedItemTax + this.Itemtotal.returnedItemTaxOnTax +
      this.Itemtotal.returnedItemServiceChargeTax + this.Itemtotal.returnedItemGratuityTax);

    this._shopservice.retainedItemTotal = this.isVATEnabled ? customToFixed(this.Itemtotal.retainedItemSubTotal - this.Itemtotal.retainedItemDiscount- this.Itemtotal.retainedItemTicketDiscount)
      : customToFixed(this.Itemtotal.retainedItemSubTotal + this.Itemtotal.retainedItemTax + this.Itemtotal.retainedItemTaxOnTax
        + this.Itemtotal.retainedItemServiceChargeTax + this.Itemtotal.retainedItemGratuityTax - this.Itemtotal.retainedItemDiscount- this.Itemtotal.retainedItemTicketDiscount);

    this._shopservice.exchangedItemTotal = this.isVATEnabled ? customToFixed(this.Itemtotal.exchangedItemSubtotal - this.Itemtotal.exchangedItemDiscount) :
      customToFixed(this.Itemtotal.exchangedItemSubtotal - this.Itemtotal.exchangedItemDiscount + (this.exempttax.value ? 0
        : this.Itemtotal.exchangedItemTax + this.Itemtotal.exchangedItemTaxOnTax + this.Itemtotal.exchangedItemServiceChargeTax
        + this.Itemtotal.exchangedItemGratuityTax));
    this._shopservice.finalAmount = customToFixed(this._shopservice.exchangedItemTotal - this._shopservice.returnItemTotal);
    this._sbs.AdjustPaymentMethods();
    if (this._sbs.IsRevenueItemIncluded) {
      this._shopservice.IsbeoItemReturned = this._shopservice.selectedReturnedRetailProducts.some(r => r.ItemType && r.ItemType == RetailItemType.RevenueItem || r.ItemType == RetailItemType.Deposit);
      this.isDataWithBeo = this._shopservice.IsbeoItemReturned;
    }
  }

  CalculateServiceChargeTaxFromLineItems(lineItems: LineItem[])
  {
    let serviceChargeVat = 0;
    lineItems?.forEach(l =>{
      l?.serviceCharges?.forEach(s => {
        s?.taxDetails?.forEach(t => {
          serviceChargeVat += t.value;
        })
      })
    });
    return serviceChargeVat;
  }

  CalculateSectionTotal() {
    this.Itemtotal = {
      retainedItemGratuity: 0.00,
      retainedItemServiceCharge: 0.00,
      retainedItemSubTotal: 0.00,
      retainedItemTax: 0.00,
      retainedItemGratuityTax:0.00,
      retainedItemServiceChargeTax:0.00,
      retainedItemVat: 0.00,
      retainedItemDiscount: 0.00,
      retainedItemTicketDiscount:0.00,
      returnedItemGratuity: 0.00,
      returnedItemServiceCharge: 0.00,
      returnedItemSubtotal: 0.00,
      returnedItemTax: 0.00,
      returnedItemGratuityTax:0.00,
      returnedItemServiceChargeTax: 0.00,
      returnedItemVat: 0.00,
      returnedItemDiscount: 0.00,
      returnedItemWeightedDiscount:0.00,
      exchangedItemGratuity: 0.00,
      exchangedItemServiceCharge: 0.00,
      exchangedItemSubtotal: 0.00,
      exchangedItemDiscount: 0.00,
      exchangedItemTax: 0.00,
      exchangedItemGratuityTax: 0.00,
      exchangedItemServiceChargeTax:0.00,
      returnedItemTaxOnTax: 0.00,
      retainedItemTaxOnTax: 0.00,
      exchangedItemTaxOnTax: 0.00
    };
    this.returnedValuesforGratuityAndServiceCharge = [];
    if (!this.PropertyInfo.UseRetailInterface && this._shopservice.retainedTicket && this._shopservice.retainedTicket.lineItems.length > 0 && this._shopservice.retainedTicket.checkData
      && this._shopservice.selectedRetainedRetailProducts && this._shopservice.selectedRetainedRetailProducts.length > 0) {
      let isTaxExempted = false;
      if (this._shopservice.selectedRetainedRetailProducts && this._shopservice.selectedRetainedRetailProducts.length > 0 &&
        this._shopservice.selectedRetainedRetailProducts[0].isTaxExempt) {
        isTaxExempted = true;
      }
      this.Itemtotal.retainedItemSubTotal = this._shopservice.retainedTicket.checkData.subTotal;
      this.Itemtotal.retainedItemDiscount = this._shopservice.retainedTicket.checkData.discount -this._shopservice.retainedTicket.checkData.checkDiscount;
      this.Itemtotal.retainedItemGratuity = this._shopservice.retainedTicket.checkData.gratuity;
      this.Itemtotal.retainedItemServiceCharge = this._shopservice.retainedTicket.checkData.serviceCharge;
      this.Itemtotal.retainedItemTicketDiscount = this._shopservice.retainedTicket.checkData.checkDiscount;
      const totalLinkedTax = this._shopservice.retainedTicket.checkData.totalLinkedTax;
      this.Itemtotal.retainedItemTax = isTaxExempted ? 0 : totalLinkedTax != 0 ? this._shopservice.retainedTicket.checkData?.totalBaseTax : this._shopservice.retainedTicket.checkData.totalItemTax;
      this.Itemtotal.retainedItemTaxOnTax = isTaxExempted ? 0 : totalLinkedTax;
      this.Itemtotal.retainedItemGratuityTax = isTaxExempted ? 0 : this._shopservice.retainedTicket.checkData.totalGratuityTax;
      this.Itemtotal.retainedItemServiceChargeTax = isTaxExempted ? 0 : this._shopservice.retainedTicket.checkData.totalServiceChargeTax;
      if(this.isVATEnabled)
      {
        let serviceChargeVat = this.CalculateServiceChargeTaxFromLineItems(this._shopservice.retainedTicket.lineItems);        
        this.Itemtotal.retainedItemServiceChargeTax = serviceChargeVat;
        this.Itemtotal.retainedItemVat = this._shopservice.retainedTicket.lineItems.reduce((sum, current) => sum + current.vat, 0);
      }
    }
    else
    {
      this._shopservice.selectedRetainedRetailProducts.forEach(element => {
        this.Itemtotal.retainedItemSubTotal += this.CalculateItemSubTotalForRetainedItem(element);
        this.Itemtotal.retainedItemTax += this.CalculateTax(element, true, false);
        this.Itemtotal.retainedItemTaxOnTax += this.CalculateTaxOnTax(element, true);
        if(element.returnType != ReturnWithTicketType.RETAINITEMONLY && element.returnType != ReturnWithTicketType.RETAINITEMANDSERVICECHARGE)
        {
          this.Itemtotal.retainedItemGratuityTax +=  this.CalculateGratuityTax(element, true);
        }
        if(element.returnType != ReturnWithTicketType.RETAINITEMONLY && element.returnType != ReturnWithTicketType.RETAINITEMANDGRATUITY)
        {
          this.Itemtotal.retainedItemServiceChargeTax +=  this.CalculateServiceChargeTax(element, true);
        }      
        this.Itemtotal.retainedItemVat += this.CalculateVat(element, true);
        this.Itemtotal.retainedItemDiscount += this.CalculateDiscount(element);
        let totalRetainedServiceCharge = 0;
        if(element.ServiceCharge && element.ServiceCharge.length > 0)
        {
          element.ServiceCharge.forEach( sc => {
            totalRetainedServiceCharge += sc.Amount + sc.customAmount;
          });
        }
     
      let totalRetainedGratuity = 0;
        if(element.Gratuity && element.Gratuity.length > 0)
        {
          element.Gratuity.forEach( g => {
            totalRetainedGratuity += g.Amount + g.customAmount;
          });
        }
     
        this.Itemtotal.retainedItemServiceCharge +=this.CalculateRetainedServiceCharge(element,true);
        this.Itemtotal.retainedItemGratuity += this.CalculateRetainedGratuity(element,true);
      });
    } 
    this.Itemtotal.returnedItemDiscount = 0;
    this.Itemtotal.returnedItemWeightedDiscount = 0;
    this._shopservice.selectedReturnedRetailProducts.forEach(element => {
      this.Itemtotal.returnedItemSubtotal += this.CalculateItemSubTotalForReturnedItem(element);
     
      if (element.returnType != ReturnWithTicketType.RETURNSERVICECHARGEONLY && element.returnType != ReturnWithTicketType.RETURNGRATUITYONLY) {
        this.Itemtotal.returnedItemTax += this.CalculateTax(element, true, false);       
        this.Itemtotal.returnedItemTaxOnTax += this.CalculateTaxOnTax(element, true); 
        this.Itemtotal.returnedItemVat += this.isVATEnabled ? this.CalculateVat(element,true) : 0;
        this.Itemtotal.returnedItemDiscount += this.CalculateDiscount(element);
        this.Itemtotal.returnedItemWeightedDiscount += this.CalculateWeightedDiscount(element);
      }
      if(element.returnType == ReturnWithTicketType.RETURNSERVICECHARGEONLY || element.returnType == ReturnWithTicketType.RETURNSERVICECHARGEANDGRATUITY || element.returnType == ReturnWithTicketType.Default )
      {       
        this.Itemtotal.returnedItemServiceChargeTax += this.CalculateServiceChargeTax(element,true);
      }
      if(element.returnType == ReturnWithTicketType.RETURNGRATUITYONLY || element.returnType == ReturnWithTicketType.RETURNSERVICECHARGEANDGRATUITY || element.returnType == ReturnWithTicketType.Default )
      {
        this.Itemtotal.returnedItemGratuityTax += this.CalculateGratuityTax(element, true);       
      }      
      let totalServiceCharge = 0;
      if(element.ServiceCharge && element.ServiceCharge.length > 0)
      {
        element.ServiceCharge.forEach( sc => {
          totalServiceCharge += (sc.Amount + sc.customAmount);
        })
      }      
      let totalGratuity = 0;
      if(element.Gratuity && element.Gratuity.length > 0)
      {
        element.Gratuity.forEach( g => {
          totalGratuity += (g.Amount + g.customAmount);
        });
      }
     
        this.Itemtotal.returnedItemServiceCharge += this.CalculateReturnedServiceCharge(element,true);
        this.Itemtotal.returnedItemGratuity += this.CalculateReturnedGratuity(element,true);
    });
  
    if(!this.PropertyInfo.UseRetailInterface && this._shopservice.Ticket && this._shopservice.Ticket.lineItems.length > 0 && this._shopservice.Ticket.checkData
      && this._shopservice.selectedExchangeRetailProducts && this._shopservice.selectedExchangeRetailProducts.length > 0) {
      this.Itemtotal.exchangedItemSubtotal = this._shopservice.Ticket.checkData.subTotal;
      this.Itemtotal.exchangedItemDiscount = this._shopservice.Ticket.checkData.discount;
      this.Itemtotal.exchangedItemGratuity = this._shopservice.Ticket.checkData.gratuity;
      this.Itemtotal.exchangedItemServiceCharge = this._shopservice.Ticket.checkData.serviceCharge;
      const totalLinkedTax = this._shopservice.Ticket.checkData.totalLinkedTax;
      this.Itemtotal.exchangedItemTaxOnTax = totalLinkedTax;
      this.Itemtotal.exchangedItemTax = totalLinkedTax != 0 ? this._shopservice.Ticket.checkData?.totalBaseTax :this._shopservice.Ticket.checkData.totalItemTax;
      this.Itemtotal.exchangedItemGratuityTax = this._shopservice.Ticket.checkData.totalGratuityTax;
      this.Itemtotal.exchangedItemServiceChargeTax = this._shopservice.Ticket.checkData.totalServiceChargeTax;
      if(this.isVATEnabled)
      {
        let serviceChargeVat = this.CalculateServiceChargeTaxFromLineItems(this._shopservice.Ticket.lineItems);
        this.Itemtotal.exchangedItemServiceChargeTax = serviceChargeVat;
      }
    }
    else  {
      this._shopservice.selectedExchangeRetailProducts.forEach(element => {
        if (!(element.isGroupingKey && !element.isPackagedItem)) {
          this.Itemtotal.exchangedItemSubtotal += this.CalculateItemSubTotal(element,true);
          if (element.returnType != ReturnWithTicketType.RETURNSERVICECHARGEONLY && element.returnType != ReturnWithTicketType.RETURNGRATUITYONLY) {
            let itemFromCheck;
            if(!this.PropertyInfo.UseRetailInterface && this._shopservice.Ticket)
            {
              itemFromCheck = this._shopservice.Ticket.lineItems.find(lineItem => lineItem.itemId == element.ItemId && lineItem.index == element.LineNumber);
            }
            this.Itemtotal.exchangedItemDiscount += this.PropertyInfo.UseRetailInterface ? element.Discount : this._shopservice.Ticket ? itemFromCheck?.discount : element.Discount;
            let totalServiceCharge = 0;
            if(element.ServiceCharge && element.ServiceCharge.length > 0)
            {
              element.ServiceCharge.forEach( sc => {
                totalServiceCharge += sc.Amount + sc.customAmount;
              });
            }
            let totalGratuity = 0;
            if(element.ServiceCharge && element.ServiceCharge.length > 0)
            {
              element.Gratuity.forEach( g => {
                totalGratuity += g.Amount + g.customAmount;
              });
            }
              this.Itemtotal.exchangedItemServiceCharge += this.PropertyInfo.UseRetailInterface ? totalServiceCharge :  itemFromCheck ? itemFromCheck?.serviceCharge : 0;
              this.Itemtotal.exchangedItemGratuity += this.PropertyInfo.UseRetailInterface ? totalGratuity : itemFromCheck ? itemFromCheck?.gratuity : 0;
          }
        }
      });
    }
    this.RefreshSummaryTotal();
    this._sbs.refreshTotal.emit();
  }

  CalculateDiscount(item: SelectedProducts) {
    let discountValue = item.Discount ? item.Discount : 0;
    const originalItem = this._shopservice.returnWithticketItems.filter(rw => {
      return item.id === rw.id && item.OriginalLineNumber === rw.OriginalLineNumber
    })[0];
    if (originalItem) {
      const originalQuantity: number = Number(originalItem.Noofitems);
      const perUnitDiscount = (originalItem.Discount ? originalItem.Discount : 0) / originalQuantity;
      discountValue = (perUnitDiscount * item.Noofitems);
    }
    return discountValue;
  }

  CalculateWeightedDiscount(item: SelectedProducts) {
    let discountValue = item.weightedDiscount ? item.weightedDiscount : 0;
    const originalItem = this._shopservice.returnWithticketItems.filter(rw => {
      return item.id === rw.id && item.OriginalLineNumber === rw.OriginalLineNumber
    })[0];
    if (originalItem) {
      const originalQuantity: number = Number(originalItem.Noofitems);
      const perUnitDiscount = (originalItem.weightedDiscount ? originalItem.weightedDiscount : 0) / originalQuantity;
      discountValue = (perUnitDiscount * item.Noofitems);
    }
    return discountValue;
  }

  CalculateTax(item: SelectedProducts, isRecalculateTax = true, includeTaxOnTax = true) {
    const TaxValue = (item) => {
      if (includeTaxOnTax)
        return item.Tax //Tax Prop will include LinkedTax
      else return item.LinkedTax > 0 ? item.BaseTax : item.Tax;
    }
    let taxValue = item.Tax ? TaxValue(item) : 0;
    const originalItem = this._shopservice.returnWithticketItems.filter(rw => {
      return item.id == rw.id && item.OriginalLineNumber == rw.OriginalLineNumber
    })[0];
    if (originalItem && isRecalculateTax) {
      const originalQuantity: number = Number(originalItem.Noofitems);
      const perUnitTax = (originalItem.Tax ? TaxValue(originalItem) : 0) / originalQuantity;
      taxValue = (perUnitTax * item.Noofitems);
    }
    return taxValue;
  }

  CalculateTaxOnTax(item: SelectedProducts, isRecalculateTax = true) {
    let taxValue = item.LinkedTax ? item.LinkedTax : 0;
    const originalItem = this._shopservice.returnWithticketItems.filter(rw => {
      return item.id == rw.id && item.OriginalLineNumber == rw.OriginalLineNumber
    })[0];
    if (originalItem && isRecalculateTax) {
      const originalQuantity: number = Number(originalItem.Noofitems);
      const perUnitTax = (originalItem.LinkedTax ? originalItem.LinkedTax : 0) / originalQuantity;
      taxValue = (perUnitTax * item.Noofitems);
    }
    return taxValue;
  }

  CalculateGratuityTax(item: SelectedProducts, isRecalculateTax = true) {
    let taxValue = item.Tax ? item.Tax : 0;
    let gratuitiesTax: number = 0;
  
    if (isRecalculateTax) {
      const originalItem = this._shopservice.returnWithticketItems.filter(rw => {
        return item.id == rw.id && item.OriginalLineNumber == rw.OriginalLineNumber 
      })[0];
      const originalQuantity: number = Number(originalItem?.Noofitems);
      let gratuitiesTax: number = 0;
      originalItem?.Gratuity.forEach(g => gratuitiesTax += g?.GratuityTax);
      const perUnitTax = (gratuitiesTax) / originalQuantity;
      taxValue = (perUnitTax * item.Noofitems);
    }
    else
    {
      item.Gratuity.forEach(s => gratuitiesTax += s.GratuityTax);
      taxValue = gratuitiesTax;
    }
    return taxValue;
  }
  CalculateServiceChargeTax(item: SelectedProducts, isRecalculateTax = true) {
    let taxValue = 0;
    let serviceChargesTax: number = 0;    
    if (isRecalculateTax) {
      const originalItem = this._shopservice.returnWithticketItems.filter(rw => {
        return item.id == rw.id && item.OriginalLineNumber == rw.OriginalLineNumber 
      })[0];
      const originalQuantity: number = Number(originalItem?.Noofitems);
      let serviceChargesTax: number = 0;
      originalItem?.ServiceCharge.forEach(s => serviceChargesTax += s.ServiceChargeTax);
      const perUnitTax = (serviceChargesTax) / originalQuantity;
      taxValue = (perUnitTax * item.Noofitems);
    }
    else
    {
      item.ServiceCharge.forEach(s => serviceChargesTax += s.ServiceChargeTax);
      taxValue = serviceChargesTax;
    }
    return taxValue;
  } 
  

  CalculateVat(item: SelectedProducts, isRecalculateTax = true) {
    let taxValue = item.Vat ? item.Vat : 0;
    const originalItem = this._shopservice.returnWithticketItems.filter(rw => {
      return item.id == rw.id && item.OriginalLineNumber == rw.OriginalLineNumber 
    })[0];
    if (originalItem) {
      const originalQuantity: number = Number(originalItem.Noofitems);
      const perUnitVat = (originalItem.Vat ? originalItem.Vat : 0) / originalQuantity;
      taxValue = (perUnitVat * item.Noofitems);
    }
    return taxValue;
  }

  ShowExchangedItemSubTotal(item: SelectedProducts) {
      if(!this.PropertyInfo.UseRetailInterface && this._shopservice.Ticket)  {
        const itemFromCheck = this._shopservice.Ticket.lineItems.find(lineItem => lineItem.itemId == item.ItemId && lineItem.index == item.LineNumber);
        let itemPrice = 0
        if(itemFromCheck) {
          itemPrice  =  itemFromCheck.unitPrice * itemFromCheck.quantity + itemFromCheck.gratuity + itemFromCheck.serviceCharge;
          return Number(itemPrice.customToFixed());
        }
        else {
          this.CalculateItemSubTotal(item);
        }
      } else {
        this.CalculateItemSubTotal(item);
      }
  }


  ShowItemSubTotal(item: SelectedProducts) {
    return this.CalculateItemSubTotal(item);
  }

  ShowReturnItemSubTotal(item: SelectedProducts) {
    if (item.returnType == ReturnWithTicketType.RETURNSERVICECHARGEONLY || item.returnType == ReturnWithTicketType.RETURNGRATUITYONLY) {
      return this.CalculateItemSubTotal(item);
    }
    else {
      return this.CalculateItemSubTotal(item);
    }
  }
 


  DisplayDiscount(item: SelectedProducts, discount = 0,isFromExchange:boolean = false,isfromRetain:boolean = false) {
    
    if(item!=null && !this.PropertyInfo.UseRetailInterface && isFromExchange && this._shopservice.Ticket)
    {
      var itemFromCheck = this._shopservice.Ticket.lineItems.find(lineItem => lineItem.itemId == item.ItemId && lineItem.index == item.LineNumber);
    discount = itemFromCheck ? itemFromCheck.discount : 0;
    }
    if (item && (item.returnType == ReturnWithTicketType.RETURNSERVICECHARGEONLY || item.returnType == ReturnWithTicketType.RETURNGRATUITYONLY)) {
      return this.localization.localizeCurrency(0, false).toString();
    }    
    if(item && !isFromExchange)
    { 
      const originalItem = this._shopservice.returnWithticketItems.filter(r => r.id == item.id && r.OriginalLineNumber == item.OriginalLineNumber)[0];
      if(! originalItem) return;
      const originalQuantity: number = Number(originalItem.Noofitems);
      const newQuantity = Number(item.Noofitems); 
      
      if(originalQuantity!=newQuantity)
      {   
        if(item.isDiscountModifiedByCreateTicket)
        {
          discount = this.CalculateDiscountForPartialQty(item,item,originalQuantity,newQuantity);     
        }
        else
        {
          discount = this.CalculateDiscountForPartialQty(item,originalItem,originalQuantity,newQuantity);   
        }
      }
    }
    if (discount > 0) {
     
      return `(${this.localization.localizeCurrency(discount??0, false)})`;
    }
    else {
      return this.localization.localizeCurrency(discount??0 * -1, false).toString();
    }   
     
  }
  CalculateReturnedServiceCharge(item: SelectedProducts, isRecalculateSC = true) {
    let returnedServiceCharge: number = 0;
    let unitServiceCharge: number = 0;   
    if(item?.returnType == ReturnWithTicketType.RETURNGRATUITYONLY)
    {
      return returnedServiceCharge;
    }
    const originalItem = this._shopservice.returnWithticketItems.filter(rw => {
      return item.id == rw.id && item.OriginalLineNumber == rw.OriginalLineNumber 
    })[0];
    if(item.isServiceChargeModifiedByCreateTicket)
    {
      item.ServiceCharge.forEach(scValue=>{ 
        returnedServiceCharge+=(scValue.ServiceCharge + scValue.additionalServiceCharge ?? 0);
      });  
    }
    else
    {
      if (originalItem && isRecalculateSC) {
        const originalQuantity: number = Number(originalItem.Noofitems);     
        originalItem.ServiceCharge.forEach(scValue=>{ 
          unitServiceCharge+=(scValue.ServiceCharge + scValue.additionalServiceCharge ?? 0)/originalQuantity;
        });
        returnedServiceCharge=this.utils.RoundOff((item.Noofitems*unitServiceCharge));
      }
    }   
    return returnedServiceCharge;
  }
  CalculateReturnedGratuity(item: SelectedProducts, isRecalculateSC = true) {
    let returnedGratuity: number = 0;
    let unitGratuity: number = 0;    
    if(item?.returnType == ReturnWithTicketType.RETURNSERVICECHARGEONLY)
    {
      return returnedGratuity;
    }
    const originalItem = this._shopservice.returnWithticketItems.filter(rw => {
      return item.id == rw.id && item.OriginalLineNumber == rw.OriginalLineNumber 
    })[0];
    if(item.isGratuityModifiedByCreateTicket)
    {
      item.Gratuity.forEach(grat=>{ 
        returnedGratuity+=(grat.gratuity + grat.additionalGratuity ?? 0);
        });
    }
    else
    {
      if (originalItem && isRecalculateSC) {
        const originalQuantity: number = Number(originalItem.Noofitems);     
        originalItem.Gratuity.forEach(grat=>{ 
        unitGratuity+=(grat.gratuity + grat.additionalGratuity ?? 0)/originalQuantity;
        });
        returnedGratuity= this.utils.RoundOff((item.Noofitems * unitGratuity));
      }
    }
   
    return returnedGratuity;
  }
    CalculateRetainedServiceCharge(item: SelectedProducts, isRecalculateSC = true) {
    let retainedServiceCharge: number=0;
    let unitServiceCharge: number=0;
    if(item?.returnType == ReturnWithTicketType.RETAINITEMANDGRATUITY || item?.returnType == ReturnWithTicketType.RETAINITEMONLY)
    {
      return retainedServiceCharge;
    }   
    const originalItem = this._shopservice.returnWithticketItems.filter(rw => {
      return item.id == rw.id && item.OriginalLineNumber == rw.OriginalLineNumber 
    })[0];
    if (originalItem && isRecalculateSC) {
      const originalQuantity: number = Number(originalItem.Noofitems);
      originalItem.ServiceCharge.forEach(scValue=>{ 
        unitServiceCharge+=((scValue.ServiceCharge + scValue.additionalServiceCharge)/originalQuantity);
      });
    retainedServiceCharge=(item.Noofitems*unitServiceCharge);
    }
    return retainedServiceCharge;
  }
  CalculateRetainedGratuity(item: SelectedProducts, isRecalculateSC = true) {
    let RetainedGratuity: number=0;
    let unitGratuity: number=0;
    if(item?.returnType == ReturnWithTicketType.RETAINITEMANDSERVICECHARGE || item?.returnType == ReturnWithTicketType.RETAINITEMONLY)
    {
      return RetainedGratuity;
    };
    const originalItem = this._shopservice.returnWithticketItems.filter(rw => {
      return item.id == rw.id && item.OriginalLineNumber == rw.OriginalLineNumber 
    })[0];
    if (originalItem && isRecalculateSC) {
      const originalQuantity: number = Number(originalItem.Noofitems);
      originalItem.Gratuity.forEach(grat=> { 
      unitGratuity+=((grat.gratuity + grat.additionalGratuity)/originalQuantity);
      });
      RetainedGratuity=(item.Noofitems * unitGratuity);
    }
    return RetainedGratuity;
  }
  ShowTotalDiscount() {
    const discount = this.totalDiscount;
    if (discount > 0) {
      return `(${this.localization.localizeCurrency(discount, false)})`;
    } else {
      return this.localization.localizeCurrency(discount * -1, false).toString();
    }
  }

  ShowTicketDiscount() {
    if (this.ticketDiscount > 0) {
      return `(${this.localization.localizeCurrency(this.ticketDiscount, false)})`;
    } else {
      return  this.localization.localizeCurrency(this.ticketDiscount * -1, false).toString() ;
    }
  }

  FormatPriceWithLocalisaction(price:number)
  {
    if (price >= 0) {
      return `${this.localization.localizeCurrency(price, false)}`;
    } else {
      return `(${this.localization.localizeCurrency(price * -1, false).toString()})`;
    }
  } 

  ShowSummarySubtotal(calculateSubTotal, discount) {
    
    return this.PropertyInfo.UseRetailInterface ? this.localization.localizeCurrency(calculateSubTotal + discount, false) : this.localization.localizeCurrency(calculateSubTotal, false);
  }

  ShowSubTotal() {
    
    return this.subTotal;
  }
  


  CalculateItemSubTotal(item: SelectedProducts, isForExchangeItem:boolean = false, includeTax: boolean = false, isRecalculateTax = true): number {
    
    if(isForExchangeItem && !this.PropertyInfo.UseRetailInterface && this._shopservice.Ticket)
    {
      var itemFromCheck = this._shopservice.Ticket.lineItems.find(lineItem => lineItem.itemId == item.ItemId && lineItem.index == item.LineNumber);
      let itemPrice = 0
      if(itemFromCheck) {
        const gratuity = itemFromCheck.gratuity;
        const servCharge = itemFromCheck.serviceCharge;
        itemPrice = itemFromCheck.unitPrice * itemFromCheck.quantity;
        if (includeTax) {
          itemPrice += this.isVATEnabled ? 0 : this.CalculateTax(item, isRecalculateTax) + this.CalculateTaxOnTax(item, isRecalculateTax);
        }
        if (itemPrice > 0) {
          itemPrice = itemPrice - ((itemFromCheck.discount || 0) > itemPrice ? itemPrice : itemFromCheck.discount);
        }
        itemPrice  += gratuity + servCharge;
      }
            return Number(itemPrice.customToFixed());
    }
    else
    {
      let originalItem = this._shopservice.returnWithticketItems.filter(rw => {
        return item.id == rw.id && item.OriginalLineNumber == rw.OriginalLineNumber
      })[0];
  
      if(!originalItem)
      {
        originalItem = item;
      }
      let gratuity = 0;
      let servCharge = 0;
      if (item.isGratuityModifiedByCreateTicket) {
        gratuity = item.Gratuity && item.Gratuity.length > 0 ? item.Gratuity.map(x => x.gratuity + x.additionalGratuity?? 0).reduce((a, b) => a + b, 0) : 0;
      }
      else {
        gratuity = item.Gratuity && item.Gratuity.length > 0 ? originalItem.Gratuity.map(x => x.gratuity  + x.additionalGratuity?? 0).reduce((a, b) => a + b, 0) : 0;
      }
      if (item.isServiceChargeModifiedByCreateTicket) {
        servCharge = item.ServiceCharge && item.ServiceCharge.length > 0 ? item.ServiceCharge.map(x => x.ServiceCharge + x.additionalServiceCharge).reduce((a, b) => a + b, 0) : 0;
      }
      else {
        servCharge = item.ServiceCharge && item.ServiceCharge.length > 0 ? originalItem.ServiceCharge.map(x => x.ServiceCharge + x.additionalServiceCharge).reduce((a, b) => a + b, 0) : 0;
      }  
      let itemPrice = item.ProductPrice * item.Noofitems;  
     
  
      let discount = originalItem ? originalItem.Discount : item.Discount;
      if (originalItem && originalItem.Noofitems != item.Noofitems) { // Returning partial quantity
        const originalQuantity: number = Number(originalItem.Noofitems);
        const perUnitGratuity = gratuity / originalQuantity;
        const perUnitServCharge = servCharge / originalQuantity;
        gratuity = item.isGratuityModifiedByCreateTicket ? gratuity :  Number((perUnitGratuity * item.Noofitems).customToFixed());
        servCharge = item.isServiceChargeModifiedByCreateTicket ? servCharge : Number((perUnitServCharge * item.Noofitems).customToFixed());
        const perUnitDiscount = discount / originalQuantity;
        discount = this.utils.MidPointRoundOffTwo(perUnitDiscount * item.Noofitems);
        if (discount * Number(originalItem.Noofitems) != originalItem.Discount) {
          discount = item.Discount;
        }
      }
      item.Discount = discount;
  
      if (includeTax) {
        itemPrice += this.isVATEnabled ? 0 : this.CalculateTax(item, isRecalculateTax);
      }      
      if (item.returnType == ReturnWithTicketType.RETURNSERVICECHARGEONLY) {
        return Number(servCharge.customToFixed());
      }
      if (item.returnType == ReturnWithTicketType.RETURNGRATUITYONLY) {
        return Number(gratuity.customToFixed());
      }
  
      if (item.returnType == ReturnWithTicketType.RETAINITEMONLY) {
        return Number(itemPrice.customToFixed());
      }
      else if (item.returnType == ReturnWithTicketType.RETAINITEMANDSERVICECHARGE && item.ServiceCharge.length > 0) {
        itemPrice += servCharge;
        return Number(itemPrice.customToFixed());
      }
      else if (item.returnType == ReturnWithTicketType.RETAINITEMANDGRATUITY && item.Gratuity.length > 0) {
        itemPrice += gratuity;
        return Number(itemPrice.customToFixed());
      }
      else {
        itemPrice += gratuity + servCharge;
        return Number(itemPrice.customToFixed());
      }
    }   
  }

  returnedValuesforGratuityAndServiceCharge = [];

    CalculateItemSubTotalForRetainedItem(item: SelectedProducts, includeTax: boolean = false, isRecalculateTax = true): number {

    let originalItem = this._shopservice.returnWithticketItems.filter(rw => {
      return item.id == rw.id && item.OriginalLineNumber == rw.OriginalLineNumber
    })[0];

      if(!originalItem)
      {
        originalItem = item;
      }

    let gratuity = item.Gratuity && item.Gratuity.length > 0 ? originalItem.Gratuity.map(x => x.gratuity + x.additionalGratuity ?? 0).reduce((a, b) => a + b, 0) : 0;
    let servCharge = item.ServiceCharge && item.ServiceCharge.length > 0 ? originalItem.ServiceCharge.map(x => x.ServiceCharge + x.additionalServiceCharge ?? 0).reduce((a, b) => a + b, 0) : 0;
    let itemPrice = item.ProductPrice * item.Noofitems;
   
    let discount = originalItem ? originalItem.Discount : item.Discount;
    if (originalItem && originalItem.Noofitems != item.Noofitems) { // Returning partial quantity
      let totalItemGratuity = 0, totalItemServiceCharge = 0;
      const originalQuantity: number = Number(originalItem.Noofitems);
      if (item.Gratuity && item.Gratuity.length > 0) {
        originalItem.Gratuity.map(x => x.gratuity + x.additionalGratuity ?? 0).forEach((gr) => {
          totalItemGratuity += Number(((gr / originalQuantity) * item.Noofitems).customToFixed());
        });
      }
      if (item.ServiceCharge && item.ServiceCharge.length > 0) {
        originalItem.ServiceCharge.map(x => x.ServiceCharge + x.additionalServiceCharge ?? 0).forEach((sc) => {
          totalItemServiceCharge += Number(((sc / originalQuantity) * item.Noofitems).customToFixed());
        });
      }
      const perUnitDiscount = discount / originalQuantity;
      discount = this.utils.MidPointRoundOffTwo(Number((perUnitDiscount * item.Noofitems)));
      item.Discount = discount;
      this.returnedValuesforGratuityAndServiceCharge.push({
        id: item.id,
        gratuity: gratuity = totalItemGratuity,
        serviceCharge: servCharge = totalItemServiceCharge,
        discount: discount
      });
    }


    if (includeTax) {
      itemPrice +=  this.isVATEnabled ? 0 : this.CalculateTax(item, isRecalculateTax);
    }
   
    if (item.returnType == ReturnWithTicketType.RETURNSERVICECHARGEONLY) {
      return Number(servCharge.customToFixed());
    }
    if (item.returnType == ReturnWithTicketType.RETURNGRATUITYONLY) {
      return Number(gratuity.customToFixed());
    }

    if (item.returnType == ReturnWithTicketType.RETAINITEMONLY) {
      return Number(itemPrice.customToFixed());
    }
    else if (item.returnType == ReturnWithTicketType.RETAINITEMANDSERVICECHARGE && item.ServiceCharge.length > 0) {
      itemPrice += servCharge;
      return Number(itemPrice.customToFixed());
    }
    else if (item.returnType == ReturnWithTicketType.RETAINITEMANDGRATUITY && item.Gratuity.length > 0) {
      itemPrice += gratuity;
      return Number(itemPrice.customToFixed());
    }
    else {
      itemPrice += gratuity + servCharge;
      return Number(itemPrice.customToFixed());
    }

  }

  CalculateItemSubTotalForReturnedItem(item: SelectedProducts, includeTax: boolean = false, isRecalculateTax = true): number {

    let originalItem = this._shopservice.returnWithticketItems.filter(rw => {
      return item.id == rw.id && item.OriginalLineNumber == rw.OriginalLineNumber
    })[0];
    if(!originalItem)
    {
      originalItem = item;
    }
    let gratuity = 0, servCharge = 0;
    if (item.isGratuityModifiedByCreateTicket) {
      gratuity = item.Gratuity && item.Gratuity.length > 0 ? item.Gratuity.map(x => x.gratuity + x.additionalGratuity ?? 0).reduce((a, b) => a + b, 0) : 0;
    }
    else {
      gratuity = item.Gratuity && item.Gratuity.length > 0 ? originalItem.Gratuity.map(x => x.gratuity + x.additionalGratuity ?? 0).reduce((a, b) => a + b, 0) : 0;
    }
    if (item.isServiceChargeModifiedByCreateTicket) {
      servCharge = item.ServiceCharge && item.ServiceCharge.length > 0 ? item.ServiceCharge.map(x => x.ServiceCharge + x.additionalServiceCharge ?? 0).reduce((a, b) => a + b, 0) : 0;
    }
    else {
      servCharge = item.ServiceCharge && item.ServiceCharge.length > 0 ? originalItem.ServiceCharge.map(x => x.ServiceCharge + x.additionalServiceCharge ??0).reduce((a, b) => a + b, 0) : 0;
    }  
    let itemPrice = item.ProductPrice * item.Noofitems;
  
    let discount = originalItem ? originalItem.Discount : item.Discount;
    if (originalItem && originalItem.Noofitems != item.Noofitems) { // Returning partial quantity
      const retainedItem = this._shopservice.selectedRetainedRetailProducts.find(x => x.id == item.id);
      const originalQuantity: number = Number(originalItem.Noofitems);
      const perUnitGratuity = gratuity / originalQuantity;
      const perUnitServCharge = servCharge / originalQuantity;
      const perUnitDiscount = discount / originalQuantity;
      gratuity = item.isGratuityModifiedByCreateTicket ? gratuity :  Number((perUnitGratuity * item.Noofitems).customToFixed());
        servCharge = item.isServiceChargeModifiedByCreateTicket ? servCharge : Number((perUnitServCharge * item.Noofitems).customToFixed());
      discount = this.utils.MidPointRoundOffTwo(Number((perUnitDiscount * item.Noofitems)));
      if (retainedItem && (retainedItem.Discount + discount) != originalItem.Discount) {
        item.Discount = discount = this.utils.MidPointRoundOffTwo(Number(originalItem.Discount - retainedItem.Discount));
      }

    }
    if (item.returnType == ReturnWithTicketType.RETURNSERVICECHARGEONLY) {
      return Number(servCharge.customToFixed());
    }
    if (item.returnType == ReturnWithTicketType.RETURNGRATUITYONLY) {
      return Number(gratuity.customToFixed());
    }
    if (includeTax) {
      itemPrice +=  this.isVATEnabled ? 0 : this.CalculateTax(item, isRecalculateTax);
    } 

    if (item.returnType == ReturnWithTicketType.RETAINITEMONLY) {
      return Number(itemPrice.customToFixed());
    }
    else if (item.returnType == ReturnWithTicketType.RETAINITEMANDSERVICECHARGE && item.ServiceCharge.length > 0) {
      itemPrice += servCharge;
      return Number(itemPrice.customToFixed());
    }
    else if (item.returnType == ReturnWithTicketType.RETAINITEMANDGRATUITY && item.Gratuity.length > 0) {
      itemPrice += gratuity;
      return Number(itemPrice.customToFixed());
    }
    else {
      itemPrice += gratuity + servCharge;
      return Number(itemPrice.customToFixed());
    }
  }

  async MenuItemClick(e, itemDetails, i) {

    const item = this._shopservice.selectedExchangeRetailProducts[i];
    switch (e.id) {
      case 1: //edit
        this.utils.ToggleLoaderWithMessage(true,this.localization.captions.lbl_processing);
        setTimeout(() => {
          this.OpenEditCommissionGratuity(itemDetails);
        }, 1);
        break;
      case 2: // Grat
        this._retailModuleService.loaderEnable.next(this.ShopCaptions.RemoveItemGratuity);
        item.Gratuity = null;
        e.isactive = false
        e.isApplied = false;
        await this.InvokeUpdateTicketForExchangeditems();
        setTimeout(() => { this._retailModuleService.loaderEnable.next("") }, 1000);
        break;
      case 3: // Comm
        this._retailModuleService.loaderEnable.next(this.ShopCaptions.AddCommissionGratuityServiceCharge);
        item.Commission = [];
        item.StaffTransactionDetail = [];
        e.isactive = false;
        e.isApplied = false;
        itemDetails.charges[0].name = this.localization.captions.shop.AddCommissionGratuity;
        setTimeout(() => { this._retailModuleService.loaderEnable.next("") }, 1000);
        break;
      case 4: // Serv
        this._retailModuleService.loaderEnable.next(this.ShopCaptions.RemoveItemServiceCharge);
        item.ServiceCharge = null;
        e.isactive = false
        e.isApplied = false;
        await this.InvokeUpdateTicketForExchangeditems();
        setTimeout(() => { this._retailModuleService.loaderEnable.next("") }, 1000);
        break;
      case 5: // Discount
        if (!this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyDiscount])) {
          break;
        }
        itemDetails.Discount = 0;
        itemDetails.DiscountTypeId = 0;
        itemDetails.DiscountPercentage = 0;
        itemDetails.discountComments = '';
        itemDetails.discountReason = 0;
        itemDetails.isCostDiscount = false;
        itemDetails.isCostPlusDiscount = false;
        itemDetails.costPlusDiscountDetails = null;

        await this.removeDiscount(i);
        var discountConfig = this.applyDiscountService.CategoryDiscountList.find(
          (x) => x.retailCategoryId == itemDetails.category && x.eligibleForDiscount
        );
        if (discountConfig) {
          this.RecalculateServiceChargeGratuityByDicount(itemDetails);
        }
        this.CalculateTotal();
        e.isactive = false
        e.isApplied = false;
        break;
        case 6:
          this.addCustomFee(itemDetails);
          break;
        case 7:
          this.openCustomFeeEditDialog(itemDetails);
          break;
      default:
        break;
    }
  }

  openCustomFeeEditDialog(data) {
		let customFeeItem = data;
		this.dialog.open(EditCustomFeeComponent, {
			width: '500px',
			height: '350px',
			disableClose: true,
			hasBackdrop: true,
			data: {
				isPercentage: customFeeItem.customFee.isPercentage,
				value: customFeeItem.customFee.value
			},
		}).afterClosed().subscribe(async result => {
			if (result) {
				customFeeItem.customFee.isPercentage = result.isPercentage == 1;
				customFeeItem.customFee.value = result.value;
				customFeeItem.customFee.isOverWritten = true;
				await this.createTicketAndUpdateOrderForCustomFee();
			}
		});
	}

  async addCustomFee(data: any) {
    const res = await this.openCustomFeeDialog(data);
    if (!res) return;
    let selectedCustomFees = this._shopservice.currentCustomFee.filter(x => res.some(y => y == x.id));
    this.processSelectedFees(selectedCustomFees, data);
		this.GridItems.selectedExchangeRetailProducts.filter(x => x.sourceTypeId == data.sourceTypeId && x.ItemType != RetailItemType.CustomFee).forEach(el => {
			el.appliedCustomFee = el.appliedCustomFee ?? [];
			el.sourceType = el.sourceType > 0 ? el.sourceType : GlobalConst.CustomFeeSourceType.RetailItem;
			el.sourceTypeId = el.sourceTypeId > 0 ? el.sourceTypeId : el.LineNumber;
			selectedCustomFees.map(x => el.appliedCustomFee.push(x.id));
			if(el.charges)el.charges[5].isApplied = el.charges[5].isactive = true;
			el.isCustomFeeAdded = true;
		});
    await this.createTicketAndUpdateOrderForCustomFee();
  }
	
  private async openCustomFeeDialog(data): Promise<any> {
    let customfeeForLineItem = _.cloneDeep(this._shopservice.currentCustomFee);
    customfeeForLineItem.forEach(element => {
      if (data.appliedCustomFee && data.appliedCustomFee.some(x => x == element.id)) {
        element.isApplied = true;
      }
    });
    const dialogRef = this.dialog.open(AddCustomFeePopupComponent, {
			width: '500px',
			height: '450px',
      disableClose: true,
      hasBackdrop: true,
      data: { customfee: customfeeForLineItem }
    });
    return dialogRef.afterClosed().toPromise();
  }

	private processSelectedFees(selectedFees: any[], data: any) {
    let lineNumber = data.LineNumber;
		this.fixLineNumber(lineNumber, this._shopservice.selectedExchangeRetailProducts.length + selectedFees.length);
		selectedFees.forEach(fee => {
      const retailItem = this.customFeeItemDetails.find(x => x.id === fee.linkedItemId).retailItemDetail;
      let itemName = this.localization.replacePlaceholders(this.ShopCaptions.customFeeAddedFor, ["CustomFeeName", "ItemDescription"], [fee.name, data.ItemDescription]);
      this._shopservice.selectedExchangeRetailProducts.push({
        ItemId: fee.linkedItemId,
        ExternalPOSItemId: retailItem.externalPOSId,
        ItemDescription: itemName,
        ItemType: retailItem.itemType,
        ServiceId: 0,
        SalesPrice: retailItem.ProductPrice,
        ProductName: itemName,
        ProductPrice: 0,
        Noofitems: 1,
        Discount: 0,
        DiscountTypeId: 0,
        DiscountPercentage: 0,
        category: retailItem.category,
        isGroupingKey: false,
        isPackagedItem: false,
        PackageItemId: 0,
        MultiPack: false,
        ClientMultiPackId: 0,
        PackageGroupId: 0,
        isOpenPricedItem: false,
        isReturn: false,
        isModificationRestricted: false,
        costPrice: retailItem.costPrice,
        marginPercentage: retailItem.marginPercentage,
        allowEarn: retailItem.allowEarn,
        discountComments: '',
        discountReason: 0,
        clientName: data.clientname,
        clientId: data.clientId,
        LineNumber: ++lineNumber,
        linkedItemLineNumber: data.LineNumber,
        totalAmount: 0,
        retailItemType: RetailItemType.CustomFee,
        customFee: this.transcustomFeeMapper(fee),
        sourceType: data.sourceType > 0 ? data.sourceType : GlobalConst.CustomFeeSourceType.RetailItem,
        sourceTypeId: data.sourceTypeId > 0 && data.sourceType != GlobalConst.CustomFeeSourceType.RetailItem ? data.sourceTypeId : data.LineNumber
      });
		});
    this._shopservice.selectedExchangeRetailProducts.sort((a, b) => a.LineNumber - b.LineNumber);
	}
  transcustomFeeMapper(customfeeconfig: CustomFeeConfig) {
		return {
			id: 0,
			customFeeId: customfeeconfig.id,
			isOverWritten: customfeeconfig.isOverWritten ?? false,
			value: customfeeconfig.value,
			isPercentage: customfeeconfig.isPercentage
		} as TransactionCustomFee
	}
	
 

	fixLineNumber(existingLineNumber, endingNumber) {
		for (let i = this._shopservice.selectedExchangeRetailProducts.length; i > existingLineNumber; i--) {
			let data = this._shopservice.selectedExchangeRetailProducts.find(x => x.LineNumber === i);
			if (data) {
				data.LineNumber = endingNumber--;
			}
		}
	}

   OpenEditCommissionGratuity(itemDetails: any) {

    const item = [itemDetails];
    let PerviousTherapistSameItem: number[] = [];
    PerviousTherapistSameItem = itemDetails && itemDetails.Commission ? itemDetails.Commission.map(a => a.StaffId) : [];
    let therapistSelectedList: number[] = [];
    this._shopservice.selectedExchangeRetailProducts.forEach(x => {
      if (x.Commission && x.Commission.length > 0) {
        therapistSelectedList = therapistSelectedList.concat(x.Commission.map(a => a.StaffId));
      }
    });
    

    const externalElementIndex: number = this._shopservice.findSelectedItemIndexWithoutPackage(itemDetails.LineNumber, this._shopservice.selectedExchangeRetailProducts) + 1;
    const lineItem = this._shopservice.Ticket.lineItems.find(r => r.itemId === item[0].ItemId && r.index === externalElementIndex);
    const itemNetPrice =this.transactionService.GetNetValue(
      item[0].ProductPrice * itemDetails.Noofitems,
					item[0].ExternalPOSItemId,
					this._shopservice.Ticket,
					externalElementIndex, item[0].ItemId 
				);
    const title = this.localization.captions.shop.AssignCommissionGratuity,
      templateName = 'AC';
    const dialogRef = this.dialog.open(ShopDialogPopUp, {
      width: '1155px',
      height: '90%',
      maxHeight: '700px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        isEdit: true, headername: title, closebool: true, templatename: templateName,
        data: [item, itemDetails.Noofitems, therapistSelectedList, PerviousTherapistSameItem, item[0].Commission && item[0].Commission.length > 0],
        netAmount: itemNetPrice,
        netUnitPriceWithoutDiscount : this.PropertyInfo.UseRetailInterface ? item[0].netUnitPriceWithoutDiscount :
        lineItem?.netUnitPriceWithoutDiscount
      },
      panelClass: 'small-popup'
    });
    this.utils.ToggleLoader(false);
    this.subscription = dialogRef.afterClosed().subscribe(x => {
      if (x) {
        itemDetails.charges[0].name = item[0].Commission && item[0].Commission.length > 0 ? this.localization.captions.shop.EditCommissionGratuity : this.localization.captions.shop.AddCommissionGratuity;

        itemDetails.charges[1].isactive = item[0].Gratuity && item[0].Gratuity.length > 0 && item[0].Gratuity.find(r => r.gratuity > 0) ? true : false;
        itemDetails.charges[2].isactive = item[0].Commission && item[0].Commission.length > 0 ? true : false;
        itemDetails.charges[3].isactive = item[0].ServiceCharge && item[0].ServiceCharge.length > 0 && item[0].ServiceCharge.find(r => r.ServiceCharge > 0) ? true : false;

        itemDetails.charges[1].isApplied = item[0].Gratuity && item[0].Gratuity.length > 0 && item[0].Gratuity.find(r => r.gratuity > 0) ? true : false;
        itemDetails.charges[2].isApplied = item[0].Commission && item[0].Commission.length > 0 ? true : false;
        itemDetails.charges[3].isApplied = item[0].ServiceCharge && item[0].ServiceCharge.length > 0 && item[0].ServiceCharge.find(r => r.ServiceCharge > 0) ? true : false;
      
        this.ServiceChargeCalculation(itemDetails, item);               
      }
    });
  }


  async ServiceChargeCalculation(itemDetails: any, item: any) {
    this._retailModuleService.loaderEnable.next(this.ShopCaptions.AddCommissionGratuityServiceCharge);
    if (Object.keys(itemDetails).length != 0) {
      itemDetails.charges[0].name =
        item[0].Commission && item[0].Commission.length > 0
          ? this.localization.captions.shop.EditCommissionGratuity
          : this.localization.captions.shop.AddCommissionGratuity;

      itemDetails.charges[1].isactive = item[0].Gratuity && item[0].Gratuity.length > 0 && item[0].Gratuity.find(r => r.gratuity > 0) ? true : false;
      itemDetails.charges[2].isactive = item[0].Commission && item[0].Commission.length > 0 ? true : false;
      itemDetails.charges[3].isactive = item[0].ServiceCharge && item[0].ServiceCharge.length > 0 && item[0].ServiceCharge.find(r => r.ServiceCharge > 0) ? true : false;

      itemDetails.charges[1].isApplied = item[0].Gratuity && item[0].Gratuity.length > 0 && item[0].Gratuity.find(r => r.gratuity > 0) ? true : false;
      itemDetails.charges[2].isApplied = item[0].Commission && item[0].Commission.length > 0 ? true : false;
      itemDetails.charges[3].isApplied = item[0].ServiceCharge && item[0].ServiceCharge.length > 0 && item[0].ServiceCharge.find(r => r.ServiceCharge > 0) ? true : false;

    }

    if (item[0].ItemType === 6) {
      const index = item[0].LineNumber - 1;
      let gratuitySum = 0;
      let serviceChargeSum = 0;
      if (item[0].Gratuity && item[0].Gratuity.length > 0) {

        item[0].Gratuity.forEach(element => {
          gratuitySum += element.Amount;

        });
      }
      if (item[0].ServiceCharge && item[0].ServiceCharge.length > 0) {

        item[0].ServiceCharge.forEach(element => {
          serviceChargeSum += element.Amount;

        });
      }
    }
      await this.InvokeUpdateTicketForExchangeditems();
      setTimeout(() => { this._retailModuleService.loaderEnable.next("") }, 1000);
  }

  CalculateGrauityforPartialQty(item: SelectedProducts, originalItem: SelectedProducts, originalQuantity: number, newQuantity: number) {
    let gratuityAmount = 0;
    if (item.isGratuityModifiedByCreateTicket) {
      if (item.Gratuity.length > 0 && item.Gratuity) {
        item.Gratuity.forEach(g => {
          gratuityAmount += g.gratuity + (g.additionalGratuity ?? 0);
        });
      }
    }
    else {
      if (originalItem.Gratuity.length > 0 && originalItem.Gratuity) {
        originalItem.Gratuity.forEach(g => {
          const Unitgratuity = (g.Amount + g.customAmount) / originalQuantity;
          gratuityAmount += Unitgratuity * newQuantity;
        });
      }
    }

    return gratuityAmount;
  }

  CalculateServiceChargeforPartialQty(item: SelectedProducts, originalItem: SelectedProducts, originalQuantity: number, newQuantity: number) {
    let serviceChargeAmount = 0;
    if (item.isServiceChargeModifiedByCreateTicket) {
      if (originalItem.ServiceCharge.length > 0 && originalItem.ServiceCharge) {
        item.ServiceCharge.forEach(svcg => {
          serviceChargeAmount += svcg.ServiceCharge + svcg.additionalServiceCharge ?? 0;
        });
      }
    }
    else {
      if (originalItem.ServiceCharge.length > 0 && originalItem.ServiceCharge) {
        originalItem.ServiceCharge.forEach(svcg => {
          const UnitServiceCharge = (svcg.Amount + svcg.customAmount ?? 0)/ originalQuantity;
          serviceChargeAmount += UnitServiceCharge * newQuantity;
        });
      }
    }
    return serviceChargeAmount; 
  }
  CalculateDiscountForPartialQty(item:SelectedProducts, originalItem:SelectedProducts,originalQuantity:number,newQuantity:number)
  {
    let discountAmount = 0;
    if(item.Discount!=0 && originalItem)
      {
        if(item.isDiscountModifiedByCreateTicket)
        {
          discountAmount = item.Discount;
        }
        else
        {
          const unitDiscount = originalItem.Discount/originalQuantity;
          discountAmount = unitDiscount * newQuantity;
        }               
      }
      return discountAmount;
  }

  CalculateTicketDiscountForPartialQty(item:SelectedProducts, originalItem:SelectedProducts,originalQuantity:number,newQuantity:number)
  {
    let discountAmount = 0;
    if(item.weightedDiscount!=0 && originalItem)
      {
        if(item.isDiscountModifiedByCreateTicket)
        {
          discountAmount = item.weightedDiscount;
        }
        else
        {
          const unitDiscount = originalItem.weightedDiscount/originalQuantity;
          discountAmount = unitDiscount * newQuantity;
        }               
      }
      return discountAmount;
  }


  CheckQuantity(item:SelectedProducts) {
    const originalItem = this._shopservice.returnWithticketItems.filter(r => r.id == item.id && r.OriginalLineNumber == item.OriginalLineNumber)[0];
    if(! originalItem) return;
    const originalQuantity: number = Number(originalItem.Noofitems);
    const newQuantity = Number(item.Noofitems);    
    item.isPartialReturn = originalQuantity != newQuantity ? true : false;
    if(item.isPartialReturn)
    {
      this.MapModifiedValuestoOriginalItem(item,originalItem);
    }
    if(item.isPartialReturn && newQuantity < originalQuantity && newQuantity!=0)
    {      
      this.updateQuantityValue();
    }
    if(newQuantity  == originalQuantity)
    {
      item = _.cloneDeep(originalItem);
    }
    if (newQuantity > originalQuantity || newQuantity == 0) {
      item.Noofitems = originalQuantity;
      item.isPartialReturn = false;
      this.updateQuantityValue();
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.captions.Incorrectquantity, GlobalConst.ButtonType.Ok);
    }
  }

  RefreshSummaryTotal() {
    const customToFixed = (val: number) => { return Number(val.customToFixed()); }
    this._shopservice.returnItemTotal = this._shopservice.retainedItemTotal = this._shopservice.exchangedItemTotal = 0;
    this.returnedValuesforGratuityAndServiceCharge = [];
    this._shopservice.returnItemTotal = this._shopservice.retainedItemTotal = this._shopservice.exchangedItemTotal = 0;

    this._shopservice.returnItemTotal = this.isVATEnabled ? customToFixed(this.Itemtotal.returnedItemSubtotal - this.Itemtotal.returnedItemDiscount - this.Itemtotal.returnedItemWeightedDiscount) :
      customToFixed(this.Itemtotal.returnedItemSubtotal + (this.returnTaxExempt.value ? 0 : this.Itemtotal.returnedItemTax + this.Itemtotal.returnedItemTaxOnTax +
        this.Itemtotal.returnedItemServiceChargeTax + this.Itemtotal.returnedItemGratuityTax) - this.Itemtotal.returnedItemDiscount - this.Itemtotal.returnedItemWeightedDiscount)
    
    this._shopservice.retainedItemTotal = this.isVATEnabled ? customToFixed(this.Itemtotal.retainedItemSubTotal - this.Itemtotal.retainedItemDiscount - this.Itemtotal.retainedItemTicketDiscount) :
      customToFixed(this.Itemtotal.retainedItemSubTotal + this.Itemtotal.retainedItemTax + this.Itemtotal.retainedItemTaxOnTax
        + this.Itemtotal.retainedItemServiceChargeTax + this.Itemtotal.retainedItemGratuityTax - this.Itemtotal.retainedItemDiscount - this.Itemtotal.retainedItemTicketDiscount);


    if (this._shopservice.Ticket && this._shopservice.Ticket.checkData && this._shopservice.selectedExchangeRetailProducts &&
      this._shopservice.selectedExchangeRetailProducts.length > 0) {
        this._shopservice.exchangedItemTotal = !this.exempttax.value ? this._shopservice.Ticket.checkData.totalAmount : this._shopservice.Ticket.checkData.totalAmountWithOutTax;
    }
    else {
      this._shopservice.selectedExchangeRetailProducts.forEach(element => {
        if (!(element.isGroupingKey && !element.isPackagedItem)) {
          this._shopservice.exchangedItemTotal = customToFixed(this._shopservice.exchangedItemTotal + this.CalculateItemSubTotal(element, true, !this.exempttax.value));
        }
      });
    }
    this._shopservice.finalAmount = customToFixed(this._shopservice.exchangedItemTotal - this._shopservice.returnItemTotal);
    this._sbs.remainingAmount = this._shopservice.finalAmount; 
    this._shopservice.totalAmountWithoutTax = customToFixed(this._shopservice.OriginalTicket?.checkData?.totalAmountWithOutTax??0);
    this._sbs.TriggerRemainingAmtCalc();
    this._sbs.AdjustPaymentMethods();
  }

  taxExemptChange(e) {
    this.exempttax.value = e[0];
    if (!this._shopservice.hasUserAccessToExemptTax) {
      this._shopservice.showBPMessage(GlobalConst.RetailBreakPoint.TaxExempt);
      this.exempttax.setValue(!this.exempttax.value);
    } else {
      this.exempttax.setValue(this.exempttax.value);
    }
    this._shopservice.exemptTaxForExchange = this.exempttax.value;
    this._shopservice.selectedExchangeRetailProducts.forEach(r => r.isTaxExempt = this.exempttax.value);
    this._shopservice.isTaxExempted = this.exempttax.value; 
    this._sbs.isTaxExempted = this.exempttax.value;
    this._sbs.taxExemptCalcTriggered = true;
    this.RefreshSummaryTotal();
  }

  returnTaxExemptChange(e) {
    this.returnTaxExempt.value = e[0];
    if (!this._shopservice.hasUserAccessToExemptTax) {
      this._shopservice.showBPMessage(GlobalConst.RetailBreakPoint.TaxExempt);
      this.returnTaxExempt.setValue(!this.returnTaxExempt.value);
    } else {
      this.returnTaxExempt.setValue(this.returnTaxExempt.value);
    }
    this._shopservice.selectedReturnedRetailProducts.forEach(r => r.isTaxExempt = this.returnTaxExempt.value);
    this.RefreshSummaryTotal();
  }

  async AmountExceedCallback(result: string, extraParams) {
    if (result.toUpperCase() == "YES") {
      var postData: ApplyDiscount[] = [{
        index: this.applyDiscountInput.index,
        isPercentage: extraParams[0].discountPercentage > 0,
        value: extraParams[0].discountPercentage > 0 ? extraParams[0].discountPercentage : extraParams[0].discountAmount
      }];
      await this.ApplyDiscountToCheckData(postData, ShopSummaryExchangedItemAction.ApplyDiscountToItem);
      this.UpdateOrAddDiscount(extraParams[0]);
    }
  }

  async applyDiscount(discount: ItemDiscount) {
    if (discount.discountAmount < 0) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.ShopCaptions.InvalidAmount);
      return;
    }
    // this.discountPopover.hide();
    if (!this.CheckDiscountAmount(discount)) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.ShopCaptions.DiscountAmountGreaterThanActual, GlobalConst.ButtonType.YesNo, this.AmountExceedCallback.bind(this), [discount]);
      return;
    }
    
    let index = this.applyDiscountInput.index;
		let isPercentage = discount.discountPercentage > 0;
		let value = discount.discountPercentage > 0 ? discount.discountPercentage : discount.discountAmount;
    
		if (discount.isCostPlusDiscount) {
			let total = 0;
			let amount = discount.costPlusDiscountDetails.value;
			total = discount.costPlusDiscountDetails.isPercentage ? Number((this.applyDiscountInput.costPrice + this.applyDiscountInput.costPrice * amount / 100).customToFixed()) : Number((this.applyDiscountInput.costPrice + amount).customToFixed());
			discount.discountAmount = value = this.applyDiscountInput.itemPrice - total;
			discount.isPercentage = isPercentage = false;
			discount.discountId = GlobalConst.DiscountType.CostPlus;
			discount.discountName = this.localization.replacePlaceholders(GlobalConst.DiscountName.CostPlus, ["value"], [`${amount}${discount.costPlusDiscountDetails.isPercentage ? '%' : this.localization.currencySymbol}`]);
		}
		else if (discount.isCostDiscount) {
			discount.discountAmount = value = Number((this.applyDiscountInput.itemPrice - this.applyDiscountInput.costPrice).customToFixed());
			discount.isPercentage = isPercentage = false;
			discount.discountId = GlobalConst.DiscountType.Cost;
			discount.discountName = GlobalConst.DiscountName.Cost;
		}

    var postData: ApplyDiscount[] = [{
      index: index,
      isPercentage: isPercentage,
      value: value
    }];
    this.UpdateOrAddDiscount(discount);
    await this.ApplyDiscountToCheckData(postData, ShopSummaryExchangedItemAction.ApplyDiscountToItem);
    const elementItem = this._shopservice.selectedExchangeRetailProducts[this.applyDiscountInput.index];
    var discountConfig = this.applyDiscountService.CategoryDiscountList.find(
      (x) => x.retailCategoryId == elementItem.category && x.eligibleForDiscount
    );
    if (discountConfig) {
      this.RecalculateServiceChargeGratuityByDicount(elementItem, discount);
    }
    this.CalculateTotal();
  }


  RecalculateServiceChargeGratuityByDicount(exchangedItem: any, discount = null) {
    if (!this._shopservice.isExcludeDiscOnServiceCharge && (exchangedItem.ServiceCharge || []).length > 0) {
      if ((exchangedItem.ServiceCharge[0].Percentage > 0 || exchangedItem.ServiceCharge[0].PercOrAmount === 2) && exchangedItem.ServiceCharge[0].Amount > 0) {
        var serviceChargeDetailBackup = exchangedItem.ServiceCharge;
        exchangedItem.ServiceCharge = null;
        if (exchangedItem.ItemType === 6) {
          const index = exchangedItem.LineNumber - 1;
        }
        if (!this.tempItemDetails || this.tempItemDetails == null) {
          this.tempItemDetails = { itemDetails: {}, item: this._shopservice.selectedExchangeRetailProducts };
        }
        if (this.tempItemDetails) {
          if (serviceChargeDetailBackup && serviceChargeDetailBackup.length > 0) {
            for (const svc of serviceChargeDetailBackup) {
              const discountedCostAmount = discount && discount.discountPercentage > 0 ? (exchangedItem.ProductPrice * discount.discountPercentage) / 100 : (discount || {}).discountAmount || 0;
              const actualAmount = exchangedItem.ProductPrice - discountedCostAmount;
              const amount = svc.PercOrAmount == 1 ? this.utils.MidPointRoundOffTwo((actualAmount * svc.Percentage) / 100) : svc.ServiceCharge;
              svc.ServiceCharge = amount;
              svc.Amount = amount;
              let customAmount = svc.customPercOrAmount == 1 ? this.utils.MidPointRoundOffTwo((actualAmount * svc.customPercentage) / 100) : svc.additionalServiceCharge;
              svc.additionalServiceCharge = customAmount;
              svc.customAmount = customAmount;
            }
          }
          exchangedItem.ServiceCharge = serviceChargeDetailBackup;
        }
      }
    }
    if (!this._shopservice.isExcludeDiscOnGratuity && (exchangedItem.Gratuity || []).length > 0) {
      if ((exchangedItem.Gratuity[0].Percentage > 0 || exchangedItem.Gratuity[0].PercOrAmount === 2) && exchangedItem.Gratuity[0].Amount > 0) {
        var gratuityDetailBackup = exchangedItem.Gratuity;
        exchangedItem.Gratuity = null;
        if (exchangedItem.ItemType === 6) {
          const index = exchangedItem.LineNumber - 1;
        }
        if (!this.tempItemDetails || this.tempItemDetails == null) {
          this.tempItemDetails = { itemDetails: {}, item: this._shopservice.selectedExchangeRetailProducts };
        }
        if (this.tempItemDetails) {
          if (gratuityDetailBackup && gratuityDetailBackup.length > 0) {
            for (const gty of gratuityDetailBackup) {
              const discountedCostAmount = discount && discount.discountPercentage > 0 ? (exchangedItem.ProductPrice * discount.discountPercentage) / 100 : (discount || {}).discountAmount || 0;
              const actualAmount = exchangedItem.ProductPrice - discountedCostAmount;
              const amount = gty.PercOrAmount == 1 ? this.utils.MidPointRoundOffTwo((actualAmount * gty.Percentage) / 100) : gty.gratuity;
              gty.gratuity = amount;
              gty.Amount = amount;
              let customAmount = gty.customPercOrAmount == 1 ? this.utils.MidPointRoundOffTwo((actualAmount * gty.customPercentage) / 100) : gty.additionalGratuity;
              gty.additionalGratuity = customAmount;
              gty.customAmount = customAmount;
            }
          }
          exchangedItem.Gratuity = gratuityDetailBackup;
        }

      }
    }
  }

  async ApplyDiscountToCheckData(postData: ApplyDiscount[], action: ShopSummaryExchangedItemAction) {

    switch (action) {
      case ShopSummaryExchangedItemAction.ApplyDiscountToItem:
        this._retailModuleService.loaderEnable.next(this.ShopCaptions.AddDiscount);
        break;
      case ShopSummaryExchangedItemAction.ApplyDiscountToAllItems:
        this._retailModuleService.loaderEnable.next(this.ShopCaptions.AddDiscount);
        break;
      case ShopSummaryExchangedItemAction.RemoveItemDiscount:
        this._retailModuleService.loaderEnable.next(this.ShopCaptions.RemoveItemDiscount);
        break;
    }
    await this.InvokeCreateTicketForExchangedItems(this._shopservice.settleOpenTransaction);
  }

  private async createTicketAndUpdateOrderForCustomFee() {
    this._shopservice.OriginalTicket = this._shopservice.Ticket = await this.transactionEngineBusiness.CreateTicket
      (this._shopservice.SelectedOutletId, this._shopservice.selectedExchangeRetailProducts, this._shopservice.settleOpenTransaction);
      this._shopservice.isamountRecalcForDiscTax = false;
    let customfeeitems = this._shopservice.selectedExchangeRetailProducts.filter(x => x.sourceTypeId > 0 && x.ItemType == RetailItemType.CustomFee);
    if (customfeeitems.length > 0) {
      customfeeitems.forEach(x => {
        x.netUnitPrice = x.ProductPrice = this._shopservice.Ticket.lineItems.find(y => y.index == x.LineNumber)?.unitPrice
      });
    }
    this.CalculateSectionTotal();
    this.FormExchangedGridData();
    this.RefreshSummaryTotal();
  }

  async InvokeUpdateTicketForExchangeditems(removeDiscBasedOnMinAmount: boolean = true){
    try{
      this._shopservice.OriginalTicket = this._shopservice.Ticket = await this.transactionEngineBusiness.CreateTicket
      (this._shopservice.SelectedOutletId, this._shopservice.selectedExchangeRetailProducts,this._shopservice.settleOpenTransaction);
      if(removeDiscBasedOnMinAmount){
        await this.removeDiscountBasedOnMinAmountInCart();
      }
      this._shopservice.isamountRecalcForDiscTax = false;
      this.CalculateSectionTotal();
      this._shopservice.remainingAmountAfterPayment = this._shopservice.finalAmount; 
    } catch(e){

    } finally{
      this._retailModuleService.loaderEnable.next('');
    }
  }

  async InvokeCreateTicketForExchangedItems(isSettleOpenTransaction: boolean){
    this._shopservice.OriginalTicket = this._shopservice.Ticket = await this.transactionEngineBusiness.CreateTicket
    (this._shopservice.SelectedOutletId, this._shopservice.selectedExchangeRetailProducts,  isSettleOpenTransaction);
    this._shopservice.isamountRecalcForDiscTax = false;
    this.SetExchangedItemsTax();
    this.CalculateSectionTotal();
    this.RefreshSummaryTotal();
    this._shopservice.remainingAmountAfterPayment = this._shopservice.finalAmount; 
    setTimeout(() => { this._retailModuleService.loaderEnable.next("") }, 1000);
    await this.removeDiscountBasedOnMinAmountInCart();
  }

  async removeItemsDiscounts(){
    let isDiscountRemoved = false;
    if(this.RS_selectedExchangeItems.length > 0){
      this.RS_selectedExchangeItems.filter(r => r.Discount > 0 || r.DiscountPercentage > 0 || r.DiscountTypeId > 0).forEach(exchangedItem => {
        const element = this._shopservice.selectedExchangeRetailProducts.find(x => x.ItemId == exchangedItem.ItemId && x.LineNumber == exchangedItem.LineNumber);
        if(element){
          element.DiscountTypeId = 0;
					element.DiscountPercentage = 0;
					element.discountComments = '';
					element.discountReason = 0;
					element.Discount = 0;
					element.isAutoMemDiscRemoved = true;
          element.charges[4].isactive = false;
          element.charges[4].isApplied = false;
          isDiscountRemoved = true;
          this.RecalculateServiceChargeGratuityByDicount(element);
        }
      });
      if(!isDiscountRemoved){
        return ;
      }
      this.RS_selectedExchangeItems = [];
      this._retailModuleService.loaderEnable.next(this.ShopCaptions.RemoveItemDiscount);
      await this.InvokeUpdateTicketForExchangeditems(false);
    }
	}

  async removeDiscountBasedOnMinAmountInCart(){
    const discount = this.applyDiscountService.AllCategoryDiscountList;
    let isDiscountRemoved = false;
    if(discount?.length > 0){
      this._shopservice.selectedExchangeRetailProducts.forEach(x => {
        const appliedDiscount = discount.find(xd => xd.retailCategoryId == x.category)?.discountTypeDetails.find(xd => xd.discountTypeId == x.DiscountTypeId);
        if(!appliedDiscount) return ;
        const isValid =  appliedDiscount.minimumAmountInCart == 0 || appliedDiscount.minimumAmountInCart <= this._shopservice.Ticket.checkData.subTotal;
        if(!isValid) {
          x.DiscountTypeId = 0;
					x.DiscountPercentage = 0;
					x.discountComments = '';
					x.discountReason = 0;
					x.Discount =0;
					x.isAutoMemDiscRemoved = true;
          this.RecalculateServiceChargeGratuityByDicount(x);
          x.charges[4].isactive = false;
          x.charges[4].isApplied = false;
          isDiscountRemoved = true;
        }
      });
    }
    if(!isDiscountRemoved){
      return ;
    }
    this._retailModuleService.loaderEnable.next(this.ShopCaptions.RemoveItemDiscount);
    await this.InvokeUpdateTicketForExchangeditems(false);
  }


  CheckDiscountAmount(discount: ItemDiscount) {
    var productdetails = this.currentSelectedItemForDiscount;
    var itemDiscount = discount;
    let discountAmt: number = 0;
    if (itemDiscount) {
      discountAmt = itemDiscount.discountPercentage > 0 ? (productdetails.Noofitems * productdetails.ProductPrice * itemDiscount.discountPercentage / 100) : itemDiscount.discountAmount;
    }

    if (discountAmt > (productdetails.Noofitems * productdetails.ProductPrice)) {
      return false;
    }
    return true;
  }

  UpdateOrAddDiscount(discount: ItemDiscount, updateSource: boolean = true) {
    const data = this.currentSelectedItemForDiscount;
    if (updateSource && this._shopservice.SelectedItemDiscount.length > 0 && this._shopservice.SelectedItemDiscount.some(x => x.itemId == data.ItemId)) {
      const itemDiscount = this._shopservice.SelectedItemDiscount.find(x => x.itemId == data.ItemId);
      itemDiscount.discountId = discount.discountId;
      itemDiscount.discountAmount = discount.discountAmount;
      itemDiscount.discountPercentage = discount.discountPercentage;
      itemDiscount.costPlusDiscountDetails = discount.costPlusDiscountDetails;
			itemDiscount.isCostDiscount = discount.isCostDiscount;
			itemDiscount.isCostPlusDiscount = discount.isCostPlusDiscount;
    }
    else if (updateSource) {
      discount.itemId = data.ItemId;
      this._shopservice.SelectedItemDiscount.push(discount);
    }

    let discountAmt = 0;
    if (discount) {
      discountAmt = discount.discountPercentage > 0 ? (data.Noofitems * data.ProductPrice * discount.discountPercentage / 100) : discount.discountAmount;
    }

    data.Discount = discountAmt;
    data.DiscountTypeId = discount.discountId;
    data.DiscountPercentage = discount.discountPercentage;
    data.isAutoMemDiscRemoved = true
    data.isCostDiscountApplied = discount.isCostDiscount;
		data.isCostPlusDiscountApplied = discount.isCostPlusDiscount;
		data.costPlusDiscountDetails = discount.costPlusDiscountDetails;
    // this.discountPopover.hide();
  }

  OpenApplyDiscountToAll(): void {
    if (!this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyDiscount])) {
      // this.applyAllPopover.hide();
      return;
    }
    if (this.isApplyDiscountFromOrderSummaryGrid) {
      const filteredData = this.RS_selectedExchangeItems.filter(x => x.Discount > 0 || x.DiscountPercentage > 0 || x.DiscountTypeId > 0 || x.ItemType!=RetailItemType.CustomFee);
      const checkedData= this.RS_selectedExchangeItems;
      const sameCategoryData = checkedData.every((x) => x.category == checkedData[0].category);
      if (filteredData && filteredData.length == 1 && this.RS_selectedExchangeItems.length == 1) {
        const selectedDiscount: ItemDiscount = {
          itemId: filteredData[0].ItemId,
          discountId: filteredData[0].DiscountTypeId,
          discountPercentage: filteredData[0].DiscountPercentage,
          discountAmount: filteredData[0].DiscountPercentage > 0 ? 0 : filteredData[0].Discount,
          discountComment: filteredData[0].discountComments,
          discountReason: filteredData[0].discountReason,
          isPercentage: filteredData[0].DiscountPercentage > 0,
          isCostDiscount: filteredData[0].isCostDiscountApplied,
          isCostPlusDiscount: filteredData[0].isCostPlusDiscountApplied,
          costPlusDiscountDetails: filteredData[0].costPlusDiscountDetails
        };
        this.applyAllDiscountInput = {
          CategoryId: filteredData[0].category,
          SelectedDiscount: selectedDiscount,
          showItemDetails: false,
          isMultipleItems: filteredData.length > 1,
          isCostOrCostPlusEligible: true,
          costPrice: filteredData.length == 1 ? filteredData[0].costPrice : 0,
					itemPrice: filteredData.length == 1 ? filteredData[0].ProductPrice : 0,
        }
      }
      else if (filteredData.length == 0 && checkedData.length == 1) {
        const filteredCheckData = this.RS_selectedExchangeItems;
				const selectedDiscount: ItemDiscount = {
          itemId: 0,
					discountId: 0,
					discountPercentage: 0,
					discountAmount: 0,
					discountComment: '',
					discountReason: 0,
          isPercentage: true,
          isCostDiscount: false,
          isCostPlusDiscount: false
				};
				this.applyAllDiscountInput = {
					CategoryId: filteredCheckData[0].category,
					SelectedDiscount: selectedDiscount,
					isMultipleItems: filteredCheckData.length > 1,
					showItemDetails: false,
          isCostOrCostPlusEligible: true,
          costPrice: filteredCheckData.length == 1 ? filteredCheckData[0].costPrice : 0,
					itemPrice: filteredCheckData.length == 1 ? filteredCheckData[0].ProductPrice : 0,
				}
			}
      else if (filteredData.length == 0 && checkedData.length >1 && sameCategoryData) {
        const filteredCheckData = this.RS_selectedExchangeItems;
				const selectedDiscount: ItemDiscount = {
          itemId: 0,
					discountId: 0,
					discountPercentage: 0,
					discountAmount: 0,
					discountComment: '',
					discountReason: 0,
          isPercentage: true,
          isCostDiscount: false,
          isCostPlusDiscount: false
				};
				this.applyAllDiscountInput = {
					CategoryId: filteredCheckData[0].category,
					SelectedDiscount: selectedDiscount,
					isMultipleItems: filteredCheckData.length > 1,
					showItemDetails: false,
          isCostOrCostPlusEligible: true,
          costPrice: filteredCheckData.length == 1 ? filteredCheckData[0].costPrice : 0,
					itemPrice: filteredCheckData.length == 1 ? filteredCheckData[0].ProductPrice : 0,
				}
			}
      else {
        const selectedDiscount: ItemDiscount = {
          itemId: 0,
          discountId: 0,
          discountPercentage: 0,
          discountAmount: 0,
          discountComment: '',
          discountReason: 0,
          isPercentage: true,
          isCostDiscount: false,
          isCostPlusDiscount: false
        };
        this.applyAllDiscountInput =
        {
          CategoryId: -1,
          SelectedDiscount: selectedDiscount,
          showItemDetails: false,
          isMultipleItems: true,
          isCostOrCostPlusEligible: true
        }
      }
    } else {
      this.applyAllDiscountInput = {
        CategoryId: -1,
        SelectedDiscount: null,
        showItemDetails: false,
        isMultipleItems: true,
        isCostOrCostPlusEligible: true
      };
    }
    this.dialog.open(ApplyDiscountComponent, {
      width: '700px',
      height: '480px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        discountInput: this.applyAllDiscountInput,
        subTotal :  this._shopservice.Ticket ? this._shopservice.Ticket.checkData.subTotal : 0,
        validateMinimumAmountInCart: true
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.applyAllDiscount(result)
      }
    });
  }

  DiscountAllAmountExceedCallback(result: string, extraParams: any[]) {
    if (result.toUpperCase() == "YES") {
      this.applyAllDiscount(extraParams[0], false);
    }
  }

  async applyAllDiscount(discount: ItemDiscount, validate: boolean = true) {
    if (discount.discountAmount < 0 && validate) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.ShopCaptions.InvalidAmount);
      return;
    }

    if (validate && (discount.discountPercentage > 100 || this.RS_selectedExchangeItems.some(x => discount.discountAmount > (x.Noofitems * x.ProductPrice)))) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.ShopCaptions.DiscountAmountGreaterThanActual, GlobalConst.ButtonType.YesNo, this.DiscountAllAmountExceedCallback.bind(this), [discount]);
      // this.applyAllPopover.hide();
      return;
    }
    // this.applyAllPopover.hide();
    var nonApplicable = [];
    var nonApplicableForCost = [];
		var nonApplicableForCostPlus = [];
    var postData: ApplyDiscount[] = [];


    for (let i = 0; i < this.RS_selectedExchangeItems.length; i++) {


      const exchangedItem = this.RS_selectedExchangeItems[i];
      // continue if the selected item is returned Item or Giftcard Item
      if (exchangedItem.isGiftCardItem) continue;

      if (exchangedItem.ItemType == RetailItemType.CustomFee) continue;

      // continue if the selected item is a packaged item
      if (exchangedItem.isGroupingKey && !exchangedItem.isPackagedItem) continue;

      const element = this._shopservice.selectedExchangeRetailProducts.find(x => x.ItemId == this.RS_selectedExchangeItems[i].ItemId && x.LineNumber == this.RS_selectedExchangeItems[i].LineNumber);

      let categoryId: number = 0;
      if (element.isPackagedItem && this.SelectedRetailItemDetails && this.SelectedRetailItemDetails.length > 0) {
        var parentItem = this.SelectedRetailItemDetails.find(x => x.retailItemDetail.id == element.PackageItemId);
        categoryId = parentItem ? parentItem.retailItemDetail.category : categoryId;
      }
      var discountConfig = this.applyDiscountService.CategoryDiscountList.find(x => x.retailCategoryId == element.category && x.eligibleForDiscount);
      var packageDiscountConfig = this.applyDiscountService.CategoryDiscountList.find(x => x.retailCategoryId == categoryId && x.eligibleForDiscount);
      if (!discountConfig) {
        nonApplicable.push(element);
        continue;
      }

      else {
        this.RecalculateServiceChargeGratuityByDicount(exchangedItem, discount);
        if ((!this._shopservice.isExcludeDiscOnServiceCharge || !this._shopservice.isExcludeDiscOnGratuity) &&
          ((exchangedItem.ServiceCharge || []).length > 0 || (exchangedItem.Gratuity || []).length > 0) && this.tempItemDetails) {
          this.ServiceChargeCalculation(this.tempItemDetails?.itemDetails, this.tempItemDetails?.item);
        }

      }

      let percentConfigDetails: DiscountTypeDetails;
      if (element.isPackagedItem) {
        percentConfigDetails = packageDiscountConfig && packageDiscountConfig.discountTypeDetails ? packageDiscountConfig.discountTypeDetails.find(x => x.discountTypeId == discount.discountId) : null;
      }
      else {
        percentConfigDetails = discountConfig.discountTypeDetails.find(x => x.discountTypeId == discount.discountId);
      }
      var percent = percentConfigDetails ? percentConfigDetails.discountValue : discount.discountPercentage;
      if (percent > 0 && discount.isPercentage && discount.discountPercentage == 0) {
        discount.discountPercentage = percent;
      } else if(percentConfigDetails && discount.discountAmount == 0){
        discount.discountAmount = percentConfigDetails.discountValue
      }

      if (discount.isCostPlusDiscount) {
				var costPlusEligible = this.applyDiscountService.CategoryDiscountList.find(
					(x) => x.retailCategoryId == exchangedItem.category && x.eligibleForCostOrCostPlus
				);
				if (!costPlusEligible) {
					nonApplicableForCost.push(exchangedItem);
					continue;
				}
				let total = 0;
				let amount = discount.costPlusDiscountDetails.value;
				total = discount.costPlusDiscountDetails.isPercentage ? Number((exchangedItem.costPrice + (exchangedItem.costPrice * amount) / 100).customToFixed()) : Number((exchangedItem.costPrice + amount).customToFixed());
        if (total > exchangedItem.ProductPrice) {
					nonApplicableForCostPlus.push(exchangedItem);
					continue;
				}
        discount.discountAmount = exchangedItem.ProductPrice - total;
				discount.isPercentage = false;
				exchangedItem.DiscountTypeId = GlobalConst.DiscountType.CostPlus;
				exchangedItem.DiscountName = this.localization.replacePlaceholders(GlobalConst.DiscountName.CostPlus, ["value"], 
					[discount.costPlusDiscountDetails.isPercentage ? `${amount}%` : `${this.localization.currencySymbol}${amount}`]);
			}
			else if (discount.isCostDiscount) {
				var costEligible = this.applyDiscountService.CategoryDiscountList.find(
					(x) => x.retailCategoryId == exchangedItem.category && x.eligibleForCostOrCostPlus
				);
				if (!costEligible) {
					nonApplicableForCost.push(exchangedItem);
					continue;
				}
        if (exchangedItem.costPrice > exchangedItem.ProductPrice) {
					nonApplicableForCostPlus.push(exchangedItem);
					continue;
				}
				discount.discountAmount = Number((exchangedItem.ProductPrice - exchangedItem.costPrice).customToFixed());
				discount.isPercentage = false;
				exchangedItem.DiscountTypeId = GlobalConst.DiscountType.Cost;				
				exchangedItem.DiscountName = GlobalConst.DiscountName.Cost;
			}
			exchangedItem.isCostDiscountApplied = discount.isCostDiscount;
			exchangedItem.isCostPlusDiscountApplied = discount.isCostPlusDiscount;
			exchangedItem.costPlusDiscountDetails = discount.costPlusDiscountDetails;

      postData.push({
        index: this._shopservice.findSelectedItemIndexWithoutPackage(element.LineNumber, this._shopservice.selectedExchangeRetailProducts),
        isPercentage: percent > 0,
        value: percent > 0 ? percent : discount.discountAmount,
        lineNumber: exchangedItem.LineNumber
      });
    }

    if (nonApplicable.length > 0) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.replacePlaceholders(this.ShopCaptions.DiscountNotEligibleForSomeItem, ["ItemCount"], [nonApplicable.length]));
    }
    if (nonApplicableForCost.length > 0) {
			this.utils.ShowErrorMessage(
				this.localization.captions.common.Error,
				this.localization.replacePlaceholders(
					this.ShopCaptions.CostDiscountNotEligibleForSomeItem,
					['ItemCount'],
					[nonApplicableForCost.length]
				)
			);
		}
		if (nonApplicableForCostPlus.length > 0) {
			this.utils.ShowErrorMessage(
				this.localization.captions.common.Error,
				this.localization.replacePlaceholders(
					this.ShopCaptions.CostPlusDiscountNotEligibleForSomeItem,
					['ItemCount'],
					[nonApplicableForCostPlus.length]
				)
			);
		}

   
    let index = 0;
    for (let i = 0; i < this.RS_selectedExchangeItems.length; i++) {

      const exchangedItem = this.RS_selectedExchangeItems[i];
      const element = this._shopservice.selectedExchangeRetailProducts.find(x => x.ItemId == this.RS_selectedExchangeItems[i].ItemId && x.LineNumber == this.RS_selectedExchangeItems[i].LineNumber);
      if (exchangedItem.ItemType == RetailItemType.CustomFee) continue;
      let categoryId: number = element.category;
      if (element.isPackagedItem && this.SelectedRetailItemDetails && this.SelectedRetailItemDetails.length > 0) {
        var parentItem = this.SelectedRetailItemDetails.find(x => x.retailItemDetail.id == element.PackageItemId);
        categoryId = parentItem ? parentItem.retailItemDetail.category : categoryId;
      }

      var discountConfigApply = this.applyDiscountService.CategoryDiscountList.find(x => x.retailCategoryId == categoryId && x.eligibleForDiscount);
      if (!discountConfigApply) {
        index++;
        continue;
      }
      if (discount.discountPercentage > 0) {
        var percentConfig = discountConfigApply.discountTypeDetails.find(x => x.discountTypeId == discount.discountId);
        element.DiscountPercentage = percentConfig ? percentConfig.discountValue : discount.discountPercentage;
        element.Discount = this.utils.MidPointRoundOffTwo(element.Noofitems * element.ProductPrice * element.DiscountPercentage / 100);
        element.DiscountTypeId = percentConfig ? percentConfig.discountTypeId : 0;
        const checkDiscount = this._shopservice.GetCheckLineItemByIndex(this._shopservice.findSelectedItemIndexWithoutPackage(element.LineNumber, this._shopservice.selectedExchangeRetailProducts))?.discount || 0;
        if ((checkDiscount != 0 || this._shopservice.TicketHasDiscount()) && element.Discount != checkDiscount) {
          element.Discount = checkDiscount;
        }
      }
      else {
        if (discount.isCostDiscount || discount.isCostPlusDiscount) {
          exchangedItem.Discount = postData.find(x => x.lineNumber == exchangedItem.LineNumber)?.value ?? 0
        }
        else
          element.Discount = percentConfig ? percentConfig.discountValue : discount.discountAmount;
        element.DiscountPercentage = 0
        if (!exchangedItem.isCostDiscountApplied && !exchangedItem.isCostPlusDiscountApplied)
          element.DiscountTypeId = percentConfig ? percentConfig.discountTypeId : 0;
      }
      element.discountComments = discount.discountComment;
      element.discountReason = discount.discountReason;
      element.isAutoMemDiscRemoved = true

      this._shopservice.SelectedItemDiscount = this._shopservice.SelectedItemDiscount.filter(x => x.itemId != element.ItemId);
      discount.itemId = element.ItemId;
      this._shopservice.SelectedItemDiscount.push(discount);
      index++;
    }
    await this.ApplyDiscountToCheckData(postData, ShopSummaryExchangedItemAction.ApplyDiscountToAllItems);
    this.FormExchangedGridData();
    this.CalculateTotal();
    this.RS_selectedExchangeItems = [];
  }

  async removeDiscount(index: number) {
    const postData: ApplyDiscount[] = [{
      index: index,
      isPercentage: false,
      value: 0
    }];
    await this.ApplyDiscountToCheckData(postData, ShopSummaryExchangedItemAction.RemoveItemDiscount);
  }

  positionPopover(arg, data, idx,charge) {
    if (!this.BPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyDiscount])) {
      // this.applyAllPopover.hide();
      return;
    }

    this.currentSelectedItemForDiscount = data;
    var selectedDiscount: ItemDiscount = {
      itemId: data.ItemId,
      discountId: data.DiscountTypeId,
      discountPercentage: data.DiscountPercentage,
      discountAmount: data.DiscountPercentage > 0 ? 0 : data.Discount,
      discountComment: data.discountComments,
      discountReason: data.discountReason,
      isPercentage: data.DiscountPercentage > 0,
      isCostDiscount: data.isCostDiscountApplied,
      isCostPlusDiscount: data.isCostPlusDiscountApplied,
      costPlusDiscountDetails: data.costPlusDiscountDetails
    };

    this.applyDiscountInput = {
      index: idx,
      CategoryId: data.category,
      SelectedDiscount: selectedDiscount,
      isMultipleItems: false,
      showItemDetails: false,
      itemName: data.ItemDescription,
      itemPrice: data.ProductPrice,
      costPrice: data.costPrice,
      isCostOrCostPlusEligible: this._shopservice.ItemEligibleForCostOrCostPlus[data.category]
    };

    if (charge?.id == 5) {
      this.dialog.open(ApplyDiscountComponent, {
        width: '700px',
        height: '480px',
        disableClose: true,
        hasBackdrop: true,
        data: {
          discountInput: this.applyDiscountInput,
          subTotal :  this._shopservice.Ticket ? this._shopservice.Ticket.checkData.subTotal : 0,
          validateMinimumAmountInCart: true
        },
      }).afterClosed().subscribe(result => {
        if (result) {
          this.applyDiscount(result)
        }
      });
    }


    
  }

  setIdx(idx) {
    this.isOpened = true;
    if (this.openedIdx == -1) {
      this.openedIdx = idx;
    }
  }

  clearIdx() {
    this.isOpened = false;
    this.openedIdx = -1;
  }

  /**
 * Remove packaged item parent if all child items are deleted
 */
  CheckAllPackagedItemRemoved() {
    try {
      var groupedItems = this._shopservice.selectedExchangeRetailProducts.filter(x => x.isGroupingKey);
      for (const element of groupedItems) {
        // check if all the child items are deleted
        if (!this._shopservice.selectedExchangeRetailProducts.some(x => x.isPackagedItem && x.PackageGroupId == element.PackageGroupId && x.PackageItemId == element.PackageItemId)) {
          var removeIndex = this._shopservice.selectedExchangeRetailProducts.indexOf(element);
          // remove from griditem
          this._shopservice.selectedExchangeRetailProducts.splice(removeIndex, 1);
          // remove from selected products
          this._shopservice.selectedProducts.splice(removeIndex, 1);
        }
      }
    }
    catch (e) {

    }
  }

  SetExchangedItemsTax() {
    this.Itemtotal.exchangedItemTax = this._shopservice.Ticket.checkData.totalTax;
    let index = 1;
    if (this.PropertyInfo.UseRetailInterface) {
      this._shopservice.selectedExchangeRetailProducts.forEach(r => {
        if (!r.isGroupingKey) {
          const resultItem = this._shopservice.Ticket.lineItems.find(
            i => i.externalPOSItemId === r.ExternalPOSItemId && i.index === index);
          if (resultItem) {
            r.Tax = resultItem.tax;
            r.Discount = resultItem.discount;
          }
          index++;
        }
      });
    }
    else {
      this._shopservice.selectedExchangeRetailProducts.forEach(r => {
        if (!r.isGroupingKey) {
          const resultItem = this._shopservice.Ticket.lineItems.find(
            i => i.itemId === r.ItemId && i.index === index);
          if (resultItem) {
            r.Tax = resultItem.tax;
            r.Discount = resultItem.discount;
          }
          index++;
        }
      });
    }
  }

  async GetPlayerDetails(transactionId: number) {
    const transactionDetailAggregate = await RetailDataAwaiters.GetPlayerNamesByTransactionId(transactionId);
    this._sbs.isMultipleMemberTransaction = false;
    if (this._shopservice.selectedTransactionRetailProducts.length > 0 && this._shopservice.returnWithticketItems.length > 0 && transactionDetailAggregate &&
       transactionDetailAggregate.playerTransactionDetails && transactionDetailAggregate.playerTransactionDetails.length > 0) {
      this._sbs.scheduledTeeTimeIds = transactionDetailAggregate?.teeTicketDetail && transactionDetailAggregate?.teeTicketDetail.scheduledTeeTimeIds || [] ;

      if(this._sbs.scheduledTeeTimeIds && this._sbs.scheduledTeeTimeIds.length) {
        const transactionpaymentDetails = await this._sbs.GetPaymentTransaction(transactionId);
        const playerIdList: number[] = _.uniq(transactionDetailAggregate.playerTransactionDetails.map(x => x.playerId));
        const transDetailIds = this._shopservice.returnWithticketItems.map(r => r.id);
        this.playerDetails = await RetailDataAwaiters.getPayeeDetails(playerIdList);
        const Member= this.playerDetails.filter(x=>Number(x.playerCategoryId)== PlayerCategory.Member );
        if(Member.length > 1 && transactionpaymentDetails.some(x => Number(x.paymentMethod)  === PaymentMethods.ARPost)) {
          const memberPaymentTransIds = transactionDetailAggregate.playerTransactionDetails.map(x => x.transactionDetailId);
          this._sbs.isMultipleMemberTransaction = transDetailIds.some(t => memberPaymentTransIds.includes(t));
        }
      }

      this._shopservice.selectedTransactionRetailProducts.forEach(p => {
        const transaction = transactionDetailAggregate.playerTransactionDetails.find(t => t.transactionDetailId == p.id);
        if (transaction) {
          p.playerName = transaction.playerName;
          p.payeeId = transaction.playerId
          //Check multiple raincheck (issettled true and false)
          p.rainCheckIssued = this.PlayerHasRainCheck(transactionDetailAggregate, transaction.playerId);
        }
      });
      this._shopservice.returnWithticketItems.forEach(p => {
        const transaction = transactionDetailAggregate.playerTransactionDetails.find(t => t.transactionDetailId == p.id);
        if (transaction) {
          p.playerName = transaction.playerName;
          p.payeeId = transaction.playerId
          //Check multiple raincheck (issettled true and false)
          p.rainCheckIssued = this.PlayerHasRainCheck(transactionDetailAggregate, transaction.playerId);
        }
      });
    }
  }
  async GetAppointmentClientDetails(transactionId: number) {
    const appointmentTransactions = await RetailDataAwaiters.GetAppointmentClientDetails(transactionId);
    if (
      this._shopservice.selectedTransactionRetailProducts.length > 0 &&
      this._shopservice.returnWithticketItems.length > 0 &&
      appointmentTransactions &&
      appointmentTransactions.length > 0
    ) {
      const depositTransactions = appointmentTransactions.filter(x => x.transactionDetailId == -1 && x.retailItemId == -1);

      this._shopservice.selectedTransactionRetailProducts.forEach((p) => {
        const transaction = appointmentTransactions.find((t) => t.transactionDetailId == p.id);
        if (transaction) {
          p.clientName = transaction.clientName;
          p.clientId = transaction.clientId;
        } else {
          if (p.retailItemType == RetailItemType.Deposit) {
            if (depositTransactions && depositTransactions.length > 0) {
              const removeItemIndex = depositTransactions.findIndex(x => x.amount == p.SalesPrice);
              if (removeItemIndex != -1) {
                const removeItem = depositTransactions[removeItemIndex];
                if (removeItem) {
                  p.clientName = removeItem.clientName;
                  p.clientId = removeItem.clientId;
                  depositTransactions.splice(removeItemIndex, 1);
                }
              }
            }
          }
        }
      });
      const depositTransactionsSet2 = appointmentTransactions.filter(x => x.transactionDetailId == -1 && x.retailItemId == -1);
      this._shopservice.returnWithticketItems.forEach((p) => {
        const transaction = appointmentTransactions.find((t) => t.transactionDetailId == p.id);
        if (transaction) {
          p.clientName = transaction.clientName;
          p.clientId = transaction.clientId;
        } else {
          if (p.retailItemType == RetailItemType.Deposit) {
            if (depositTransactionsSet2 && depositTransactionsSet2.length > 0) {
              const removeItemIndex = depositTransactionsSet2.findIndex(x => x.amount == p.SalesPrice);
              if (removeItemIndex != -1) {
                const removeItem = depositTransactionsSet2[removeItemIndex];
                if (removeItem) {
                  p.clientName = removeItem.clientName;
                  p.clientId = removeItem.clientId;
                  depositTransactionsSet2.splice(removeItemIndex, 1);
                }
              }
            }
          }
        }
      });
    }
  }

  ScAndGCheckBoxChange(evt, retainItem: SelectedProducts) {
    if (retainItem.isPartialReturn && retainItem.returnType == ReturnWithTicketType.Default) {
      const retainedPartialItem = this._shopservice.selectedRetainedRetailProducts.find(x => x.id == retainItem.id && x.returnType > ReturnWithTicketType.Default);
      if (retainedPartialItem && retainedPartialItem.returnType == ReturnWithTicketType.RETAINITEMONLY) {
        retainItem.isServiceChargeReturn = retainItem.isGratuityReturn = evt.checked ? true : false;
      }
    }
  }

  DisableRetainForPartialScAndG(retainItem: SelectedProducts, type: string): Boolean {
    const retainedPartialItem = this._shopservice.selectedRetainedRetailProducts.find(x => x.id == retainItem.id && x.returnType > ReturnWithTicketType.Default);
    if (retainedPartialItem && retainItem.returnType == ReturnWithTicketType.Default) {
      if (type == "GRATUITY") {
        if (retainedPartialItem.returnType == ReturnWithTicketType.RETAINITEMANDGRATUITY || retainedPartialItem.isServiceChargeReturn || retainedPartialItem.isItemChecked) {
          return true;
        }
      }
      if (type == "SERVICECHARGE") {
        if (retainedPartialItem.returnType == ReturnWithTicketType.RETAINITEMANDSERVICECHARGE || retainedPartialItem.isGratuityReturn || retainedPartialItem.isItemChecked) {
          return true;
        }
      }
      if (type == "ITEM") {
        return true;
      }
    }
    else if (retainedPartialItem && retainItem.returnType != ReturnWithTicketType.Default) {
      const retainedDefaultPartialItem = this._shopservice.selectedRetainedRetailProducts.find(x => x.id == retainItem.id && x.returnType == ReturnWithTicketType.Default);
      if (retainedDefaultPartialItem && (retainedDefaultPartialItem.isServiceChargeReturn || retainedDefaultPartialItem.isGratuityReturn)) {
        if (type == "GRATUITY") {
          if (retainedPartialItem.returnType == ReturnWithTicketType.RETAINITEMANDGRATUITY) return true;
        }
        if (type == "SERVICECHARGE") {
          if (retainedPartialItem.returnType == ReturnWithTicketType.RETAINITEMANDSERVICECHARGE) return true;
        }
        if (type == "ITEM") {
          return true;
        }
      }
    }
    return false;
  }

  /**
	 * Helper method to apply the gratuity to the first eligible item in the selected products list
	 * @param event eventData containing the tip amount and the quickUserId 
	 */
	async ApplyTipAsGratuityForExchangeItem(event: ApplyTipAsGratuityData) {
		try {
			const tipAmount = event.tipAmount;
			const quickUserId = event.quickUserId;
			if (tipAmount) {
				const loggedInUserType = 'USER';
				const itemToBeApplied = this._shopservice.selectedExchangeRetailProducts[0]; //Tip will be auto applied as Gratuity to the first item in the cart
        itemToBeApplied.charges[0].name = this.localization.captions.shop.AddCommissionGratuity;
        itemToBeApplied.charges[1].isactive = true;
        itemToBeApplied.charges[1].isApplied = true;
        const tipAsGratuity: Gratuity[] = [{
          PercOrAmount: 1,
          StaffType: loggedInUserType, //Gratuity will be added against the loggedIn USER
          TherapistId: quickUserId > 0 ? quickUserId : Number(this.localization.GetPropertyInfo('UserId')),
          gratuity: tipAmount,
          Id: 0,
          TransactionDetailId: 0,
          PaymentTransactionId: event.paymentTransactionId,
          Amount: tipAmount,
          Percentage: 0,
          customAmount: 0,
          PercentageId: 0
        }];
        if (itemToBeApplied?.Gratuity?.length > 0) {
          //If the current loggedIn User has gratuity applied on the first item, then assigning tip as Additional Gratuity
          if (itemToBeApplied.Gratuity[0].TherapistId == tipAsGratuity[0].TherapistId && itemToBeApplied.Gratuity[0].StaffType == loggedInUserType
            && !itemToBeApplied.Gratuity[0].PaymentTransactionId) {
            itemToBeApplied.Gratuity[0].additionalGratuity = tipAmount;
            itemToBeApplied.Gratuity[0].customAmount = tipAmount;
            itemToBeApplied.Gratuity[0].customPercOrAmount = 2;
            itemToBeApplied.Gratuity[0].gratuity += tipAmount;
          } else {
            itemToBeApplied.Gratuity = itemToBeApplied.Gratuity.concat(tipAsGratuity);
          }
        } else {
          itemToBeApplied.Gratuity = tipAsGratuity;
        }
        this._shopservice.tipAppliedAsGratuity = true;
				await this.InvokeUpdateTicketForExchangeditems();				
			}
		} catch (error) {
			console.log("Error while auto applying the gratuity! ::" + error)
		}    
	}

  RemoveTipFromReversedPayment(paymentHistory: PaymentHistory[]) {
    const reversedPayments = paymentHistory.filter(x => x.isReversed);
    //Remove auto applied gratuity from the grid
    reversedPayments.forEach(payment => {
      const autoAppliedGratityIndex = this._shopservice.selectedExchangeRetailProducts[0].Gratuity?.findIndex(g => g.PaymentTransactionId == payment.paymentReferenceId);
      if (autoAppliedGratityIndex != -1) {
        this._shopservice.selectedExchangeRetailProducts[0].Gratuity.splice(autoAppliedGratityIndex, 1);
        let itemToBeApplied = this._shopservice.selectedExchangeRetailProducts[0];
        itemToBeApplied.charges[0].name = "";
        itemToBeApplied.charges[1].isactive = false;
        itemToBeApplied.charges[1].isApplied = false;
        this._shopservice.tipAppliedAsGratuity = false;
      }
    });
    this.CalculateSectionTotal();
    this.FormExchangedGridData();
    this.RefreshSummaryTotal();
  }

  PlayerHasRainCheck = (x: TransactionDetailAggregate, playerId): boolean =>
    Array.isArray(x.rainCheckDetail) && x.rainCheckDetail.some(t => t.playerId == playerId);

  CheckAllRainCheckIssued = () =>
    this.GridItems.selectedTransactionRetailProducts && this.GridItems.selectedTransactionRetailProducts.some(x => x.rainCheckIssued === true);

  btnCheck = () => (this.frompage != 'RetailSummary' &&
  (this.selectedReturnItems?.length == 0 &&
    !this.GridItems.selectedTransactionRetailProducts?.some(x => x?.isServiceChargeReturn || x?.isGratuityReturn)))
  || (this.frompage == 'RetailSummary' && this.RS_selectedRtainedItems?.length == 0 &&
    !this._shopservice.selectedRetainedRetailProducts?.some(x => x?.isServiceChargeReturn || x?.isGratuityReturn));
  removeBtnCheck = () => ((this.RS_selectedExchangeItems?.length == 0 && (this.RS_selectedReturnItems?.length == 0 && !this.GridItems.selectedReturnedRetailProducts?.some(x => x?.isServiceChargeReturn || x?.isGratuityReturn))) || this._retailModuleService.paymentProcessing);

  CheckQuantityDisable = (ordersummary) => (this.selectedReturnItems.indexOf(ordersummary) != -1
    || ordersummary.isServiceChargeReturn || ordersummary.isGratuityReturn);

}
export enum ShopSummaryExchangedItemAction {
  AddTax = 1,
  RemoveItems,
  ApplyDiscountToAllItems,
  ApplyDiscountToItem,
  AddCommissionGratuityServiceCharge,
  RemoveCommission,
  RemoveGratuity,
  RemoveServiceCharge,
  RemoveItemDiscount

}

