import { Component, OnInit, Inject, ViewEncapsulation, Output, EventEmitter, ViewContainerRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppointmentpopupService } from '../../shared/service/appointmentpopup.service';
import { DatePipe } from '@angular/common';
import { HttpMethod, HttpServiceCall } from '../service/http-call.service';
import { ClientDetail, PhoneNumber, Email, Address, ClientInfo, MedicalHistory, ContactLog, GuestContact } from '../business/new-booking.model';
import { BaseResponse, LoyaltyDetail, GenderOverrideMessage, GenderOverrideTransLog, SubscribeProfile, SubscriptionType } from '../../shared/business/shared.modals';
import { Host, ButtonType, ImgRefType, PromptType, ButtonOptions, ActionType, SPAScheduleBreakPoint, DefaultGUID, CancellationNoShowFeeSwitch } from '../globalsContant';
import { UntypedFormBuilder } from '@angular/forms';
import { SpaUtilities } from '../utilities/spa-utilities';
import { multiClientInfo, appointment, appointmentDetail, appointmentTherapist, BaseResp, SystemConfig, AppointmentLostDeniedBusinessClient } from '../business/shared.modals';
import { AppointmentUtilities } from '../utilities/appointment-utilities';
import { appointmentService, SelectedSession } from '../service/appointment.service';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { ImageProcessorService } from '../service/image-processor-service';
import { UserAlerts } from '../../core/config/alerts-config';
import { BreakPointAccess } from '../service/breakpoint.service';
import * as _ from 'lodash';
import { SpaPropertyInformation } from '../../core/services/spa-property-information.service';
import { ReplaySubject, SubscriptionLike as ISubscription, Subject } from 'rxjs';
import * as GlobalConst from '../../shared/globalsContant';
import { ClientLabel } from '../../shared/business/new-booking.model';
import { AppointmentActionsCommonDialogComponent } from '../common-functionalities/appointment-actions-dialog/appointment-actions-common-dialog.component';
import { ClientService } from '../service/client-service.service';
import { CreateAppointmentComponent } from './create-appointment/create-appointment.component';
import { PMSAction } from 'src/app/common/external-request/pms-request.model';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { takeUntil } from 'rxjs/operators';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { ClientCardInfo, GuaranteeMethodBusiness } from './create-appointment/guarantee-method/guarantee-method.business';
import { GuaranteeMethod } from 'src/app/retail/shared/business/shared.modals';
import { AddLostDeniedBusinessPopupComponent } from '../appointment-actions/add-lost-denied-business-popup/add-lost-denied-business-popup.component';
import { LostDeniedBusinessPopupService } from '../appointment-actions/add-lost-denied-business-popup/lost-denied-business-popup.service';
import { OperationType, AlertType, PhoneTypes } from '../../shared/globalsContant';
import { MailTypes } from 'src/app/retail/shared/shared.modal';
import { PolicyType } from 'src/app/common/shared/shared.modal';
import { ApplyPolicy } from 'src/app/common/consent-management/consent-management.model';
import { ConsentManagementBusiness } from 'src/app/common/consent-management/consent-management.business';
import * as moment from 'moment';
import { SPAConfig } from 'src/app/core/config/SPA-config';
import { VCartMessageHandler } from 'src/app/common/components/menu/vcart/vcart.MessageHandler';
import { CartItineraryUI, CartPayload, CartWindowMessageType, HeaderValue, TempHold } from 'src/app/common/components/menu/vcart/vcart.modal';
import { CartUtilities } from 'src/app/common/components/menu/vcart/cart.utilities';
import { IcartDataService } from 'src/app/common/dataservices/icart.data.service';
import { EventNotification } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { NotificationConfigurationService } from 'src/app/common/templates/notification-configuration/notification-configuration.service';
import { E } from '@angular/cdk/keycodes';
import { debounceTime } from 'rxjs/operators';
import { SEARCH_DEBOUNCE_TIME } from 'src/app/common/shared/shared/setupConstants';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-appointment-popup',
  templateUrl: './appointment-popup.component.html',
  styleUrls: ['./appointment-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [GuaranteeMethodBusiness, ConsentManagementBusiness]
})
export class AppointmentPopupComponent implements OnInit {
  captions: any = this.localization.captions.bookAppointment;
  allCaptions: any = this.localization.captions;
  @Output() popupclosed: EventEmitter<any> = new EventEmitter();
  @ViewChild('appointmentcomponent') appointmentComponent: CreateAppointmentComponent;
  EnableSave: boolean = true;
  availableMultipacks: any;
  linkMultiPackOpted: boolean;
  title: string = ''
  MultipackUpdate: boolean;
  multipackunlinkArray: any;
  APPOINTMENT_SHOW_RECAP: boolean = true;
  APPOINTMENT_LOST_DENIED_BUSINESS: boolean = false;
  SEND_AUTOMATIC_APPOINTMENT_NOTIFICATION: boolean = false;
  saveSubscription: ISubscription;
  dialogSubscription: any;
  public _render: any;
  addClient: any;
  activateClientSelected: any;
  selectedTabIndex: number = 0;
  selectedIndex: number = 0;
  guestName: any;
  clientInfo: any;
  lostDeniedDatas: any = [];
  clientGuid: any;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  isGenderPreferenceOverride: boolean = false;
  sendAutomaticNotification: boolean = true;
  notificationSetup: any;
  saveBtnClicked: Subject<any> = new Subject();
  automation: string;
  fromItineraryObject: any;
  existingAddons: any = [];
  canCloseClientPopup: boolean = false;
  throttleTime: number = 3000;

  ngOnDestroy(): void {
    if (!this.appointmentpopservice.isLocationBlock) {
      if (!this.appointmentpopservice.isFromWaitlist && !this.appointmentpopservice.isFromLostDeniedBusiness) { // Do not clear multi-client info when add client component opened from wailist
        this.appointmentpopservice.multiClientInfo = [];
        this._quickLoginUtil.resetQuickIdDetails();
        if (!this.appointmentservice.isAppointmentFromPackage && (this.appointmentservice.SelectedSession != SelectedSession.ClientAppointment)) {
          this.appointmentpopservice.labelRecords = [];
          this.appointmentpopservice.recordsArray = [];
        }
        this.appointmentpopservice.currentClient = [];
        this.appointmentpopservice.guestContact = [];
        this.appointmentpopservice.relatedClient = [];
        this.appointmentpopservice.resultNewAppointment = [];
        this.appointmentpopservice.resultNewClient = [];
        this.appointmentpopservice.otherdetails = { linkcode: 0, vip: false, intakeForm: false, NoMove: false, gender: 0, guestType: 0, customField1: null, customField2: null, customField3: null, customField4: '', customField5: '', comments: '', policy: '', checkout: '', noShowComments: '', valueChange: false, client_scheduling: false };
        this.appointmentpopservice.add_client = false;
        this.appointmentpopservice.clientId = 0;
        this.title = this.appointmentpopservice.popupTitle;
        this.appointmentpopservice.popupTitle = this.captions.NEWAPPOINTMENT;
        this.appointmentpopservice.editselectedClient = false;
        this.appointmentpopservice.copySelectedClient = false;
        this.appointmentpopservice.addFromClientModule = false;
        this.appointmentpopservice.isfromAppoinment = false;
        this.appointmentpopservice.isFromLostDeniedBusiness = false;
        this.appointmentpopservice.isEditAppointment = false;
        this.appointmentpopservice.isSpaWizard = false;
        this.appointmentpopservice.isWizardGroupContactView = false;
        this.appointmentpopservice.TempHoldArr = [];
        this.appointmentpopservice.linkedAppointments = [];
        this.appointmentpopservice.linkedAppointmentUpdated = [];
        this.appointmentpopservice.clientScreenProperties.maxGuestLocation = 0;
        this.appointmentpopservice.linkedAppointmentId = null;
        this.appointmentpopservice.clientBlockInfos = [];
        this.appointmentpopservice.isFromWaitlist = false;
        this.appointmentpopservice.isFromLostDeniedBusiness = false;
        this.appointmentpopservice.clientScreenProperties.minGuest = 0;
        this.appointmentpopservice.clientScreenProperties.maxGuest = 1;
        this.appointmentpopservice.clientScreenProperties.selectedStaff = null;
        this.appointmentpopservice.clientScreenProperties.helperText = '';
        this.appointmentpopservice.isEditClient = false;
        this.appointmentpopservice.tempHoldValues = {
          service: 0,
          location: 0,
          startTime: '',
          endTime: '',
          serviceGroupId: 0,
          comments: '',
          status: '',
          breakDownTime: 0,
          duration: 0,
          setUpTime: 0,
          price: 0,
          doNotMove: false,
          requestStaff: false,
          isBookingLocked: false,
          isVip: false,
          genderPreference: 0,
          checkOutComments: '',
          noShowComments: '',
          noShow: false,
          packageId: 0,
          clientId: 0,
          therapist: [],
          appointmentId: 0,
          cancelId: '',
          appointmentDate: '',
          TempHoldId: '',
          TempHoldLinkId: 0,
          originalClientId: 0,
          multiGroupId: 0,
          guestTypeId: 0,
          discountType: ''
        };
        this.appointmentpopservice.therapistAssociationError = false;
        this.appointmentpopservice.resultExistingAppointment = null;
        this.appointmentpopservice.resultExistingAppActivity = null;
        this.appointmentpopservice.resultPkgEntitlementData = null;
        this.appointmentpopservice.isOverbook = false;
        this.appointmentpopservice.activateClientSelected = null;
        this.appointmentpopservice.clientGuestMap = [];
        this.appointmentpopservice.activateClientSelected = null;
        this.appointmentpopservice.ClientActivities = [];
        this.appointmentpopservice.existingAppointmentsRef = null;
        this.appointmentpopservice.guaranteeMethodData = null;
        this.appointmentpopservice.guaranteeMethodConfig = null;
        this.appointmentpopservice.isGuaranteeMethodValid = false;
        this.appointmentpopservice.isGuaranteeMethodDirty = false;

        this.appointmentpopservice.ClientSelectedTab = 0;
        this.appointmentpopservice.clientCreditCardInfo = null;
        this.appointmentpopservice.isPreviousDayAppt = false;
        this.appointmentpopservice.recreateTempAppointments = false;

        this.appointmentpopservice.overrideClientBlock = false;

        this.appointmentpopservice.addOns = [];
        this.appointmentpopservice.addOnsResult = [];
        this.appointmentpopservice.makeAddOnsCall = false;
        this.appointmentpopservice.addOnsSaveBtnEnable = false;
        this.appointmentpopservice.multiClientTherapistChange = false;

        if (this.saveSubscription) {
          this.saveSubscription.unsubscribe();
        }
        if (this.dialogSubscription) {
          this.dialogSubscription.unsubscribe();
        }
      }
    }
    if (this.$destroyed) {
      this.$destroyed.next(true);
      this.$destroyed.complete();
    }
  }
  datRec: any;
  AppointmentId: any;
  personalInfoIsValid: boolean;
  additionalInfoIsValid: boolean;
  cancelBoo;
  from: boolean = false;
  popupTitle: any = this.appointmentpopservice.popupTitle == this.captions.NEWAPPOINTMENTOverbook ? this.captions.NEWAPPOINTMENT : this.appointmentpopservice.popupTitle;

  saveText: any = this.captions.SAVE;
  cancelText: any = this.captions.CANCEL;
  linkText: any = this.captions.LINK;
  unlinkText: any = this.captions.UNLINK;
  isUpdate: boolean = this.popupTitle == this.captions.NEWAPPOINTMENT || this.appointmentservice.isAppointmentFromPackage ? false : true;
  isvalidateButton: any;
  exisitngAppointmentDate: Date;
  newAppointmentDate: Date;
  IsGDPREnabled: boolean = false;
  isEmbedded: boolean = false;
  isEmailSendRequired: boolean = true;
  isCancellationNoShowFeeEnabled: boolean = false;
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AppointmentPopupComponent>,
    public appointmentpopservice: AppointmentpopupService,
    public appointmentservice: appointmentService,
    public datePipe: DatePipe,
    private http: HttpServiceCall,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private Utilities: SpaUtilities,
    private ApptUtilities: AppointmentUtilities,
    public localization: SpaLocalization,
    private imgService: ImageProcessorService,
    private userAlert: UserAlerts,
    private breakPoint: BreakPointAccess,
    private PropertyInfo: SpaPropertyInformation,
    public _fb: UntypedFormBuilder,
    private clientService: ClientService,
    private _vcr: ViewContainerRef,
    private _retailPropertyInfoService: RetailPropertyInformation,
    private _quickLoginUtil: QuickLoginUtilities,
    private _guaranteeBusiness: GuaranteeMethodBusiness,
    private lostDeniedBusinessPopupService: LostDeniedBusinessPopupService,
    private consentManagementBusiness: ConsentManagementBusiness,
    private routeConfig: SPAConfig,
    private cartUtils: CartUtilities,
    private _icartDataService: IcartDataService,
    private _NotificationConfigurationService: NotificationConfigurationService,
    private router: Router
  ) {
    this.isEmbedded = this.cartUtils.isEmbed();
    this.appointmentpopservice.resultNewAppointment = data;
    this.from = data.fromPackage;
    this.appointmentpopservice.cancelBool = true;
    this.appointmentpopservice.saveText = (this.appointmentpopservice.isEditAppointment || this.appointmentpopservice.isEditClient || (!this.appointmentpopservice.add_client && this.appointmentpopservice.clientId > 0) || this.appointmentpopservice.editClientFromPackage) ? this.allCaptions.common.UPDATE : this.allCaptions.common.SAVE;
    if (this.appointmentpopservice.copySelectedClient) {
      this.appointmentpopservice.saveText = this.allCaptions.common.SAVE;
    }
    this.cancelText = this.allCaptions.common.Cancel
    this.appointmentpopservice.attachClient = false;
    this.appointmentpopservice.clientBlockOverrideAccess = this.breakPoint.CheckForAccess([SPAScheduleBreakPoint.overrideClientBlock], false);
  }


  /**
   * @method ngAfterViewInit
   * @description If Changes the any form in particular steper the dom rendering enable, if enable the form group will valid this form and call to  this.validateSave()
   * @input <form group>
   * @output <form group call the validateSave()>
   */

  ngAfterViewInit() {
    this.isvalidateButton = this._fb.group({
      'personaldetailsformGroup': this.appointmentpopservice.personalDetailsFormGroup,
      'additionalformGroup': this.appointmentpopservice.additionalDetailsFormGroup,
      'medicalformGroup': this.appointmentpopservice.medicalHistoryFormGrp,
      'preferenceGroup': this.appointmentpopservice.preferenceFormGroup,
      'soapNotes': this.appointmentpopservice.soapNotesFormGrp,
      'clieninformationformGroup': this.appointmentpopservice.clientInformationForm,
      'otherDetailsFormGroup': this.appointmentpopservice.otherDetailsFormGroup,
      'appoinmentDetailFormArray': this.appointmentpopservice.appoinmentDetailFormArray,
      'blockClientInfoFormArray': this.appointmentpopservice.clientBlockIformationFormGroup,
      'contactLogsFormArray': this.appointmentpopservice.contactLogsFormGrp,
      'guestContacFormArray': this.appointmentpopservice.guestContactFormGrp,
    })
    this.saveSubscription = this.isvalidateButton.valueChanges.subscribe(x => { this.validateSave() })
    this.exisitngAppointmentDate = (this.appointmentpopservice && this.appointmentpopservice.resultExistingAppointment && this.appointmentpopservice.resultExistingAppointment.appointmentDetail && this.appointmentpopservice.resultExistingAppointment.appointmentDetail.startTime);
    this.automation = this.appointmentpopservice.add_client ? 'client' : 'appointment'
  }

  promptUserForUnsavedChanges() {
    this.appointmentpopservice.isCancel = true;  //Fix For No changes in client-info
    if (this.appointmentpopservice.add_client) {
      //currently in Client detail
      this.showAlertForClient();
    } else {
      //currently in appointment detail screen
      this.showAlertForAppointmentDetail();
    }
  }

  showAlertForClient() {
    if (this.IsClientScreenDirty()) {
      this.userAlert.showPrompt(PromptType.UnsavedChanges, this.close.bind(this))
    } else {
      this.OnClosingOrCancelling();
    }
  }

  showAlertForAppointmentDetail() {
    if (this.IsAppointmentScreenDirty() && this.APPOINTMENT_LOST_DENIED_BUSINESS && !this.appointmentpopservice.isEditAppointment) {
      this.Utilities.ShowErrorMessage(this.localization.captions.common.Confirmation,
        this.captions.appointmentCancellationLostDenied, ButtonType.YesNo, async (res, extraParams) => {
          if (res.toLowerCase() === ButtonOptions.Yes) {
            await this.OpenLostDeniedBusinessRecords(extraParams);
            this.close(res);
          }
        }, null, true)
    }
    else if (this.IsAppointmentScreenDirty() && !this.cartUtils.isEmbed()) {
      this.userAlert.showPrompt(PromptType.UnsavedChanges, this.close.bind(this), null, true);
    } else {
      this.OnClosingOrCancelling();
    }
  }
  setGdprConfiguredFlag() {
    this.consentManagementBusiness.GetIsGdprConfiguredFlag().then(res => {
      this.IsGDPREnabled = !!res;
    });
  }
  // showAlertForAppointmentDetail() {
  //   if (this.IsAppointmentScreenDirty()) {
  //     this.userAlert.showPrompt(PromptType.UnsavedChanges, this.close.bind(this), null, true)
  //   } else {
  //     this.OnClosingOrCancelling();
  //   }
  // }

  async close(resultFromUserPrompt: string) {
    if (resultFromUserPrompt.toLowerCase() === ButtonOptions.Yes) {
      this.OnClosingOrCancelling();
    }
  }

  private IsClientScreenDirty(forLinkButton = false): boolean {
    return ((this.appointmentpopservice.personalDetailsFormGroup && this.appointmentpopservice.personalDetailsFormGroup.dirty) ||
      (this.appointmentpopservice.additionalDetailsFormGroup && this.appointmentpopservice.additionalDetailsFormGroup.dirty) ||
      (this.appointmentpopservice.medicalHistoryFormGrp && this.appointmentpopservice.medicalHistoryFormGrp.dirty) ||
      (this.appointmentpopservice.preferenceFormGroup && this.appointmentpopservice.preferenceFormGroup.dirty) ||
      (this.appointmentpopservice.soapNotesFormGrp && this.appointmentpopservice.soapNotesFormGrp.dirty) ||
      (this.appointmentpopservice.clientBlockIformationFormGroup && this.appointmentpopservice.clientBlockIformationFormGroup.dirty) ||
      (this.appointmentpopservice.contactLogsFormGrp && this.appointmentpopservice.contactLogsFormGrp.dirty) ||
      (this.appointmentpopservice.guestContactFormGrp && this.appointmentpopservice.guestContactFormGrp.dirty) || forLinkButton);
  }

  private IsAppointmentScreenDirty(): boolean {
    return ((this.appointmentpopservice.appoinmentDetailFormArray && this.appointmentpopservice.appoinmentDetailFormArray.dirty)
      || (this.appointmentpopservice.clientInformationForm && this.appointmentpopservice.clientInformationForm.dirty)
      || (this.appointmentpopservice.otherDetailsFormGroup && this.appointmentpopservice.otherDetailsFormGroup.dirty)
      || this.appointmentpopservice.isLinkedAppointments || this.appointmentpopservice.addOnsSaveBtnEnable);
  }


  closeDialog(params?) {
    params ? this.dialogRef.close(params) : this.dialogRef.close();
    this.appointmentpopservice.showDetails = false;
  }

  onItineraryEditFlow(e) {
    this.fromItineraryObject = e;
    if (this.data && this.data.fromClient) {
      this.router.navigate(['appointment/newappointment'], {
        state: {
          fromItineraryObject: this.fromItineraryObject,
          // date:this.fromItineraryObject.appointmentDate
        }
      });
      this.closeDialog();
    } else {
      this.appointmentpopservice.add_client = false;
      this.OnClosingOrCancelling();
    }
  }

  async clientprofilelink(e, type) {
    this.canCloseClientPopup = true;
    const showAlertAndWaitForResponse = (message) => {
      return new Promise((resolve) => {
        this.Utilities.showAlert(message, GlobalConst.AlertType.Warning, GlobalConst.ButtonType.AddCancel, (res) => resolve(res))
      });
    };

    if (this.appointmentpopservice?.clientEditData?.clientDetail?.guestId == Guid.EMPTY) {
      let res = await showAlertAndWaitForResponse(this.allCaptions.newProfileAlert);
      if (res == GlobalConst.AlertAction.CONTINUE) {
        let clientInfo = await this.clientService.createClientByGuestId(this.appointmentpopservice.clientEditData, this.appointmentpopservice.clientEditData.clientDetail.guestId);
        this.appointmentpopservice.clientEditData = clientInfo;
        this.appointmentpopservice.guestId = clientInfo?.clientDetail.guestId;
      }
      else {
        this.canCloseClientPopup = false;
        return;
      }
    }
    else if (!this.clientService.isExistingGuestSelectedForLinking && type == SubscriptionType.Subscribe) {
      let clientDetails = this.appointmentpopservice.clientEditData.clientDetail;
      let alertMessage = this.localization.replacePlaceholders(this.allCaptions.linkToExisitngGuestAlert, ['guestName'], [clientDetails.firstName + ' ' + clientDetails.lastName]);
      let res = await showAlertAndWaitForResponse(alertMessage);
      if (res == GlobalConst.AlertAction.CANCEL) {
        this.canCloseClientPopup = false;
        return;
      }
    }

    let bodyObj: SubscribeProfile = {
      guestId: this.appointmentpopservice?.clientEditData?.clientDetail.guestId,
      interfaceGuestId: this.appointmentpopservice?.clientEditData?.clientDetail.clientLinkId,
      interfaceName: "OPERA",//change
      subscriptionType: type == 0 ? SubscriptionType.UnSubscribe : SubscriptionType.Subscribe
    }

    let linkResponse = await this.clientService.clientProfileLink(bodyObj);
    if (linkResponse) {
      if (type == SubscriptionType.Subscribe) {
        this.appointmentpopservice.clientEditData.clientDetail.isExternalGuest = true;
        if (this.appointmentpopservice.clientEditData.clientDetail.id == 0) {
          let clientInfo = await this.clientService.createClientByGuestId(this.appointmentpopservice.clientEditData, this.appointmentpopservice.clientEditData.clientDetail.guestId);
          this.appointmentpopservice.clientEditData = clientInfo;
        }
        this.appointmentpopservice.clientId = this.appointmentpopservice.clientEditData.clientDetail.id;
        this.save();
        this.OnClosingOrCancelling();
      }
      else {
        this.appointmentpopservice.isExternalClientLinked = false;
      }
    }
  }


  OnClosingOrCancelling() {
    this.appointmentservice.hasActiveTempHold = false;
    this.appointmentpopservice.ImgTempHolder = {};
    this.appointmentpopservice.clientAddedFromClientInformation = false;
    this.appointmentpopservice.isMultiPackAppointment = false;
    this.appointmentpopservice.IsClientBlockInfoDirty = false;
    this.appointmentpopservice.isLinkedAppointments = false;
    this.appointmentpopservice.isLinkedAppointmentsLoaded = this.appointmentpopservice.add_client ? this.appointmentpopservice.isLinkedAppointmentsLoaded : false;
    this.appointmentpopservice.linkedAppointments = [];
    this.appointmentpopservice.linkedAppointmentUpdated = [];
    this.appointmentpopservice.linkedAppointmentId = null;
    this.appointmentpopservice.clientBlockIformationFormGroup = null;
    this.appointmentpopservice.clientBlockInfos = [];
    this.appointmentpopservice.isClientBlocked = false;
    this.appointmentpopservice.overrideClientBlock = false;
    this.appointmentpopservice.disableClientBlock = false;
    this.appointmentpopservice.ClientBlockInformation = null;
    this.appointmentpopservice.ExtendedSearchConfiguration = [];
    this.appointmentpopservice.isInternalPMSIntegrated = false;
    this.appointmentpopservice.isExternalClient = false;
    this.appointmentpopservice.isExternalClientLinked = false;
    this.appointmentpopservice.isEditClient = false;

    if (this.appointmentpopservice.IsAddClientFromSearch || this.appointmentservice.isAppointmentFromPackage || this.appointmentpopservice.IsAddClientFromSPA || this.appointmentpopservice.IsOrderSummary || this.appointmentpopservice.editselectedClient || this.appointmentpopservice.copySelectedClient || this.appointmentpopservice.addFromClientModule || this.appointmentpopservice.isLocationBlock || this.appointmentservice.isaddClientFromClientInfo) {
      this.appointmentservice.isaddClientFromClientInfo = false;
      this.closeDialog();
      return;
    }
    if (this.appointmentpopservice.add_client) {
      this.appointmentpopservice.add_client = false;
      this.appointmentpopservice.exsisting_client = false;
      this.appointmentpopservice.popupTitle = this.popupTitle;
      this.appointmentpopservice.saveText = (this.appointmentpopservice.clientId > 0 && !this.appointmentpopservice.checkwhetherfromClient) ? this.allCaptions.common.Update : this.allCaptions.common.SAVE;
    } else {

      if (this.appointmentpopservice.TempHoldArr) {
        let tempHoldIds = this.appointmentpopservice.TempHoldArr.filter(function (tempObj) { return tempObj.appointmentId });

        if (tempHoldIds.length > 0) {
          this.ApptUtilities.deleteTempHold(tempHoldIds, this.successCallback.bind(this), this.errorCallback.bind(this));
        }
        else {
          this.appointmentpopservice.saveText = this.allCaptions.common.Update;
          // this.appointmentpopservice['fromAction']=true;
          this.dialogRef.close(this.appointmentpopservice);
          //this.clearClientSelected();
        }
      }
    }
  }

  validateSave(forLinkButton = false) {
    let isSaveValid = false;
    let externalClientValidation = (this.appointmentpopservice.isEditClient && this.appointmentpopservice.isExternalClient && !forLinkButton && this.appointmentpopservice.guestId == DefaultGUID) ? this.appointmentpopservice.isExternalClientLinked : true;
    if (this.appointmentpopservice.add_client) {
      if (this.appointmentpopservice.ClientSelectedTab == 0) {
        return this.appointmentpopservice.personalDetailsFormGroup && (this.appointmentpopservice.personalDetailsFormGroup['CreateClientValid'] || this.appointmentpopservice.personalDetailsFormGroup.valid) && this.EnableSave && this.IsClientScreenDirty(forLinkButton) && !this.appointmentpopservice?.clientEditData?.clientDetail?.isPurged && externalClientValidation;
      }
      if (this.appointmentpopservice.ClientSelectedTab == 1) {
        return this.appointmentpopservice.additionalDetailsFormGroup && this.appointmentpopservice.additionalDetailsFormGroup['CreateClientValid'] && this.EnableSave && this.IsClientScreenDirty(forLinkButton) && !this.appointmentpopservice?.clientEditData?.clientDetail?.isPurged && externalClientValidation;
      }
      // if all the fields are entered.
      return (this.appointmentpopservice.personalDetailsFormGroup && (this.appointmentpopservice.personalDetailsFormGroup['CreateClientValid'] || this.appointmentpopservice.personalDetailsFormGroup.valid)
        || (this.appointmentpopservice.additionalDetailsFormGroup && this.appointmentpopservice.additionalDetailsFormGroup['CreateClientValid'])) && this.EnableSave && this.IsClientScreenDirty(forLinkButton) && externalClientValidation;
    } else {
      isSaveValid = this.appointmentpopservice.resultNewAppointment.CreateFormValid
        && this.IsAppointmentScreenDirty()
        && this.appointmentpopservice.ClientActivities.length == 0 && this.EnableSave && !this.appointmentpopservice?.clientEditData?.clientDetail?.isPurged;
    }
    if (!isSaveValid && this.appointmentpopservice.resultNewAppointment.CreateFormValid && this.appointmentpopservice.ClientActivities.length == 0) {
      isSaveValid = (this.appointmentpopservice.isGuaranteeMethodValid && this.appointmentpopservice.isGuaranteeMethodDirty);
    }

    return isSaveValid && this.appointmentpopservice.isLinkedAppointmentsLoaded && !this.appointmentpopservice?.clientEditData?.clientDetail?.isPurged && externalClientValidation;
  }

  async addToCart() {
    await this.save(false, false, true);
  }

  saveOnClick() {
    this.saveBtnClicked.next();
  }

  /**
   * retrieve guest deposit details
   * @returns -- guest details
   */
  getGuestDepositDetail() {
    return [
      ...this.appointmentpopservice.multiClientInfo.filter(
        (cl) => (cl.depositAmount ? cl.depositAmount : 0) > 0
      ),
    ];
  }

  async save(durationOverBook?: boolean, overbookOptions?: any, isAddToCart: boolean = false) {
    if (this.appointmentpopservice.clientScreenProperties && this.appointmentpopservice.clientScreenProperties.maxGuestLocation && this.appointmentpopservice.clientScreenProperties.maxGuestLocation > 0 &&
      this.appointmentpopservice.recordsArray.length > this.appointmentpopservice.clientScreenProperties.maxGuestLocation) {
      let message = this.captions.LocationMaxGuestClientConflict.interpolate({ location: this.appointmentpopservice.clientScreenProperties.maxGuestLocation });
      this.Utilities.showAlert(message, GlobalConst.AlertType.Warning, ButtonType.Ok);
      return false;
    }
    let guestDepositDetails = this.getGuestDepositDetail();
    if (guestDepositDetails.length > 0) {
      const existingService = this.appointmentpopservice.resultExistingAppointment?.appointmentDetail ?? { serviceId: 0, price: 0 };

      const tempService = this.appointmentpopservice.resultNewAppointment
        ? {
          serviceId: Number(
            this.appointmentpopservice.resultNewAppointment.service
          ),
          price: this.appointmentpopservice.resultNewAppointment.price,
        }
        : { serviceId: 0, price: 0 };

      if ((Number(tempService.price) !== existingService.price)) {
        let depositDetail = {
          existingServiceAmount: existingService.price,
          tempServiceAmount: tempService.price,
          guestDetails: guestDepositDetails.map(data => {
            return {
              clientId: data.id,
              clientName: this.getClientName(data.id),
              depositAmount: data.depositAmount,
              remainingAmount: (Number(tempService.price) - Number(data.depositAmount))
            }
          })
        };

        this.ApptUtilities.showDepositInfoDialog(
          depositDetail,
          this.ConfirmAppointment.bind(this),
          [{ durationOverBook, overbookOptions, isAddToCart }]
        );
        return false;
      }
    }

    this.ConfirmAppointment(durationOverBook, overbookOptions, isAddToCart);
  }

  /**
   * get client name based on client id
   * @param clientId 
   * @returns -- firstName lastName
   */
  private getClientName(clientId: number) {
    let clientDetail = this.appointmentpopservice.recordsArray.find(
      (cl) => cl.id === clientId
    )?.clientDetail ?? { firstName: "", lastName: "" };
    return clientDetail.firstName?.concat(" " + clientDetail.lastName);
  }

  async validateGenderPreference(extraParams) {
    if (this.isGenderPreferenceOverride && extraParams['durationOverBook']) {
      extraParams['isGenderPreferenceOverride'] = true;
      return false;
    }
    let therapistIds: number[] = [];
    let genderOverrideClientDetails: GenderOverrideMessage[] = [];
    therapistIds = this.appointmentpopservice.appoinmentdetailArray.controls['StaffDropDownFormArr'].value.map(x => Number(x.therapist));
    if (therapistIds.length > 0 && this.appointmentpopservice.recordsArray.length > 0) {
      let therapists = this.appointmentpopservice.therapistArray.filter(x => therapistIds.includes(x.id));
      if (this.appointmentpopservice.recordsArray.length === 1) {

        this.appointmentpopservice.recordsArray.forEach(element => {
          if (element.clientDetail.genderPreference !== "" && element.clientDetail.genderPreference != GlobalConst.Gender.NoPreference && element.clientDetail.genderPreference) {
            therapists.forEach(staff => {

              if (element.clientDetail.genderPreference !== staff.gender) {
                genderOverrideClientDetails.push({ clientName: element.clientDetail.firstName + " " + element.clientDetail.lastName, clientPreferredGender: element.clientDetail.genderPreference, therapistGender: staff.gender, isOverriden: false, appointmentId: 0, serviceId: 0, therapistId: 0, clientId: 0 });
              }

            });

          }

        });

      } else {
        this.appointmentpopservice.recordsArray.forEach(element => {
          const therapistId = this.appointmentpopservice.multiClientInfo.find(x => x.id === element.clientDetail.id).TherapistId;
          const therapistDetail = therapists.find(x => x.id === therapistId);
          if (element.clientDetail.genderPreference && element.clientDetail.genderPreference !== "") {
            if (therapistDetail && element.clientDetail.genderPreference !== therapistDetail.gender) {
              genderOverrideClientDetails.push({ clientName: element.clientDetail.firstName + " " + element.clientDetail.lastName, clientPreferredGender: element.clientDetail.genderPreference, therapistGender: therapistDetail.gender, isOverriden: false, appointmentId: 0, serviceId: 0, therapistId: 0, clientId: 0 });
            }
          }
        });
      }
    }
    if (genderOverrideClientDetails.length > 0) {
      if (this.breakPoint.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.OverrideGenderPreference])) {

        this.showGenderPreferenceOverridePopup(genderOverrideClientDetails, extraParams);

      } else {
        this.appointmentpopservice.selectedIndex = 0;
      }
      return true;

    }
    else {
      return false;
    }

  }

  async showGenderPreferenceOverridePopup(genderOverrideClientDetails: GenderOverrideMessage[], extraParams) {
    if (this.appointmentpopservice.resultNewAppointment.requestStaff == true) {
      this.genderPreferenceOverrideCallback(this, GlobalConst.ButtonOptions.Yes);
    }
    else {
      let customMessageTemplate: string = this.localization.captions.bookAppointment.GenderOverrideMessagecustom + "<br>";
      let customMessage: string = "";
      genderOverrideClientDetails.forEach(x => {
        let clientName: string = x.clientName;
        let clientPreferredGender: string = x.clientPreferredGender;
        let therapistGender: string = x.therapistGender;
        customMessage += customMessageTemplate.interpolate({ clientName, clientPreferredGender, therapistGender });

      });

      let tempGenderOverrideMessage: string = customMessage + this.localization.captions.bookAppointment.GenderOverrideMessagestatic;

      this.Utilities.ShowErrorMessage(this.localization.captions.alertPopup.warning,
        tempGenderOverrideMessage, ButtonType.YesNo, this.genderPreferenceOverrideCallback.bind(this, extraParams));
    }

  }
  async genderPreferenceOverrideCallback(extraParams, result) {
    if (result.toLowerCase() === GlobalConst.ButtonOptions.Yes) {
      extraParams['isGenderPreferenceOverride'] = true;
      this.isGenderPreferenceOverride = true;
      this.FormObjectAndCreateAppointment(extraParams);

    }
    else {
      this.appointmentpopservice.selectedIndex = 0;
    }

  }

  async ConfirmAppointment(durationOverBook?: boolean, overbookOptions?: any, isAddToCart?: boolean) {
    if (this.appointmentpopservice.add_client) {
      //commenting client tab index change not working while creating client
      if (!this.clientService.validateClientSave()) {
        return;
      }

      if ('NEW' == this.appointmentpopservice.clientWidowActionType || this.appointmentpopservice.IsAddClientFromSearch || this.appointmentpopservice.copySelectedClient) {
        this.appointmentpopservice.clientId = 0;
        this.CreateUpdateClient(false);
      }
      else {
        this.CreateUpdateClient(true);
      }
    } else {
      if (!await this.validateAppointment()) {
        return;
      }
      if (!this.IsMultiPackAvailable()) {
        if (this.isUpdate) {
          this.multipackunlinkArray = this.getMultipacksTobeUnlinked();
          this.MultipackUpdate = this.multipackunlinkArray && this.multipackunlinkArray.length > 0;
        }
        await this.CreateNewAppoitment(durationOverBook, overbookOptions, isAddToCart);
      }
      else {
        if (durationOverBook) {
          await this.CreateNewAppoitment(durationOverBook, overbookOptions, isAddToCart)
        }
        else {
          if (!this.isUpdate) {
            this.Utilities.ShowErrorMessage(this.allCaptions.common.Information, this.captions.MultipackAvailablePrompt, ButtonType.YesNo, this.linkCallBack.bind(this), [{ durationOverBook, overbookOptions, isAddToCart }]);
          } else {
            if (this.appointmentpopservice.recordsArray.length === 1) {
              let newClientId = this.appointmentpopservice.recordsArray[0].clientDetail.id;
              const existingApptMultipack = this.appointmentpopservice.existingAppointmentsRef.find(ref => ref.appointmentDetail.clientId === newClientId);
              const existingApptMultipackId = existingApptMultipack ? existingApptMultipack.appointmentDetail.clientMultiPackId : 0;
              if (this.availableMultipacks && this.availableMultipacks.length > 0 && (!existingApptMultipackId || !this.availableMultipacks.find(x => x.id === existingApptMultipackId))) {
                this.MultipackUpdate = true;
                this.multipackunlinkArray = this.getMultipacksTobeUnlinked();
                this.Utilities.ShowErrorMessage(this.allCaptions.common.Information, this.captions.MultipackAvailablePrompt, ButtonType.YesNo, this.linkCallBack.bind(this), [{ durationOverBook, overbookOptions, isAddToCart }]);
              }
              else {
                await this.CreateNewAppoitment(durationOverBook, overbookOptions)
              }
            }
            else {
              if (this.availableMultipacks && this.availableMultipacks.length > 0 && this.checkMultipackForMultiClient()) {
                this.MultipackUpdate = true;
                this.multipackunlinkArray = this.getMultipacksTobeUnlinked();
                this.Utilities.ShowErrorMessage(this.allCaptions.common.Information, this.captions.MultipackAvailablePrompt, ButtonType.YesNo, this.linkCallBack.bind(this), [{ durationOverBook, overbookOptions, isAddToCart }]);
              }
              else {
                await this.CreateNewAppoitment(durationOverBook, overbookOptions)
              }
            }
          }
        }
      }
      this.appointmentpopservice.resultNewAppointment.dateField = this.datePipe.transform(
        this.appointmentpopservice.resultNewAppointment.dateField,
        'yyyy-MM-dd'
      );
    }
  }


  private checkMultipackForMultiClient() {
    let modifiedClients = this.appointmentpopservice.TempHoldArr.map(x => x.clientId);
    let result = modifiedClients.map(client => {
      let oldAppt = this.appointmentpopservice.existingAppointmentsRef.find(x => x.appointmentDetail.clientId === client);
      if (oldAppt) {
        if ((!oldAppt.appointmentDetail.clientMultiPackId && this.availableMultipacks.some(x => x.clientId == client) || (oldAppt.appointmentDetail.clientMultiPackId && !this.availableMultipacks.some(x => x.id == oldAppt.appointmentDetail.clientMultiPackId)))) {
          return true;
        }
        return false;
      }
      else {
        return this.availableMultipacks.some(x => x.clientId == client)
      }
    });
    return result.some(x => x);
  }

  private getMultipacksTobeUnlinked() {
    let selectedService = this.appointmentpopservice.serviceArray.find(x => x.id == this.appointmentpopservice.resultNewAppointment.service);
    return this.appointmentpopservice.existingAppointmentsRef.filter(x => x.appointmentDetail.clientMultiPackId > 0).filter(x => {
      let existingMultipack = this.appointmentpopservice.clientMultipacks.find(multipack => multipack.id == x.appointmentDetail.clientMultiPackId && selectedService.retailItemId == multipack.linkedRetailItemId && !multipack.isReturned && multipack.remaining == 0);
      if (!existingMultipack) {
        return x.appointmentDetail.clientMultiPackId;
      }
    });
  }

  CreateUpdateClient(isClientUpdate: boolean) {
    let isTempClientCreation = !!(this.appointmentpopservice.clientEditData && this.appointmentpopservice.clientEditData.clientDetail.tempId);
    let PersonalDetailsControls: any = this.appointmentpopservice
      .personalDetailsFormGroup.controls;
    let AdditionalDetailsControls: any = this.appointmentpopservice
      .additionalDetailsFormGroup.controls;
    let ClientPreferencesControls: any = this.appointmentpopservice
      .preferenceFormGroup.controls;

    let loyaltyDet: LoyaltyDetail = PersonalDetailsControls.patronid.value ? {
      patronId: PersonalDetailsControls.patronid.value,
      rank: PersonalDetailsControls.rank.value ? PersonalDetailsControls.rank.value : ''
    } : null;
    let loyaltyObj: LoyaltyDetail[] = loyaltyDet ? [loyaltyDet] : [];
    let clientObj: ClientDetail = {
      id: isClientUpdate ? this.appointmentpopservice.clientEditData.clientDetail.id || 0 : 0,
      GuestId: isClientUpdate ? this.appointmentpopservice.clientEditData.clientDetail.guestId : '00000000-0000-0000-0000-000000000000',
      Title: PersonalDetailsControls.title.value,
      FirstName: this.Utilities.capitalizeFirstLetter(PersonalDetailsControls.firstName.value),
      LastName: this.Utilities.capitalizeFirstLetter(PersonalDetailsControls.lastName.value),
      Pronounce: this.Utilities.capitalizeFirstLetter(PersonalDetailsControls.pronounced.value),
      Gender: PersonalDetailsControls.gender.value,
      DateOfBirth:
        (PersonalDetailsControls.dob.value == '' || PersonalDetailsControls.dob.value == null) ? ''
          : this.Utilities.GetFormattedDate(PersonalDetailsControls.dob.value),
      Comments: AdditionalDetailsControls.comments.value,
      BypassClientScheduling: AdditionalDetailsControls.client_scheduling.value,
      CustomField1: AdditionalDetailsControls.customField1 ? AdditionalDetailsControls.customField1.value : 0,
      CustomField2: AdditionalDetailsControls.customField2 ? AdditionalDetailsControls.customField2.value : 0,
      CustomField3: AdditionalDetailsControls.customField3 ? AdditionalDetailsControls.customField3.value : 0,
      CustomField4: AdditionalDetailsControls.customField4 ? AdditionalDetailsControls.customField4.value : '',
      CustomField5: AdditionalDetailsControls.customField5 ? AdditionalDetailsControls.customField5.value : '',
      GenderPreference: ClientPreferencesControls.gender ? ClientPreferencesControls.gender.value : '',
      PMSActivityId: isClientUpdate ? this.appointmentpopservice.clientEditData.clientDetail.pmsActivityId : null,
      LastChangeId: isClientUpdate ? this.appointmentpopservice.clientEditData.clientDetail.lastChangeId : '00000000-0000-0000-0000-000000000000',
      InterfaceGuestId: isClientUpdate ? this.appointmentpopservice.clientEditData.clientDetail.interfaceGuestId : '',
      PriceTypeId: AdditionalDetailsControls.pricetype.value ? AdditionalDetailsControls.pricetype.value : 0,
      loyaltyDetail: loyaltyObj,
      tempId: isClientUpdate ? this.appointmentpopservice.clientEditData.clientDetail.tempId : null,
      clientType: isClientUpdate ? this.appointmentpopservice.clientEditData.clientDetail.clientType : 0,
      clientLinkId: isClientUpdate ? this.appointmentpopservice.clientEditData.clientDetail.clientLinkId : '',
      arAccountNumber: isClientUpdate ? this.appointmentpopservice.clientEditData.clientDetail.arAccountNumber : '',
      isContactRelationUpdateRequired: this.appointmentpopservice.isContactRelationUpdateRequired,
      discountType: isClientUpdate ? this.appointmentpopservice.clientEditData.clientDetail?.discountType : '',
      platformBussinessCardRevUuid: isClientUpdate ? this.appointmentpopservice.clientEditData.clientDetail?.platformBussinessCardRevUuid : '',
      platformBussinessCardUuid: isClientUpdate ? this.appointmentpopservice.clientEditData.clientDetail?.platformBussinessCardUuid : '',
      platformGuestUuid: isClientUpdate ? this.appointmentpopservice.clientEditData.clientDetail?.platformGuestUuid : '',
      platformRevUuid: isClientUpdate ? this.appointmentpopservice.clientEditData.clientDetail?.platformRevUuid : '',
      isExternalGuest: isClientUpdate ? this.appointmentpopservice.clientEditData.clientDetail?.isExternalGuest : false,
      vip: AdditionalDetailsControls.viptype.value ? AdditionalDetailsControls.viptype.value : ''
    };

    let clientInfoObj: ClientInfo = {
      id: clientObj.id,
      clientDetail: clientObj,
      emails: this.formEmailObj(),
      addresses: this.formAddressObject(),
      phoneNumbers: this.formPhoneObject(),
      clientMedicalCondition: this.formMedicalCondition(),
      clientPreferences: this.appointmentpopservice.clientPreferences,
      clientSOAPNotes: this.appointmentpopservice.clientSoapNotes,
      clientCreditCardInfo: this.appointmentpopservice.clientCreditCardInfo,
      clientBlock: this.clientBlockInfo(),
      clientContactLogs: this.ContactLogs(),
      guestContact: this.GuestContacts(),
    }
    let callDesc = isClientUpdate ? 'updateClient' : 'createNewClient'
    let uriParams = isClientUpdate ? { id: clientObj.id } : {};

    if (isClientUpdate && !this.appointmentpopservice.clientId && !isTempClientCreation) {
      return;
    }
    this.EnableSave = false;
    if (this.appointmentpopservice.checkVar == 'orderSummary') {
      this.appointmentpopservice.totalData = clientInfoObj;
      this.appointmentpopservice.sendData();
    }
    this.clientInfo = clientInfoObj;

    if (!isTempClientCreation) {
      this.http.CallApiWithCallback({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: callDesc,
        uriParams: uriParams,
        method: isClientUpdate ? HttpMethod.Put : HttpMethod.Post,
        body: clientInfoObj,
        showError: false,
        extraParams: []
      });
    }
    else {
      this.dialogRef.close(clientInfoObj);
    }
    this.appointmentpopservice.medicalhistoryArray = [];
  }



  clientBlockInfo() {
    if (this.appointmentpopservice.clientBlockIformationFormGroup && this.appointmentpopservice.IsClientBlockInfoDirty) {
      const formData = this.appointmentpopservice.clientBlockIformationFormGroup.value;
      return {
        blockClient: formData.isClientBlocked,
        serviceBlock: formData.clientDetailService && formData.clientDetailService.length > 0 ? formData.clientDetailService.map(x => x.typeId) : null,
        therapistBlock: formData.clientDetailTherapist && formData.clientDetailTherapist.length > 0 ? formData.clientDetailTherapist.map(x => x.typeId) : null,
        clientId: this.appointmentpopservice.clientId,
        comments: formData.comments
      };
    }
    return null;
  }

  ContactLogs(): ContactLog[] {
    if (this.appointmentpopservice.clientEditData) {
      return this.appointmentpopservice.clientEditData?.clientContactLogs;
    } else {
      return this.appointmentpopservice.clientContactLogs;
    }
  }

  GuestContacts(): GuestContact[] {
    if (this.appointmentpopservice.guestContact) {
      return this.appointmentpopservice.guestContact.filter(x => x.contactRelationshipId != -1);
    }
  }

  IsMultiPackAvailable = (): boolean => {
    let appointmentDate: Date = typeof this.appointmentpopservice.resultNewAppointment.dateField == 'string' ? this.Utilities.getDate(this.appointmentpopservice.resultNewAppointment.dateField) : this.appointmentpopservice.resultNewAppointment.dateField;
    let appointmentDatewithoutTime = this.Utilities.GetDateWithoutTime(this.Utilities.getDate(appointmentDate));

    if (this.appointmentpopservice.clientMultipacks && this.appointmentpopservice.serviceArray && this.appointmentpopservice.resultNewAppointment.service) {
      let selectedService = this.appointmentpopservice.serviceArray.find(x => x.id == this.appointmentpopservice.resultNewAppointment.service);
      this.availableMultipacks = this.appointmentpopservice.clientMultipacks.filter(x =>
        !x.isExpired && appointmentDatewithoutTime >= this.Utilities.getDate(x.startDate) && appointmentDatewithoutTime <= this.Utilities.getDate(x.expirationDate) && !x.isReturned && x.remaining != 0 && selectedService && x.linkedRetailItemId == selectedService.retailItemId)
      if (this.availableMultipacks && this.availableMultipacks.length > 0) {
        return true;
      }
    }
    return false;
  }

  linkCallBack = (result: any, extraParams?: any) => {
    this.linkMultiPackOpted = false;
    if (result.toLowerCase() == 'YES'.toLowerCase()) {
      this.linkMultiPackOpted = true;
      this.CreateNewAppoitment(extraParams[0].durationOverBook, extraParams[0].overbookOptions, extraParams[0]?.isAddToCart)
    }
    else {
      this.CreateNewAppoitment(extraParams[0].durationOverBook, extraParams[0].overbookOptions, extraParams[0]?.isAddToCart);
    }

  }

  async validateAppointment(): Promise<boolean> {
    if (!this.appointmentpopservice.resultNewAppointment.CreateFormValid) {
      this.appointmentpopservice.selectedIndex = 0;
      return false;
    }

    if (this.appointmentpopservice.recordsArray.length == 0) {
      this.appointmentpopservice.attachClient = true;
      this.appointmentpopservice.selectedIndex = 1;
      return false;
    }
    else if (!this.appointmentpopservice.validateMultiClient() && this.appointmentpopservice.multiClientInfo.length > 1) {
      this.appointmentpopservice.therapistAssociationError = true;
      this.appointmentpopservice.selectedIndex = 1;
      return false;
    }
    else if (this.appointmentpopservice.multiClientInfo.length > 1 && !this.appointmentpopservice.ValidateAllTherapistsAssociated()) {
      this.appointmentpopservice.selectedIndex = 1;
      return false;
    }
    else if (this.checkForMinGuestNeeded()) {
      this.Utilities.ShowErrorMessage(this.captions.ErrorinCreatingAppointment, this.captions.MinimumGuestRequiredforthisServiceis + ' ' + this.appointmentpopservice.clientScreenProperties.minGuest);
      return false;
    }
    else if (this.checkForMaxGuestExceeded()) {
      this.Utilities.ShowErrorMessage(this.captions.ErrorinCreatingAppointment, this.captions.MaximumGuestRequiredforthisServiceis + ' ' + this.appointmentpopservice.clientScreenProperties.maxGuest);
      return false;
    }
    else if (this.appointmentpopservice.otherDetailsFormGroup.invalid) {
      this.appointmentpopservice.otherDetailsFormGroup.controls['customField1'].markAsTouched();
      this.appointmentpopservice.otherDetailsFormGroup.controls['customField2'].markAsTouched();
      this.appointmentpopservice.otherDetailsFormGroup.controls['customField3'].markAsTouched();
      this.appointmentpopservice.otherDetailsFormGroup.controls['customField4'].markAsTouched();
      this.appointmentpopservice.otherDetailsFormGroup.controls['customField5'].markAsTouched();
      this.appointmentpopservice.selectedIndex = 2;
      return false;
    } else if (this.appointmentpopservice.isGuaranteeMethodRequired && !this.appointmentpopservice.isGuaranteeMethodValid) {
      this.appointmentpopservice.selectedIndex = 5;
      return false;
    }
    else if (this.appointmentpopservice.otherDetailsFormGroup.controls.gender.value == 0 && this.appointmentpopservice.REQUIREGENEDERPREFRENCE) {
      this.appointmentpopservice.selectedIndex = 2;
      return false;
    }
    else if (!await this.checkClientMandatoryFields()) {
      return false;
    }

    return true;
  }

  checkForGuaranteeMethodAndCOF(extraParams) {
    let clientsNotHavingCOF = this.appointmentpopservice.recordsArray.filter(client => client.clientCreditCardInfo?.every(c => !c.isActive));

    if (clientsNotHavingCOF?.length > 0 && !this.checkIfGuarenteeMethodIsAvailable() && !extraParams.durationOverBook) {
      const clientNames = clientsNotHavingCOF
        .map(c => `${c.clientDetail.firstName} ${c.clientDetail.lastName}`)
        .join('<br>');
      this.Utilities.showAlert(this.localization.replacePlaceholders(this.allCaptions.warn_PaymentInFoMissing, ['ClientsName'], [clientNames]), AlertType.Info, ButtonType.YesNo, this.checkForGuaranteeMethodAndCOFCallBack.bind(this), extraParams);
    }
    else
      this.ShowEmailSMSPopup(extraParams);
  }

  IsMultiClient: boolean;
  async CreateNewAppoitment(_durationOverBook?: boolean, _overbookOptions?: any, isAddToCart?: boolean) {
    const extraParams: any = { durationOverBook: _durationOverBook, overbookOptions: _overbookOptions, isAddToCart: isAddToCart };

    if (this.isCancellationNoShowFeeEnabled)
      this.checkForGuaranteeMethodAndCOF(extraParams);
    else
      this.ShowEmailSMSPopup(extraParams);
  }

  async CheckEmailSMSConfiguredAndTriggerPopup(extraParams) {
    const EmailSMSTriggerPopupMessage: string = await this.appointmentpopservice.CheckEmailSMSConfigured(this.isUpdate);
    if (EmailSMSTriggerPopupMessage && !extraParams.durationOverBook) {
      this.Utilities.showAlert(EmailSMSTriggerPopupMessage, AlertType.Info, ButtonType.YesNo, this.FormObjectAndCreateAppointmentCallBack.bind(this), extraParams);
    } else {
      this.checkQuickIdLogin(extraParams);
    }
  }

  async ShowEmailSMSPopup(extraParams) {
    let clientRecords = this.appointmentpopservice.multiClientInfo;
    this.IsMultiClient = (clientRecords.length > 1) ? true : false;
    await this.CheckEmailSMSConfiguredAndTriggerPopup(extraParams);
  }

  checkForGuaranteeMethodAndCOFCallBack(result, extraParams: any) {
    if (result.toLowerCase() == ButtonOptions.Yes) {
      this.ShowEmailSMSPopup(extraParams);
    }
  }

  FormObjectAndCreateAppointmentCallBack(result, extraParams) {
    if (result.toLowerCase() === ButtonOptions.Yes) {
      this.checkQuickIdLogin(extraParams);
    }
  }

  async checkQuickIdLogin(extraParams) {
    extraParams['isGenderPreferenceOverride'] = false;
    const quickLogin = !this.isUpdate ? this._retailPropertyInfoService.getQuickIdConfig && this._retailPropertyInfoService.getQuickIdConfig.QUICKID_APPOINTMENTBOOK :
      this._retailPropertyInfoService.getQuickIdConfig && this._retailPropertyInfoService.getQuickIdConfig.QUICKID_APPOINTMENTEDIT;
    if (quickLogin) {
      const quickLoginDialogRef = this._quickLoginUtil.QuickLogin();
      quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
        if (quickLoginDialogResult.isLoggedIn) {
          let result: boolean = await this.validateGenderPreference(extraParams);
          if (!result) {
            this.FormObjectAndCreateAppointment(extraParams);
          }
        }
      });
    }
    else {
      let result: boolean = await this.validateGenderPreference(extraParams);
      if (!result) {
        this.FormObjectAndCreateAppointment(extraParams);
      }
    }
  }

  async FormObjectAndCreateAppointment(extraParams) {
    if (this.SEND_AUTOMATIC_APPOINTMENT_NOTIFICATION && ((!this.appointmentpopservice.isEditAppointment && this.notificationSetup.find(x => x.eventId == EventNotification.AppointmentBooking && (x.sendSMS == true || x.sendMail == true)) && this.isEmailSendRequired) ||
      (this.appointmentpopservice.isEditAppointment && this.notificationSetup.find(x => x.eventId == EventNotification.AppointmentUpdate && (x.sendSMS == true || x.sendMail == true)) && this.isEmailSendRequired))) {
      this.Utilities.showAlert(this.allCaptions.common.AutomaticEmailSend, AlertType.Warning, ButtonType.YesNo, this.FormObjectAndCreateAppointmentwithAutomaticNotificationCallBack.bind(this), extraParams)
      this.isEmailSendRequired = false;
    }
    else {
      this.FormObjectAndCreateAppointmentwithAutomaticNotification(extraParams);
    }
  }

  FormObjectAndCreateAppointmentwithAutomaticNotificationCallBack(result, extraParams) {

    if (result.toLowerCase() === ButtonOptions.Yes) {
      this.sendAutomaticNotification = true;
      this.FormObjectAndCreateAppointmentwithAutomaticNotification(extraParams);
    }
    else {
      this.sendAutomaticNotification = false;
      this.FormObjectAndCreateAppointmentwithAutomaticNotification(extraParams);
    }
  }

  async FormObjectAndCreateAppointmentwithAutomaticNotification(extraParams) {
    const durationOverBook: boolean = extraParams['durationOverBook'];
    const overbookOptions: any = extraParams['overbookOptions'];
    const isGenderPreferenceOverride: boolean = extraParams['isGenderPreferenceOverride'];
    const isAddToCart: boolean = extraParams['isAddToCart'];
    let dt: Date =
      typeof this.appointmentpopservice.resultNewAppointment.dateField == "string"
        ? this.Utilities.getDate(this.appointmentpopservice.resultNewAppointment.dateField)
        : this.appointmentpopservice.resultNewAppointment.dateField;
    let startTime = this.Utilities.convertDateFormat(this.Utilities.getDate(
      dt.getFullYear() +
      "-" +
      this.Utilities.getFullMonth(dt) +
      "-" +
      this.Utilities.getFullDate(dt) +
      "T" +
      this.localization.DeLocalizeTime(this.appointmentpopservice.resultNewAppointment.time)
    )
    );

    let appBodyObj: appointment[];
    // let isLocationView: boolean = false;
    // let isTherapistView: boolean = false;
    /*
  * While saving appointment , need to get reset the PriceTypeID, if priceType is not applicable
  */
    this.appointmentpopservice.updatePriceTypeIDForAppointment();
    if (this.IsMultiClient) {
      appBodyObj = await this.formMultiClientAssociation(startTime);
    } else {
      appBodyObj = await this.formSingleClientObj(startTime);
    }



    if (appBodyObj[0].appointmentTherapists.find(a => a.therapistId == this.appointmentservice.unAssignedTherapistId)) {
      if (!this.breakPoint.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.BookUnassignedTherapist])) {
        return;
      }
    }
    appBodyObj[0].SendEmailNotification = this.sendAutomaticNotification;
    let baseAppointmentId: number = 0;
    if (this.isUpdate) {
      baseAppointmentId = this.appointmentpopservice.resultExistingAppointment.appointmentDetail.id;
    }
    else if (this.appointmentpopservice.TempHoldArr && this.appointmentpopservice.TempHoldArr.length > 0) {
      baseAppointmentId = this.appointmentpopservice.TempHoldArr[0].appointmentId;
    }

    if (!this.appointmentpopservice.isOverbook) {
      let isCreate: boolean = this.isUpdate ? false : true
      if (durationOverBook) {
        // Set Over book options
        for (const element of appBodyObj) {
          element.appointmentDetail.isLocationOverbook = element.appointmentDetail.isLocationOverbook ? element.appointmentDetail.isLocationOverbook : overbookOptions.location;
          element.appointmentDetail.isTherapistOverbook = element.appointmentDetail.isTherapistOverbook ? element.appointmentDetail.isTherapistOverbook : overbookOptions.therapist;
          element.appointmentDetail.isClientOverbook = element.appointmentDetail.isClientOverbook ? element.appointmentDetail.isClientOverbook : overbookOptions.client;

        }
      }
      this.UpdateAppointmentServiceCall(baseAppointmentId, isCreate, appBodyObj, isGenderPreferenceOverride, isAddToCart);
    }
    else {
      let tempextraParams: any = { tempId: baseAppointmentId, body: appBodyObj };
      this.Utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.getError(500), ButtonType.YesNo, this.CreateApp.bind(this), tempextraParams);
    }
  }


  async getGuestInfos(body) {
    let guestIfos = [];
    let clientInfo: BaseResponse<any>;
    // appointment.appointmentDetail.guestId
    let guestIds = body.map(x => x.appointmentDetail.guestId);
    if (body && body.length > 0) {
      if (guestIds && guestIds.length > 0) {
        const response = await this.http.CallApiAsync<ClientInfo[]>(
          {
            callDesc: 'GetSpaClientsByGuid',
            method: HttpMethod.Put,
            host: GlobalConst.Host.spaManagement,
            body: guestIds
          }
        )
        console.log("GetSpaClientsByGuid => ", response?.result);
        response?.result.forEach(element => { guestIfos.push(this.mapGuestDetailForVCart(element)) });
      }
      if (guestIfos && guestIfos.length > 0)
        return guestIfos;
    }
    return [];
  }
  mapGuestDetailForVCart(clientData) {
    return {
      firstName: clientData.clientDetail.firstName ?? '',
      lastName: clientData.clientDetail.lastName ?? '',
      guestId: clientData.clientDetail.guestId ?? '',
      platformGuestUuid: '00000000-0000-0000-0000-000000000000',
      email: clientData.emails?.map(email => {
        if (email.contactTypeId == MailTypes.office || email.contactTypeId == MailTypes.personal) {
          return {
            isPrivate: email.isPrivate,
            isPrimary: email.isPrimary,
            value: email.emailId,
            type: email.contactTypeId
          }
        }
      }),
      phone: clientData.phoneNumbers?.map(phoneNumber => {
        if (phoneNumber.contactTypeId == PhoneTypes.office || phoneNumber.contactTypeId == PhoneTypes.home || phoneNumber.contactTypeId == PhoneTypes.home) {
          return {
            isPrivate: phoneNumber.isPrivate,
            isPrimary: phoneNumber.isPrimary,
            value: phoneNumber.number,
            type: phoneNumber.contactTypeId
          }
        }
      })
    };
  }

  async UpdateAppointmentServiceCall(appId, isCreateApp, body: appointment[], isGenderPreferenceOverride: boolean = false, isAddtoCart?: boolean) {
    let currentTempHoldIds: any = [];
    let tempHoldIdsForAppointment: any = [];
    for (let tempItem of this.appointmentpopservice.TempHoldArr) {
      tempHoldIdsForAppointment.push(tempItem.appointmentId);
      if (tempItem.clientId != null && tempItem.clientId != 0) {
        currentTempHoldIds.push(tempItem.appointmentId);
      }
    }
    let updateApptObj: any = {
      tempHoldId: tempHoldIdsForAppointment,
      AppointmentInfo: body,
      LinkedAppointments: this.appointmentpopservice.linkedAppointmentUpdated.map(x => x.appointmentId)
    }
    let updateApptObjForRecap: any = {
      tempHoldId: currentTempHoldIds,
      AppointmentInfo: body
    }
    this.EnableSave = false;

    const tempHold: TempHold =
    {
      appointmentId: body[0].appointmentDetail.id,
      holdMinutes: 0
    }
    let guestInfos = [];

    if (isAddtoCart) {
      if (this.cartUtils.isEmbed() && !this.data?.isEditVcart) {
        // V-cart check and alert if the guest has any other activities in the Cart for the same time
        let guestIdsToValidate: string[] = body.map(x => x.appointmentDetail.guestId);
        if (guestIdsToValidate && guestIdsToValidate.length) {
          if (await this.cartUtils.checkIfAnyActivityPresentInCart(this.appointmentpopservice.tempHoldValues.startTime, this.appointmentpopservice.tempHoldValues.endTime, guestIdsToValidate)) {
            return;
          }
        }
      }

      var appointmentInfo = _.cloneDeep(updateApptObj);
      appointmentInfo.AppointmentInfo.forEach(x => x.appointmentDetail.status = "TEMP");

      await this.http.CallApiWithCallback({
        host: Host.schedule,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: 'CreateBookTempAppointment',
        uriParams: { id: appId, isCreateAppointment: isCreateApp },
        method: HttpMethod.Put,
        body: appointmentInfo,
        showError: false,
        extraParams: [updateApptObjForRecap, appId, isGenderPreferenceOverride, isCreateApp]
      });
    } else {
      this.Utilities.ToggleLoader(true);
      this.http.CallApiWithCallback({
        host: Host.schedule,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: 'UpdateAppointment',
        uriParams: { id: appId, isCreateAppointment: isCreateApp, isFromVcart: false },
        method: HttpMethod.Put,
        body: updateApptObj,
        showError: false,
        extraParams: [updateApptObjForRecap, appId, isGenderPreferenceOverride]
      });
    }
  }

  private getHeaders(): HeaderValue[] {
    let headers: HeaderValue[] = [];
    let token = null;
    let userSessionId = null;
    if (sessionStorage.getItem("quickIdJwt")) {
      token = sessionStorage.getItem("quickIdJwt");
      userSessionId = sessionStorage.getItem("quickIdUserSession");
    }
    else {
      token = sessionStorage.getItem("_jwt");
      userSessionId = sessionStorage.getItem("userSession");
    }
    headers.push({ key: 'Accept-Language', value: navigator.language });
    headers.push({ key: 'Content-Type', value: 'application/json' });
    headers.push({ key: 'Authorization', value: token ? 'Bearer ' + token : "" });
    headers.push({ key: 'SessionId', value: userSessionId ? userSessionId : "" });
    return headers;
  }
  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  private getApiUrl(callDesc: string, uriParams?: any, host?: Host): string {
    let url: string = this.getRouteUrl("host." + host);
    if (uriParams != undefined && uriParams != null && typeof uriParams == "object") {
      let uriParam = this.getRouteUrl(callDesc);
      let keys = Object.keys(uriParams);
      for (let i = 0; i < keys.length; i++) {
        const escapedKey = this.escapeRegExp(keys[i]);
        var regEx = new RegExp("{" + escapedKey + "}", "ig");
        uriParam = uriParam.replace(regEx, uriParams[keys[i]]);
      }
      url += uriParam;
    }
    else {
      let buildParams = (uriParams == undefined || uriParams == null || uriParams == "") ? "" : "/" + uriParams
      url += this.getRouteUrl(callDesc) + buildParams;
    }
    let spaGatewayURL = sessionStorage.getItem("spaConfigsgatewayURL");
    console.log("spaGatewayURL", spaGatewayURL ?? '');
    if (!(url.indexOf("http://") > -1 || url.indexOf("https://") > -1)) {
      //url = "window.location.origin" + url;
      url = sessionStorage.getItem('appurl') + url;
    }
    return url;
  }

  /*
   * @param callDesc - Call Description
   * Returns the Route URL
   */
  private getRouteUrl(callDesc: string): string {
    return this.routeConfig.getUrl(callDesc);
  }
  generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  CreateApp(result, extraParams) {
    if (result.toLowerCase() == 'NO'.toLowerCase()) {
      this.OnClosingOrCancelling();
      this.appointmentpopservice.resultNewAppointment = {};
      this.appointmentpopservice.SelectedClientId = null;
      this.appointmentpopservice.showDetails = false;
      this.appointmentpopservice.labelRecords = [];
      this.appointmentpopservice.clientsearchArray = [];
      this.appointmentpopservice.recordsArray = [];
      this.appointmentpopservice.overrideClientBlock = false;
      this.appointmentpopservice.clientScreenProperties.maxGuestLocation = 0;
    }
    else {
      this.UpdateAppointmentServiceCall(extraParams['tempId'], this.isUpdate ? false : true, extraParams['body'], extraParams['isGenderPreferenceOverride']);
    }
  }


  async formSingleClientObj(startTime): Promise<appointment[]> {
    this.newAppointmentDate = startTime;
    let appObjArr: appointment[] = [];
    let appObj: appointment;
    let appDetail: appointmentDetail;
    let appTherap: appointmentTherapist[] = [];

    let istherapistoverbook: boolean = false;
    let islocationoverbook: boolean = false;
    let isClientOverbook = false;
    let multipack: any;

    if (this.appointmentpopservice.isOverbook) {
      if ((typeof (this.appointmentpopservice.isTherapistView) != 'undefined')) {
        istherapistoverbook = this.appointmentpopservice.isTherapistView;
        islocationoverbook = !this.appointmentpopservice.isTherapistView;
      }
      if (this.appointmentpopservice.isFromAppointmentSearch) {
        istherapistoverbook = islocationoverbook = true;
      }
    }
    else if (this.appointmentpopservice.resultExistingAppointment) {
      islocationoverbook = this.appointmentpopservice.resultExistingAppointment.appointmentDetail.isLocationOverbook;
      istherapistoverbook = this.appointmentpopservice.resultExistingAppointment.appointmentDetail.isTherapistOverbook;
      isClientOverbook = this.appointmentpopservice.resultExistingAppointment.appointmentDetail.isClientOverbook;
    }
    let Clientinfo = this.appointmentpopservice.multiClientInfo.find(mc => mc.id == this.appointmentpopservice.recordsArray[0].clientDetail.id);
    let endTime = this.localization.ConvertDateToISODateTime(this.localization.AddMinutes(new Date(startTime), this.appointmentpopservice.resultNewAppointment.duration))
    appDetail = {
      startTime: startTime,
      endTime: endTime,
      originalStartTime: startTime,
      originalEndTime: startTime,
      serviceGroupId: this.appointmentpopservice.resultNewAppointment.ServiceGroupId,
      serviceId: this.appointmentpopservice.resultNewAppointment.service,
      locationId: this.appointmentpopservice.resultNewAppointment.location || 0,
      clientId: this.appointmentpopservice.recordsArray[0].clientDetail.id,
      comments: this.appointmentpopservice.resultNewAppointment.comments ? this.appointmentpopservice.resultNewAppointment.comments : '',
      status: this.isUpdate && this.appointmentpopservice.resultExistingAppointment.appointmentDetail.status != 'NOSHOW' ? this.appointmentpopservice.resultExistingAppointment.appointmentDetail.status : 'RESV',
      cancelId: '',
      duration: this.appointmentpopservice.resultNewAppointment.duration && !isNaN(this.appointmentpopservice.resultNewAppointment.duration) ? this.appointmentpopservice.resultNewAppointment.duration : 0,
      setUpTime: this.appointmentpopservice.resultNewAppointment.setupTime && !isNaN(this.appointmentpopservice.resultNewAppointment.setupTime) ? this.appointmentpopservice.resultNewAppointment.setupTime : 0,
      breakDownTime: this.appointmentpopservice.resultNewAppointment.breakDownTime && !isNaN(this.appointmentpopservice.resultNewAppointment.breakDownTime) ? this.appointmentpopservice.resultNewAppointment.breakDownTime : 0,
      price: Clientinfo.price,
      doNotMove: this.appointmentpopservice.resultNewAppointment.doNotMove ? this.appointmentpopservice.resultNewAppointment.doNotMove : false,
      requestStaff: this.appointmentpopservice.resultNewAppointment.requestStaff ? this.appointmentpopservice.resultNewAppointment.requestStaff : false,
      isBookingLocked: this.appointmentpopservice.resultNewAppointment.isBookingLocked,
      isVip: this.appointmentpopservice.otherdetails.vip,
      guestTypeId: this.appointmentpopservice.otherdetails.guestType,
      genderPreference: this.appointmentpopservice.otherdetails.gender,
      checkOutComments: this.appointmentpopservice.otherdetails.checkout,
      noShowComments: this.appointmentpopservice.otherdetails.noShowComments,
      noShow: false,
      packageId: 0,
      appointmentType: '',
      cancelReason: 0,
      cancelComments: '',
      cancelFee: 0,
      customField1: this.appointmentpopservice.otherdetails.customField1 ? this.appointmentpopservice.otherdetails.customField1 : null,
      customField2: this.appointmentpopservice.otherdetails.customField2 ? this.appointmentpopservice.otherdetails.customField2 : null,
      customField3: this.appointmentpopservice.otherdetails.customField3 ? this.appointmentpopservice.otherdetails.customField3 : null,
      customField4: this.appointmentpopservice.otherdetails.customField4 ? this.appointmentpopservice.otherdetails.customField4 : '',
      customField5: this.appointmentpopservice.otherdetails.customField5 ? this.appointmentpopservice.otherdetails.customField5 : '',
      tempHoldId: '',
      tempHoldLinkId: 0,
      isTherapistOverbook: istherapistoverbook,
      isLocationOverbook: islocationoverbook,
      linkCodeId: this.appointmentpopservice.otherdetails.linkcode,
      guestId: this.appointmentpopservice.clientGuestMap.filter(x => x.ClientId == this.appointmentpopservice.recordsArray[0].clientDetail.id).map(y => y.GuestId)[0],
      clientComments: this.appointmentpopservice.multiClientInfo[0].comments,
      ServiceCharge: 0,
      Gratuity: 0,
      Tax: 0,
      TotalAmount: Clientinfo.price,
      priceTypeId: Clientinfo.priceTypeId,
      discountType: Clientinfo.discountType ? Clientinfo.discountType : "",
      serviceChargePercent: 0,
      gratuityPercent: 0,
      isClientOverbook: isClientOverbook,
      yieldPrice: this.appointmentpopservice.resultNewAppointment.price,
      intakeForm: this.appointmentpopservice.otherdetails.intakeForm
    }
    if (!this.isUpdate && this.appointmentpopservice.TempHoldArr && this.appointmentpopservice.TempHoldArr.length > 0) {
      appDetail.id = this.appointmentpopservice.TempHoldArr[0].appointmentId;
    }

    if (this.availableMultipacks && this.availableMultipacks.length > 0) {
      multipack = this.availableMultipacks.find(pack => pack.clientId == this.appointmentpopservice.recordsArray[0].clientDetail.id && this.Utilities.getDate(this.appointmentpopservice.resultNewAppointment.dateField) <= this.Utilities.getDate(pack.expirationDate));
    }

    await this.UpdateAppointmentPriceDetail(appDetail, multipack);
    this.updateClientMultipackID(appDetail, multipack, this.appointmentpopservice.recordsArray[0].clientDetail);

    appTherap = this.formTherapistObjForSingleClientAppointment();

    let tempApptId = this.appointmentpopservice.TempHoldArr && this.appointmentpopservice.TempHoldArr.length > 0 ? this.appointmentpopservice.TempHoldArr[0].appointmentId : 0

    let pmsActivityId = this.appointmentpopservice.recordsArray[0].clientDetail.pmsActivityId ? this.appointmentpopservice.recordsArray[0].clientDetail.pmsActivityId : this.appointmentpopservice.clientEditData?.clientDetail?.pmsActivityId;


    let externalpmsconfno = this.appointmentpopservice.recordsArray[0].clientDetail.isExternalGuest ? this.appointmentpopservice.recordsArray[0].clientDetail.confirmationNumber ? this.appointmentpopservice.recordsArray[0].clientDetail.confirmationNumber : this.appointmentpopservice.clientEditData?.clientDetail?.confirmationNumber : null;
    if (!externalpmsconfno) {
      externalpmsconfno = this.appointmentpopservice.externalPMSConfNo;
    }
    let addons = this.appointmentpopservice.recordsArray.find(x => x.clientDetail.id == appDetail.clientId)?.clientDetail.selectedAddOns;

    if (this.appointmentpopservice.isEditAppointment && addons == undefined) {
      this.mapExistingAddons(appDetail.clientId);
      addons = this.existingAddons;
    }

    appObj = {
      appointmentDetail: appDetail,
      appointmentTherapists: appTherap,
      appointmentAddOn: addons || [],
      pMSActivityId: pmsActivityId,
      externalPMSConfNo: externalpmsconfno
    }

    if (this.appointmentpopservice.guaranteeMethodData && this.appointmentpopservice.guaranteeMethodData.guaranteePaymentMethod
      && this.appointmentpopservice.guaranteeMethodData.guaranteePaymentMethod.GuaranteeMethod) {
      appObj.appointmentBillingDetail = {
        id: this.appointmentpopservice.guaranteeMethodData.id,
        appointmentId: tempApptId,
        billingDetail: this._guaranteeBusiness.GetAPIStringJSON(this.appointmentpopservice.guaranteeMethodData)
      };
    }

    appObjArr.push(appObj);
    return appObjArr;
  }



  async formMultiClientAssociation(startTime): Promise<appointment[]> {
    let appObjArr: appointment[] = [];
    let appObj: appointment;
    let appDetail: appointmentDetail;
    let appTherap: appointmentTherapist[] = [];
    let clientTherapistObj: multiClientInfo[] = this.appointmentpopservice.multiClientInfo;

    let istherapistoverbook: boolean = false;
    let islocationoverbook: boolean = false;
    let isClientOverbook = false;
    let multipack: any = null;

    if (this.appointmentpopservice.isOverbook) {
      if ((typeof (this.appointmentpopservice.isTherapistView) != 'undefined')) {
        istherapistoverbook = this.appointmentpopservice.isTherapistView;
        islocationoverbook = !this.appointmentpopservice.isTherapistView;
      }

      if (this.appointmentpopservice.isFromAppointmentSearch) {
        istherapistoverbook = islocationoverbook = true;
      }
    }
    else if (this.appointmentpopservice.resultExistingAppointment) {
      islocationoverbook = this.appointmentpopservice.resultExistingAppointment.appointmentDetail.isLocationOverbook;
      istherapistoverbook = this.appointmentpopservice.resultExistingAppointment.appointmentDetail.isTherapistOverbook;
      isClientOverbook = this.appointmentpopservice.resultExistingAppointment.appointmentDetail.isClientOverbook;
    }
    let endTime = this.localization.ConvertDateToISODateTime(this.localization.AddMinutes(new Date(startTime), this.appointmentpopservice.resultNewAppointment.duration));
    for (const client of clientTherapistObj) {
      let Clientinfo = this.appointmentpopservice.multiClientInfo.find(mc => mc.id == client.id);
      appDetail = {
        startTime: startTime,
        endTime: endTime,
        originalStartTime: startTime,
        originalEndTime: startTime,
        serviceGroupId: this.appointmentpopservice.resultNewAppointment.ServiceGroupId,
        serviceId: this.appointmentpopservice.resultNewAppointment.service,
        locationId: this.appointmentpopservice.resultNewAppointment.location || 0,
        clientId: client.id,
        comments: this.appointmentpopservice.resultNewAppointment.comments ? this.appointmentpopservice.resultNewAppointment.comments : '',
        status: this.isUpdate && this.appointmentpopservice.resultExistingAppointment.appointmentDetail.status != 'NOSHOW' ? this.appointmentpopservice.resultExistingAppointment.appointmentDetail.status : 'RESV',
        cancelId: '',
        duration: this.appointmentpopservice.resultNewAppointment.duration && !isNaN(this.appointmentpopservice.resultNewAppointment.duration) ? this.appointmentpopservice.resultNewAppointment.duration : 0,
        setUpTime: this.appointmentpopservice.resultNewAppointment.setupTime && !isNaN(this.appointmentpopservice.resultNewAppointment.setupTime) ? this.appointmentpopservice.resultNewAppointment.setupTime : 0,
        breakDownTime: this.appointmentpopservice.resultNewAppointment.breakDownTime && !isNaN(this.appointmentpopservice.resultNewAppointment.breakDownTime) ? this.appointmentpopservice.resultNewAppointment.breakDownTime : 0,
        price: Clientinfo.price,
        doNotMove: this.appointmentpopservice.resultNewAppointment.doNotMove ? this.appointmentpopservice.resultNewAppointment.doNotMove : false,
        requestStaff: this.appointmentpopservice.resultNewAppointment.requestStaff ? this.appointmentpopservice.resultNewAppointment.requestStaff : false,
        isBookingLocked: this.appointmentpopservice.resultNewAppointment.isBookingLocked,
        guestTypeId: this.appointmentpopservice.otherdetails.guestType,
        genderPreference: this.appointmentpopservice.otherdetails.gender,
        checkOutComments: this.appointmentpopservice.otherdetails.checkout,
        noShowComments: this.appointmentpopservice.otherdetails.noShowComments,
        noShow: false,
        packageId: 0,
        appointmentType: '',
        cancelReason: 0,
        isVip: this.appointmentpopservice.otherdetails.vip,
        cancelFee: 0,
        cancelComments: '',
        customField1: this.appointmentpopservice.otherdetails.customField1 ? this.appointmentpopservice.otherdetails.customField1 : null,
        customField2: this.appointmentpopservice.otherdetails.customField2 ? this.appointmentpopservice.otherdetails.customField2 : null,
        customField3: this.appointmentpopservice.otherdetails.customField3 ? this.appointmentpopservice.otherdetails.customField3 : null,
        customField4: this.appointmentpopservice.otherdetails.customField4 ? this.appointmentpopservice.otherdetails.customField4 : '',
        customField5: this.appointmentpopservice.otherdetails.customField5 ? this.appointmentpopservice.otherdetails.customField5 : '',
        tempHoldId: '',
        tempHoldLinkId: 0,
        isTherapistOverbook: istherapistoverbook,
        isLocationOverbook: islocationoverbook,
        linkCodeId: this.appointmentpopservice.otherdetails.linkcode,
        guestId: this.appointmentpopservice.clientGuestMap.filter(x => x.ClientId == client.id).map(y => y.GuestId)[0],
        clientComments: Clientinfo.comments,
        ServiceCharge: 0,
        Gratuity: 0,
        Tax: 0,
        TotalAmount: Clientinfo.price,
        priceTypeId: Clientinfo.priceTypeId,
        discountType: Clientinfo.discountType ? Clientinfo.discountType : "",
        serviceChargePercent: 0,
        gratuityPercent: 0,
        isClientOverbook: isClientOverbook,
        yieldPrice: this.appointmentpopservice.resultNewAppointment.price,
        intakeForm: this.appointmentpopservice.otherdetails.intakeForm
      }

      if (this.availableMultipacks && this.availableMultipacks.length > 0) {
        multipack = this.availableMultipacks.find(pack => pack.clientId == client.id && this.Utilities.getDate(this.appointmentpopservice.resultNewAppointment.dateField) <= this.Utilities.getDate(pack.expirationDate));
      }
      await this.UpdateAppointmentPriceDetail(appDetail, multipack);
      this.updateClientMultipackID(appDetail, multipack, this.appointmentpopservice.recordsArray[0].clientDetail);
      let tempApptId: number = 0;

      let tempApptObj: any = this.appointmentpopservice.TempHoldArr.findIndex(function (tempobj) {
        return tempobj.clientId == client.id;
      });
      if (tempApptObj > -1) {
        tempApptId = this.appointmentpopservice.TempHoldArr[tempApptObj].appointmentId;
      }

      appDetail.id = tempApptId;

      if (this.IsMultiClient) {
        appTherap = [{
          therapistId: client.TherapistId,
          appointmentId: tempApptId,
          servicePoints: this.appointmentpopservice.resultNewAppointment.servicePoints
        }]
      } else {
        appTherap = this.formTherapistObjForSingleClientAppointment();
      }
      let pmsactivityId = this.appointmentpopservice.recordsArray.filter(x => x.clientDetail.id == client.id).map(x => x.clientDetail.pmsActivityId)[0];
      let addons = this.appointmentpopservice.recordsArray.find(x => x.clientDetail.id == appDetail.clientId)?.clientDetail.selectedAddOns;

      if (this.appointmentpopservice.isEditAppointment && addons == undefined) {
        this.mapExistingAddons(appDetail.clientId);
        addons = this.existingAddons;
      }

      appObj = {
        appointmentDetail: appDetail,
        appointmentTherapists: appTherap,
        appointmentAddOn: addons || [],
        pMSActivityId: pmsactivityId
      }

      if (this.appointmentpopservice.guaranteeMethodData && this.appointmentpopservice.guaranteeMethodData.guaranteePaymentMethod
        && this.appointmentpopservice.guaranteeMethodData.guaranteePaymentMethod.GuaranteeMethod) {
        appObj.appointmentBillingDetail = {
          id: this.appointmentpopservice.guaranteeMethodData.id,
          appointmentId: tempApptId,
          billingDetail: this._guaranteeBusiness.GetAPIStringJSON(this.appointmentpopservice.guaranteeMethodData)
        };
      }

      appObjArr.push(appObj);
    }
    return appObjArr;
  }

  private mapExistingAddons(clientId) {
    this.existingAddons = this.appointmentpopservice.existingAppointmentsRef.find(x => x.appointmentDetail.clientId == clientId)?.appointmentAddOn;
    this.existingAddons?.forEach(a => a.id = 0);
  }

  private async UpdateAppointmentPriceDetail(appDetail: appointmentDetail, multipack: any) {
    if (!this.isUpdate || this.MultipackUpdate) {
      if (this.linkMultiPackOpted && multipack) {
        appDetail.clientMultiPackId = multipack.id;
      }
    }
    else {
      let clientMultiPackItem = this.appointmentpopservice.existingAppointmentsRef.find(x => x.appointmentDetail.clientId == appDetail.clientId)
      appDetail.clientMultiPackId = clientMultiPackItem ? clientMultiPackItem.appointmentDetail.clientMultiPackId : 0;
    }

    var service = this.appointmentpopservice.aptPriceTypeGlobalService.find(x => { return x.id == this.appointmentpopservice.resultNewAppointment.service });
    let priceModel = await this.appointmentpopservice.getPriceForServiceChargeAndGratuity(appDetail.price, service);

    appDetail.serviceChargePercent = this.appointmentpopservice.GetServiceGratuityPercent(this.isUpdate, this.appointmentpopservice.resultNewAppointment.service,
      GlobalConst.SERVICECHARGE, this.appointmentpopservice.aptPriceTypeGlobalService);
    appDetail.gratuityPercent = this.appointmentpopservice.GetServiceGratuityPercent(this.isUpdate, this.appointmentpopservice.resultNewAppointment.service,
      GlobalConst.GRATUITY, this.appointmentpopservice.aptPriceTypeGlobalService);

    appDetail.ServiceCharge = this.appointmentpopservice.serviceChargecalc(this.isUpdate, this.appointmentpopservice.resultExistingAppointment ? this.appointmentpopservice.resultExistingAppointment.appointmentDetail.serviceId : 0,
      service, priceModel.priceForServiceCharge,
      this.appointmentpopservice.aptPriceTypeGlobalService,
      appDetail.serviceChargePercent);

    appDetail.Gratuity = this.appointmentpopservice.GratuityCalculation(this.isUpdate, this.appointmentpopservice.resultExistingAppointment ? this.appointmentpopservice.resultExistingAppointment.appointmentDetail.serviceId : 0,
      service, priceModel.priceForGratuity,
      this.appointmentpopservice.aptPriceTypeGlobalService, appDetail.gratuityPercent);
    appDetail.TotalAmount = appDetail.price + appDetail.ServiceCharge + appDetail.Gratuity;

  }

  private updateClientMultipackID(appDetail: appointmentDetail, multipack: any, client: multiClientInfo) {
    if (!this.isUpdate || this.MultipackUpdate) {
      if (this.linkMultiPackOpted && multipack) {
        appDetail.clientMultiPackId = multipack.id;
        appDetail.price = multipack.salePrice;
        appDetail.yieldPrice = appDetail.price;
        appDetail.ServiceCharge = multipack.serviceCharge;
        appDetail.Gratuity = multipack.gratuity;
        appDetail.TotalAmount = multipack.salePrice + multipack.serviceCharge + multipack.gratuity + multipack.tax
      }
      else {
        appDetail.clientMultiPackId = 0
      }
    }
    else {
      let clientMultiPackItem = this.appointmentpopservice.existingAppointmentsRef.find(x => x.appointmentDetail.clientId == client.id)
      appDetail.clientMultiPackId = clientMultiPackItem ? clientMultiPackItem.appointmentDetail.clientMultiPackId : 0;
    }
  }

  formTherapistObjForSingleClientAppointment(): appointmentTherapist[] {
    let appTher: appointmentTherapist;
    let appTherArr: appointmentTherapist[] = [];
    for (const therapist of this.appointmentpopservice.clientScreenProperties.selectedStaff) {
      appTher = {
        therapistId: therapist.id,
        appointmentId: this.appointmentpopservice.TempHoldArr && this.appointmentpopservice.TempHoldArr.length > 0 ? this.appointmentpopservice.TempHoldArr[0].appointmentId : 0,
        servicePoints: this.appointmentpopservice.resultNewAppointment.servicePoints
      }
      appTherArr.push(appTher);
    }
    return appTherArr;
  }

  clearClientSelected() {
    //this.appointmentpopservice.multiClientInfo = [];
  }

  checkForMinGuestNeeded(): boolean {
    let errorExist = false;
    if (this.appointmentpopservice.multiClientInfo && this.appointmentpopservice.clientScreenProperties &&
      this.appointmentpopservice.multiClientInfo.length < this.appointmentpopservice.clientScreenProperties.minGuest) {
      errorExist = true;
    }
    return errorExist;
  }

  checkForMaxGuestExceeded(): boolean {
    let errorExist = false;
    if (this.appointmentpopservice.multiClientInfo && this.appointmentpopservice.clientScreenProperties &&
      this.appointmentpopservice.multiClientInfo.length > this.appointmentpopservice.clientScreenProperties.maxGuest) {
      errorExist = true;
    }
    return errorExist;
  }

  onNoClick(): void {
    this.promptUserForUnsavedChanges();
  }
  private newClientId: number;
  openDialog(AppointmentId: number, intakeForm: boolean, clientId: number, guestId: any, AppointmentDate: Date, clientArray: any, therapistArray: any) {
    let from = 'AppointmentPopupComponent';
    let appinfo = { AppointmentId, intakeForm, clientId, guestId, AppointmentDate, from, clientArray, therapistArray };
    let imgHolder = this.appointmentpopservice.clientImageObj && this.appointmentpopservice.clientImageObj.source ? this.appointmentpopservice.clientImageObj.source.value : []
    if (imgHolder && imgHolder.some(x => x.imageReferenceId === guestId)) {
      this.appointmentservice.clientImageChange(imgHolder);
    } else {
      this.appointmentservice.clientImageChange([]);
    }
    let dialogRef = this.dialog.open(AppointmentActionsCommonDialogComponent, {
      width: '90%',
      maxWidth: '1000px;',
      height: '80%',
      hasBackdrop: true,
      data: { id: ActionType.recap, headername: this.captions.AppointmentRecap, closebool: true, appointmentInfomation: appinfo },
      panelClass: 'action-dialog-overlay'

    });
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      this.appointmentpopservice.appComponent.reloadCss();
    });
  }

  UpdateCardDetailsToClientProfile() {
    const guaranteeData = this.appointmentpopservice.guaranteeMethodData && this.appointmentpopservice.guaranteeMethodData.guaranteePaymentMethod;
    if (guaranteeData && this.appointmentpopservice.guaranteeMethodData.updateCardDetailsToClientProfile
      && guaranteeData.GuaranteeMethod == GuaranteeMethod.Creditcard
      && guaranteeData.CardDetail && this.appointmentpopservice.guaranteeMethodData.isFormDirty) {
      let cardInfo: ClientCardInfo[] = [];
      this.appointmentpopservice.TempHoldArr.forEach(x => {
        if (!cardInfo.some(c => c.clientId == x.clientId)) {
          cardInfo.push({
            clientId: x.clientId,
            tokenTransId: guaranteeData?.CardDetail?.PaymentReferenceId
          });
        }
      });
      this._guaranteeBusiness.UpdateCardInfoToClientProfile(cardInfo);
    }
  }

  async successCallback<T>(
    result: BaseResponse<T>,
    callDesc: string,
    extraParams: any[]
  ) {
    switch (callDesc) {
      case "UpdateAppointment":
        {
          this.Utilities.ToggleLoader(false);
          this.AppointmentId = result.result;
          // Clear previous inputs
          if (this.isUpdate && !this.MultipackUpdate && this.appointmentpopservice.resultNewAppointment && this.appointmentpopservice.resultNewAppointment.service > 0 && this.appointmentpopservice.existingAppointmentsRef && this.appointmentpopservice.existingAppointmentsRef.some(x => x.appointmentDetail.clientMultiPackId > 0)) {
            let oldserviceobj = this.appointmentpopservice.existingAppointmentsRef.find(x => x.appointmentDetail.serviceId == this.appointmentpopservice.resultNewAppointment.service);
            if (!oldserviceobj) {
              let reinstateMultipackIds = this.appointmentpopservice.existingAppointmentsRef.filter(x => x.appointmentDetail.clientMultiPackId > 0).map(x => x.appointmentDetail.clientMultiPackId);
              if (reinstateMultipackIds && reinstateMultipackIds.length > 0) {
                this.appointmentservice.reinstateMultiPack(reinstateMultipackIds);
              }
            }
          }
          this.appointmentpopservice.resultNewAppointment = {};
          this.appointmentpopservice.SelectedClientId = null;
          this.appointmentpopservice.showDetails = false;
          this.appointmentpopservice.labelRecords = [];
          this.appointmentpopservice.clientsearchArray = [];
          this.appointmentpopservice.recordsArray = [];
          this.appointmentpopservice.isLinkedAppointments = false;
          this.appointmentpopservice.isLinkedAppointmentsLoaded = false;
          this.appointmentpopservice.linkedAppointments = [];
          this.appointmentpopservice.linkedAppointmentUpdated = [];
          this.appointmentpopservice.linkedAppointmentId = null;
          this.appointmentpopservice.clientBlockInfos = [];

          this.UpdateCardDetailsToClientProfile();
          let clientArray = [];
          let therapistArray = [];
          let multipackArray: any = [];

          // Removing PMS inputs after creating an appointment
          if (!this.isUpdate && this.appointmentpopservice.getPMSDataForAction(PMSAction.Reservation)) {
            this.Utilities.removeSession(GlobalConst.PMSDATA);
          }

          if (this.appointmentpopservice.TempHoldArr) {
            let tempHoldIds = this.appointmentpopservice.TempHoldArr.filter(function (tempObj) { return tempObj.appointmentId });


            if (tempHoldIds.length > 0) {

              this.appointmentpopservice.TempHoldArr.forEach(x => {

                if (clientArray.indexOf(x.clientId) == -1) {
                  clientArray.push(x.clientId)
                }
                if (!this.IsMultiClient) {
                  for (const therapist of this.appointmentpopservice.clientScreenProperties.selectedStaff) {
                    if (therapistArray.indexOf(therapist.id.toString()) == -1) {
                      therapistArray.push(therapist.id);
                    }
                  }
                }
                else {
                  this.appointmentpopservice.multiClientInfo.forEach(l => {
                    if (therapistArray.indexOf(l.TherapistId.toString()) == -1) {
                      therapistArray.push(l.TherapistId);
                    }
                  });
                }
                if (this.availableMultipacks && this.availableMultipacks.length > 0) {
                  let appointmentDatewithoutTime = this.Utilities.GetDateWithoutTime(this.Utilities.getDate(x.startTime));
                  let multipack = this.availableMultipacks.find(pack => pack.clientId == x.clientId && appointmentDatewithoutTime <= this.Utilities.getDate(pack.expirationDate));
                  // appointment multipack link
                  if (multipack) {
                    multipackArray.push(multipack.id);
                  }
                }
              });

              this.ApptUtilities.deleteTempHold(tempHoldIds, this.successCallback.bind(this), this.errorCallback.bind(this));
              if (extraParams[2] === true) {
                let genderOverrideTransLog: GenderOverrideTransLog = { appointmentIds: this.isUpdate ? tempHoldIds.map(x => Number(x.TempHoldLinkId)) : tempHoldIds.map(x => x.appointmentId) };
                this.ApptUtilities.insertTransLogforGenderPreferenceOverride(genderOverrideTransLog);
                this.isGenderPreferenceOverride = false;
              }


            }
            else {
              // this.appointmentpopservice['fromAction']=true;
              this.dialogRef.close(this.appointmentpopservice);
              this.clearClientSelected();
            }
          }
          else {
            // this.appointmentpopservice['fromAction']=true;
            this.dialogRef.close(this.appointmentpopservice);
            this.clearClientSelected();
          }
          let RecapQueryParams = this.getRecapParams(extraParams, clientArray, therapistArray);

          // unlink multipack for the appointment
          if (this.isUpdate && this.MultipackUpdate && this.appointmentpopservice.existingAppointmentsRef && this.appointmentpopservice.existingAppointmentsRef.some(x => x.appointmentDetail.clientMultiPackId > 0)) {
            let reinstateMultipackIds = this.appointmentpopservice.existingAppointmentsRef.filter(x => x.appointmentDetail.clientMultiPackId > 0).map(x => x.appointmentDetail.clientMultiPackId);
            this.appointmentservice.reinstateMultiPack(reinstateMultipackIds);
          }

          if (multipackArray && multipackArray.length > 0 && this.linkMultiPackOpted) {
            this.appointmentservice.redeemMultiPack(multipackArray);
          }
          if (this.APPOINTMENT_SHOW_RECAP)
            this.openDialog(RecapQueryParams.appointmentId, RecapQueryParams.intakeForm, RecapQueryParams.clientId, RecapQueryParams.guestId, RecapQueryParams.appointmentDate, clientArray, therapistArray);
          await this.UpdatePackageYield(this.appointmentpopservice.resultExistingAppointment, this.exisitngAppointmentDate, this.newAppointmentDate);
          this._quickLoginUtil.resetQuickIdDetails();
        }
        break;
      case "CreateBookTempAppointment":
        {
          let appointmentInfo = <any>result.result;
          appointmentInfo = appointmentInfo.result;
          // Clear previous inputs
          if (this.isUpdate && !this.MultipackUpdate && this.appointmentpopservice.resultNewAppointment && this.appointmentpopservice.resultNewAppointment.service > 0 && this.appointmentpopservice.existingAppointmentsRef && this.appointmentpopservice.existingAppointmentsRef.some(x => x.appointmentDetail.clientMultiPackId > 0)) {
            let oldserviceobj = this.appointmentpopservice.existingAppointmentsRef.find(x => x.appointmentDetail.serviceId == this.appointmentpopservice.resultNewAppointment.service);
            if (!oldserviceobj) {
              let reinstateMultipackIds = this.appointmentpopservice.existingAppointmentsRef.filter(x => x.appointmentDetail.clientMultiPackId > 0).map(x => x.appointmentDetail.clientMultiPackId);
              if (reinstateMultipackIds && reinstateMultipackIds.length > 0) {
                this.appointmentservice.reinstateMultiPack(reinstateMultipackIds);
              }
            }
          }
          this.appointmentpopservice.resultNewAppointment = {};
          this.appointmentpopservice.SelectedClientId = null;
          this.appointmentpopservice.showDetails = false;
          this.appointmentpopservice.labelRecords = [];
          this.appointmentpopservice.clientsearchArray = [];
          this.appointmentpopservice.recordsArray = [];
          this.appointmentpopservice.isLinkedAppointments = false;
          this.appointmentpopservice.isLinkedAppointmentsLoaded = false;
          this.appointmentpopservice.linkedAppointments = [];
          this.appointmentpopservice.linkedAppointmentUpdated = [];
          this.appointmentpopservice.linkedAppointmentId = null;
          this.appointmentpopservice.clientBlockInfos = [];

          this.UpdateCardDetailsToClientProfile();
          let clientArray = [];
          let therapistArray = [];
          let multipackArray: any = [];

          // Removing PMS inputs after creating an appointment
          if (!this.isUpdate && this.appointmentpopservice.getPMSDataForAction(PMSAction.Reservation)) {
            this.Utilities.removeSession(GlobalConst.PMSDATA);
          }

          if (this.appointmentpopservice.TempHoldArr && this.isUpdate) {
            let tempHoldIds = this.appointmentpopservice.TempHoldArr.filter(function (tempObj) { return tempObj.appointmentId });


            if (tempHoldIds.length > 0) {

              this.appointmentpopservice.TempHoldArr.forEach(x => {

                if (clientArray.indexOf(x.clientId) == -1) {
                  clientArray.push(x.clientId)
                }
                if (!this.IsMultiClient) {
                  for (const therapist of this.appointmentpopservice.clientScreenProperties.selectedStaff) {
                    if (therapistArray.indexOf(therapist.id.toString()) == -1) {
                      therapistArray.push(therapist.id);
                    }
                  }
                }
                else {
                  this.appointmentpopservice.multiClientInfo.forEach(l => {
                    if (therapistArray.indexOf(l.TherapistId.toString()) == -1) {
                      therapistArray.push(l.TherapistId);
                    }
                  });
                }
                if (this.availableMultipacks && this.availableMultipacks.length > 0) {
                  let appointmentDatewithoutTime = this.Utilities.GetDateWithoutTime(this.Utilities.getDate(x.startTime));
                  let multipack = this.availableMultipacks.find(pack => pack.clientId == x.clientId && appointmentDatewithoutTime <= this.Utilities.getDate(pack.expirationDate));
                  // appointment multipack link
                  if (multipack) {
                    multipackArray.push(multipack.id);
                  }
                }
              });

              this.ApptUtilities.deleteTempHold(tempHoldIds, this.successCallback.bind(this), this.errorCallback.bind(this));
              if (extraParams[2] === true) {
                let genderOverrideTransLog: GenderOverrideTransLog = { appointmentIds: this.isUpdate ? tempHoldIds.map(x => Number(x.TempHoldLinkId)) : tempHoldIds.map(x => x.appointmentId) };
                this.ApptUtilities.insertTransLogforGenderPreferenceOverride(genderOverrideTransLog);
                this.isGenderPreferenceOverride = false;
              }


            }
            else {
              // this.appointmentpopservice['fromAction']=true;
              this.dialogRef.close(this.appointmentpopservice);
              this.clearClientSelected();
            }
          }
          else {
            // this.appointmentpopservice['fromAction']=true;
            this.dialogRef.close(this.appointmentpopservice);
            this.clearClientSelected();
          }

          // unlink multipack for the appointment
          if (this.isUpdate && this.MultipackUpdate && this.appointmentpopservice.existingAppointmentsRef && this.appointmentpopservice.existingAppointmentsRef.some(x => x.appointmentDetail.clientMultiPackId > 0)) {
            let reinstateMultipackIds = this.appointmentpopservice.existingAppointmentsRef.filter(x => x.appointmentDetail.clientMultiPackId > 0).map(x => x.appointmentDetail.clientMultiPackId);
            this.appointmentservice.reinstateMultiPack(reinstateMultipackIds);
          }

          if (multipackArray && multipackArray.length > 0 && this.linkMultiPackOpted) {
            this.appointmentservice.redeemMultiPack(multipackArray);
          }
          await this.UpdatePackageYield(this.appointmentpopservice.resultExistingAppointment, this.exisitngAppointmentDate, this.newAppointmentDate);
          this._quickLoginUtil.resetQuickIdDetails();
          if (this.cartUtils.isEmbed()) {
            let guestInfos = [];
            let currentTempHoldIds = appointmentInfo.map(x => x.appointmentDetail.id);
            console.log(appointmentInfo)
            appointmentInfo.forEach(x => x.appointmentDetail.status = "RESV");
            let updateApptObj: any = {
              tempHoldId: currentTempHoldIds,
              AppointmentInfo: appointmentInfo,
              LinkedAppointments: this.appointmentpopservice.linkedAppointmentUpdated.map(x => x.appointmentId)
            }
            guestInfos = await this.getGuestInfos(appointmentInfo);
            const url = this.getApiUrl('UpdateAppointment', { id: extraParams[1], isCreateAppointment: extraParams[3] }, Host.schedule)
            const urlForTempHold = this.getApiUrl('ExtendTempHold', { holdMinutes: 0 }, Host.schedule);
            const urlForReleaseHold = this.getApiUrl('ReleaseTempHold', {}, Host.schedule);
            var payload: CartPayload = {
              userId: parseInt(this.localization.GetPropertyInfo('UserId')),
              comments: '',
              description: this.appointmentpopservice.serviceArray.find(x => x.id == appointmentInfo[0].appointmentDetail.serviceId).description,
              activityType: 'Appointment',
              cardInfos: null,
              price: appointmentInfo[0].appointmentDetail.price,
              startDate: appointmentInfo[0].appointmentDetail.startTime,
              endDate: appointmentInfo[0].appointmentDetail.endTime,
              productCode: 'SPA',
              guestInfos: guestInfos,
              depositAmount: 0,
              productId: parseInt(this.Utilities.GetPropertyInfo('ProductId')),
              propertyId: parseInt(this.Utilities.GetPropertyInfo('PropertyId')),
              tenantId: Number(this.Utilities.GetPropertyInfo('TenantId')),
              bookAPI: {
                headers: this.getHeaders(),
                path: url,
                verb: 'PUT',
                payload: JSON.stringify(updateApptObj),
              },
              holdAPI: {
                headers: this.getHeaders(),
                path: urlForTempHold,
                verb: 'PUT',
                payload: JSON.stringify(currentTempHoldIds),
              },
              releaseAPI: {
                headers: this.getHeaders(),
                path: urlForReleaseHold,
                verb: 'DELETE',
                payload: JSON.stringify(currentTempHoldIds),
              }
            };
            if (this.data.isEditVcart)
              this.cartUtils.pushMessageToParentWindow(CartWindowMessageType.UpdateCart,
                {
                  cartDetailId: this.data.editCartItemDetail.cartDetail.id,
                  cartItineraryId: this.data.editCartItemDetail.cartItinerary.id,
                  cartPayload: payload
                });
            else
              this.cartUtils.pushMessageToParentWindow(CartWindowMessageType.AddToCart, payload);
          }
        }
        break;
      case 'DeleteTempHold':
        {
          const tempIds = extraParams;
          for (let idItem of tempIds) {
            if (idItem > 0) {
              const tempHoldIndex = this.appointmentpopservice.TempHoldArr.findIndex(function (tempobj) {
                return tempobj.appointmentId == idItem;
              });

              if (tempHoldIndex !== -1) {
                this.appointmentpopservice.TempHoldArr.splice(tempHoldIndex, 1);
              }
            }
          }
          this.appointmentpopservice['fromAction'] = true;
          this.dialogRef.close(this.fromItineraryObject);
          this.clearClientSelected();
          this.appointmentpopservice.showDetails = false;
          this.appointmentpopservice.clientPreferedGender = "";
        }
        break;
      case 'createNewClient':
      case 'updateClient':
        {
          this.EnableSave = true;
          const tempresponse: any = <any>result.result;
          this.newClientId = tempresponse.clientDetail.id;
          this.clientGuid = tempresponse.clientDetail.guestId;
          this.appointmentpopservice.clientGuestMap.push({ 'ClientId': this.newClientId, 'GuestId': tempresponse.clientDetail.guestId, 'PMSActivityId': this.appointmentpopservice.pmsActivityId });
          this.appointmentpopservice.popupTitle = (this.newClientId > 0 && !this.appointmentpopservice.checkwhetherfromClient) ?
            this.captions.EDITAPPOINTMENT :
            this.captions.NEWAPPOINTMENT; // edit appointment popup title change based on add or edit client

          this.appointmentpopservice.add_client = false;
          this.appointmentpopservice.showDetails = false;
          this.appointmentpopservice.saveText = (this.newClientId > 0 && !this.appointmentpopservice.checkwhetherfromClient) ?
            this.allCaptions.common.Update :
            this.allCaptions.common.SAVE;
          const clientImage = this.appointmentpopservice.ImgTempHolder;
          this.appointmentpopservice.checkwhetherfromClient = false;
          this.appointmentpopservice.clientAddedFromClientInformation = false;
          this.appointmentpopservice.IsClientBlockInfoDirty = false;
          this.appointmentpopservice.clientBlockIformationFormGroup = null;
          this.appointmentpopservice.clientBlockInfos = [];
          this.appointmentpopservice.ClientBlockInformation = null;


          if (!_.isEmpty(clientImage)) {
            clientImage.referenceId = 0;
            clientImage.imageReferenceId = this.clientGuid;
            clientImage.referenceType = ImgRefType.guest;
            let clientImages = [];
            clientImages.push(clientImage);
            if ((callDesc == 'createNewClient' || !this.appointmentpopservice.clientHasPic) && this.newClientId > 0 && this.clientGuid != DefaultGUID) {
              this.imgService.saveImage(clientImages, this.successCallback.bind(this), this.errorCallback.bind(this), [], true);
              this.appointmentpopservice.clientHasPic = true;
            } else {
              if (this.appointmentpopservice.isClientImgRemoved) {
                this.imgService.DeleteImageByReference(this.clientGuid,
                  ImgRefType.guest,
                  this.successCallback.bind(this),
                  this.errorCallback.bind(this),
                  [], true);
              } else {
                this.imgService.updateImage(clientImage.id, clientImages, this.successCallback.bind(this), this.errorCallback.bind(this), [], true)
              }
            }
          } else if (this.appointmentpopservice.isClientImgRemoved) {
            this.imgService.DeleteImageByReference(this.clientGuid,
              ImgRefType.guest,
              this.successCallback.bind(this),
              this.errorCallback.bind(this),
              [], true);
          }
          if (this.IsGDPREnabled && this.appointmentpopservice.additionalDetailsFormGroup.value.consentPolicyId != 0) {
            let applyPolicy: ApplyPolicy = {
              guestId: this.clientGuid,
              consentDate: this.localization.convertDateTimeToAPIDateTimeSec(this.appointmentpopservice.additionalDetailsFormGroup.value.consentDate),
              consentExpiryDate: this.localization.convertDateTimeToAPIDateTimeSec(this.appointmentpopservice.additionalDetailsFormGroup.value.consentExpiryDate),
              policyId: this.appointmentpopservice.additionalDetailsFormGroup.value.consentPolicyId,
              policyType: PolicyType.ConsentPolicy
            }
            this.appointmentservice.updateConsentPolicyDetailsForGuestId(applyPolicy);
          }
          if (this.appointmentpopservice.IsOrderSummary) {  // Add Code only for Temp Fix For 2728, once  Validate Dev Team for Correct Fix
            this.dialogRef.close(result.result);
            return;
          }


          if (this.appointmentpopservice.IsAddClientFromSearch || this.appointmentpopservice.addFromClientModule) {
            this.closeDialog(this.newClientId);
          } else if (this.appointmentservice.isAppointmentFromPackage) {
            this.appointmentservice.PackageSelectedServices.forEach(element => {
              if (element.guestId == tempresponse.clientDetail.guestId) {
                element.bypassClientScheduling = tempresponse.clientDetail.bypassClientScheduling;
              }
            });

            this.dialogRef.close(this.newClientId);
          } else if (this.appointmentpopservice.isSpaWizard || this.appointmentpopservice.editselectedClient) {
            this.dialogRef.close(tempresponse);
          } else {
            let _priceTypeId = tempresponse.clientDetail.priceTypeId;
            this.appointmentpopservice.clientVipType = this.appointmentpopservice.GetVipTypeName(tempresponse.clientDetail.vip);
            if (callDesc == 'createNewClient' && this.newClientId > 0) {
              this.appointmentpopservice.clientAddedFromClientInformation = true;
              let selectedpriceTypeCode = this.appointmentpopservice.selectedServicePriceTypes.find(y => y.priceTypeId == _priceTypeId);
              _priceTypeId = selectedpriceTypeCode ? selectedpriceTypeCode.priceTypeId : 0
              this.appointmentpopservice.multiClientInfo.push({ 'id': this.newClientId, 'comments': '', 'TherapistId': 0, 'priceTypeId': _priceTypeId, 'price': 0, 'discountType': '' });
              this.appointmentpopservice.isAptPriceReadOnly = this.appointmentpopservice.isPriceTypeApplicable();
              this.appointmentpopservice.updateMultiClientInfoPriceDetails();
              this.appointmentpopservice.setPriceTypeUIforMutliClient(tempresponse.clientDetail.id);
            }
            if (!this.appointmentpopservice.isEditAppointment && callDesc == 'updateClient' && this.newClientId > 0) {
              //While editing appointment, Client priceType edit no need to reflect in appointment
              let isUpdateServicePrice: boolean = false;
              if (this.appointmentpopservice.isPriceTypeApplicable()) {
                this.appointmentpopservice.multiClientInfo.forEach(client => {
                  if (client.id == this.newClientId) {
                    isUpdateServicePrice = client.priceTypeId != _priceTypeId;
                    client.priceTypeId = _priceTypeId;
                  }
                });
                this.appointmentpopservice.updateMultiClientInfoPriceDetails();
                if (isUpdateServicePrice) {
                  await this.appointmentpopservice.getServicePriceTypePriceBasedOnYield(tempresponse.clientDetail);
                }
                this.appointmentpopservice.setPriceTypeUIforMutliClient(tempresponse.clientDetail.id);
              }
            }

            this.appointmentpopservice.ClientAddedEvent(this.newClientId);
            if (callDesc == 'createNewClient' && this.newClientId > 0) {
              this.ApptUtilities.CreateTempholdForClientSelected(this.newClientId);
            }
          }
          if (this.appointmentpopservice.isLocationBlock) {
            this.dialogRef.close(result.result);
            return;
          }
          // Add Code only for Temp Fix For 2728, once  Validate Dev Team for Correct Fix
          if (this.appointmentpopservice != null && this.appointmentpopservice.resultNewAppointment != null &&
            this.appointmentpopservice.resultNewAppointment.service != null && this.appointmentpopservice.resultNewAppointment.service != 0
            && this.newClientId != 0) {
            this.ApptUtilities.CheckMedicalWarning(this.appointmentpopservice.resultNewAppointment.service, [this.newClientId],
              this.successCallback.bind(this), this.errorCallback.bind(this));
          }
          if (this.appointmentservice.isaddClientFromClientInfo) {
            this.appointmentservice.isaddClientFromClientInfo = false;
            this.closeDialog();
          }
        }
        break;
      case 'saveImage':
      case 'v2saveImage':
        {
          this.appointmentpopservice.guestId = this.clientGuid;
          this.appointmentpopservice.clientImageChange([this.appointmentpopservice.ImgTempHolder]);
        }
        break;
      case 'v2updateImage':
      case 'updateImageById':
        {
          const clientId = this.appointmentpopservice.clientId;
          const tempObj = _.cloneDeep(this.appointmentpopservice.ImgTempHolder);
          this.appointmentpopservice.imageArray = _.map(this.appointmentpopservice.imageArray, (x) => {
            if (x && x['referenceId'] === clientId && x['referenceType'] === ImgRefType.client) {
              x = tempObj;
            }
            return x;
          });
          this.appointmentpopservice.clientImageChange([tempObj]);
        }
        break;
      case 'v2DeleteImageByReference':
        {
          const clientId = this.appointmentpopservice.guestId;
          _.remove(this.appointmentpopservice.imageArray, (x) => x && x['imageReferenceId'] === clientId && x['referenceType'] === ImgRefType.guest)
          this.appointmentpopservice.clientImageChange([]);
        }
        break;
      // case 'GetConfiguration':
      //   let res: SystemConfig = <any>result.result;
      //   this.APPOINTMENT_SHOW_RECAP = res.value == 'true' ? true : false;
      //   break;
      case 'GetAllSettingByModule':
        let results = <any>result.result[0];
        this.APPOINTMENT_SHOW_RECAP = results['APPOINTMENT_SHOW_RECAP'];
        this.APPOINTMENT_LOST_DENIED_BUSINESS = results['APPOINTMENT_LOST_DENIED_BUSINESS'];
        this.SEND_AUTOMATIC_APPOINTMENT_NOTIFICATION = results['SEND_AUTOMATIC_APPOINTMENT_NOTIFICATION'];
        this.notificationSetup = await this._NotificationConfigurationService.GetEventNotificationGroupByProduct();
        break;
      case 'getClientRecentAppointmentInfo':
        let resp: any = result.result;
        let RecentAppsUIArr = [];
        for (let item of resp) {
          const element = item;
          RecentAppsUIArr.push(this.createRecentAppsObj(element.serviceName, element.therapistNames, element.date, element.status));
        }
        this.appointmentpopservice.RecentAppsUIArr = RecentAppsUIArr;
        break;
      case 'getClientInfo':
        if (!this.appointmentpopservice.labelRecords) {
          this.appointmentpopservice.labelRecords = [];
        }
        if (!this.appointmentpopservice.labelRecords.some(r => r.Id == resp.clientDetail.id)) {
          let clientLable: ClientLabel = {
            Id: resp.clientDetail.id,
            FirstName: resp.clientDetail.firstName,
            LastName: resp.clientDetail.lastName
          }
          this.appointmentpopservice.labelRecords.push(clientLable);
        }

        if (!this.appointmentpopservice.recordsArray) {
          this.appointmentpopservice.recordsArray = [];
        }
        resp.id = resp.clientDetail.id;

        if (this.appointmentpopservice.recordsArray.map(x => x.clientDetail).map(x => x.id).indexOf(resp.id) == -1) {
          this.appointmentpopservice.recordsArray.push(resp);
          if (this.appointmentpopservice.recordsArray.length == 1) {
            this.appointmentpopservice.clientPreferedGender = resp.clientDetail ? resp.clientDetail.genderPreference : 'Male';
          }
        }
        this.activateClientSelected = resp;
        break;
      case "GetMedicalWarning": {
        this.ApptUtilities.GetMedicalWarning(<any>result.result, this._vcr, 'client', this.clientInfo ? this.clientInfo.clientDetail : null, this.MedicalWarningPopUpCallback.bind(this))
      }
        break;
    }
  }

  async MakeAsyncCall(callDesc: string, host: GlobalConst.Host, method: HttpMethod, uriParams: any): Promise<any> {
    try {
      return await this.http.CallApiAsync({
        callDesc: callDesc,
        host: host,
        method: method,
        uriParams: uriParams
      });
    } catch (e) {
      this.Utilities.exceptionHandle(e);
    }
  }

  async UpdatePackageYield(appointmentUpdate: appointment, fromDate: Date, toDate: Date) {
    if (appointmentUpdate && appointmentUpdate.appointmentDetail && appointmentUpdate.appointmentDetail.appointmentType === 'PACKAGE') {
      const uriParams = {
        packageId: appointmentUpdate.appointmentDetail.packageId,
        packageGroupId: appointmentUpdate.appointmentDetail.packageGroupId,
        fromDate: this.Utilities.formatDate(fromDate),
        toDate: this.Utilities.formatDate(toDate)
      };
      await this.MakeAsyncCall('UpdatePackageYieldOnMove', GlobalConst.Host.spaManagement, HttpMethod.Put, uriParams);
    }
  }

  MedicalWarningPopUpCallback(result: any, extraParams: any[]) {
    if (result && result.popupCloseInfo.toLowerCase() == ButtonOptions.No.toLowerCase() && result && result.clientId > 0) {
      this.appointmentpopservice.recordsArray = this.appointmentpopservice.recordsArray.filter(r => r.clientDetail.id != result.clientId);
      this.appointmentpopservice.multiClientInfo = this.appointmentpopservice.multiClientInfo.filter(r => r.id != result.clientId);
      this.appointmentpopservice.labelRecords = this.appointmentpopservice.labelRecords.filter(r => r.Id != result.clientId);
      this.appointmentpopservice.clientGuestMap = this.appointmentpopservice.clientGuestMap.filter(r => r.ClientId != result.clientId);
      this.appointmentpopservice.clientsearchArray = this.appointmentpopservice.clientsearchArray.filter(r => r.id != result.clientId);
    }
  }

  private getRecapParams(extraParams: any[], clientArray: any[], therapistArray: any[]) {
    let RecapQueryParams = {
      appointmentId: this.isUpdate ? extraParams[1] : extraParams[0].AppointmentInfo[0].appointmentDetail.id,
      clientId: extraParams[0].AppointmentInfo[0].appointmentDetail.clientId,
      guestId: extraParams[0].AppointmentInfo[0].appointmentDetail.guestId,
      appointmentDate: extraParams[0].AppointmentInfo[0].appointmentDetail.startTime,
      intakeForm: extraParams[0].AppointmentInfo[0].appointmentDetail.intakeForm,
      clientArray,
      therapistArray
    };
    let multipackedAppointment = null;
    if (!this.isUpdate) {
      multipackedAppointment = extraParams[0].AppointmentInfo.find(x => x.appointmentDetail.clientMultiPackId > 0);
    }
    else {
      if (!this.MultipackUpdate) {
        multipackedAppointment = this.appointmentpopservice.existingAppointmentsRef.find(x => x.appointmentDetail.clientMultiPackId > 0);
      }
      else {
        let multipackedTempAppointment = extraParams[0].AppointmentInfo.find(x => x.appointmentDetail.clientMultiPackId > 0);
        multipackedAppointment = this.appointmentpopservice.existingAppointmentsRef.find(x => multipackedTempAppointment && x.appointmentDetail.clientId == multipackedTempAppointment.appointmentDetail.clientId);
      }
    }
    if (multipackedAppointment) {
      RecapQueryParams = {
        appointmentId: multipackedAppointment.appointmentDetail.id,
        clientId: multipackedAppointment.appointmentDetail.clientId,
        guestId: multipackedAppointment.appointmentDetail.guestId,
        appointmentDate: multipackedAppointment.appointmentDetail.startTime,
        intakeForm: multipackedAppointment.appointmentDetail.IntakeForm,
        clientArray,
        therapistArray
      };
    }
    return RecapQueryParams;
  }

  setClientProperties(callDesc, id, body) {
    if (body.length != 0) {
      this.http.CallApiWithCallback({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: callDesc,
        method: HttpMethod.Post,
        body: body,
        uriParams: { id: id },
        showError: true,
        extraParams: []
      });
    }
  }

  formAddressObject(): Address[] {
    let addObjarr: Address[] = [];
    let addressControlArr: any = this.appointmentpopservice.personalDetailsFormGroup.get(
      'Address'
    );
    if (addressControlArr.value.length == 1 && addressControlArr.value[0].addressLine == ''
      && this.appointmentpopservice.personalDetailsFormGroup.controls.state.value == ''
      && this.appointmentpopservice.personalDetailsFormGroup.controls.city.value == ''
      && this.appointmentpopservice.personalDetailsFormGroup.controls.country.value == ''
      && this.appointmentpopservice.personalDetailsFormGroup.controls.postal_code.value == '') {
      return null;
    }
    let addressArr = addressControlArr.controls;
    let _line1: string = '';
    let _line2: string = '';
    let _line3: string = '';
    let isPrivate: boolean;

    for (let i = 0; i < addressArr.length; i++) {
      const element = addressArr[i];

      if (i == 0) {
        _line1 = element.value['addressLine'];
        isPrivate = element.value['privateAddress'] ? element.value['privateAddress'] : false //only to bind PrivateAddress flag from Line 1
      }
      if (i == 1) {
        _line2 = element.value['addressLine'];
      }
      if (i == 2) {
        _line3 = element.value['addressLine'];
      }
    }
    let addrObj: Address = {
      ClientId: this.appointmentpopservice.clientId,
      ContactTypeId: 1,
      Line1: _line1 ? _line1 : '',
      Line2: _line2 ? _line2 : '',
      Line3: _line3 ? _line3 : '',
      State: this.appointmentpopservice.personalDetailsFormGroup.controls.state.value ? this.appointmentpopservice.personalDetailsFormGroup.controls.state.value : '',
      City: this.appointmentpopservice.personalDetailsFormGroup.controls.city.value ? this.appointmentpopservice.personalDetailsFormGroup.controls.city.value : '',
      Country: this.Utilities.FilterCountryValueFromData(this.appointmentpopservice.personalDetailsFormGroup.controls.country.value),
      PlatformAddressUuid: this.appointmentpopservice.personalDetailsFormGroup.controls.platformAddressUuid.value ? this.appointmentpopservice.personalDetailsFormGroup.controls.platformAddressUuid.value : '',
      Zip: this.appointmentpopservice.personalDetailsFormGroup.controls.postal_code.value ? this.appointmentpopservice.personalDetailsFormGroup.controls.postal_code.value : '',
      IsPrivate: isPrivate
    }

    addObjarr.push(addrObj);


    let guestAddressList: Array<any> = this.appointmentpopservice.personalDetailsFormGroup.controls.platformAddressList.value ? this.appointmentpopservice.personalDetailsFormGroup.controls.platformAddressList.value : [];

    if (guestAddressList && guestAddressList.length > 1) {
      guestAddressList.forEach(x => {
        if (x.platformAddressUuid != addrObj.PlatformAddressUuid) {
          let platformaddrObj: Address = {
            ClientId: this.appointmentpopservice.clientId,
            ContactTypeId: x.contactTypeId,
            Line1: x.line1,
            Line2: x.line2,
            Line3: x.line3,
            State: x.state,
            City: x.city,
            Country: x.country,
            PlatformAddressUuid: x.platformAddressUuid,
            Zip: x.zip,
            IsPrivate: x.isPrivate
          }
          addObjarr.push(platformaddrObj);
        }
      })
    }

    return addObjarr;
  }

  formPhoneObject(): PhoneNumber[] {
    let clientPhoneObj: PhoneNumber[] = [];
    let phoneControlFrmArr: any = this.appointmentpopservice.personalDetailsFormGroup.get(
      'Phone'
    );
    let phoneArr = phoneControlFrmArr.controls;
    for (let phoneArrItem of phoneArr) {
      let _Number: string = '';
      if (phoneArrItem.value['PhoneNumber'] && phoneArrItem.value['PhoneNumber'] != '') {
        const phoneNumber = (phoneArrItem.value['PhoneNumber']).replace(/\D/g, '');
        //Added For Phone Number when contact type is work.
        if (phoneArrItem.value['PhoneNumberLabel'] == '3' && phoneArrItem.value['Extension'] != '') {
          _Number = phoneArrItem.value['Extension'] + ":";
        }
        _Number += phoneArrItem.value['countryCode'] ? phoneArrItem.value['countryCode'] + "|" + phoneNumber : phoneNumber;
        let phoneObj: PhoneNumber = {
          ClientId: this.appointmentpopservice.clientId,
          ContactTypeId: phoneArrItem.value['PhoneNumberLabel'] ? phoneArrItem.value['PhoneNumberLabel'] : 0,
          Number: _Number,
          Extension: phoneArrItem.value['Extension'] ? phoneArrItem.value['Extension'].replace('+', '') : '',
          IsPrivate: phoneArrItem.value['PhonePrivate'] ? phoneArrItem.value['PhonePrivate'] : false,
          IsPrimary: phoneArrItem.controls['PhonePrimary']?.value ? phoneArrItem.controls['PhonePrimary']?.value : false,
          PlatformContactUuid: phoneArrItem.value['PlatformContactUuid'] ? phoneArrItem.value['PlatformContactUuid'] : ''
        };
        clientPhoneObj.push(phoneObj);
      }
    }
    return clientPhoneObj;
  }

  formEmailObj(): Email[] {
    let emailObjArr: Email[] = [];
    let emailControlFrmArr: any = this.appointmentpopservice.personalDetailsFormGroup.get(
      'Email'
    );

    let emailArr = emailControlFrmArr.controls;
    for (let emailArrItem of emailArr) {
      if (emailArrItem.value['EmailId'] && emailArrItem.value['EmailId'] != '') {
        let emailObj: Email = {
          ClientId: this.appointmentpopservice.clientId,
          ContactTypeId: (emailArrItem.value['EmailLabel']) ? (emailArrItem.value['EmailLabel']) : 0,
          EmailId: emailArrItem.value['EmailId'],
          IsPrivate: emailArrItem.value['EmailPrivate'] ? emailArrItem.value['EmailPrivate'] : false,
          IsPrimary: emailArrItem.controls['EmailPrimary']?.value ? emailArrItem.controls['EmailPrimary']?.value : false,
          PlatformContactUuid: emailArrItem.value['PlatformContactUuid'] ? emailArrItem.value['PlatformContactUuid'] : ''
        };
        emailObjArr.push(emailObj);
      }
    }
    return emailObjArr;
  }

  formMedicalCondition(): MedicalHistory[] {
    let clientMedicalHistory: MedicalHistory[] = [];
    clientMedicalHistory = this.appointmentpopservice.medicalhistoryArray ? this.appointmentpopservice.medicalhistoryArray.map(x => {
      return {
        ClientId: this.appointmentpopservice.clientId,
        MedicalConditionId: x.id,
        LoggedTime: x.loggedTime ? x.loggedTime : this.PropertyInfo.CurrentDate
      }
    }) : [];

    return clientMedicalHistory;
  }
  errorCallback<T>(
    error: BaseResponse<T>,
    callDesc: string,
    extraParams: any[]
  ): void {

    if (callDesc == 'CreateAppointment') {
      let errorResp: BaseResp = error as BaseResp;
      let errMess: string = this.localization.getError(parseInt((errorResp.result && errorResp.result.length) ? errorResp.result[0] : errorResp.result));
      this.Utilities.ShowErrorMessage('Error in Creating Appointment for Following Clients', errMess);
    }
    else if (callDesc == 'UpdateAppointment') {
      this.Utilities.ToggleLoader(false);
      this.EnableSave = true;
      let errorResp: BaseResp = error as BaseResp;
      if (errorResp.result && errorResp.result.length) {
        let temperror = errorResp.result ? errorResp.result : [];
        let errArr: number[] = typeof temperror === 'number' ? [temperror] : temperror;
        if (this.IsAppointmentFailureOnTempAutoRemoval(errArr)) {
          this.RetryAppointmentWithNewTemp();
          return;
        }
        var appointmentInfo = extraParams[0].AppointmentInfo;
        let overbookErrorCodes = {
          therapist: 923,
          location: 933,
          client: 942
        };
        let overBookErr = [overbookErrorCodes.therapist, overbookErrorCodes.location, overbookErrorCodes.client];
        if (!this.appointmentpopservice.isOverbook && errArr && errArr.length > 0 && errArr.some(x => { return overBookErr.includes(x) }) && !errArr.some(x => x == GlobalConst.ErrorConstants.TherapistIsOnCall)) {
          let overBookOptions: any = {
            location: errArr.includes(overbookErrorCodes.location),
            therapist: errArr.includes(overbookErrorCodes.therapist),
            client: errArr.includes(overbookErrorCodes.client)
          };
          this.Utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.getError(-3), ButtonType.YesNo, this.DurationOverBookCallBack.bind(this), overBookOptions);
          return;
        }
        let clientCanbeOverBooked = (errArr && errArr.length == 1 && errArr[0] == overbookErrorCodes.client);
        if (clientCanbeOverBooked) {
          let overBookOptions: any = {
            location: appointmentInfo && appointmentInfo.some(app => app.appointmentDetail.isLocationOverbook),
            therapist: appointmentInfo && appointmentInfo.some(app => app.appointmentDetail.isTherapistOverbook),
            client: clientCanbeOverBooked
          };
          this.appointmentpopservice.isOverbook = false;
          this.Utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.getError(-3), ButtonType.YesNo, this.DurationOverBookCallBack.bind(this), overBookOptions);
          return;
        }
      }
      let errMess: string = this.localization.getError(parseInt((errorResp.result && errorResp.result.length) ? errorResp.result[0] : errorResp.result));
      this.Utilities.ShowErrorMessage(this.localization.getError(-116), errMess);
    } else if (callDesc == 'createNewClient') {
      let errorResp: BaseResp = error as BaseResp;
      this.handleException(errorResp);
      this.EnableSave = true;
    }
    else if (callDesc == 'updateClient') {
      let errorResp: BaseResp = error as BaseResp;
      this.handleException(errorResp);
      this.EnableSave = true;
    }
    else if (callDesc == 'CreateBookTempAppointment') {
      this.EnableSave = true;
      let errorResp: any = error.result as any;
      if (errorResp.errorList && errorResp.errorList.length) {
        let temperror = errorResp.errorList ? errorResp.errorList : [];
        let errArr: number[] = typeof temperror === 'number' ? [temperror] : temperror;
        if (this.IsAppointmentFailureOnTempAutoRemoval(errArr)) {
          this.RetryAppointmentWithNewTemp();
          return;
        }
        var appointmentInfo = extraParams[0].AppointmentInfo;
        let overbookErrorCodes = {
          therapist: 923,
          location: 933,
          client: 942
        };
        let overBookErr = [overbookErrorCodes.therapist, overbookErrorCodes.location, overbookErrorCodes.client];
        if (!this.appointmentpopservice.isOverbook && errArr && errArr.length > 0 && errArr.some(x => { return overBookErr.includes(x) }) && !errArr.some(x => x == GlobalConst.ErrorConstants.TherapistIsOnCall)) {
          let overBookOptions: any = {
            location: errArr.includes(overbookErrorCodes.location),
            therapist: errArr.includes(overbookErrorCodes.therapist),
            client: errArr.includes(overbookErrorCodes.client)
          };
          this.Utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.getError(-3), ButtonType.YesNo, this.DurationOverBookCallBackForVcart.bind(this), overBookOptions);
          return;
        }
        let clientCanbeOverBooked = (errArr && errArr.length == 1 && errArr[0] == overbookErrorCodes.client);
        if (clientCanbeOverBooked) {
          let overBookOptions: any = {
            location: appointmentInfo && appointmentInfo.some(app => app.appointmentDetail.isLocationOverbook),
            therapist: appointmentInfo && appointmentInfo.some(app => app.appointmentDetail.isTherapistOverbook),
            client: clientCanbeOverBooked
          };
          this.appointmentpopservice.isOverbook = false;
          this.Utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.getError(-3), ButtonType.YesNo, this.DurationOverBookCallBackForVcart.bind(this), overBookOptions);
          return;
        }
      }
      let errMess: string = this.localization.getError(parseInt((errorResp.errorList && errorResp.errorList.length) ? errorResp.errorList[0] : errorResp.errorList));
      this.Utilities.ShowErrorMessage(this.localization.getError(-116), errMess);
    }
  }

  DataOutOfDatePopupCallback(result: any, extraParams?: any[]) {
    if (result.toLowerCase() == "OK".toLowerCase()) {
      let params = {

        action: 'ReloadClient',
        client: {
          id: this.appointmentpopservice.clientId,
          guestId: this.appointmentpopservice.guestId
        }
      }
      this.closeDialog(params);
    }
  }

  handleException(errorResp: any) {
    let errMess: string;
    if (errorResp && errorResp.errorCode != null && errorResp.errorCode) {

      if (errorResp.errorCode == -302) {
        errMess = this.localization.getError(-302);
        this.Utilities.ShowErrorMessage(this.localization.captions.common.Warning, errMess, ButtonType.Ok, this.DataOutOfDatePopupCallback.bind(this));
        return;
      }
      else if (errorResp.errorCode == 310002) {
        errMess = this.localization.getError(310002);
        this.Utilities.ShowErrorMessage(this.localization.captions.common.Warning, errMess, ButtonType.Ok, this.DataOutOfDatePopupCallback.bind(this));
        return;
      }
      else if (errorResp.errorCode == 310001) {
        errMess = this.localization.getError(310001);
        this.Utilities.ShowErrorMessage(this.localization.captions.common.Warning, errMess, ButtonType.Ok, this.DataOutOfDatePopupCallback.bind(this));
        return;
      }
      else {
        errMess = this.localization.getError(errorResp.errorCode);
        this.Utilities.ShowErrorMessage(this.localization.captions.common.Error, errMess);
        return;
      }
    }
    else {
      errMess = this.localization.getError(-2);
      this.Utilities.ShowErrorMessage(this.localization.captions.common.Error, errMess);
      return;
    }

  }

  IsAppointmentFailureOnTempAutoRemoval(errorList: number[]): boolean {
    return errorList && errorList.length == 1 && errorList[0] == GlobalConst.ErrorConstants.TempNotFound;
  }

  RetryAppointmentWithNewTemp() {
    this.appointmentpopservice.TempHoldArr = [];
    this.appointmentservice.tempRecordCreated = false;
    this.appointmentpopservice.recreateTempAppointments = true;
    this.appointmentComponent.appointmentdetail.CreateTempHold();
    setTimeout(() => {
      this.save();
    }, 3000);
  }

  DurationOverBookCallBack(result: string, extraParams: any) {
    if (result.toLowerCase() == 'YES'.toLowerCase()) {
      if (this.IsUserAuthorizedForOverBook(extraParams)) {
        this.save(true, extraParams);
      }
    }
  }

  DurationOverBookCallBackForVcart(result: string, extraParams: any) {
    if (result.toLowerCase() == 'YES'.toLowerCase()) {
      if (this.IsUserAuthorizedForOverBook(extraParams)) {
        this.save(true, extraParams, true);
      }
    }
  }

  IsUserAuthorizedForOverBook(overBookOptions: any): boolean {
    let isAuthorized: boolean = true;
    let breakPointNumber: number[] = [];
    if (overBookOptions.location) {
      breakPointNumber.push(SPAScheduleBreakPoint.OverbookLocation);
    }
    if (overBookOptions.therapist) {
      breakPointNumber.push(SPAScheduleBreakPoint.OverbookTherapist);
    }
    if (breakPointNumber.length > 0) {
      isAuthorized = this.breakPoint.CheckForAccess(breakPointNumber);
    }
    return isAuthorized
  }

  //this func will return formated name of Client eg "John Dyer" for gived clientId.
  getClientFormatedName(id): string {
    for (let appointmentpopitem of this.appointmentpopservice.recordsArray) {
      if (id == appointmentpopitem.clientDetail.id) {
        return appointmentpopitem.clientDetail ? appointmentpopitem.clientDetail.firstName + ' ' + appointmentpopitem.clientDetail.lastName : ''
      }
    }
  }

  async ngOnInit() {
    this.isCancellationNoShowFeeEnabled = JSON.parse(sessionStorage.getItem('defaultSettings'))?.find(x => x.switch == CancellationNoShowFeeSwitch).value == 'true';
    this.appointmentservice.LoadTherapists();
    this.appointmentpopservice.GetEmailConfigurationSetting();
    window.onbeforeunload = (ev) => {
      this.ApptUtilities.deleteTempOnUnload();
      let dialogText = this.localization.captions.common.RefreshMessage;
      ev.returnValue = dialogText;
      return dialogText;
    };
    this.setGdprConfiguredFlag();
    this.appointmentpopservice.GetAllVipTypes();
    this.appointmentpopservice.selectedClientIndex = 0;
    this.datRec = this.data.datarecord;
    this.appointmentpopservice.selectedIndex = 0;
    this.linkMultiPackOpted = false;
    //this.Utilities.getConfiguration(this.successCallback.bind(this), this.errorCallback.bind(this), 'Appointment', 'APPOINTMENT_SHOW_RECAP');
    this.Utilities.GetAllSettingByModule(this.successCallback.bind(this), this.errorCallback.bind(this), 'Appointment');
    if (this.appointmentpopservice.add_client && this.data.action == 'Edit') {
      this.appointmentpopservice.clientEditData = this.data.clientInfo;
    }
    // Added to load data for Order Summary PatronID
    else if (this.appointmentpopservice.add_client && this.data.oderSummaryPlayerId) {
      this.appointmentpopservice.orderSummaryPatronId = this.data.oderSummaryPlayerId;
    }
    else {
      if (this.data && this.data.action == 'Edit') {
        this.appointmentpopservice.resultNewAppointment = this.data;
      }
      else if (this.data) {
        this.appointmentpopservice.resultNewAppointment = this.data;
        this.appointmentpopservice.clientEditData = null;
        if (!this.appointmentpopservice.add_client) {
          let pmsData = this.appointmentpopservice.getPMSDataForAction(PMSAction.Reservation);
          if (pmsData) {
            this.appointmentpopservice.setPMSGuestInfoForAppointment(pmsData.clientInfo);
          }
        }
      }

      if (this.data && this.data.linkedAppointmentID && this.data.linkedAppointmentID !== "") {
        this.appointmentpopservice.linkedAppointmentId = this.data.linkedAppointmentID;
      }
    }

    if (this.data && this.data.title) {
      this.appointmentpopservice.popupTitle = this.data.title;
    }
    else if (this.isEmbedded) {
      //this.appointmentpopservice.popupTitle = this.captions.NEWAPPOINTMENT;
      this.title = this.appointmentpopservice.popupTitle;
    }

    if (this.data.action == 'CreateClient') {
      this.appointmentpopservice.clientEditData.clientDetail.firstName = this.data.firstName;
    }
    this.appointmentpopservice.isExternalClient = (this.appointmentpopservice.clientEditData && this.appointmentpopservice.clientEditData.clientDetail.isExternalGuest) ?? false;
    this.appointmentpopservice.isExternalClientLinked = (this.appointmentpopservice.clientEditData && this.appointmentpopservice.clientEditData.clientDetail.isExternalGuestLinked) ?? false;
    this.setTitleForVcart();
    if (this.isEmbedded) {
      this._icartDataService.CartItinerarySelected.pipe(takeUntil(this.$destroyed)).
        subscribe(async x => {
          this.setTitleForVcart(x.confirmationId);
        });
      this._icartDataService.CartResult.pipe(takeUntil(this.$destroyed)).
        subscribe(async x => {
          try {
            if (x.result)
              this.Utilities.showCommonAlert(this.allCaptions.msg_AppointmentAddedToCart, AlertType.Success);
          }
          finally {
            this.Utilities.ToggleLoader(false);
          }
        });
    }

    this.saveBtnClicked.pipe(debounceTime(SEARCH_DEBOUNCE_TIME), takeUntil(this.$destroyed))
      .subscribe(() => {
        this.save()
      });

  }

  setTitleForVcart(cartConfirmationId: string = null) {
    if (this.isEmbedded) {
      if (cartConfirmationId)
        this.title = `${this.appointmentpopservice.popupTitle} - ${cartConfirmationId}`;
      else {
        var confirmationId = this.cartUtils.getActiveCartItinerary()?.confirmationId;
        if (confirmationId) {
          this.title = `${this.appointmentpopservice.popupTitle} - ${confirmationId}`;
        }
      }
    }
  }

  ClientAddedEvent(clientId) {
    if (clientId && clientId > 0) {
      this.getClientInformation(clientId);
      this.getRecentAppointments(clientId);
    }
  }

  getRecentAppointments(clientId: number) {
    this.http.CallApiWithCallback<any[]>({
      host: GlobalConst.Host.schedule,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'getClientRecentAppointmentInfo',
      uriParams: { clientID: clientId },
      method: HttpMethod.Get,
      showError: false,
      extraParams: []
    });
  }

  getClientInformation(clientId: number) {
    this.http.CallApiWithCallback<any[]>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'getClientInfo',
      uriParams: { id: clientId },
      method: HttpMethod.Get,
      showError: false,
      extraParams: []
    });
  }

  createRecentAppsObj(ServiceName: string, TherapistNames: string[], onDate: Date, Status: string): any {
    let dt = this.Utilities.getDate(onDate)
    let dtStr = this.localization.LocalizeShortDateTime(dt);
    let desc: string = ServiceName + ' ' + this.captions.by + ' ' + TherapistNames.toString() + ' ' + this.captions.on + ' ' + dtStr;
    let UiStatus: string;

    if (Status == 'RESV') {
      UiStatus = this.captions.Scheduled
    } else if (Status == 'CKIN') {
      UiStatus = this.captions.CheckedIn
    }
    else if (Status == 'CKOUT') {
      UiStatus = this.captions.CheckedOut
    }
    else if (Status == 'NOSHOW') {
      UiStatus = this.captions.NoShow
    }
    else if (Status == 'CANC') {
      UiStatus = this.captions.Cancelled
    }
    return {
      appointmentDetails: desc,
      action: UiStatus
    };
  }
  async checkClientMandatoryFields(): Promise<boolean> {
    let valid = true;
    if (this.appointmentpopservice.recordsArray && this.appointmentpopservice.recordsArray.length > 0) {
      await this.clientService.GetClientMandatoryandCustomFields();
      for (let recordItem of this.appointmentpopservice.recordsArray) {
        if (recordItem.clientDetail.clientType != GlobalConst.ClientType.Member) {
          valid = await this.clientService.MandatoryFieldsValidation(recordItem);
          if (!valid) {
            this.Utilities.ShowErrorMessage(this.captions.ErrorinCreatingAppointment, this.localization.replacePlaceholders(this.localization.getError(100005), ["clientName",], [recordItem.clientDetail.firstName.concat(' ', recordItem.clientDetail.lastName)]));
            return valid;
          }
        }
      }
    }
    return valid;
  }
  async OpenLostDeniedBusinessRecords(eve) {
    await this.lostDeniedBusinessPopupService.GetFilters();
    this.lostDeniedBusinessPopupService.InitializeData();
    if (!await this.SetLostDeniedBusiness(this.appointmentpopservice)) {
      return;
    }
    this.lostDeniedBusinessPopupService.selectedIndex = 0;
    this.lostDeniedBusinessPopupService.action = OperationType.Create;
    let dialogRef = this.dialog.open(AddLostDeniedBusinessPopupComponent, {
      width: '80%',
      height: '80%',
      hasBackdrop: true,
      disableClose: true,
      data: {
        headername: this.captions.pg_title_createLostAndFound,
        form: this.lostDeniedDatas,
        appointmentInfomation: this.lostDeniedDatas,
        setHeight: true,
        //currentdata: eve[0],
        //isEdit: true
        closebool: true
      }
    });
    // this.actionDialogSubscription = dialogRef.afterClosed().subscribe(result => {
    //   if (result && result == "SAVE")
    //     this.loadData();
    // });
  }
  async SetLostDeniedBusiness(appointmentPopupService: any): Promise<boolean> {
    if (!appointmentPopupService) {
      console.log('No data Found');
      return false;
    }
    appointmentPopupService.isfromAppoinment = true;
    let clientInfo: AppointmentLostDeniedBusinessClient[] = [];
    let therapistIds: number[] = [];
    appointmentPopupService.multiClientInfo.forEach(r => {
      clientInfo.push({
        id: r.id,
        LostDeniedBusinessId: 0,//r.lostDeniedBusinessId,
        clientId: r.id,
        therapistId: r.TherapistId,
        comments: r.comments,
        guestId: r.guestId
      });
    });
    appointmentPopupService.TempHoldArr[0].therapist.forEach(t => {
      therapistIds.push(t)
    });
    this.lostDeniedDatas = {
      id: 0,//this.appointmentpopservice.resultNewAppointment.id,
      startDTTM: this.appointmentpopservice.resultNewAppointment.dateField ? this.localization.AddTimeToDate(this.Utilities.getDate(this.appointmentpopservice.resultNewAppointment.dateField), moment(this.appointmentpopservice.resultNewAppointment.time, this.localization.timeFormat).toDate()) : "",//this.appointmentpopservice.resultNewAppointment.fromdate,
      startTime: this.appointmentpopservice.resultNewAppointment.time ? this.appointmentpopservice.resultNewAppointment.time : "",//this.Utilities.getDate(this.appointmentpopservice.resultNewAppointment.fromdate),
      EndDTTM: this.appointmentpopservice.resultNewAppointment.dateField ? this.localization.AddTimeToDate(this.Utilities.getDate(this.appointmentpopservice.resultNewAppointment.dateField), moment(this.appointmentpopservice.resultNewAppointment.time, this.localization.timeFormat).toDate()) : "",//appointmentPopupService.todate,
      endTime: this.appointmentpopservice.resultNewAppointment.time ? this.appointmentpopservice.resultNewAppointment.time : "",//this.Utilities.getDate(appointmentPopupService.todate),
      ServiceGroupId: this.appointmentpopservice.resultNewAppointment.ServiceGroupId ? this.appointmentpopservice.resultNewAppointment.ServiceGroupId : 0,
      ServiceId: this.appointmentpopservice.resultNewAppointment.service ? Number(this.appointmentpopservice.resultNewAppointment.service) : 0,
      lostDeniedReasonId: null,//appointmentPopupService.lostDeniedReasonId,
      //TherapistId: this.appointmentpopservice.resultNewAppointment.therapist?this.appointmentpopservice.resultNewAppointment.therapist:0,
      TherapistId: therapistIds,
      price: this.localization.currencyToSQLFormat(this.appointmentpopservice.resultNewAppointment.price),
      comments: this.appointmentpopservice.resultNewAppointment.comments ? this.appointmentpopservice.resultNewAppointment.comments : "",
      client: clientInfo,
      billingDetail: appointmentPopupService.guaranteeMethodData ? appointmentPopupService.guaranteeMethodData : null,
      waitListId: 0,
      isMultiTherapist: therapistIds.length ? true : false
      //appointmentPopupService.waitListId
    }
    this.lostDeniedBusinessPopupService.appointmentDetail = appointmentPopupService.guaranteeMethodData;
    // Setting client information
    var client: number[] = clientInfo.map(r => r.clientId);
    client = Array.from(new Set(client)); // Unique
    var guest: any[] = clientInfo.map(r => r.guestId);
    guest = Array.from(new Set(guest));
    //this.GetClientImage(guest);
    this.appointmentpopservice.resultExistingClients = client;
    this.appointmentpopservice.resultExistingAppointment = {};
    this.appointmentpopservice.multiClientInfo = [];
    for (let itemClient of clientInfo) {
      if (this.appointmentpopservice.multiClientInfo.some(t => t.id == itemClient.clientId)) { // Ensure duplicate client are not added
        continue;
      }
      this.appointmentpopservice.multiClientInfo.push({ id: itemClient.clientId, comments: itemClient.comments, TherapistId: itemClient.therapistId, priceTypeId: 0, price: 0, discountType: "" });
    }

    // Clientscreen Property's selected staff
    let selectedStaff: any[] = [];
    for (let clientInfoItem of clientInfo) {
      if (selectedStaff.some(r => r.id == clientInfoItem.therapistId)) {
        continue;
      }
      selectedStaff.push({
        id: clientInfoItem.therapistId,
        serviceId: appointmentPopupService.serviceId,
        therapistId: clientInfoItem.therapistId,
        therapistName: this.ApptUtilities.GetTherapistNameById(clientInfoItem.therapistId)
      });
    }
    this.appointmentpopservice.clientScreenProperties.selectedStaff = selectedStaff;
    this.appointmentpopservice.addwaitlist = true;
    return true;
  }

  private ResetAppointmentPopupService() {
    this.appointmentpopservice.resultNewAppointment = {};
    this.appointmentpopservice.SelectedClientId = null;
    this.appointmentpopservice.showDetails = false;
    this.appointmentpopservice.labelRecords = [];
    this.appointmentpopservice.clientsearchArray = [];
    this.appointmentpopservice.recordsArray = [];
    this.appointmentpopservice.isLinkedAppointments = false;
    this.appointmentpopservice.isLinkedAppointmentsLoaded = false;
    this.appointmentpopservice.linkedAppointments = [];
    this.appointmentpopservice.linkedAppointmentUpdated = [];
    this.appointmentpopservice.linkedAppointmentId = null;
    this.appointmentpopservice.clientBlockInfos = [];
  }

  private checkIfGuarenteeMethodIsAvailable(): boolean {
    return (this.appointmentpopservice.guaranteeMethodData && this.appointmentpopservice.guaranteeMethodData.guaranteePaymentMethod
      && this.appointmentpopservice.guaranteeMethodData.guaranteePaymentMethod.GuaranteeMethod) ? true : false;
  }
}
