// import { PrintType } from 'src/app/shared/shared-models';

import { PrintType } from "../../shared/shared.modal";

export class ReportAPIOptions {
    code: string;
    format?: ReportDownloadFormat = 'HTML';
    params: ReportParams[];
    URIParams: ReportParams[];
    filters: any;
    pageBreak: boolean;
    reportData?: string;
}


export type ReportDownloadFormat = 'PDF' | 'WORD' | 'EXCEL' | 'IMAGE' | 'HTML' | 'CSV' | 'RAWDATA';

export interface ReportParams {
    [parameter: string]: string | number | boolean;
}

export interface HTMLDomElement extends Element {
    name?: string;
  }

  export interface ReportAPIModel {
    code: string;
    format: ReportDownloadFormat;
    downloadFileName: string;
    parameters: { [key: string]: string };  //Dictionary<string,string>
    uRIParams: { [key: string]: string };   //Dictionary<string,string>
    filterBody: any;
    dateFormat: string;
    reportData?: string;
  }

  export class sendEmailRequest {
    RefId: number;
    LetterType: number;
    PrintType: PrintType;
    EmailTemplateId: number;
    SMSTemplateId: number;
    ContactPhone: string;
    ContactEmail: string;
    SenderEmail: string;
    FromName: string;
    MailBody: string;
    MailSubject: string;
    IsPrint: boolean;
    IsSendVersaEmail: boolean;
    IsSendADMEmail?: boolean;
    IsSendSMS: boolean;
    htmlContent: string;
    folioId?: string;
    ADMEventType?: string;
  }

  export enum NotifyTemplateType 
  {
    Email = 1,
    SMS
  }

  export enum LettterSetup {
    FolioStatement = 1
  }

  export interface PrintCardResponse {
    fileType: number,
    name: string,
    value: string;
    action: number,
    isEmailSent: boolean,
    errorCode: number
  
  }