import { Host } from '../../shared/globalsContant';



// TO DO Need to remove( dependency in translog report )
export interface ReportOptions {
  code: string;
  params: ReportParams[];
  URIParams: ReportParams[];
  Filter: ReportFilterParams[];
  filters?: any;
  pageBreak: boolean;
  layout: string;
  language: string;
}

export class ReportAPIOptions {
  code: string;
  format?: ReportDownloadFormat = 'HTML';
  params: ReportParams[];
  URIParams: ReportParams[];
  templateParams?:TemplateParams[];
  filters: any;
  pageBreak: boolean;
}


/**
 * API Model for Report microservice request PUT call.
 */
export interface ReportAPIModel {
  code: string;
  format: ReportDownloadFormat;
  downloadFileName: string;
  parameters: { [key: string]: string };  //Dictionary<string,string>
  uRIParams: { [key: string]: string };   //Dictionary<string,string>
  filterBody: any;
  dateFormat: string;
}

export interface TemplateReportAPIModel {
  code: string;
  format: ReportDownloadFormat;
  downloadFileName: string;
  parameters: { [key: string]: string };  //Dictionary<string,string>
  uRIParams: { [key: string]: string };   //Dictionary<string,string>
  templateParams:{[key: string]: Uint8Array}  //Dictionary<string,Uint8Array>
  filterBody: any;
  dateFormat: string;
}

export type ReportDownloadFormat = 'PDF' | 'WORD' | 'EXCEL' | 'IMAGE' | 'HTML'|'CSV' | 'RAWDATA';


/**
 * Report UI Control Configuration Model
 */
export interface ReportUIConfig {
  dropDownFilterName?: string;
  dropDownStatusFilter?: string;
  startDatePicker: boolean;
  startDateCaption?: string;
  endDatePicker: boolean;
  endDateCaption?: string;
  dropDownFilters?: Promise<DropDownData[]>;
  dropDownStatusFilterData?: DropDownData[];
  inActiveToggle: boolean;
  inActiveToggleName?: '';
  pageBreakToggle: boolean;
  timePicker?: boolean;
  layout: 'PORTRAIT' | 'LANDSCAPE';
  allowFutureDate?: boolean;
  preventHistoricDate?: boolean;
  disableGenerateButton?: boolean;
  excludeGratuity?: boolean;
  excludeServiceCharge?: boolean;
  statusToggle?: boolean;
}

export const enum SPAReports {
  report1 = 'AppointmentListingByLocation',
  report2 = 2,
  report3 = 5
}

export enum ReportTab {
  Appointment = 0,
  Retail = 1,
  Client = 2,
  CommissionGratuityServiceCharge = 3,
  TransactionLog = 4,
  GiftCards = 5,
  Security =6
}

export enum AllReports {
  AppointmentListingByLocation = 'AppointmentByLocation',
  AppointmentListing = 'AppointmentListing',
  PackageSoldReport = 'PackageSoldReport',
  AppointmentByStaffMember = 'AppointmentByStaffMember',
  AppointmentByStaffPrevNext = 'AppointmentByStaffPrevNext',
  AppointmentByLinkCode = 'AppointmentByLinkCode',
  Itinerary = 'Itinerary',
  CommissionReport = 'CommissionReport',
  Gratuity = 'Gratuity',
  CustomField1 = 'CustomField1',
  CustomField2 = 'CustomField2',
  CustomField3 = 'CustomField3',
  CustomField4 = 'CustomField4',
  CustomField5 = 'CustomField5',
  Cancellation = 'Cancellation',
  NoShowAppointment = 'NoShowAppointment',
  ServiceCharge = 'ServiceCharge',
  StaffMemberSummary = 'StaffMemberSummary',
  MultiPackItems = 'MultiPackItems',
  ReturnedItems = 'ReturnedItems',
  InventorySummary = 'InventorySummary',
  InventoryDetails = 'InventoryDetails',
  InventoryTransfer = 'InventoryTransfer',
  InventoryWash = 'InventoryWash',
  InventoryAudit = 'InventoryAudit',
  SalesByDiscountTypes = 'SalesByDiscountTypes',
  SalesBySubCategory = 'SalesBySubCategory',
  SalesByCategory = 'SalesByCategory',
  SaleByItem = 'SaleByItem',
  CorrectVoid = 'CorrectVoid',
  Shift = 'Shift',
  RevenueSummary = 'RevenueSummary',
  Transaction = 'Transaction',
  Receipt = 'Receipt',
  StaffSchedule = 'StaffSchedule',
  GuestAppointments = "GuestAppointments",
  ProjectedRevenue = "ProjectedRevenue",
  Utilization = "Utilization",
  ClientListing = 'ClientListing',
  BatchClientItinerary = 'BatchClientItinerary',
  AppointmentByUser = 'AppointmentByUser',
  OnlineAppointment = "OnlineAppointment",
  UserAccessReport="UserAccessReport",
  GuestItinerary ="GuestItinerary",
  GroupItinerary = "GroupItinerary",
  MemberServiceAvailed = "MemberServiceAvailed",
  AppointmentDeposit="AppointmentDeposit",
  LostDeniedBusinessReport = "LostDeniedBusiness",
  DayPassReport="DayPassReport",
  ClassClientListing="ClassClientListing",
  ClassClientNoShow= "ClassClientNoShow",
  ClassClientCancellation= "ClassClientCancellation",
  LockerUtilization ="LockerUtilization"
}

export interface ReportParams {
  [parameter: string]: string | number | boolean;
}

export interface TemplateParams {
  [parameter: string]: Uint8Array;
}

export interface ReportFilterParams {
  [Filter: string]: number[];
}

export interface ReportDropDown {
  id: number | string;
  viewValue: string;
  callDesc: string;
  IsAuthorized: boolean;
  breakPointNumber?: number;
}

export interface HTMLDomElement extends Element {
  name?: string;
}

export interface ReportControls {
  code: string;
  reportConfig: ReportControlConfig;
  params?: ReportOptions;
}


export interface ReportControlConfig {
  startDatePicker: boolean;
  endDatePicker: boolean;
  dropDownFilters?: ReportFilter[];
  inActiveToggle: boolean;
  inActiveToggleName?: string;
  pageBreakToggle: boolean;
  allowFutureDate?: boolean;
  layout: 'Portrait' | 'Landscape';
  startDateCaption?: string;
  endDateCaption?: string;
}

export interface ReportFilter {
  callDesc: string;
  name: string;
  host: Host;
}

export interface MultiPackItemFilterData {
  StartDate: string;
  EndDate: string;
  MultiPackIds: number[];
  IsAllMultiPacks: boolean;
  IsExpirationDateRange: boolean;
  IsSoldDate: boolean;
  IsAllByGuests: boolean;
  IsOutstandingOnly: boolean;
}

export interface CorrectVoidFilterData {
  StartDate: string;
  EndDate: string;
  OutletIds: number[];
  UserIds: number[];
}

export interface RevenueSummaryFilterData {
  StartDate: string;
  EndDate: string;
  OutletIds: number[];
  UserIds: number[];
}

export interface DropDownData {
  id: number;
  code?: string;
  description: string;
  isActive?: boolean;
  showInDropDown?: boolean;
  staffType?: string
}

export interface StaffFilter {
  id: number;
  code?: string;
  description: string;
  isActive?: boolean;
  showInDropDown?: boolean;
  staffType?: string
}

export interface StaffFilterData {
  staffId: number;
  staffType?: string
}

export interface ReportSelector {
  id: number;
  group: string;
  code: string;
  value: string;
  breakPointNumber: number;
  IsAuthorized: boolean;
  enableJasperReports?:boolean;
  reportUrlpath?:string;
}

export interface AllCustomFields {
  id: number;
  columnName: string;
  fieldName: string;
  moveRecordFromClient: boolean;
  requiredOnAppointment: boolean;
  displayOn: number;
  customFieldValues: any[];
  propertyId: number;
  subPropertyId: number;
}

export interface TherapistData {
  code: string;
  firstName: string;
  lastName: string;
  id: number;
  isActive: boolean;
  allowCommission: boolean;
  allowServiceCharge: boolean;
  allowGratuity: boolean;

}



export interface CustomFields {
  id: number;
  columnName: string;
  fieldName: string;
  moveRecordFromClient: boolean;
  requiredOnAppointment: boolean;
  displayOn: number;
  customFieldValues: CustomFieldValue[];
  propertyId: number;
  subPropertyId: number;

}

export interface CustomFieldValue {
  id: number;
  customFieldId: number;
  code: string;
  description: string;
  isUtilized: boolean;
  propertyId: number;
  subPropertyId: number;
  showInDropDown: boolean;
}

export interface ClientDetailForReport {
  clientDetail: ClientDetail;
  id: number;
  requestUid: string;
}

export interface ClientDetail {
  id: number;
  guestId: string;
  title: string;
  firstName: string;
  lastName: string;
}

export interface clientAutoComplete {
  name: string;
  id: number;
}



export interface LinkCodes {
  id: number;
  code: string;
  description: string;
  listOrder: number;
  color: string;
  startDate: string;
  endDate: string;
  status: string;
  propertyId: number;
  subPropertyId: number;
}

export interface Users {
  tenantId: 1;
  userId: 1;
  userName: string;
  firstName: string;
  lastName: string;
  password: null;
  isActive: boolean;
}

export interface TranslogType {
  id: number;
  code: string;
  description: string;
  logtype: string;
}

export interface InventoryDetailFilterData {
  categoryIds: number[];
  subCategory1Ids: number[];
  subCategory2Ids: number[];
  subCategory3Ids: number[];
  subCategory4Ids: number[];
  subCategory5Ids: number[];
}

export interface InventoryTransferFilterData {
  CategoryIds: number[];
  SubCategory1Ids: number[];
  SubCategory2Ids: number[];
  SubCategory3Ids: number[];
  subCategory4Ids: number[];
  SubCategory5Ids: number[];
  OutletIds: number[]
  Type: number[]
}


export interface MultiPackTypes {
  id: number;
  itemDescription: string;
}

export interface InventoryAuditFilter {
  startDate?: string;
  endDate?: string;
  outlets: number[];
  category: number[];
  subCategory1: number[];
  subCategory2: number[];
  subCategory3: number[];
  subCategory4: number[];
  subCategory5: number[];
  includeinactiveitems: boolean;
  includenoninventoryitems: boolean;
  searchbyitem: string;
  searchbydesc: string;
  searchbysupplier: string;
  searchbybarcode: string;
  searchbyotherscannercode: string;
  searchbysupplieritem: number;
  purchaseorder: number;
}

export interface OutletAndCategoryFilter {
  startDate?: string;
  endDate?: string;
  OutletIds: number[];
  CategoryIds: number[];
  SubCategory1Ids: number[];
  SubCategory2Ids: number[];
  SubCategory3Ids: number[];
  SubCategory4Ids: number[];
  SubCategory5Ids: number[];
  DiscountType: number[];
  SearchbyItem: number[];
  ClerkIds: number[];
  SelectedItemIds: number[];
  FilterItems: boolean;
  ReportCode: string;
}

export interface InventoryWashFilterData {
  CategoryIds: number[];
  SubCategory1Ids: number[];
  SubCategory2Ids: number[];
  SubCategory3Ids: number[];
  subCategory4Ids: number[];
  SubCategory5Ids: number[];
  OutletIds: number[]
}

export interface StaffScheduleBody {
  therapistids: number[];
  filterid: number;
}

export interface ProjectedRevenueFilter
{
  therapistids: number[];
  serviceIds: number[];
  serviceGroupIds: number[];
}

export interface UtilizationBody {
  ReportType: any;
  IncludeRetailRevenue: any;
  IncludeInactivestaff: any;
  IncludeInactiveLocation: any;
  ScheduleOnly: any;
  StartDate: any;
  EndDate: any;
  fromWildCard?:any;
  toWildCard?:any;
  fromIncrement?:any;
  toIncrement?:any;
}

export interface ClientListingFilterData
{
  StartDate : string;
  EndDate : string;
  ReportType : number;
  ExcludeWithoutPhoneNumbers: boolean;
  ExcludeWithoutEmails: boolean;
  PhoneNumberFormat: string;
  ExtensionCaption : string;
  AppendReportCaption : string;
  DateFormat : string;
}

export interface PreSettlementReceiptFilter{
  AppointmentAddonIds : AppointmentAddonIds[];
  OutletId : number;
  TerminalId : number;
}

export interface AppointmentAddonIds{
  AppointmentId : number;
  AddOnIds : number[];
  IsMultiPack : boolean;
}

export interface CancelReasons{
  id: number;
  reasonCode: string;
}
export class JasperReportDetails{
  reportCode : string;
  isJaspersoftReport: boolean;
  reportUrlPath: string;
  reportUrlParameters? : string;
}