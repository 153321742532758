import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from "./http-call.service";
import { Host, PackageRetailItemType } from "../globalsContant";
import { BaseResponse, DepositAPIModel, AppointmentCancel, DayPassModel, TransactionDetail, ClassDepositAPIModel, ClassClientCancel } from "../business/shared.modals";
import { AppointmentRetailItem } from "src/app/appointment/slide-information/slide-information.component";
import { RetailShopItem } from "src/app/retail/shop/shop.modals";
import { CancellationNoShowChargeEventModel, IClassCheckOutEventModel, UpdateCancellationNoShowChargeEventModel } from "src/app/retail/shared/events/event.model";
import { SpaPackageItemType } from "src/app/retail/payment/PaymentFlowHelpers";
import { appointmentService } from "./appointment.service";

@Injectable({
  providedIn: "root"
})
export class RetailEventHandler {
  constructor(private http: HttpServiceCall, private apptService: appointmentService) {
  }

  private async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async UpdateAppointmentTransaction(oldTransactionId: number, newTransactionId: number, transactionDetail: TransactionDetail[],returnData: RetailShopItem[]) {
    await this.UpdateAppointmentsWithCommonTransactionId(oldTransactionId, newTransactionId, transactionDetail,returnData);
  }

  public async UpdateAppointmentsWithCommonTransactionId(oldTransactionId: number, newTransactionId: number, transactionDetail: TransactionDetail[],returnData: RetailShopItem[]) {
    const transDetailsCopy = [...transactionDetail];
    const appointments = await this.InvokeServiceCallAsync('GetAppointmentDetailsByTransactionIds', Host.schedule, HttpMethod.Put, null, [oldTransactionId]);
    if (
      appointments.successStatus &&
      appointments.result &&
      appointments.result.length > 0
    ) {
      let requestContent:any[]=[];
      await Promise.all(appointments.result.forEach(async (appointment,index) => {
        const service = await this.InvokeServiceCallAsync(
          "GetSpecificService",
          Host.spaManagement,
          HttpMethod.Get,
          { id: appointment.serviceId }
        );
        if (service.successStatus && service.result && service.result.id > 0) {
          let newTransactionDetailId = appointment.transactionDetailId; // default transdetail id
          const detailIndex = transDetailsCopy.findIndex(
            (t) => t.itemId == service.result.serviceDetail.retailItemId
          );
          if (detailIndex !== -1) {
            const newTransactionDetail = transDetailsCopy[detailIndex];
            if (newTransactionDetail) {
              newTransactionDetailId = newTransactionDetail.id;
              transDetailsCopy.splice(detailIndex, 1);
            }
          }
          requestContent.push({
            appointmentId: appointment.id,
            transactionId: newTransactionId,
            transactionDetailId: newTransactionDetailId,
          });          
        }
        if (index === appointments.result.length - 1){
          if (requestContent?.length > 0) {
            await this.InvokeServiceCallAsync(
              "UpdateAppointmentTransactionId",
              Host.schedule,
              HttpMethod.Put,
              null,
              requestContent
            );
          }
        }
      }));
    }

    if(returnData && returnData.length >0){
      var updateCustomFee:any[]=[];

      returnData.forEach(element => {
        if(element.customFee){
          updateCustomFee.push(
            {
               customFeeId: element.customFee.customFeeId,
               appointmentId : element.sourceTypeId,
               returnedAmount : element.customFee.value,
               transactionId: element.id 
            }
          );
        }

      });

      await this.InvokeServiceCallAsync('UpdateReturnTransactionCustomFee', Host.schedule, HttpMethod.Put,null, updateCustomFee);
      if (returnData.some(t => SpaPackageItemType.includes(Number(t.sourceType)))) 
        {
          var dayPassId=returnData.find(x=>x.sourceType==PackageRetailItemType)?.sourceTypeId
          if(dayPassId>0)
          this.UpdateIsActiveForDayPass(dayPassId);
        }
    }
  }

  public async AddDeposit(deposit: DepositAPIModel[]) {
    this.InvokeServiceCallAsync('UpdateAppointmentDeposit', Host.schedule, HttpMethod.Put, { transactionId: deposit[0].depositTransactionId }, deposit);
  }

  public async AddClassClientDeposit(deposit: ClassDepositAPIModel[]) {
    this.InvokeServiceCallAsync('UpdateClassClientDeposit', Host.schedule, HttpMethod.Post, { transactionId: deposit[0].depositTransactionId }, deposit);
  }

  public async CreateUpdateDeleteAppointmentRetailItem(appointmentRetailItem: AppointmentRetailItem){
    this.InvokeServiceCallAsync('CreateUpdateDeleteAppointmentRetailItem', Host.schedule, HttpMethod.Put, null, appointmentRetailItem);
  }

  public async UpdateTransactionForDayPass(dayPassModel: DayPassModel){
    this.InvokeServiceCallAsync('UpdateTransactionForDayPass', Host.schedule, HttpMethod.Put, null, dayPassModel);
  }

  public async RevertDeposit(transactionId: number) {
  this.InvokeServiceCallAsync('RevertDeposit', Host.schedule, HttpMethod.Put, { transactionId: transactionId });
  }

  public async RevertClassDeposit(transactionId: number) {
    this.InvokeServiceCallAsync('RevertClassDeposit', Host.schedule, HttpMethod.Put, { transactionId: transactionId });
  }

  public async CancelAppointmentDeposit(transactionId: number, appointments: AppointmentCancel[]) {
    this.InvokeServiceCallAsync('CancelDepositAppointment', Host.schedule, HttpMethod.Put, { transactionId: transactionId, canPerformCancel: appointments[0].canPerformCancel}, appointments);
  }
  public async UpdateDayPassIdInAppointment( appointments: AppointmentCancel[]) {
    this.InvokeServiceCallAsync('UpdateDayPassIdInAppointment', Host.schedule, HttpMethod.Put, null, appointments);
  }

  public async CancelClientDeposit(transactionId: number, classDepositCancel: ClassClientCancel) {
    this.InvokeServiceCallAsync('CancelClientDeposit', Host.schedule, HttpMethod.Put, { transactionId: transactionId}, classDepositCancel);
  }

  public async updateAppointmentWithcharge(appointments: CancellationNoShowChargeEventModel[]) {
    this.InvokeServiceCallAsync('UpdateAppointmentWithcharge', Host.schedule, HttpMethod.Put, null, appointments);
  }

  public async updateCancellationNoShowFeeWaiveOff(appointmentId: number){
    this.InvokeServiceCallAsync('WaiveOffCancellationOrNoShowFee', Host.schedule, HttpMethod.Put, {appointmentId: appointmentId})
  }

  private async UpdateIsActiveForDayPass(dayPassId: number) {
    this.InvokeServiceCallAsync('UpdateIsActiveForDayPass', Host.schedule, HttpMethod.Put, { id: dayPassId })
  }

  public async UndoAppointment(transactionId: number): Promise<any> {
    try {
      return await this.http.CallApiAsync<any>({
        host: Host.schedule,
        callDesc: "UndoCheckOutAppointmentByTransactionId",
        method: HttpMethod.Put,
        uriParams: { transactionId: transactionId }
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async UndoClassClientAppointment(transactionId: number): Promise<any> {
    try {
      return await this.http.CallApiAsync<any>({
        host: Host.schedule,
        callDesc: "UndoCheckOutClassClientByTransactionId",
        method: HttpMethod.Put,
        uriParams: { transactionId: transactionId }
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async handleClassCheckOut(checkOutRequest:IClassCheckOutEventModel[]){
    this.InvokeServiceCallAsync('checkOutClassClients', Host.schedule, HttpMethod.Put, null, checkOutRequest);
  }

  public async updateCancellationNoShowCharge(charge: UpdateCancellationNoShowChargeEventModel){
    this.InvokeServiceCallAsync('UpdateCancellationNoShowCharge', Host.schedule, HttpMethod.Put, null, charge);
  }

  public async UpdateAppointmentAfterCorrection(newTransactionId: number, transactionDetail: TransactionDetail[], ticketNumber: string) {
    const checkoutApptBody = this.apptService.buildCheckoutAppointmentBody(transactionDetail, newTransactionId, ticketNumber);
    
    if(checkoutApptBody?.length == 0)
      return;

    this.InvokeServiceCallAsync('CheckOutAppointment', Host.schedule, HttpMethod.Put, null, checkoutApptBody);
  }
}
