

export enum MsGraphRoutes {
  Me = '/me',
  MeMessages = '/me/messages',
  MeEvents = '/me/events',
  MeTasks = '/me/outlook/tasks',
  MeContacts = '/me/contacts?$filter=createdDateTime ge {lastSyncTime} or lastModifiedDateTime ge {lastSyncTime} &$top=100',

  MeDeltaContacts = '/me/contacts/delta',
  MeUpdateContacts = '/me/contacts',
  MeSendMail = '/me/sendMail',
  Batch = '/$batch'
}

export enum DataMigrationServiceApiRoutes{
  getAllDataUtilityModules='DataUtilityDetails/GetAllDataUtilityModules',
  getImportDataUtilitytModules ='DataUtilityDetails/getImportDataUtilitytModules',
  getAllDataUtilityTables='DataUtilityDetails/GetDataUtilityTables/{serviceName}',
  exportExcel='DataUtilityDetails/Export/{serviceName}?isCreate={isCreate}',
  downloadExcel='DataUtilityDetails/Download/{dataUtilityId}',
  postDataUtilityDetails='DataUtilityDetails/ImportExcelData',
  getAllExportDataUtilityDetails='DataUtilityDetails/DataUtilityExportDetails/{serviceName}',
  getAllImportDataUtilityDetails='DataUtilityDetails/DataUtilityImportDetails/{serviceName}',
  getDataUtilityDetailsByScreen='DataUtilityDetails/DataUtilityDetailsByScreen/{serviceName}/{screenName}/{fromScreen}', 
  processDataMigrationStatistics='DataUtilityDetails/ProcessDataUtilityStatistics',
  downloadExcelForDataMigrationStatistics='DataUtilityDetails/DownloadDataMigrationStatisticsExcelFile/{statisticalDetailsId}/{downloadFileType}',
  downloadErrorRowsExcelData ='DataUtilityDetails/downloadErrorRowsExcelfile/{dataUtilityId}',
  getAllDataUtilityErrors='DataUtilityDetails/DataUtilityErrors/{dataUtilityId}',
  getBulkImportDataUtilityEntityNames ='DataUtilityDetails/GetBulkImportDataUtilityEntityNames/{isFastTrack}',
  queueDataFiles ='DataUtilityDetails/QueueDataFiles/{isFastTrack}/{isOverWrite}',
  getAllBulkImportFiles = 'DataUtilityDetails/GetAllBulkImportFiles/{includeInactive}',
  getLoggedInPropertyQueueProgressCount = 'DataUtilityDetails/GetLoggedInPropertyQueueProgressCount',
  deleteQueueBulkImportItem = 'DataUtilityDetails/DeleteQueueBulkImportItem/{id}',
  reQueueBulkImportDetails = 'DataUtilityDetails/ReQueueBulkImportDetails/{id}',
  getAllDataMigrationConfiguration = 'DataUtilityDetails/DataMigrationConfiguration/{settingType}',
  createDataMigrationConfiguration = 'DataUtilityDetails/CreateDataMigrationConfiguration'
}

export enum V1CartServiceApiRoutes {
    CompleteBooking = 'CartService/Cart/completeBooking?cartItineraryId={cartItineraryId}',
    GetItinerariesByUserId = 'CartService/Cart/userId/{userId}',
    CreateItinerary = 'CartService/Cart',
    UpdateItinerary = 'CartService/Cart',
    UpdateCartDetail = 'CartService/Cart/cartDetail',
    AddToCartItinerary = 'CartService/Cart/cartDetail',
    DeleteCartItineraryById = 'CartService/Cart/itinerary/{id}',
    GetCartItinerary = 'CartService/Cart/CartItinerary/{CartItineraryId}',
    GetCartItineraryByWildCard = 'CartService/Cart/searchValue',
    DeleteItinerariesByUserId = 'CartService/Cart/userId',
    TransferCartByUserId = 'CartService/Cart/transfer/{userId}/{fromUserId}/{fromUserName}',
    DeleteCartDetailById = 'CartService/Cart/detail/{id}',
    RemoveCartItineraryById = 'CartService/Cart/remove/{CartItineraryId}/{releaseItinerary}',
    HoldItineraryByById = 'CartService/Cart/hold?cartItineraryId={cartItineraryId}&holdMinutes={holdMinutes}',
    SendPaymentForAuthorize = 'CartService/Cart/authorize?cartItineraryId={cartItineraryId}&emailId={emailId}',
    MoveCartDetail = 'CartService/Cart/move/{cartDetailId}/{sourceCartItineraryId}/{destinationCartItineraryId}',
    UpdateCartComments = 'CartService/Cart/comments/{cartItineraryId}',
    SetActiveCardOnFile = 'CartService/Cart/setCardOnFile/cartItineraryId/{cartItineraryId}/authId{authId}',
    AddCardToCart = 'CartService/Cart/cartItineraryId/{cartItineraryId}/cardInfo',
    GetAllItineraries = 'CartService/Cart/itineraries',
  }

export enum CommonGuestProfileServiceRoutes {
    SearchGuestBySearchKeywithFilter = "GuestProfileService/tenant/{tenantId}/trialsearch",
    GetGuestByGuestId = "GuestProfileService/tenant/{tenantId}/guest/{id}"
}

export enum PropertyApiRoutes {
  changePropertyNewTab = '{hostName}/setProperty?token={token}&propertyId={propertyId}&propertyName={propertyName}&routeParam={routeParam}',
  changePropertySameTab = '/setProperty',
    GetAllLanguages = 'language/GetAllLanguages',
    GetVCartConfiguration = 'VCartConfiguration/GetVCartConfiguration/{tenantId}',
  GetTenantByTenantId = 'tenantOrganization/GetTenantByTenantId/{tenantId}',
  GetProperty = 'property/getPropertyInfoByPropertyId/{id}',
  UpdateProperty = 'property/UpdatePropertyInfo',
  SetProperty = 'setProperty?token={token}&propertyId={propertyId}',
  // Property
  GetLoginToken = 'property/GetLoginToken/{sessionId}/{propertyId}/{productId}',
  GetPropertiesByUserId = 'property/GetPropertiesByUserId/{UserId}',
  GetPropertiesInformationByUserIdAndProductId = 'property/GetPropertiesByUserIdAndProductId/userId/{userId}/productId/{productId}',
  GetLoginDetailsByToken = 'property/GetLoginDetailsByToken',
  UpdatePropertySetting = 'property/PatchUpdatePropertySetting/{PropertyId}',
  UpdatePropertySettingDateforaccounting = 'PropertySetting/UpdatePropertySetting/{PropertyId}/{Propertydate}',
  UpdatePropertySettingDateByProductId = 'PropertySetting/UpdateProductPropertySetting/{PropertyId}/{ProductId}/{Propertydate}',
  SavePropertyConfiguration = "PropertyConfiguration",
  GetAllPropertyConfigurationSettings = 'propertyConfiguration/configurationName/{configurationName}/propertyId/{propertyId}/productId/{productId}',
  GetJaspersoftServerUri = "User/SetRoleToUserForJasper",
  UpdateRoleAndAttributesToUser = "ReportingService/JasperSynchronise/UpdateRoleAndAttributesToUser",
  GetAllPropertyConfigurationsByUserId = 'propertyConfiguration/configurationName/{configurationName}/userId/{userId}',
  GetAllPropertySettings = 'property/GetPropertySetting/{propertyId}',
  GetIsADMEnabled = 'property/ADM/Enabled/{propertyId}',
  GetPaymentConfigurationByProperty = 'Payment/GetPaymentConfigurationByProperty/{propertyId}',
  GetSupportedPMAgentVersionByProperty = 'Payment/GetPMAgentVersion/{propertyId}',
  GetDefaultUserConfiguration = 'UserSessionConfiguration/user/{userId}',
  GetWebCommunicationProxyVersion = 'Payment/GetWebCommunicationProxyVersion',
  GetADB2CAuthConfig = 'Adb2CAuthentication/GetADB2CAuthConfigByProductId/{tenantId}/{productId}',
  AddJwtForJasper = 'APIConnector/AddJwtForJasper',
  // Revenue setup
  GetAllOutlets = 'Outlets',
  // Machine Info
  GetMachineNamesByPropertyId = "MachineName/property/{propertyId}",
  GetMachineNamesConfigurationInfo = "MachineName/GetMachineNames/ConfigInfo/{productId}/{userId}",
  GetDefaultOutlet = 'RetailManagementService/Outlets/GetDefaultOutlet',
  OutletWithId = 'RetailManagementService/outlets/{id}',
  SyncDefaultOutlet = 'RetailManagementService/Outlets/SyncDefaultOutlet',
  CreateUserOutletAccess = 'RetailManagementService/UserOutlets/CheckAndUpdateUserAccess/outlet/{outletId}/user/{userId}',
  CreateDefaultTenantUserConfiguration = "UserConfiguration",
  UpdateDefaultTenantUserConfiguration = "UserConfiguration",
  GetDefaultTenantUserConfiguration = "UserConfiguration/configurationName/{configurationName}/{propertyId}/{productId}/{userId}",
  GetPropertyFeatures = 'PropertyFeature/GetPropertyFeatures/property/{propertyId}/product/{productId}',
  GetProductApplicationType = 'PropertyFeature/GetProductApplicationType',
  GetPropertyFeatureConfiguration = 'FeaturesConfiguration/GetFeaturesConfigurations/{PropertyFeatureId}',
  GetOutletsCCTerminalAggregate = 'CreditCardTerminals/outlet-creditcard-info/{propertyId}',

  //Misc
  GetMiscConfigurationByPropertyId = "RetailManagementService/settings/AllSetting/{propertyId}/Misc"

}

export enum CustomizableDashboardAPIRoutes{
  GetCustomizableDashboardDetailsByUserIdPropertyId = 'CustomizableDashboardDetails/GetCustomizableDashboardDetailsByUserIdPropertyId/{userId}/{propertyId}/{productId}',
  GetCustomizableDashboardDetailsByPropertyId = 'CustomizableDashboardDetails/GetCustomizableDashboardDetailsByPropertyId/{propertyId}/{productId}',
  UpdateCustomizableDashboardDetails = 'CustomizableDashboardDetails'
}

export enum FeatureApiRoutes {
  IsFeatureEnabled = 'feature/IsFeatureEnabled/{featureName}'
}

export enum GoogleApiRoutes {
  MapApi = 'https://maps.google.com/maps/api/js?sensor=true&key={apiKey}&libraries=places&language={language}'
}


export enum CommonApiRoutes {
  GatewayConfiguration = 'GatewayConfiguration',
  //Retail - AR Account Lookup
  ARAccountLookup = 'AccountsReceivable/Lookup/ByName/{byname}',
  ValidatePay = 'Payment/ValidatePay/outletId/{outletId}',
  SaleByToken = 'Payment/SaleByToken/outletId/{outletId}',
  Settlement = 'Payment/Settlement/outletId/{outletId}',
  VoidAuth = 'Payment/VoidAuth/outletId/{outletId}',
  AuthByToken = 'Payment/AuthByToken/outletId/{outletId}',
  CreditByToken = 'Payment/CreditByToken/outletId/{outletId}',
  ValidateAuth = 'Payment/ValidateAuth/outletId/{outletId}',
  //PayAgent
  GetHandles = 'GetHandles',
  CreateToken = 'CreateToken',
  Sale = 'sale',
  Auth = 'auth',
  Credit = 'Credit',
  GetUserSessionConfiguration = 'UserSessionConfiguration/user/{userId}',
  GetCEDSByOutletId = 'Outlets/GetCEDSByOutletId/{outletId}',
  VoidSale = 'Payment/VoidSale',
  //CreditCardConfiguration
  GetCCConfiguration = 'Setting',
  UpdateCCConfiguration = 'Setting',
  GetCCConfigurationByScreen = 'Setting/{module}/{screen}',
  PaymentMethodByProduct = 'GetPaymentMethodsByProductId',
  //Get CEDS
  GetCEDSByTerminalId = "Payment/GetCEDSByTerminalId/{terminalId}",
  //Payment
  StoreToken = 'Payment/StoreToken',
  StoreTokenRF = 'AccountingService/Payment/StoreToken',
  GetCardInfo = 'Payment/GetCardInfoByTransaction/{tokenTransId}',
  GetOutletsCCTerminalAggregate = 'CreditCardTerminals/outlet-creditcard-info/{propertyId}',
  GetAuthDetails = 'Payment/GetAuthDetails/authDetails',
  GetAuthDetailsbyDateRange = 'Payment/GetAuthDetails/authDetailsbyDateRange',
  GetAuthDetailsbyStayIds = 'Payment/GetAuthDetailsBySourceTypeIds/{sourceType}',
  GetDeferredTransactionsbyStayIds = 'Payment/GetDeferredTransactionBySourceTypeIds/{sourceType}',
  GetAuthDetailsByPaymentTransactionIds = 'Payment/GetAuthDetailsByPaymentTransactionIds',
  GetCardInfosByTransactionIds = 'Payment/GetCardInfosByTransactionIds',
  RedeemVoucher = "Payment/RedeemVoucher/{patronId}",
  GetAcesToken = "MemberShip/GetAcesToken",

  //Payment API 2.0 Routes
  BuildRGuestPayRequest = "PaymentService/V2/Payment/BuildRGuestPayRequest",
  ValidatePayV2 = "PaymentService/V2/Payment/ValidatePay/outletId/{outletId}",
  ValidateAuthV2 = "PaymentService/V2/Payment/ValidateAuth/outletId/{outletId}",
  RetailStoreTokenV2 = "PaymentService/V2/Payment/StoreToken",
  FolioStoreTokenV2 = "FolioService/V2/Payment/StoreToken",
  FolioValidateAuthV2 = "FolioService/V2/Payment/ValidateAuth/outletId/{outletId}",
  FolioValidatePayV2 = "FolioService/V2/Payment/ValidatePay/outletId/{outletId}",

  //ProxyCall
  ProxyCallToRGuestPay = "PaymentService/V2/Payment/ProxyCallToRGuestPay?ProxyPaymentMethodId={proxyPaymentMethod}",

  //PassCode
  GetAllSystemPasscode = 'SystemPasscodes',
  GetSystemPasscodeByPasscodeTag = 'SystemPasscodes/GetSystemPasscodeByPasscodeTag/{passcodeTag}',
  UpdateSystemPasscodes = 'SystemPasscodes/UpdateSystemPasscodes',
  CreateSystemPassCodeAudit = 'SystemPasscodes/CreateSystemPassCodeAudit',
  ValidateSystemPasscode = 'SystemPasscodes/ValidateSystemPasscode',
  GetSystemPasscodeStatus = 'SystemPasscodes/GetSystemPasscodeStatus',
  GetEncryptKey = 'Login/encValue',
  VerifyEncryptedPassword = 'User/VerifyPassword',

  //Accounting--AccountingService
  GetARCustomerAccountByCodePattern = 'AccountingService/ARCustomer/searchByPattern/{pattern}',
  GetViewMemberAccountActivity = 'AccountingService/ARBatch/{arCode}/{fromDate}/{toDate}',
  GetARCustomerAccounts = 'AccountingService/ARCustomer/ARAccounts',
  GetARAccountByFilter = 'AccountingService/ARCustomer/GetAccountsByFilter?searchText={searchText}&searchBy={searchBy}&accountCategorySearchType={accountCategorySearchType}',
  GetAllARTrasactionCode = 'AccountingService/ARTransactionCode?includeInactive={includeInactive}',
  GetAlldocumentIdsForScheduler = 'AccountingService/ARInvoices/GetAllDocumentIdsByScheduler/{schedulerId}/{jobHistoryId}',

  GetUserScreenPreferenceOfGridConfiguration='UserScreenPreference/{id}/{screenTableKey}',
  UpdateUserScreenPreferenceOfGridConfiguration='UserScreenPreference',
  GetAllInstalledPrinters = 'V1PrintManager/PrinterDetails/GetAllInstalledPrinters',
  Print = 'V1PrintManager/PrinterDetails/Print',
  PrinterManagerHeartBeat = 'V1PrintManager/HeartBeat',
  GetPrinterManagerVersion = 'V1PrintManager/HeartBeat/GetProductVersion',
  IsImageSupportAvailable = 'V1PrintManager/HeartBeat/IsImageSupportAvailable',

  GetMachinePrinterConfiguration = "MachinePrinterConfiguration",
  GetMachinePrinterConfigurationByMachineId = "MachinePrinterConfiguration/{machineId}",
  CreateMachinePrinterConfiguration = "MachinePrinterConfiguration",

  GetDataMagineConfig = 'V1InternalIntegrationService/DataMagine/GetDmConfig',
  CreateDataMagineConfig = 'V1InternalIntegrationService/DataMagine/CreateDmConfig',
  UpdateDataMagineConfig = 'V1InternalIntegrationService/DataMagine/UpdateDmConfig',
  GetDmDocTypesMapByDmCodeId = 'V1InternalIntegrationService/DataMagine/GetDmDocTypesMapByDmCodeId/{id}',
  UpdateDmDocTypesMap = 'V1InternalIntegrationService/DataMagine/UpdateDmDocTypesMap',
  GetSigCaptureByTranId = 'V1InternalIntegrationService/DataMagine/GetSigCaptureByTranId?TransactionId={TransactionId}',

  GetEformConfig = 'V1InternalIntegrationService/Eform/GetEformConfig',
  CreateEformConfig = 'V1InternalIntegrationService/Eform/CreateEformConfig',
  UpdateEformConfig = 'V1InternalIntegrationService/Eform/UpdateEformConfig',

   GetAllDigitalInvoices = 'V1IntegrationService/DigitalInvoice?includeInactive={includeInactive}',
   GetDigitalInvoice = 'V1IntegrationService/DigitalInvoice/{id}',
   DeleteDigitalInvoice = 'V1IntegrationService/DigitalInvoice/{id}',
   CreateDigitalInvoice = 'V1IntegrationService/DigitalInvoice',
   UpdateDigitalInvoice = 'V1IntegrationService/DigitalInvoice',
   GetNextListOrder_DigitalInvoice = 'V1IntegrationService/DigitalInvoice/NextListOrder',

   //Catalogue Setup
   //CompTemplateSetup
   GetAllCatalogueSetups = 'V1IntegrationService/CatalogueSetup?includeInactive={includeInactive}',
   GetAllCatalogueSetupsBySystemId = 'V1IntegrationService/CatalogueSetup/GetCataloguesBySystemId/{systemId}?includeInactive={includeInactive}',
   GetCatalogueSetup = 'V1IntegrationService/CatalogueSetup/{id}',
   ImportCatalogue = 'V1IntegrationService/CatalogueSetup/importData',
   DeleteCatalogueSetup = 'V1IntegrationService/CatalogueSetup/{id}',
   CreateCatalogueSetup = 'V1IntegrationService/CatalogueSetup',
   UpdateCatalogueSetup = 'V1IntegrationService/CatalogueSetup',

   GetAllDigitalInvoiceTemplateMapping = 'V1IntegrationService/DigitalInvoiceTemplateMapping',
   GetAllDigitalInvoiceTemplateMappingBySystemId = 'V1IntegrationService/DigitalInvoiceTemplateMapping/GetTemplateMappingBySystemId/{systemId}/{templateId}',
   GetTemplateAssignmentsBySystemId = 'V1IntegrationService/DigitalInvoiceTemplateMapping/GetTemplateAssignmentsBySystemId/{systemId}/{templateId}',
   GetDigitalInvoiceTemplateMapping = 'V1IntegrationService/DigitalInvoiceTemplateMapping/{id}',
   DeleteDigitalInvoiceTemplateMapping = 'V1IntegrationService/DigitalInvoiceTemplateMapping/{id}',
   DeleteAllDigitalInvoiceTemplateMapping = 'V1IntegrationService/DigitalInvoiceTemplateMapping/Mappings/{id}',
   DeleteAllDigitalInvoiceTemplateAssignments = 'V1IntegrationService/DigitalInvoiceTemplateMapping/Assignments/{id}',
   CreateDigitalInvoiceTemplateMapping = 'V1IntegrationService/DigitalInvoiceTemplateMapping',
   UpdateDigitalInvoiceTemplateMapping = 'V1IntegrationService/DigitalInvoiceTemplateMapping',

   GetAllCatalogueTypes = 'V1IntegrationService/CatalogueType?includeInactive={includeInactive}',
   GetCatalogueType = 'V1IntegrationService/CatalogueType/{id}',
   DeleteCatalogueType = 'V1IntegrationService/CatalogueType/{id}',
   CreateCatalogueType = 'V1IntegrationService/CatalogueType',
   UpdateCatalogueType = 'V1IntegrationService/CatalogueType',
   GetNextListOrder_CatalogueType = 'V1IntegrationService/CatalogueType/NextListOrder',

   GetAllDigitalInvoiceTemplates = 'V1IntegrationService/DigitalInvoiceTemplate?includeInactive={includeInactive}',
   GetDigitalInvoiceTemplate = 'V1IntegrationService/DigitalInvoiceTemplate/{id}',
   DeleteDigitalInvoiceTemplate = 'V1IntegrationService/DigitalInvoiceTemplate/{id}',
   CreateDigitalInvoiceTemplate = 'V1IntegrationService/DigitalInvoiceTemplate',
   UpdateDigitalInvoiceTemplate = 'V1IntegrationService/DigitalInvoiceTemplate',
   GetNextListOrder_DigitalInvoiceTemplate = 'V1IntegrationService/DigitalInvoiceTemplate/NextListOrder',

   //DigitalInvoice Interface Monitor
  GetAllDigitalInvoiceLogBySystem = "V1IntegrationService/DigitalInvoiceLog/Code/{destinationSystem}?withPayload={withPayload}",
  GetAllDigitalInvoiceLogByDestinationSystem = "V1IntegrationService/DigitalInvoiceLog/destinationSystem/{destinationSystem}",
  RetryDigitalInvoiceUpload = "V1IntegrationService/DigitalInvoiceLog/RetryFailedMessage/{correlationId}",
  GetPartnerDetailsForPartnerSystemDownload = "V1IntegrationService/DigitalInvoiceLog/{digitalInvoiceCode}/{startDate}/{endDate}",
  GetAllDigitalInvoiceLogByDateRange = "V1IntegrationService/DigitalInvoiceLog/DateRange?digitalInvoiceCode={digitalInvoiceCode}&startDate={startDate}&endDate={endDate}&withPayload={withPayload}",
  GetAllDigitalInvoiceLogByCount = "V1IntegrationService/DigitalInvoiceLog/{digitalInvoiceCode}/{count}?withPayload={withPayload}",
  GetDigitalInvoiceLogById = "V1IntegrationService/DigitalInvoiceLog/{id}",

  GetAllCategories = "Categories",
  GetAllPostType = 'FolioService/PostTypes?includeInactive={includeInactive}&includeRelatedData={includeRelatedData}',
  //DmDocTypes
  GetAllDmDocTypes = 'V1InternalIntegrationService/DataMagine/DmDocTypes?includeInactive={includeInactive}',
  GetDmDocTypes = 'V1InternalIntegrationService/DataMagine/DmDocTypes/{id}',
  DeleteDmDocTypes = 'V1InternalIntegrationService/DataMagine/DmDocTypes/{id}',
  CreateDmDocTypes = 'V1InternalIntegrationService/DataMagine/DmDocTypes',
  UpdateDmDocTypes = 'V1InternalIntegrationService/DataMagine/DmDocTypes',
  GetNextListOrder_DmDocTypes = 'V1InternalIntegrationService/DataMagine/DmDocTypes/nextlistorder',

  //DmCodes
  GetAllDmCodes = 'V1InternalIntegrationService/DataMagine/DmCodes?includeInactive={includeInactive}',
  GetDmCodes = 'V1InternalIntegrationService/DataMagine/DmCodes/{id}',
  DeleteDmCodes = 'V1InternalIntegrationService/DataMagine/DmCodes/{id}',
  CreateDmCodes = 'V1InternalIntegrationService/DataMagine/DmCodes',
  UpdateDmCodes = 'V1InternalIntegrationService/DataMagine/DmCodes',
  GetNextListOrder_DmCodes = 'V1InternalIntegrationService/DataMagine/DmCodes/nextlistorder',

  //DmSettings
  GetAllDmSettings = 'V1InternalIntegrationService/DataMagine/DataMagineSettings',
  GetDmSettingByScreenId = 'V1InternalIntegrationService/DataMagine/DataMagineSettingByScreenId?ScreenId={ScreenId}',
  UpdateDataMagineSettings = 'V1InternalIntegrationService/DataMagine/UpdateDataMagineSettings',

  //DmEforms
  GetEformsTemplate = 'ReservationService/DMIntegration?eventid={eventid}&preferredLanguageId={preferredLanguageId}',
  SendEformsEmail = 'ReservationService/DMIntegration',

  //CGPS
  GetGuestInformationByPlatformGuestId = 'ReservationService/GuestInformation/platformGuestGuid/{platformGuestGuid}',

  // SamsotechIntegration Settings
  UpdateSettingsInternalIntegration = 'V1InternalIntegrationService/Setting',
  GetSettingByModuleScreenInternalIntegration = 'V1InternalIntegrationService/Setting/{module}/{screen}',

  //Guest Export Details
  GetGuestExportDetailsHtml = 'rest_v2/reports/public/CommonReports/GuestExport/GuestExport.html?GuestId={GuestId}',
  GetGuestExportDetailsPdf = 'rest_v2/reports/public/CommonReports/GuestExport/GuestExport.pdf?GuestId={GuestId}',

  //Query Builder
  GetCustomQueryGroups = 'ReportingService/CustomQuery/GetAllCustomQueryGroups',

  // Adhoc View Reports
  GetAlladhocReportsPath = 'ReportingService/JasperScheduler/GetAdhocViewReportDeatils',

  //Diary
  CreateDiary = 'Diary/CreateDiary',
  UpdateDiary = 'Diary/UpdateDiary',
  SearchDiary = 'Diary/SearchDiary',
  GetDiaryCount = 'Diary/GetCount?startDate={startDate}&endDate={endDate}',
  GetDiaryEventByDate = 'Diary/GetDiaryEventByDate/{date}',
  SearchDiaryTag = 'Diary/SearchTag/{name}',

  //FileUpload
  SaveDocuments = 'ReportingService/Report/UploadFiles',
  GetSaveDocuments = 'ReportingService/Report/GetSavedDocuments',

  //DmEforms
  // GetEformsTemplate = 'ReservationService/DMIntegration?eventid={eventid}',
  // SendEformsEmail = 'ReservationService/DMIntegration',
  CheckConnection = '/dmsidp/api/dms/idp/login',
  CheckSNCConnection = '/dmsidp/api/dms/idp/integrationlogin',
  GetAllViews = '/dmssystem/api/dms/system/views',
  GetIndexesByViewName = '/dmssystem/api/dms/system/indexes/indexbyview/{viewName}',
  GetViewByViewName = '/dmssystem/api/dms/system/views/{viewName}',
  GetDmFormDetails = 'V1InternalIntegrationService/DataMagine/GetDmFormDetails',
  GetDocInformation = 'V1InternalIntegrationService/DataMagine/GetDocInformation',
  CreateDocInformation = 'V1InternalIntegrationService/DataMagine/CreateDocInformation',
  DeleteDocInformation = 'V1InternalIntegrationService/DataMagine/DeleteDocInformation',
  IndexingUrl = "/dmsdoc/api/dms/indexingv2",
  SearchDmDoc = "/dmsdoc/api/dms",
  ExportDmDocs = "/dmsdoc/api/dms/documentoperations/export?ids={ids}&IncludeAnnotations=false&ExportInOriginalFormat={exportInOriginalFormat}&ZipFile=false&CombineDocuments=false&ConvertToTiff=false",
  DeleteDmDoc = "/dmsdoc/api/dms/folders/{folderId}/docs/{documentId}",
  SigCaptureCallBack = "/V1InternalIntegrationService/DataMagine/SigCaptureCallBack",
  DMLogin = "/dmsidp/api/dms/idp/login",
  GetSubmittedDoc = "/dmsdoc/api/dms/documentoperations/folders/{folderId}/documents/{documentId}",
  DataMagineAuthLogin = "/api/dms/idp/login",
  GetEformList = "/api/dms/system/eforms/clienteforms?TenantId={0}&PropertyId={1}",
  GetEformLink = "/api/dms/system/eforms/clienteformurl",
  SearchEformDoc = "/api/dms",
  ExportEformDocs = "/api/dms/documentoperations/export?ids={ids}&IncludeAnnotations=false&ExportInOriginalFormat={exportInOriginalFormat}&ZipFile=false&CombineDocuments=false&ConvertToTiff=false",
  GetAllEformViews = '/api/dms/system/views',
  GetEformViewByViewName = '/api/dms/system/views/{viewName}',
  GetEformIndexesByViewName = '/api/dms/system/indexes/indexbyview/{viewName}',

  //Condo Service
  GetAllEFTTransactionUsedBankIds = 'V1CondoService/EFTPayment/GetAllUsedEFTTransBankIds',

  // Staff Reader Board
  CreateStaffReaderBoard = "StaffReaderBoard/CreateStaffReaderBoard",
  UpdateStaffReaderBoard = "StaffReaderBoard/UpdateStaffReaderBoard",
  DeleteStaffReaderBoard = "StaffReaderBoard/DeleteStaffReaderBoard/{id}",
  GetAllStaffReaderBoardList = "StaffReaderBoard/GetAllStaffReaderBoardList/{userId}",
  FilterAllStaffReaderBoard = "StaffReaderBoard/FilterAllStaffReaderBoard",
  GetAllStaffReaderBoardDetails = "StaffReaderBoard/GetAllStaffReaderBoardDetails/{id}",
  GetStaffReaderBoardById = "StaffReaderBoard/GetStaffReaderBoardById/{id}",
  UpdateStaffReaderBoardDetailsIsViewed = "StaffReaderBoard/UpdateStaffReaderBoardDetailsIsViewed/{id}",
  UpdateStaffReaderBoardDetailsIsAcknowledged = "StaffReaderBoard/UpdateStaffReaderBoardDetailsIsAcknowledged/{id}",
  UpdateMultipleStaffReaderBoardDetailsIsAcknowledged = "StaffReaderBoard/UpdateMultipleStaffReaderBoardDetailsIsAcknowledged",
  UpdateStaffReaderBoardDetailsIsSnoozed = "StaffReaderBoard/UpdateStaffReaderBoardDetailsIsSnoozed/{id}/{isSnoozed}/{interval}",
  GetAllNotes = "StaffReaderBoard/GetAllNotes/{userId}",
  FilterAllNotes = "StaffReaderBoard/FilterAllNotes",
  GetRecentNotes = "StaffReaderBoard/GetRecentNotes/{userId}/{isShowNoteAsPopup}",
  GetRecentNotesCount = "StaffReaderBoard/GetRecentNotesCount/{userId}/{isShowNoteAsPopup}",

  //Users and Roles
  GetActiveUserRolesByPropertyId = 'UserRole/GetActiveUserRolesByPropertyId/{propertyId}/{includeInActive}',
  GetAllUserbyPropertyId = 'User/GetAllUserbyPropertyId/{propertyId}/{productId}',

  //Send Email with attachment
  SendEmailWithAttachment = 'ReservationService/Letters/EmailWithAttachment',

  UpdateGuestContactDetails = 'ReservationService/GuestInformation/updateGuestContactDetails',
  GetStayContactInfo = 'ReservationService/Reservation/stayContactInfo/{stayId}',
  GetAllUsers = 'User/GetAllUserbyTenantId/{tenantId}?inActive={inActive}',
  GetGuestInformationById = 'ReservationService/GuestInformation/{id}',
  //GDPR
  //Data Retention Policy
  GetDataRetentionPolicies = 'Policy/GetDataRetentionPolicies?tenantId={tenantId}&platformTenantId={platformTenantId}',
  GetDataRetentionPolicyConfiguredFlag = 'Policy/GetDataRetentionPolicyConfiguredFlag/{tenantId}',
  GetDataGroups = 'Policy/GetDataGroups/{tenantId}/{policyId}/{categoryId}',
  GetConsentPoliciesForProduct = 'Policy/GetConsentPoliciesForProduct/{tenantId}/{productId}',
  GetConsentPoliciesForProductUsingCategoryId = 'Policy/GetConsentPoliciesUsingCategoryId/{tenantId}/{productId}/{categoryId}',
  UpdateConsentPolicyDetailsForGuestId = 'GuestPolicy/UpdateConsentPolicyDetailsForGuestId',
  ApplyDataPolicyPurgeForGuestId = 'GuestPolicy/ApplyDataPolicyPurgeForGuestId',
  GetPolicyTypeUsingPolicyId = 'Policy/GetPolicyTypeUsingPolicyId/{policyId}',
  UpdateConsentPolicyDetailsByGuestIdList = 'GuestPolicy/UpdateListConsentPolicyDetailForGuest',
  GetPolicyDetailsUsingPolicyId = 'Policy/GetPolicyDetailsUsingPolicyId/{policyId}',

  //EATEC
  EatecToken = 'Login/Token/Eatec',
  AcesToken = 'Login/Token/Aces',
  AuthorizeBySession = 'User/AuthorizeBySession',

  //AuthorizeIntegration
  GetAuthorizeConfiguration = 'V1InternalIntegrationService/AuthorizeConfiguration/GetAuthorizeConfig',
  GetAuthorizeConfig = 'V1InternalIntegrationService/AuthorizeConfiguration/GetAuthorizeConfig',
  CreateAuthorizeConfiguration = 'V1InternalIntegrationService/AuthorizeConfiguration/CreateOrUpdateAuthorizeConfiguration',
  CreateAuthorizeConfig = 'V1InternalIntegrationService/AuthorizeConfiguration/CreateOrUpdateAuthorizeConfiguration?isSetupScreen={isSetupScreen}',
  DeleteAuthorizeConfig = 'V1InternalIntegrationService/AuthorizeConfiguration/DeleteAuthorizeConfig',
  GetAddonIntegrationConfiguration = "AddonsIntegrationService/PMSIntegrationConfiguration",
  UpdateAddonIntegrationConfiguration = "AddonsIntegrationService/PMSIntegrationConfiguration",


  // Common
  GetAllTitles = 'Common/GetAllTitles/{tenantId}/{includeInActive}',
  GetAllNationality = 'Nationality/GetAllNationality/{tenantId}/{includeInactive}',
  GetCountries = 'Nationality/GetCountries',
  CheckPMSCommunication = 'AddonsIntegrationService/PMSIntegrationConfiguration/Check',
  GetAllPMSIntegrationHostConfigurations = '/PMSIntegrationConfiguration',
  SavePMSIntegrationHostConfiguration = '/PMSIntegrationConfiguration',
  GetExtenalFieldMapping = '/PMSIntegrationConfiguration/ExternalField?',

  //JasperReportServer
  GetReportExecutionIds = 'rest_v2/reportExecutions',
  GetReportByExecutionIds = 'rest_v2/reportExecutions/{requestId}/exports/{exportId}/outputResource',
  JaspersoftReportExecutionAPI = 'ReportingService/JaspersoftReportExecutionAPI',
  JaspersoftFolioReportExecutionAPI = 'ReportingService/FolioService/JaspersoftReportExecutionAPI',
  SetJaspersoftReportOptions = "ReportingService/JaspersoftReportExecutionAPI/SetJaspersoftReportOptions",


  //GenericExtracts
  GetAllExtract = 'ReportingService/CustomQuery/GetAllExtracts',
  GetAllExtractLog = 'ReportingService/CustomQuery/GetAllExtractLog',
  GetExtractLogByFilter = 'ReportingService/CustomQuery/GetExtractLogByFilter',
  UpdateCustomExtracts = 'ReportingService/CustomQuery',
  FetchCustomExtracts = 'ReportingService/FolioService/CustomQuery/FetchExtractData/{downloadDate}',

  //QueryBuilder
  EQEndpoint = 'ReportingService/easyquery',
  FetchEasyQuery = 'ReportingService/easyquery/models/{queryModel}/fetch',
  UpdateExtractedData = 'ReportingService/CustomQuery/UpdateExtractedData',

  //SFTP Cpnfg
  GetSFTPConfigLink = "NotificationService/GetSFTPSetup",
  GetSFTPConfigLinkById = "NotificationService/GetSFTPSetupById/{id}",


  //CustomFeeConfiguration
  GetCustomFeeConfiguration = 'RetailManagementService/CustomFeeConfiguration',
  UpdateCustomFeeConfiguration = 'RetailManagementService/CustomFeeConfiguration',
  CreateCustomFeeConfiguration = 'RetailManagementService/CustomFeeConfiguration',
  DeleteCustomFeeConfiguration = 'RetailManagementService/CustomFeeConfiguration?id={id}',
  GetCustomFeeConfigByFilters = 'RetailManagementService/CustomFeeConfiguration/GetCustomFeeConfigByFilters',
  GetCurrentCustomFeeConfig = 'RetailManagementService/CustomFeeConfiguration/GetCustomFeeForCurrentDTM',

}
