import { UntypedFormGroup } from "@angular/forms";
import { DialogOverviewExampleDialog } from "../dialog-popup/dialogPopup-componenet";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Injectable, OnDestroy } from "@angular/core";
import { ButtonType, Product } from "../globalsContant";
import { MoreFilterOptions, PropertyReceiptModel, SplitValue } from "../../retail.modals";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { Router } from "@angular/router";
import { MoreSectionServiceService } from '../more-section/more-section-service.service';
import { Subscription, ReplaySubject } from "rxjs";
import { AlertMessagePopupComponent } from "../alert-message-popup/alert-message-popup.component";
import { AlertType, AlertAction, PhoneTypes, SelectedProducts, RetailItemType } from "../shared.modal";
import { AlertPopupComponent } from "../alert-popup/alert-popup.component";
import { NotUploadedPopupComponent } from '../not-uploaded-popup/not-uploaded-popup.component';
import { UserSessionConfiguration } from 'src/app/common/shared/retail.modals';
import { JSONReaderService } from 'src/app/common/shared/services/load-json.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { HttpServiceCall } from 'src/app/common/shared/shared/service/http-call.service';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { FormatText } from 'src/app/common/shared/shared/pipes/formatText-pipe.pipe';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { DeleteDependencyAlertComponent } from "src/app/common/components/delete-dependency-alert/delete-dependency-alert.component";
import { AlertType as CommonAlertType, ButtonType as CommonButtonType } from "src/app/common/enums/shared-enums";
import { Location } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { takeUntil } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PaymentErrorCodes, RGuestPayErrorCodes } from "../business/shared.modals";
import { ItemType } from "../../shop/shop.modals";
import { GroupRetailItems } from "../service/common-variables.service";
export enum RedirectToModules {
    none,
    retail,
    order,
    appointment,
    settings,
    Utilities,
    exchange,
    Dayend,
    home,
    TeeTime,
    TeeSheet,
    TeeTimeGraphicalView,
    TeeTimeSearch,
    ReturnWithTicket,
    RetailTransactions,
    Spawizard,
    BackToPrevLocation,
    CreateReservation,
    EditReservation,
    RevenuePostingsLog,
    Classes,
    Client
}


export enum SNCRoute{
    Booking='/booking/bookingsearch/edit'
  }

  export enum SNCFromPage{
    beoPost = 'beoPost'
  }

export interface PatchJson {
    op: PatchOperation;
    path: string;
    value: any;
}

export interface AppointmentColors {
    BackGround: string;
    BackGroundLight: string;
    Color: string;
    ColorLight: string;
    Border: string;
}

export enum PatchOperation {
    add = "add",
    remove = "remove",
    replace = "replace",
    copy = "copy",
    move = "move",
    test = "test"
}

export enum RecurringType {
    Daily = 0,
    Weekly = 1,
    Monthly = 2,
    Yearly = 3
}

export enum AppointmentColorConfiguration {
    "CKIN" = "APPOINMENT_CHECKEDIN_COLOR",
    "RESV" = "APPOINMENT_SCHEDULED_COLOR",
    "NOSHOW" = "APPOINMENT_NOSHOW_COLOR",
    "CKOUT" = "APPOINMENT_CHECKEDOUT_COLOR",
    "CANC" = "APPOINMENT_CANCEL_COLOR",
    "WAIT" = "APPOINMENT_WAIT_COLOR",
    "OPEN" = "APPOINMENT_OPEN_COLOR",
    "CLOSED" = "APPOINMENT_CLOSED_COLOR",
    "TEMP" = "APPOINMENT_TEMP_COLOR",
    "BREAK" = "APPOINMENT_BREAK_COLOR",
    "SETUP" = "APPOINMENT_SETUP_COLOR",
    "BREAKDOWN" = "APPOINMENT_BREAKDOWN_COLOR",
    "ONCALL" = "APPOINMENT_ONCALL_COLOR"
}

export enum InputTypeNumbers {
    NUMBERS = "onlynumber",
    ONLYPOSITIVE = "nonnegative",
    ONLYNEGATIVE = "onlynegative",
    NODECIMAL = "nodecimal",
    DECIMAL = "decimal",
    ROUNDOFF = "roundoff2",
    PERCENT = "validPercentage",
    POSITIVEDECIMAL = "onlyPositiveDecimal",
    POSITIVEDECIMALORNUMERIC = 'PositiveDecimalOrNumeric',
    NUMBERWITHSEPARATOR = "numberWithSeparator"
}

export enum InputTypeText {
    CAP = "capitalise",
    TEXT = "textonly",
    NOSPL = "nospecailchar",
    NOSPACE = "notallowspace",
    EMAIL = "email",
    NOPRESPACE = "noprespace",
    RESTRICTSCRIPT = "restrictscript"
}


export interface CssProp {
    property: string;
    value: string;
}


export function stringFormat(input: string, appendBy?: string) {
    if (appendBy) {
        const tempTrue = (input == "" ? "" : input + appendBy);
        return input ? tempTrue : "";
    } else {
        const tempFalse = (input == "" ? "" : input);
        return input ? tempFalse : "";
    }
}



export function convertPhoneNoToUiNo(num: string): string {
    if (num != null || num == "") {
        return (
            num.substring(3, 0) +
            " - " +
            num.substring(6, 3) +
            " - " +
            num.substring(num.length, 6)
        );
    } else {
        return "";
    }
}

export function tConvert(tt) {
    var time = tt.substring(tt.indexOf("T") + 1, tt.length);
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
        time
    ];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
}

export function addMinutesToGivenTime(time, minTobeAdded) {
    let dummyDate: Date = this.getDate("2019-01-01T" + time);
    let dateTimeValue = dummyDate.setMinutes(
        dummyDate.getMinutes() + minTobeAdded
    );
    let dateTimeWithAddedMinutes = this.getDate(dateTimeValue);

    let hours = dateTimeWithAddedMinutes.getHours();
    let min = dateTimeWithAddedMinutes.getMinutes();

    return (hours < 9 ? "0" + hours.toString() : hours.toString()) + ":" + (min < 9 ? "0" + min.toString() : min.toString());
}

@Injectable({ providedIn: 'root' })
export class RetailUtilities extends CommonUtilities implements OnDestroy {
    subscription: Subscription;
    destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    countryDetails: any;
    constructor(public localization: RetailLocalization, public dialog: MatDialog,
        public http: HttpServiceCall,
        public route: Router, private commonUtils: QuickLoginUtilities, public snackBar: MatSnackBar,
        public _MoreSectionServiceService: MoreSectionServiceService, public PropertyInfo: CommonPropertyInformation,
        public formatphno: FormatText, public jsonTextReader: JSONReaderService, private location: Location, private httpClient: HttpClient) {
        super(localization, dialog, http, route,
            _MoreSectionServiceService, PropertyInfo, formatphno, snackBar);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
    filterRetailRentalItems(products: SelectedProducts[], from?: ItemType, groupList: GroupRetailItems[] = []){
        if ((from == ItemType.PMSAddOnsRentalItem || from == ItemType.retailItem) && groupList) {
          products = groupList.flatMap(x => x.retailItems);
          const itemType = from == ItemType.PMSAddOnsRentalItem ? RetailItemType.PMSAddOnsRentalItem : RetailItemType.RetailItemRetailPOSOnly;
          products = products.filter(x => x.ItemType == itemType);
        }
        return products;
      }

    getEditedData(formGroup: UntypedFormGroup, idKey?: string, defaultKeys?: string[], dateKeys?: string[]): any {
        let patchJson = [];
        let keyValue: string;
        let keys: string[] = Object.keys(formGroup.controls);

        defaultKeys = defaultKeys ? defaultKeys : [];
        let fnconvertDateObjToAPIdate = this.localization.convertDateObjToAPIdate;
        keys.forEach(function (key) {
            if (idKey == key) keyValue = formGroup.controls[key].value;

            if (formGroup.controls[key].dirty || defaultKeys.includes(key)) {
                var val = formGroup.controls[key].value;
                if (dateKeys && dateKeys.includes(key)) {
                    val = typeof val != "string" ? fnconvertDateObjToAPIdate(val) : val;
                }
                patchJson.push({
                    op: PatchOperation.replace,
                    path: "/" + key,
                    value: val
                });
            }
        });
        return {
            key: keyValue,
            patchJson: patchJson
        };
    }

    GetFormattedDateDDMMYYYY(dt: Date) {
        return this.localization.GetFormattedDateDDMMYYYY(dt);
    }

    ToggleLoader(loader, loaderMessage?) {
        const loadingContainer = document.getElementById('custom-cover-spin');
        const loadingContainerMessage = document.getElementById('custom-cover-message');
        if (loadingContainer) {
            if (loader) {
                loadingContainer.style.display = 'block';
            } else {
                loadingContainer.style.display = 'none';
            }
            if (loaderMessage) {
                loadingContainerMessage.innerText = loaderMessage;
            } else {
                loadingContainerMessage.innerText = '';
            }
        }
    }
    signatureLoader(loader, loaderMessage?) {
        const loadingContainer = document.getElementById('signature-loader');
        const loadingContainerMessage = document.getElementById('signature-cover-message');
        if (loadingContainer) {
            if (loader) {
                loadingContainer.style.display = 'block';
            } else {
                loadingContainer.style.display = 'none';
            }
            if (loaderMessage) {
                loadingContainerMessage.innerText = loaderMessage;
            } else {
                loadingContainerMessage.innerText = '';
            }
        }
    }


    GetAppointmentColors(status: string): AppointmentColors {
        let AppointmentColors: AppointmentColors = {
            BackGround: "",
            BackGroundLight: "",
            Border: "",
            Color: "",
            ColorLight: ""
        };
        if (status == "CKIN") {
            AppointmentColors.BackGround = "checkinBGclr";
            AppointmentColors.BackGroundLight = "checkinBGclrLight";
            AppointmentColors.Color = "checkedinTextclr";
            AppointmentColors.ColorLight = "checkedinTextclrLight";
            AppointmentColors.Border = "checkinBDRclr";
        }
        else if (status == "CKOUT") {
            AppointmentColors.BackGround = "checkoutBGclr";
            AppointmentColors.BackGroundLight = "checkoutBGclrLight";
            AppointmentColors.Color = "checkedoutTextclr";
            AppointmentColors.ColorLight = "checkedoutTextclrLight";
            AppointmentColors.Border = "checkoutBDRclr";
        }
        else if (status == "CLOSED" || status == "TEMP" || status == "BREAK") {
            AppointmentColors.BackGround = "closeBGclr";
            AppointmentColors.BackGroundLight = "closeBGclrLight";
            AppointmentColors.Color = "closeTXTclrLight";
            AppointmentColors.ColorLight = "closeTXTclrLight";
            AppointmentColors.Border = "closeBDRclr";
        }
        else if (status == "NOSHOW") {
            AppointmentColors.BackGround = "nsBGclr";
            AppointmentColors.BackGroundLight = "nsBGclrLight";
            AppointmentColors.Color = "noshowTextclr";
            AppointmentColors.ColorLight = "noshowTextclrLight";
            AppointmentColors.Border = "nsBDRclr";

        }
        else if (status == "RESV") {
            AppointmentColors.BackGround = "sBGclr";
            AppointmentColors.BackGroundLight = "sBGclrLight";
            AppointmentColors.Color = "scheduledTextclr";
            AppointmentColors.ColorLight = "scheduledTextclrLight";
            AppointmentColors.Border = "sBDRclr";
        }
        return AppointmentColors;
    }

    public appendFormat(value: string | number, format: string): string {
        if (!value && value == "") {
            return "";
        }
        let userInput = value.toString();
        let returnVal: string = "";
        let indexOfUserValue: number = 0;
        if (format == "") {
            return value.toString();
        }
        userInput = userInput.indexOf('|') == -1 ? userInput : userInput.split('|')[1];
        userInput = (userInput.length > 1 && userInput[0] === '0' && format[1] === '0' ? userInput.replace(/[0]/, '') : userInput);
        for (let i = 0; i < format.length; i++) {
            const char = format[i];
            let charCode = char.toString().charCodeAt(0);
            const IsFormatNumber: boolean = (charCode === 57);
            if (!IsFormatNumber) {
                returnVal = returnVal + format[i];
            } else {
                if (userInput[indexOfUserValue]) {
                    returnVal = returnVal + userInput[indexOfUserValue];
                    indexOfUserValue++;
                } else {
                    break;
                }
            }
        }
        return returnVal;
    }

    RedirectTo(redirectToModules: RedirectToModules, fromCancellation: boolean = false): void {
        let url: string = '';
        let redirectByUrl = false;
        switch (redirectToModules) {
            case RedirectToModules.retail:
                url = '/shop/viewshop'
                break;
            case RedirectToModules.order:
                url = '/shop/viewshop/order'
                break
            case RedirectToModules.appointment:
                url = '/appointment/newappointment'
                break;
            case RedirectToModules.settings:
                url = '/setting'
                break;
            case RedirectToModules.exchange:
                url = '/shop/exchange'
                break;
            case RedirectToModules.Dayend:
                url = '/audit/dayend';
                break;
            case RedirectToModules.home:
                url = '/home/dashboard'
                break;
            case RedirectToModules.TeeTime:
                redirectByUrl = true;
                url = fromCancellation ? 'tee-time/teesheet/teeSheet?loadLastVisitedSlot=true&fromCancellation=true' : 'tee-time/teesheet/teeSheet?loadLastVisitedSlot=true';
                break;
            case RedirectToModules.TeeTimeGraphicalView:
                url = 'tee-time/teesheet/graphicalView';
                break;
            case RedirectToModules.TeeTimeSearch:
                url = 'tee-time/search';
                break;
            case RedirectToModules.RetailTransactions:
                url = 'shop/viewshop/retailtransactions';
                break;
            case RedirectToModules.ReturnWithTicket:
                url = 'shop/viewshop/retailtransactions/returnwithticket';
                break;
            case RedirectToModules.Spawizard:
                url = '/appointment/spa-wizard';
                break;
            case RedirectToModules.TeeSheet:
                redirectByUrl = true;
                url = 'tee-time/teesheet/teeSheet';
                break;
            case RedirectToModules.Classes:
                url ='/class/bookclass';
                break;
            case RedirectToModules.Client:
                url = '/client/allclients'
                break;
            default:
                break;
        }
        this.commonUtils.resetQuickIdDetails();

        if (redirectToModules == RedirectToModules.BackToPrevLocation) {
            let prevUrl = this.GetPreviousURL();
            if (prevUrl) {
                let urlTree = this.route.parseUrl(prevUrl);
                urlTree.queryParams['initialilzed'] = 'true';
                urlTree.queryParams['isFromShop'] = 'true';
                this.route.navigateByUrl(urlTree);
                return;
            } else {
                if(Number(this.GetPropertyInfo('ProductId')) === Product.PMS){
                    this.backTrack();
                } else {
                    this.location.back();
                }
                return;
            }
        }

        if (redirectByUrl) {
            this.route.navigateByUrl(url);
        }
        else {
            this.route.navigate([url]);
        }
    }

    backTrack(){
        let sessionFromURLArray = sessionStorage.getItem('fromURLPath');
        let fromURLPathArray = sessionFromURLArray? JSON.parse(sessionFromURLArray): [];
        if(fromURLPathArray.length > 0){
            this.route.navigateByUrl(fromURLPathArray[fromURLPathArray.length - 1], {
                state: { fromBack: true }
            });
            fromURLPathArray.pop();
            sessionStorage.setItem('fromURLPath', JSON.stringify(fromURLPathArray));
        }
    }

    public getOutletFilterByObject(DataArr, SelectedArr, gv, column) {
        let selectedIds: number[] = SelectedArr.map(x => x[column]);
        if (selectedIds.indexOf(gv[column]) == -1) {
            SelectedArr.push(gv);
            if (SelectedArr.length == DataArr.length - 1 && this.isExist(DataArr, { [column]: 0 }) != -1) {
                SelectedArr = [];
                DataArr.forEach(result => { SelectedArr.push(result) });
            }
        }
        else {
            SelectedArr.splice(selectedIds.indexOf(gv[column]), 1);
            if (SelectedArr.length == DataArr.length - 1) {
                if (SelectedArr.findIndex(x => x == 0) != -1)
                    SelectedArr.splice(SelectedArr.findIndex(x => x[column] == 0), 1);
            }
            else {
                if (SelectedArr.findIndex(x => x[column] == 0) != -1) {
                    SelectedArr.splice(SelectedArr.findIndex(x => x[column] == 0), 1)
                }
            }
        }

        return SelectedArr;
    }

    setFilterPopUp(ShowMoreFilters: boolean, moreData: any, selectedMoreData: any, topPos: number, leftPos: number, belongto: string, fromPage: string) {
        this._MoreSectionServiceService.filterOptions = {
            'ShowMoreFilters': ShowMoreFilters,
            'moreData': moreData,
            'selectedMoreData': selectedMoreData,
            'top': topPos,
            'left': leftPos,
            'belongto': belongto,
            'fromPage': fromPage
        }
    }

    getFilterPopUpOption(ShowMoreFilters: boolean, moreData: any, selectedMoreData: any, topPos: number, leftPos: number, belongto: string, fromPage: string): MoreFilterOptions {
        return {
            'ShowMoreFilters': ShowMoreFilters,
            'moreData': moreData,
            'selectedMoreData': selectedMoreData,
            'top': topPos,
            'left': leftPos,
            'belongto': belongto,
            'fromPage': fromPage
        }
    }

    roomOpenDialog(popupData: any, callback?: (x: any) => void): MatDialogRef<any, any> {
        const data = {
            headername: popupData.headertext,
            headerIcon: popupData.icon,
            headerMessage: popupData.text,
            buttonName: popupData.buttonname,
            type: 'message',
            isloaderenable: popupData.isloaderenable,
            isHiddenFieldRequired: popupData.isHiddenFieldRequired,
            cardpayment: popupData.cardpayment,
            isManualEntry: popupData.isManualEntry,
            showProgress: popupData.showProgress,
            progressPercent: popupData.progressPercent,
            progressMsg: popupData.progressMsg
        };
        const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
            width: '350px',
            hasBackdrop: true,
            panelClass: 'small-popup',
            data: data,
            disableClose: true
        });
        const subscription = dialogRef.afterClosed().subscribe((x) => {
            if (callback) {
                callback(x);
            }
            if (subscription) {
                subscription.unsubscribe();
            }
        });
        return dialogRef;
    }

    public ShowErrorMessage(title: string, message: any, popButtonType: ButtonType = ButtonType.Ok, callback?: (result: string, extraParams?: any[]) => void, extraParams?: any[] | any, closebool = true) {
        this.hideOverlays();
        let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
            height: 'auto',
            width: '40%',
            data: { headername: title, closebool: closebool, templatename: 'In the Given Data', datarecord: message, buttonType: popButtonType },
            panelClass: 'small-popup',
            disableClose: true,
            hasBackdrop: true,
            backdropClass: 'Overlay-Alerts'
        });
        this.subscription = dialogRef.afterClosed().subscribe(res => {
            if (callback)
                callback(res, extraParams);
        });
        return dialogRef;
    }
    /**
   * rGuest-Golf Alert popup to show 'Warning' , 'Error' , 'Success'
   * @param {string} message  - Content
   * @param {AlertType} type  - Type of Popup
   * @param {ButtonType} [btnType=ButtonType.Ok] - Button Actions Type( by default 'Ok')
   * @param {(result: string, extraParams?: any[]) => void} [callback] - CallBack ( optional )
   * @returns - Dialog Reference of the modal with Result of enum AlertAction
   * @memberof Utilities
   */
    public showUploadAlert(headerText: string, message: string, type: AlertType,
        callback?: (result: AlertAction, extraParams?: any[]) => void, extraParams?: any[]): MatDialogRef<NotUploadedPopupComponent, AlertAction> {

        const dialogRef = this.dialog.open(NotUploadedPopupComponent, {
            height: '80%',
            width: '95%',
            data: { type: type, message: message, header: headerText, closebool: true, extraParams: extraParams },
            panelClass: 'small-popup',
            disableClose: true,
            hasBackdrop: true
        });
        this.subscription = dialogRef.afterClosed().subscribe(res => {
            if (callback)
                callback(res, extraParams);
        });
        return dialogRef;
    }



    /**
   * rGuest-retail Alert popup to show 'Warning' , 'Error' , 'Success'
   * @param {string} message  - Content
   * @param {AlertType} type  - Type of Popup
   * @param {ButtonType} [btnType=ButtonType.Ok] - Button Actions Type( by default 'Ok')
   * @param {(result: string, extraParams?: any[]) => void} [callback] - CallBack ( optional )
   * @returns - Dialog Reference of the modal with Result of enum AlertAction
   * @memberof Utilities
   */
    showAlert(message: string, type: AlertType | CommonAlertType, btnType: (ButtonType | CommonButtonType) = ButtonType.Ok,
        callback?: (result: AlertAction, extraParams?: any[]) => void, extraParams?: any[], headerText?: string): MatDialogRef<AlertPopupComponent, AlertAction> {

        const dialogRef = this.dialog.open(AlertPopupComponent, {
            height: 'auto',
            width: '300px',
            data: { type: type, message: message, buttontype: btnType, header: headerText },
            panelClass: 'small-popup',
            disableClose: true,
            hasBackdrop: true,
            backdropClass: 'Overlay-Alerts'
    });
        this.subscription = dialogRef.afterClosed().subscribe(res => {
            if (callback)
                callback(res, extraParams);
        });
        return dialogRef;
    }

    public showDependencyAlert(lable: string, message: string[], subHeader?: string, buttonType?: ButtonType,
        callback?: (result: AlertAction, extraParams?: any[], additionalInfo?: { message: string, class: string }) => void) {

        const dialogRef = this.dialog.open(DeleteDependencyAlertComponent, {
            height: 'auto',
            width: '500px',
            data: { lable, message, subHeader, buttonType },


            panelClass: 'small-popup',
            disableClose: true,
        });
        this.subscription = dialogRef.afterClosed().subscribe(res => {
            if (callback) {
                callback(res);
            }
        });
        return dialogRef;
    }

    /**
     * Shows rGuest-Golf Error Popup
     * @param {string} message content
     * @memberof Utilities
     */
    public showError(message: string): void {
        this.showAlert(message, AlertType.Error, ButtonType.Ok);
    }

    /**
     * @description Opens a warning popup to alert the user that the changes were not saved
     * @returns MatDialogRef
     */
    openSaveChangedWarningPopup(popupMsg?: string): MatDialogRef<AlertMessagePopupComponent, AlertAction> {
        return this.dialog.open(AlertMessagePopupComponent, {
            width: '400px',
            height: '300px',
            hasBackdrop: true,
            panelClass: 'small-popup',
            data: {
                headername: this.localization.captions.common.Warning, headerIcon: 'icon-warning-icon', headerMessage: popupMsg ? popupMsg : this.localization.captions.common.saveChangesMessage, buttonName: this.localization.captions.common.Yes, noButton: true, noButtonName: this.localization.captions.common.No, type: 'message'
            },
            disableClose: true,
        });
    }


    public getLoggedinUserSessionConfiguration(): UserSessionConfiguration {
        let userSessionConfigKey: string = 'userSessionConfigInfo'
        if (sessionStorage.getItem(userSessionConfigKey) != null) {
            return {
                id: Number(this.localization.GetsessionStorageValue(userSessionConfigKey, 'Id')),
                userId: Number(this.localization.GetsessionStorageValue(userSessionConfigKey, 'UserId')),
                defaultOutletId: Number(this.localization.GetsessionStorageValue(userSessionConfigKey, 'DefaultOutletId')),
                defaultCourseId: Number(this.localization.GetsessionStorageValue(userSessionConfigKey, 'DefaultCourseId')),
                defaultPaymentDevice: this.localization.GetsessionStorageValue(userSessionConfigKey, 'DefaultPaymentDevice'),
                defaultDeviceName: this.localization.GetsessionStorageValue(userSessionConfigKey, 'DefaultDeviceName'),
                defaultTerminalId: Number(this.localization.GetsessionStorageValue(userSessionConfigKey, 'DefaultTerminalId')),
                isIdtechSred: Boolean(this.localization.GetsessionStorageValue(userSessionConfigKey, 'IsIdtechSred')),
                smallStickersPrinter: this.localization.GetsessionStorageValue(userSessionConfigKey, 'SmallStickersPrinter'),
                hangingTicketsPrinter: this.localization.GetsessionStorageValue(userSessionConfigKey, 'HangingTicketsPrinter'),
                propertyId: Number(this.localization.GetsessionStorageValue(userSessionConfigKey, 'PropertyId')),
                defaultPaymentId: Number(this.localization.GetsessionStorageValue(userSessionConfigKey, 'defaultPaymentId'))
            }
        }
        return null;
    }

    public getUserDefaults(): UserSessionConfiguration {
        let userSessionConfig = sessionStorage.getItem('userDefaults');
        if (userSessionConfig != null) {
            const config = JSON.parse(userSessionConfig);
            return {
                id: Number(config.id),
                userId: Number(config.userId),
                defaultOutletId: Number(config.defaultOutletId),
                defaultCourseId: Number(config.defaultCourseId),
                defaultPaymentDevice: config.defaultPaymentDevice,
                defaultDeviceName: config.defaultDeviceName,
                defaultTerminalId: Number(config.defaultTerminalId),
                isIdtechSred: Boolean(config.isIdtechSred),
                smallStickersPrinter: config.smallStickersPrinter,
                hangingTicketsPrinter: config.hangingTicketsPrinter,
                propertyId: Number(config.propertyId),
                defaultPaymentId: Number(config.defaultPaymentId)
            }
        }
        return null;
    }

    getFullYear(date: string) {
        if (date) {
            let dt = this.getDate(date);
            return dt.getFullYear();
        }
        return null;
    }
    getNumberString(input: string): string {
        '67:3442|(09)(3443)(234234-23423)'
        return input.replace(/[^0-9]/g, '');
    }
    public validateString(input: string) {
        if (input != "null" && input != null && input != undefined && input != '') {
            return true;
        }
        return false;
    }

    buildPhoneNumber(phoneType: number, extension: string, phoneNumber: string, countrycode?: string | number): string {
        phoneNumber = this.validateString(phoneNumber) ? phoneNumber : '';
        if (countrycode) {
            countrycode = countrycode.toString();
            return (phoneType == PhoneTypes.office) && extension ? extension + ":" + this.getNumberString(countrycode) + "|" + this.getNumberString(phoneNumber) :
                this.getNumberString(countrycode) + "|" + this.getNumberString(phoneNumber);
        } else {
            return (phoneType == PhoneTypes.office) && extension ? extension + ":" + this.getNumberString(phoneNumber) : this.getNumberString(phoneNumber);
        }
    }
    public phoneNumberFormatWithExtension(value: string) {
        const tempValue = (value.indexOf(':') != -1) ? this.phonenumberWithExtn(value) : this.phonenumberWithOutExtn(value);
        return value ? tempValue : '';
    }
    public phonenumberWithExtn(value) {
        return this.concateCCodePhone(value.split(':')[1]) + " ext:" + value.split(':')[0].toString();
    }
    public phonenumberWithOutExtn(value) {
        return this.concateCCodePhone(value);
    }
    public concateCCodePhone(value) {
        let ccode, pno;
        if (value.indexOf('|') == -1) {
            ccode = '';
            pno = value;
        } else {
            let splitnumbers = value.split('|');
            ccode = splitnumbers[0] + ' - ';
            pno = splitnumbers[1];
        }
        const cmnPhoneFormat = this.getPhoneFormat();
        let phoneFormat = pno.length > 10 ? '(999) 999-9999-99' : cmnPhoneFormat;
        return ccode + this.appendFormat(pno, phoneFormat).toString()
    }

    getCountryCodeFromValue(phoneType: number, phoneNumber: string): string {
        if (phoneNumber) {
            let result: string = '';
            if (phoneNumber.indexOf('|') == -1) {
                result = "00";
            } else {
                result = ((phoneType === PhoneTypes.office && phoneNumber.split(':')[1]) ? this.appendFormat(phoneNumber.split(':')[1].split('|')[0], '')
                    : this.appendFormat(phoneNumber.split('|')[0], '')).toString();
            }
            return result;
        }
        return "";
    }
    getExtensionFromValue(phoneType: number, phoneNumber: string): string {
        const tempPhoneType = phoneNumber.split(':')[1] ? phoneNumber.split(':')[0] : "";
        return phoneType === PhoneTypes.office ? tempPhoneType : "";
    }

    getPhoneNumberFromValue(phoneType: number, phoneNumber: string): string {
        if (phoneNumber) {
            const cmnPhoneFormat = this.getPhoneFormat();
            let phoneFormat = phoneNumber.length > 10 ? '(999) 999-9999-99' : cmnPhoneFormat;
            let result: string = '';
            if (phoneNumber.indexOf('|') == -1) {
                var phone = (phoneType === PhoneTypes.office && phoneNumber.split(':')[1]) ? phoneNumber.split(':')[1] : phoneNumber;
                phoneFormat = phone.length > 10 ? '(999) 999-9999-99' : cmnPhoneFormat;
                result = ((phoneType === PhoneTypes.office && phoneNumber.split(':')[1]) ? this.appendFormat(phoneNumber.split(':')[1], phoneFormat)
                    : this.appendFormat(phoneNumber, phoneFormat)).toString();
            } else {
                var phone = (phoneType === PhoneTypes.office && phoneNumber.split(':')[1]) ? phoneNumber.split(':')[1].split('|')[1] : phoneNumber.split('|')[1];
                phoneFormat = phone.length > 10 ? '(999) 999-9999-99' : cmnPhoneFormat;
                result = ((phoneType === PhoneTypes.office && phoneNumber.split(':')[1]) ?
                    this.appendFormat(phoneNumber.split(':')[1].split('|')[1], phoneFormat)
                    : this.appendFormat(phoneNumber.split('|')[1], phoneFormat)).toString();
            }
            return result;
        }
        return ''
    }

    disableControls(formGroup: UntypedFormGroup, excludedControls?: string[]) {
        excludedControls = excludedControls ? excludedControls : [];
        const allControls: string[] = Object.keys(formGroup.controls);
        for (let i = 0; i < allControls.length; i++) {
            let _control: string = allControls[i];
            if (!excludedControls.includes(_control)) {
                formGroup.controls[_control].disable({ emitEvent: false });
            }
        }

    }

    public GetEnablemachineTransaction() {
        return sessionStorage.getItem('enableMachineTransaction');
    }

    GetSplitup(actualValue: number, numberOfItems: number): SplitValue {
        let result: SplitValue = { equalSplit: 0, upScaleValue: 0 };
        if (actualValue > 0 && numberOfItems > 0) {
            result.equalSplit = Number((actualValue / numberOfItems).customToFixed());
            if (Number((result.equalSplit * numberOfItems).customToFixed()) < actualValue) {
                result.upScaleValue = result.equalSplit + (actualValue - Number((result.equalSplit * numberOfItems).customToFixed()));
            }
            else {
                result.upScaleValue = result.equalSplit;
            }
        }
        return result;
    }

    geCountriesJSON() {
        let countryDetails = this.httpClient.get(`assets/i18n/Countries/${this.localization.localeCode}.Countries.json`);
        countryDetails.pipe(takeUntil(this.destroyed$)).subscribe((data: any) => {
            if (data) {
                this.countryDetails = data.Countries;
            }
        },
        error => {
            if (error.status === 404) {
            countryDetails = this.httpClient.get(`assets/i18n/Countries/en-US.Countries.json`);
            countryDetails.pipe(takeUntil(this.destroyed$)).subscribe((data: any) => {
                if (data) {
                    this.countryDetails = data.Countries;
                }
            });
        }
        });
        return Promise.resolve(this.countryDetails);
    }

    FilterCountryValueFromData(country) {
        let countryValue = '';
        if (country && this.FilterCountryByName(country, this.countryDetails).length) {
            countryValue = country;
        } else {
            countryValue = '';
        }
        return countryValue;
    }

    /**
   * search filter
   * @param searchText
   */
  SearchFilter(searchText: string, searchKey: string[], searcData: any[]): any {
    const headerarray = searchKey;
    if (headerarray && headerarray.length > 0 && searcData) {
      return searcData.filter((result) => {
        const headerKey = headerarray;
        for (const key in result) {
          if (
            typeof result[key] == "string" &&
            result[key].toLowerCase().includes(searchText.toLowerCase())
          ) {
            if (headerKey.indexOf(key) != -1) {
              return result[key]
                .toLowerCase()
                .includes(searchText.toLowerCase());
            }
          } else if (typeof result[key] == "number") {
            if (headerKey.indexOf(key) != -1) {
              const matchedValue = Number(
                result[key]
                  .toString()
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              );
              if (matchedValue) {
                return matchedValue;
              }
            }
          }
        }
      });
    }
  }

    RoundOff(value: number, decimals: number = 2) {
        return Number(value.customToFixed())
    }

    GetOriginalTenderId(tenderId: number, parentTenderId: number) : number{
        return parentTenderId > 0 ? parentTenderId : tenderId;
    }
    
    PaymentErrorPrompt(errcodes, errorMsg = "") {
        if (!(errcodes in PaymentErrorCodes) && errorMsg) {
            return this.ShowErrorMessage(this.localization.captions.common.Error, errorMsg);
        }
        switch (parseInt(errcodes))
        {
            case PaymentErrorCodes.ClientInvalidRequest:
                return this.ShowErrorMessage(this.localization.captions.common.Error, errorMsg ? errorMsg : this.localization.captions.shop.PMInvalidRequest);
            case PaymentErrorCodes.DeviceNotAvailable:
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.PMDeviceNotAvailable);
            case PaymentErrorCodes.DeviceNotReady:
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.PMDeviceBusy);
            case PaymentErrorCodes.DeviceUserPressedCancel:
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.PMDeviceCancel);
            case PaymentErrorCodes.TransactionTimeOut:
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.PMTransactionTimeOut);
            case PaymentErrorCodes.DeviceError:
            case PaymentErrorCodes.CardErrorInvalidAccountNumber:
            case PaymentErrorCodes.CardErrorInvalidExpirationDate:
            case PaymentErrorCodes.CardError:
            case PaymentErrorCodes.CardErrorUnsupportedCardType:
            case PaymentErrorCodes.CardErrorUnsupportedCardIssuer:
            case PaymentErrorCodes.CardDeclinedExpired:    
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(10721));
            case PaymentErrorCodes.DeviceOperationAborted:
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.PMDeviceTimeOut);
            case PaymentErrorCodes.CardDeclinedLimit:
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.InsufficientFunds);
            case PaymentErrorCodes.GatewayAuthenticationFailed:
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.PMAuthFailed);
            case PaymentErrorCodes.CardDeclinedChipDecline:
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.PMcardDeclinedChipDecline);
            case PaymentErrorCodes.PaymentManagerTimedOut:
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.PMAgentUnavailable);
            case PaymentErrorCodes.CardNumberNotValid:
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(70609));
            case PaymentErrorCodes.CardAlreadyIssued:
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.GiftCard.CardAlreadyIssued);

            case PaymentErrorCodes.PayAgentDissabledInPaymentManager:
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.GiftCard.PayAgentDissabledInPaymentManager);
            case PaymentErrorCodes.PayAgentNotFoundInPaymentManager:
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.GiftCard.PayAgentNotFoundInPaymentManager);
            case PaymentErrorCodes.PayAgentNotActivatedInPaymentManager:
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.GiftCard.PayAgentNotActivatedInPaymentManager);

            case PaymentErrorCodes.CardNumberNotActive:
            case PaymentErrorCodes.CardPINInvalid:
            case PaymentErrorCodes.CardAlreadyActive:
            case PaymentErrorCodes.CardAlreadyInActive:
            case PaymentErrorCodes.CardExpired:
                const errorMsgMap = {
                    [PaymentErrorCodes.CardNumberNotActive]: 70611,
                    [PaymentErrorCodes.CardPINInvalid]: 70612,
                    [PaymentErrorCodes.CardAlreadyActive]: 70613,
                    [PaymentErrorCodes.CardAlreadyInActive]: 70614,
                    [PaymentErrorCodes.CardExpired]: 70615
                }
                return this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(errorMsgMap[parseInt(errcodes)]));
            case PaymentErrorCodes.DeviceInvalidData:
            default:
                const promptMsg = errorMsg ? errorMsg : this.checkIfErrorCodeExistsInRemainingSet(parseInt(errcodes));
                return this.ShowErrorMessage(this.localization.captions.common.Error, promptMsg);
        }
    }

    checkIfErrorCodeExistsInRemainingSet(errorCode)
    {        
        if(Object.values(RGuestPayErrorCodes).filter(x => !isNaN(Number(x))).find(x => x === errorCode) && Number(this.GetPropertyInfo('ProductId')) === Product.PMS)
        {
            return this.localization.getError(errorCode)
        }
        else
        {
            return this.localization.captions.shop.PMUnexpectedError;
        }                
    }

    ValidateNumber(value) {
        const OnlyNumRegex = /^[0-9]+$/;
        return OnlyNumRegex.test(String(value).toLowerCase());
    }

    parsePropertyReceiptConfig(propertyReceiptConfig: PropertyReceiptModel): PropertyReceiptModel {
        propertyReceiptConfig.configValue = this.isStringified(propertyReceiptConfig.configValue) ? JSON.parse(propertyReceiptConfig.configValue) : propertyReceiptConfig.configValue;
        propertyReceiptConfig.defaultValue = this.isStringified(propertyReceiptConfig.defaultValue) ? JSON.parse(propertyReceiptConfig.defaultValue) : propertyReceiptConfig.defaultValue;
        return propertyReceiptConfig;
    }
    
    isStringified(value: any): boolean {
        if (typeof value !== 'string') {
            return false;
        }
        try {
            JSON.parse(value);
            return true;
        } catch {
            return false;
        }
    }
}
